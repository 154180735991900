<template>
  <div>
    <!-- Modal Add Image and Download PDF -->
    <div class="modal fade" id="modalAddImageAndDownloadPdf" tabindex="-1" role="dialog" aria-labelledby="modalAddImageAndDownloadPdfLabel" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="modalAddImageAndDownloadPdfLabel">Télécharger une Présentation PDF</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <v-text-field
            v-model="ChargeExploitation"
            label="OPEX: Charge d'exploitation"
            outlined
            ></v-text-field>

            <v-text-field
            v-model="PrimeInvestissement"
            label="Prime d'investissement"
            outlined
            ></v-text-field>

            <label 
            for="addImageAddress" 
            class="drop-container" 
            id="dropcontainer"
            @dragover.prevent="handleDragOver" 
            @dragenter="handleDragEnter" 
            @dragleave="handleDragLeave"
            @drop.prevent="handleDrop"
            >
              <span class="drop-title">Déposez Votre image ici</span>
              ou
              <p class="add-title">Ajouter une image</p>
              <input 
              ref="addImageAddress"
              id="addImageAddress" 
              class="inputImageAddress" 
              type="file" 
              @change="handleImageAddressClientChange" 
              accept="image/*" 
              style="display: none"
              />
            </label>

            <div v-if="addressImageToShow" class="image-preview-wrapper">
              <img :src="addressImageToShow" />
            </div>

            <v-btn
              class="button-download-pdf"
              :color="$colors[1]"
              @click="previewPdf()"
            >
              <span class="white--text">preview</span>
            </v-btn>
            <v-btn
              class="button-download-pdf"
              :color="$colors[0]"
              @click="generatePdf()"
            >
              <v-icon class="white--text">mdi-download-outline</v-icon>
            </v-btn>
          </div>
        </div>
      </div>
    </div>
    <!-- button toggle modal image input -->
    <v-btn
    class="ml-4"
    data-toggle="modal" data-target="#modalAddImageAndDownloadPdf"
    :color="$colors[0]"
    >
      <v-icon class="white--text">mdi-download-outline</v-icon>
    </v-btn>
    <!-- pdf content to render inside pdf (chart) -->
    <div id="pdf-content">
      <div class="chart-wrapper">
        <apexchart 
        type="line" 
        height="300px" 
        :options="consoProdChartOptions" 
        :series="consoProdChartSeries">
        </apexchart>
      </div>

      <div class="chart-wrapper">
        <apexchart 
        type="bar" 
        height="300px" 
        :options="gainsChartOptions" 
        :series="gainsChartSeries">
        </apexchart>
      </div>

      <div class="chart-wrapper">
        <apexchart 
        type="bar" 
        height="300px" 
        :options="amortissementChartOptions" 
        :series="amortissementChartSeries">
        </apexchart>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { PDFDocument, rgb } from "pdf-lib";
import VueApexCharts from "vue-apexcharts";
export default {
  props: {
    pdfData: Object,
    compteur: String,
  },
  components: {
    apexchart: VueApexCharts,
  },
  data() {
    return {
      // modal image
      addressImageToShow: null,

      // page De Garde
      pageDeGardeTitle: '',
      RespName: '',
      RespFonction: ``,
      RespEmail: '',
      RespTel: '',
      ClientName: '',
      ClientSociete: '',
      ClientTel: '',
      // page 4
      PuissanceProjetKwc: '',
      ProductionAttendueKWh: '',
      NombreDePannaux: "",
      // page 5
      address: "",
      addressImage: null,
      // page 8
      CoutTotalInstallation: "",
      RevenusExploitationMoyenAnnuelAttendu: "",
      ChargeExploitation: "",
      TempsDeRetour: "",
      // page 9
      BudgetPhotovoltaique: "",
      PrimeInvestissement: "",
      GainAnnuelMoyen: "",
      totalEconomie: "",
      totalVenteSurplus: "",
      totalGains: "",
      tableAmortissement: [],

      // charts
      consoProdChartSeries: [
        {
          name: 'Votre consommation réalisée',
          type: 'column',
          data: [0,0,0,0,0,0,0,0,0,0,0,0]
        }, {
          name: 'Production solaire',
          type: 'area',
          data: [0,0,0,0,0,0,0,0,0,0,0,0]
        }
      ],
      consoProdChartOptions:{
        chart: {
          id: 'chartSyntheseConso',
          height: 350,
          type: 'line',
          stacked: false,
          zoom: {
            enabled: false
          },
          toolbar: {
            show: false,
          },
          election: {
            enabled: false,
          }
        },
        colors: ['#14DAF5', '#00f7bd'],
        stroke: {
          width: [0, 2, 5],
          curve: 'smooth'
        },
        plotOptions: {
          bar: {
            columnWidth: '50%'
          }
        },
        
        fill: {
          opacity: [0.85, 0.25, 1],
          gradient: {
            inverseColors: false,
            shade: 'light',
            type: "vertical",
            opacityFrom: 0.85,
            opacityTo: 0.55,
            stops: [0, 100, 100, 100]
          }
        },
        markers: {
          size: 0
        },
        xaxis: {
          type: 'category',
          categories: ['janv.', 'fév.', 'mars', 'avr', 'mai', 'juin', 'juill.', 'aout', 'sept.', 'oct.', 'nov.', 'déc.'],

        },
        yaxis: {
          labels: {
            formatter: function (value) {
              return value.toFixed(0) + " kWh";
            }
          }
        },
        dataLabels: {
          enabled: false,
        },
      },
      gainsChartSeries: [{
        name: 'ECONOMIES SUR FACTURES',
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0]
      }, {
        name: 'VENTE DE SURPLUS',
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0]
      }],
      gainsChartOptions: {
        chart: {
          id: 'gainsChart',
          type: 'bar',
          height: 350,
          zoom: {
            enabled: false
          },
          toolbar: {
            show: false,
          },
          election: {
            enabled: false,
          }
        },
        colors: ['#00f7bd', '#14DAF5'],
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '55%',
            endingShape: 'rounded'
          },
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent']
        },
        xaxis: {
          type: "numeric",
          labels: {
            formatter: function (value) {
              return value.toFixed(0);
            }
          }
        },
        yaxis: {
          labels: {
            formatter: function (value) {
              return value.toFixed(2) + " €";
            }
          }
        },
        fill: {
          opacity: 1
        },
      },
      amortissementChartSeries: [{
        name: 'Amortissement',
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0]
      }],
      amortissementChartOptions: {
        chart: {
          id: 'amortissementChart',
          type: 'bar',
          height: 350,
          zoom: {
            enabled: false
          },
          toolbar: {
            show: false,
          },
          election: {
            enabled: false,
          }
        },
        colors: ['#14DAF5', '#00f7bd'],
        plotOptions: {
          bar: {
            colors: {
              ranges: [{
                from: -100,
                to: -46,
              }, {
                from: -45,
                to: 0,
              }]
            },
            columnWidth: '80%',
          }
        },
        dataLabels: {
          enabled: false,
        },
        yaxis: {
          labels: {
            formatter: function (value) {
              return value.toFixed(2);
            }
          }
        },
        xaxis: {
          type: "numeric",
          labels: {
            formatter: function (value) {
              return value.toFixed(0);
            }
          }
        }
      },
    };
  },
  created() {
    this.fillPropsData();
  },
  watch: {
    'pdfData': {
      deep: true,
      handler(newVal, oldVal) {
        this.fillPropsData();
      },
    },
  },
  methods: {
    fillPropsData() {
      // Use the created lifecycle hook to fill the child component's state
      this.pageDeGardeTitle = this.pdfData.pageDeGardeTitle;
      this.RespName = this.pdfData.RespName;
      this.RespFonction = this.pdfData.RespFonction;
      this.RespEmail = this.pdfData.RespEmail;
      this.RespTel = this.pdfData.RespTel;
      this.ClientName = this.pdfData.ClientName;
      this.ClientSociete = this.pdfData.ClientSociete;
      this.ClientTel = this.pdfData.ClientTel;
      // page 4
      this.PuissanceProjetKwc = this.pdfData.PuissanceProjetKwc;
      this.ProductionAttendueKWh = this.pdfData.ProductionAttendueKWh;
      this.NombreDePannaux = this.pdfData.NombreDePannaux;
      // page 5
      this.address = this.pdfData.address;
      // this.addressImage = this.pdfData.addressImage;
      // page 8
      this.CoutTotalInstallation = this.pdfData.CoutTotalInstallation;
      this.RevenusExploitationMoyenAnnuelAttendu = this.pdfData.RevenusExploitationMoyenAnnuelAttendu;
      // this.ChargeExploitation = this.pdfData.ChargeExploitation;
      this.TempsDeRetour = this.pdfData.TempsDeRetour;
      // page 9
      this.BudgetPhotovoltaique = this.pdfData.BudgetPhotovoltaique;
      // this.PrimeInvestissement = this.pdfData.PrimeInvestissement;
      this.GainAnnuelMoyen = this.pdfData.GainAnnuelMoyen;
      this.totalEconomie = this.pdfData.totalEconomie;
      this.totalVenteSurplus = this.pdfData.totalVenteSurplus;
      this.totalGains = this.pdfData.totalGains;
      this.tableAmortissement = this.pdfData.tableAmortissement;

      // Fill chart data
      if (this.pdfData?.consoProdChartSeries) {
        this.consoProdChartSeries = this.pdfData.consoProdChartSeries;
      }

      if (this.pdfData?.gainsChartSeries) {
        this.gainsChartSeries = this.pdfData.gainsChartSeries;
      }

      if (this.pdfData?.amortissementChartSeries) {
        this.amortissementChartSeries = this.pdfData.amortissementChartSeries;
      }
    },

    handleDragOver(event) {
      event.preventDefault();
    },
    handleDragEnter(event) {
      // Add a class to indicate dragging
      event.currentTarget.classList.add("drag-active");
    },
    handleDragLeave(event) {
      // Remove the class when dragging leaves the container
      event.currentTarget.classList.remove("drag-active");
    },
    handleDrop(event) {
      event.preventDefault();

      // Handle dropped files
      event.currentTarget.classList.remove("drag-active");

      const fileInput = this.$refs.addImageAddress;
      fileInput.files = event.dataTransfer.files;

      // Call your existing method to handle the change
      this.handleImageAddressClientChange();
    },
    handleImageAddressClientChange() {
      const file = this.$refs.addImageAddress.files[0];

      if (file) {
        // Ensure that the selected file is an image
        if (file.type.startsWith('image/')) {
          // set the image for pdf
          this.addressImage = file;
          // Convert the selected image to a data URL
          const reader = new FileReader();
          reader.onload = () => {
            this.addressImageToShow = reader.result;
          };
          reader.readAsDataURL(file);
        } else {
          // Display an error message or handle the invalid file type accordingly
          alert('Invalid file type. Please select an image.');
        }
      }
    },

    
    to_fixed2LocaleString(num) {
      return num!= null && num>=0 ? Number(parseFloat(num).toFixed(2)).toLocaleString("fr-FR").replace(/\u202F/g, ' ').toString() : "";
    },
    async convertJpegToPng(jpegBytes) {
      return new Promise((resolve, reject) => {
        const image = new Image();

        image.onload = () => {
          const canvas = document.createElement("canvas");
          const context = canvas.getContext("2d");
          canvas.width = image.width;
          canvas.height = image.height;

          // Draw the JPEG image onto the canvas
          context.drawImage(image, 0, 0, image.width, image.height);

          // Get the PNG bytes from the canvas
          canvas.toBlob(
            (blob) => {
              const reader = new FileReader();
              reader.onloadend = () => {
                resolve(new Uint8Array(reader.result));
              };
              reader.readAsArrayBuffer(blob);
            },
            "image/png",
            1 // Quality (1 is the highest)
          );
        };

        // Set the JPEG bytes as the source for the image
        const blob = new Blob([jpegBytes], { type: "image/jpeg" });
        image.src = URL.createObjectURL(blob);

        image.onerror = (error) => {
          reject(error);
        };
      });
    },
    async convertApexCartToImage(chartId) {
      const chartInstance = window.Apex._chartInstances.find(chart => chart.id === chartId);
      const base64 = await chartInstance.chart.dataURI();
      return base64.imgURI;
    },
    // view pdf as html
    async previewPdf() {
      try {
        // *********** presentation content *********** 
        let pdfData = {
          // page de Garde
          vendeur: {
            prenom: "",
            nom: "",
            fonction: "",
            email: "",
            telephone: "",
          },
          societe: {
            prenom: "",
            nom: "",
            raison: "",
            siret: "",
          },

          puissanceKwc: 0,
          productionAttendueKwh: 0,
          nombrePanneaux: 0,

          // page: Synthèse technique de mon projet (Address image)
          address: null,
          codePostale: null,

          // page: Synthèse de consommation de mon projet (1 Charts)
          seriesConsoProd: [
            {
              name: 'Votre consommation réalisée',
              type: 'column',
              data: [0,0,0,0,0,0,0,0,0,0,0,0]
            }, {
              name: 'Production solaire',
              type: 'area',
              data: [0,0,0,0,0,0,0,0,0,0,0,0]
            }
          ],

          // page: Synthèse de financière de mon projet (2 Charts)
          seriesGains: [{
            name: 'ECONOMIES SUR FACTURES',
            data: [0, 0, 0, 0, 0, 0, 0, 0, 0]
          }, {
            name: 'VENTE DE SURPLUS',
            data: [0, 0, 0, 0, 0, 0, 0, 0, 0]
          }],
          seriesAmortissement: [{
            name: 'Amortissement',
            data: [0, 0, 0, 0, 0, 0, 0, 0, 0]
          }],

          // page: Coût de mon projet
          coutTotalInstallation: 0,
          revenusExploitation: 0,
          chargesExploitation: 0,
          tempsDeRetour: null,

          // page: Synthèse financière de mon projet
          gainAnnuelMoyen: 0,
          primeInvestissement: 0,
          budgetPhotovoltaique: 0,
          totalEconomie: 0,
          totalSurplus: 0,
          totalGain: 0,
          tableauAmourtisement: [],
        };
        // fill presentation content
        pdfData.vendeur = {
          prenom: this.pdfData.RespNom,
          nom: this.pdfData.RespPrenom,
          fonction: this.pdfData.RespFonction,
          email: this.pdfData.RespEmail,
          telephone: this.pdfData.RespTel,
        };
        pdfData.societe = {
          prenom: this.pdfData.ClientNom,
          nom: this.pdfData.ClientPrenom,
          raison:  this.pdfData.ClientSociete,
          siret: this.pdfData.ClientTel,
        };
        // page 4
        pdfData.puissanceKwc = this.pdfData.PuissanceProjetKwc;
        pdfData.productionAttendueKwh = this.pdfData.ProductionAttendueKWh;
        pdfData.nombrePanneaux = this.pdfData.NombreDePannaux;
        // page 5
        pdfData.address = this.pdfData.address;
        pdfData.codePostale = this.pdfData.codePostale;
        // page 8
        pdfData.coutTotalInstallation = this.pdfData.CoutTotalInstallation;
        pdfData.revenusExploitation = this.pdfData.RevenusExploitationMoyenAnnuelAttendu;
        pdfData.chargesExploitation = this.ChargeExploitation; // from input
        pdfData.tempsDeRetour = this.pdfData.TempsDeRetour;
        // page 9
        // pdfData.budgetPhotovoltaique = this.pdfData.BudgetPhotovoltaique;
        pdfData.budgetPhotovoltaique = this.pdfData.CoutTotalInstallation; // change it later
        pdfData.primeInvestissement = this.PrimeInvestissement; // from input
        pdfData.gainAnnuelMoyen = this.pdfData.GainAnnuelMoyen;
        pdfData.totalEconomie = this.pdfData.totalEconomie;
        pdfData.totalSurplus = this.pdfData.totalVenteSurplus;
        pdfData.totalGain = this.pdfData.totalGains;
        pdfData.tableauAmourtisement = this.pdfData.tableAmortissement;

        // Fill chart data
        if (this.pdfData?.consoProdChartSeries) {
          pdfData.seriesConsoProd = this.pdfData.consoProdChartSeries;
        }
        if (this.pdfData?.gainsChartSeries) {
          pdfData.seriesGains = this.pdfData.gainsChartSeries;
        }
        if (this.pdfData?.amortissementChartSeries) {
          pdfData.seriesAmortissement = this.pdfData.amortissementChartSeries;
        }

        const formData = new FormData();
        formData.append("num_compteur", this.compteur);
        formData.append("content", JSON.stringify(pdfData));
        formData.append("address_image", this.addressImage); // from input

        const res = await axios.post(`${process.env.VUE_APP_URL_API_CLIENT}generateIndvPreviewToken/`, formData);
        if (res?.status == 200 && res?.data) {
          window.open(`/solar-indv-presentation/${res?.data.token}`, '_blank', 'noreferrer');
        }
      } catch (error) {
        console.log(error)
      }
    },
    // pdf generation
    async generatePdf() {
      console.log('generating pdf...');

      const pageDeGardeDoc = await this.pageDeGardeFiller();
      const page2Doc = await this.page2Filler();
      const page3Doc = await this.page3Filler();
      const page4Doc = await this.page4Filler();
      const page5Doc = await this.page5Filler();
      const page6Doc = await this.page6Filler();
      const page7Doc = await this.page7Filler();
      const page8Doc = await this.page8Filler();
      const page9Doc = await this.page9Filler();
      const pageFinaleDoc = await this.pageFinaleFiller();

      console.log('loaded and filled all pdf forms...');

      let mergedPdf = await PDFDocument.create()
      mergedPdf = await this.addPagesToPdf(mergedPdf, pageDeGardeDoc);
      mergedPdf = await this.addPagesToPdf(mergedPdf, page2Doc);
      mergedPdf = await this.addPagesToPdf(mergedPdf, page3Doc);
      mergedPdf = await this.addPagesToPdf(mergedPdf, page4Doc);
      mergedPdf = await this.addPagesToPdf(mergedPdf, page5Doc);
      mergedPdf = await this.addPagesToPdf(mergedPdf, page6Doc);
      mergedPdf = await this.addPagesToPdf(mergedPdf, page7Doc);
      mergedPdf = await this.addPagesToPdf(mergedPdf, page8Doc);
      mergedPdf = await this.addPagesToPdf(mergedPdf, page9Doc);
      mergedPdf = await this.addPagesToPdf(mergedPdf, pageFinaleDoc);

      console.log('marged all pdf...');

      // save pdf
      const filledPdfBytes = await mergedPdf.save();
      console.log('Downloading the filled PDF...');
      // Download the filled PDF
      const blob = new Blob([filledPdfBytes], { type: "application/pdf" });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = "Présentation.pdf";
      link.click();
    },
    // to merge pdf pages
    addPagesToPdf: async function (mergedPdf, donorPdf) {
      const copiedPages = await mergedPdf.copyPages(
        donorPdf,
        donorPdf.getPageIndices()
      )
      copiedPages.forEach((page) => {
        mergedPdf.addPage(page)
      })
      return mergedPdf;
    },
    // Page: page de garde
    async pageDeGardeFiller(){
      // Load the PDF from the project folder
      const pdfPath = "/pdf/Eneffic/EnefficAnnexe-1.pdf";
      const response = await fetch(pdfPath);
      const arrayBuffer = await response.arrayBuffer();
      const pdfDoc = await PDFDocument.load(arrayBuffer);

      // Get the form containing all the fields
      const form = pdfDoc.getForm();
      const fields = form.getFields()

      // Get all fields in the PDF by their names
      const pageDeGardeTitle = form.getTextField('PageDeGarde-title')
      const RespName = form.getTextField('PageDeGarde-RespName')
      const RespFonction = form.getTextField('PageDeGarde-RespFonction')
      const RespEmail = form.getTextField('PageDeGarde-RespEmail')
      const RespTel = form.getTextField('PageDeGarde-RespTel')
      const ClientName = form.getTextField('PageDeGarde-ClientName')
      const ClientSociete = form.getTextField('PageDeGarde-ClientSociete')
      const ClientTel = form.getTextField('PageDeGarde-ClientTel')

      // Fill in the basic info fields
      pageDeGardeTitle.setText(this.pageDeGardeTitle.toString());
      RespName.setText(this.RespName.toString());
      RespFonction.setText(this.RespFonction.toString());
      RespEmail.setText(this.RespEmail.toString());
      RespTel.setText(this.RespTel.toString());
      ClientName.setText(this.ClientName.toString());
      ClientSociete.setText(this.ClientSociete.toString());
      ClientTel.setText(this.ClientTel.toString());

      // make pdf form input readOnly
      fields.forEach(field => {
        field.enableReadOnly()
      });
      // change appearence from input to text
      form.updateFieldAppearances();

      return pdfDoc;
    },
    // Page: Notre approche
    async page2Filler(){
      // Load the PDF from the project folder
      const pdfPath = "/pdf/Eneffic/EnefficAnnexe-2.pdf";
      const response = await fetch(pdfPath);
      const arrayBuffer = await response.arrayBuffer();
      const pdfDoc = await PDFDocument.load(arrayBuffer);

      return pdfDoc;
    },
    // Page: Detail du processus
    async page3Filler(){
      // Load the PDF from the project folder
      const pdfPath = "/pdf/Eneffic/EnefficAnnexe-3.pdf";
      const response = await fetch(pdfPath);
      const arrayBuffer = await response.arrayBuffer();
      const pdfDoc = await PDFDocument.load(arrayBuffer);

      return pdfDoc;
    },
    // Page: Synthèse technique de mon projet (Numbers)
    async page4Filler(){
      // Load the PDF from the project folder
      const pdfPath = "/pdf/Eneffic/EnefficAnnexe-4.pdf";
      const response = await fetch(pdfPath);
      const arrayBuffer = await response.arrayBuffer();
      const pdfDoc = await PDFDocument.load(arrayBuffer);

      // Get the form containing all the fields
      const form = pdfDoc.getForm();
      const fields = form.getFields()

      // Get all fields in the PDF by their names
      const PuissanceProjetKwc = form.getTextField('PuissanceProjetKwc')
      const ProductionAttendueKWh = form.getTextField('ProductionAttendueKWh')
      const NombreDePannaux = form.getTextField('NombreDePannaux')

      // Fill in the basic info fields
      const PKWC = `${this.to_fixed2LocaleString(this.PuissanceProjetKwc)}`;
      const PAKWH = `${this.to_fixed2LocaleString(this.ProductionAttendueKWh)}`;
      const NBRP = this.NombreDePannaux.toString();
      PuissanceProjetKwc.setText(PKWC);
      ProductionAttendueKWh.setText(PAKWH);
      NombreDePannaux.setText(NBRP);

      // make pdf form input readOnly
      fields.forEach(field => {
        field.enableReadOnly()
      });
      // change appearence from input to text
      form.updateFieldAppearances();

      return pdfDoc;
    },
    // Page: Synthèse technique de mon projet (Address Image)
    async page5Filler(){
      // Load the PDF from the project folder
      const pdfPath = "/pdf/Eneffic/EnefficAnnexe-5.pdf";
      const response = await fetch(pdfPath);
      const arrayBuffer = await response.arrayBuffer();
      const pdfDoc = await PDFDocument.load(arrayBuffer);

      // Get the form containing all the fields
      const form = pdfDoc.getForm();
      const fields = form.getFields();
      var pages = pdfDoc.getPages();
      var page = pages[0];

      // Get all fields in the PDF by their names
      const address = form.getTextField('address');

      // Fill in the basic info fields
      address.setText(this.address.toString());
      address.setFontSize(11);
      // Upload image from input file
      const imageFile = this.addressImage;

      if (imageFile) {
        // Convert the image file to bytes
        const imageBytes = await imageFile.arrayBuffer();
        // Check the image type and use the appropriate function
        if (imageFile.type === "image/png") {
          // embed the image to the pdf
          const imagePage = await pdfDoc.embedPng(imageBytes);
          const { width, height } = page.getSize();
          // Add the image to the PDF
          page.drawImage(imagePage, {
            x: 125,
            y: 180,
            width: 350,
            height: 350,
          });
          
        } else if (imageFile.type === "image/jpeg") {
          // Convert jpeg image to PNG image
          const convertedImageBytes = await this.convertJpegToPng(imageBytes);
          // embed the image to the pdf
          const imagePage = await pdfDoc.embedPng(convertedImageBytes);
          const { width, height } = page.getSize();
          // Add the image to the PDF
          page.drawImage(imagePage, {
            x: 125,
            y: 180,
            width: 350,
            height: 350,
          });
        } else {
          console.error("Unsupported image type. Please use PNG or JPEG.");
          page.drawRectangle({
            x: 125,
            y: 180,
            width: 350,
            height: 350,
            color: rgb(0.94, 0.96, 0.97),
            borderColor: rgb(0.60, 0.64, 0.70),
            borderWidth: 1.5,
          });
        }
      } else {
        console.warn("No Image Selected. Please use PNG or JPEG.");
        page.drawRectangle({
          x: 125,
          y: 180,
          width: 350,
          height: 350,
          color: rgb(0.94, 0.96, 0.97),
          borderColor: rgb(0.60, 0.64, 0.70),
          borderWidth: 1.5,
        });
      }

      // make pdf form input readOnly
      fields.forEach(field => {
        field.enableReadOnly()
      });
      // change appearence from input to text
      form.updateFieldAppearances();

      return pdfDoc;
    },
    // Page: Synthèse de consommation de mon projet (chart conso)
    async page6Filler(){
      // Load the PDF from the project folder
      const pdfPath = "/pdf/Eneffic/EnefficAnnexe-6.pdf";
      const response = await fetch(pdfPath);
      const arrayBuffer = await response.arrayBuffer();
      const pdfDoc = await PDFDocument.load(arrayBuffer);

      var pages = pdfDoc.getPages();
      var page = pages[0];

      // convert apexchart into png image
      try {
        const imageChartDataURL = await this.convertApexCartToImage('chartSyntheseConso');
  
        if (imageChartDataURL) {
          // Convert the image file to bytes
          const imageBytes = await fetch(imageChartDataURL).then((res) => res.arrayBuffer());
          const imagePage = await pdfDoc.embedPng(imageBytes);
          page.drawImage(imagePage, {
            x: 40,
            y: 400,
            width: 500,
            height: 200,
          });
        }
      } catch (error) {
        console.error("error seting chart consoProd:",error);
      }

      return pdfDoc;
    },
    // Page: Synthèse financière de mon projet (chart gains + amortissement)
    async page7Filler(){
      // Load the PDF from the project folder
      const pdfPath = "/pdf/Eneffic/EnefficAnnexe-7.pdf";
      const response = await fetch(pdfPath);
      const arrayBuffer = await response.arrayBuffer();
      const pdfDoc = await PDFDocument.load(arrayBuffer);

      var pages = pdfDoc.getPages();
      var page = pages[0];

      // convert gainsChart apexchart into png image
      try {
        const imageGainsChartDataURL = await this.convertApexCartToImage('gainsChart');
        const imageAmortissementChartDataURL = await this.convertApexCartToImage('amortissementChart');
        if (imageGainsChartDataURL) {
          // Convert the image file to bytes
          const imageBytes = await fetch(imageGainsChartDataURL).then((res) => res.arrayBuffer());
          const imagePage = await pdfDoc.embedPng(imageBytes);
          page.drawImage(imagePage, {
            x: 40,
            y: 450,
            width: 500,
            height: 200,
          });
        }
        if (imageAmortissementChartDataURL) {
          // Convert the image file to bytes
          const imageBytes = await fetch(imageAmortissementChartDataURL).then((res) => res.arrayBuffer());
          const imagePage = await pdfDoc.embedPng(imageBytes);
          page.drawImage(imagePage, {
            x: 40,
            y: 100,
            width: 500,
            height: 200,
          });
        }

      } catch (error) {
        console.error("error seting chart gains + amortissement:",error);
      }


      return pdfDoc;
    },
    // Page: Cout de mon projet
    async page8Filler(){
      // Load the PDF from the project folder
      const pdfPath = "/pdf/Eneffic/EnefficAnnexe-8.pdf";
      const response = await fetch(pdfPath);
      const arrayBuffer = await response.arrayBuffer();
      const pdfDoc = await PDFDocument.load(arrayBuffer);

      // Get the form containing all the fields
      const form = pdfDoc.getForm();
      const fields = form.getFields()

      // Get all fields in the PDF by their names
      const CoutTotalInstallation = form.getTextField('CoutTotalInstallation');
      const RevenusExploitationMoyenAnnuelAttendu = form.getTextField('RevenusExploitationMoyenAnnuelAttendu');
      const ChargeExploitation = form.getTextField('ChargeExploitation');
      const TempsDeRetour = form.getTextField('TempsDeRetour');

      // Fill in the basic info fields
      const CI = `${this.to_fixed2LocaleString(this.CoutTotalInstallation)}€ HT`;
      const EA = `${this.to_fixed2LocaleString(this.RevenusExploitationMoyenAnnuelAttendu)}€ HT/an`;
      const CE = `${this.to_fixed2LocaleString(this.ChargeExploitation)} €/an`;
      const TR = this.TempsDeRetour.toString();
      CoutTotalInstallation.setText(CI);
      RevenusExploitationMoyenAnnuelAttendu.setText(EA);
      ChargeExploitation.setText(CE);
      TempsDeRetour.setText(TR);

      // make pdf form input readOnly
      fields.forEach(field => {
        field.enableReadOnly()
      });
      // change appearence from input to text
      form.updateFieldAppearances();

      return pdfDoc;
    },
    // Page: Synthèse financière de mon projet (Table)
    async page9Filler(){
      // Load the PDF from the project folder
      const pdfPath = "/pdf/Eneffic/EnefficAnnexe-9.pdf";
      const response = await fetch(pdfPath);
      const arrayBuffer = await response.arrayBuffer();
      const pdfDoc = await PDFDocument.load(arrayBuffer);

      // Get the form containing all the fields
      const form = pdfDoc.getForm();
      const fields = form.getFields()

      // Get all fields in the PDF by their names
      const BudgetPhotovoltaique = form.getTextField('BudgetPhotovoltaique');
      const PrimeInvestissement = form.getTextField('PrimeInvestissement');
      const GainAnnuelMoyen = form.getTextField('GainAnnuelMoyen');

      // get Table Total fields
      const totalEconomie = form.getTextField("tablefinance-total-economie");
      const totalVenteSurplus = form.getTextField("tablefinance-total-venteSurplus");
      const totalGains = form.getTextField("tablefinance-total-gains");

      // Fill in the basic info fields
      const BP = `${this.to_fixed2LocaleString(this.CoutTotalInstallation)}€ HT`;
      const PI = `${this.to_fixed2LocaleString(this.PrimeInvestissement)} €/Kwc`;
      const GAM = `${this.to_fixed2LocaleString(this.GainAnnuelMoyen)}€ TTC`;
      BudgetPhotovoltaique.setText(BP);
      PrimeInvestissement.setText(PI);
      GainAnnuelMoyen.setText(GAM);

      // Fill table Total fields
      const TE = `${this.to_fixed2LocaleString(this.totalEconomie)}`;
      const TVS = `${this.to_fixed2LocaleString(this.totalVenteSurplus)}`;
      const TG = `${this.to_fixed2LocaleString(this.totalGains)}`;
      totalEconomie.setText(TE);
      totalVenteSurplus.setText(TVS);
      totalGains.setText(TG);
      // Fill table values
      for(let i=0; i<25; i++){
        for (let j = 1; j <= 4; j++){
          let value = 0;
          if(this.tableAmortissement[i]){
            if(j == 1) value = this.tableAmortissement[i].productionSolar;
            if(j == 2) value = this.tableAmortissement[i].enconomie;
            if(j == 3) value = this.tableAmortissement[i].surplus;
            if(j == 4) value = this.tableAmortissement[i].gainTotal;
          }
          
          const field = form.getTextField(`tablefinance-${i + 1}-${j}`);
          const tranformedValue = `${this.to_fixed2LocaleString(value)}`;
          field.setText(tranformedValue);
        }
      }

      // make pdf form input readOnly
      fields.forEach(field => {
        field.enableReadOnly()
      });
      // change appearence from input to text
      form.updateFieldAppearances();

      return pdfDoc;
    },
    // Page: Finale
    async pageFinaleFiller() {
      // Load the PDF from the project folder
      const pdfPath = "/pdf/Eneffic/EnefficAnnexe-10.pdf";
      const response = await fetch(pdfPath);
      const arrayBuffer = await response.arrayBuffer();
      const pdfDoc = await PDFDocument.load(arrayBuffer);

      return pdfDoc;
    },
  }
}
</script>

<style scoped>
#pdf-content{
  width: 0px;
  height: 0px;
  overflow: hidden;
}
.chart-wrapper{
  width: 800px;
  height: 300px;
  background: #fff;
}
.modal-header {
  background: rgb(0, 121, 145);
  color: #fff !important;
}
.inputImageAddress::file-selector-button {
  margin-right: 20px;
  border: none;
  background: rgb(0, 121, 145);
  padding: 10px 20px;
  border-radius: 10px;
  color: #fff;
  cursor: pointer;
  transition: background .2s ease-in-out;
}

.inputImageAddress::file-selector-button:hover {
  background: rgb(0, 121, 145);
}
.drop-container {
  position: relative;
  display: flex;
  gap: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 200px;
  padding: 20px;
  border-radius: 10px;
  border: 2px dashed #555;
  color: #444;
  cursor: pointer;
  transition: background .2s ease-in-out, border .2s ease-in-out;
}

.drop-container:hover {
  background: #eee;
  border-color: #111;
}

.drop-container:hover .drop-title {
  color: #222;
}

.drop-title {
  color: #444;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  transition: color .2s ease-in-out;
}
.drop-container.drag-active {
  background: #eee;
  border-color: #111;
}

.drop-container.drag-active .drop-title {
  color: #222;
}

.add-title {
  color: #747777;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  transition: color .2s ease-in-out;
}

.image-preview-wrapper{
  width: 100%;
  height: 230px;
  border-radius: 4px;
  overflow: hidden;
  margin-top: 20px;
  img{
    width: 100%;
    height: 100%;
  }
}

.button-download-pdf{
  width: 100%;
  height: 44px;
  margin-top: 20px;
}
</style>
