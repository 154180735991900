<template>
  <v-dialog
       v-model="dispalyed"
       persistent
       max-width="60%" style="z-index: 9999;"
       class="modalCreateEvent"
       >
       <v-card>
           <v-toolbar style="background-color:rgb(21, 191, 166);color: #ffffff;">
               <span class="text-h6 pl-0">Créer Un Visite Technique</span>
           </v-toolbar>
           <v-form ref="formAddEvent" class="pa-4">
               <v-row class="ma-0 pa-0">
                   <v-col cols="12" class="ma-0 pa-0">
                       <v-text-field
                          dense
                          v-model="newEvent.nameEvent"
                          placeholder="25 rue Baltimore 1234"
                          prepend-inner-icon="mdi-map-search-outline"
                          label="Nom"
                          outlined
                        />
                   </v-col>
                   <v-col cols="6" >
                       <v-text-field
                          dense
                          type="date"
                          v-model="newEvent.dateDebut"
                          placeholder="25 rue Baltimore 1234"
                          prepend-inner-icon="mdi-map-search-outline"
                          label="Date Debut"
                          outlined
                        />
                   </v-col>
                   <v-col cols="6">
                       <v-text-field
                          dense
                          type="date"
                          v-model="newEvent.dateFin"
                          placeholder="25 rue Baltimore 1234"
                          prepend-inner-icon="mdi-map-search-outline"
                          label="Date Fin"
                          outlined
                        />
                   </v-col>
                   <v-col cols="6" >
                       <v-text-field
                          dense
                          type="time"
                          v-model="newEvent.timeDebut"
                          placeholder="25 rue Baltimore 1234"
                          prepend-inner-icon="mdi-map-search-outline"
                          label="Time debut"
                          outlined
                        />
                   </v-col>
                   <v-col cols="6">
                       <v-text-field
                          dense
                          type="time"
                          v-model="newEvent.timeFin"
                          placeholder="25 rue Baltimore 1234"
                          prepend-inner-icon="mdi-map-search-outline"
                          label="Time Fin"
                          outlined
                        />
                   </v-col>
                   <v-col cols="12" class="ma-0 pa-0">
                       <v-textarea
                          dense
                          v-model="newEvent.description"
                          placeholder="25 rue Baltimore 1234"
                          prepend-inner-icon="mdi-map-search-outline"
                          label="Description"
                          outlined
                        />
                    </v-col>
                    <v-col cols="12" class="ma-0 pa-0">
                       <v-textarea
                          dense
                          v-model="newEvent.location"
                          placeholder="25 rue Baltimore 1234"
                          prepend-inner-icon="mdi-map-search-outline"
                          label="Commentaire"
                          outlined
                        />  
                    </v-col>
                   <v-col cols="12" sm="12" md="12" lg="12" xl='12' class="ma-0 pa-0">
                       <v-row class="button-row">
                           <v-col cols="12" sm="4" md="4" lg="4" xl='4' class="ma-0 pa-0">
                            <div class="button-container mx-3">
                               <v-btn class="btn-close" @click="modalCreate = false"> Close </v-btn>
                               <v-btn class="btn-create" @click="CreateNewEvent"> Créer </v-btn>
                            </div>
                           </v-col>
                       </v-row>
                   </v-col>
               </v-row>
           </v-form>
       </v-card>
   </v-dialog>
</template>
  <script>
    export default {
        name: 'AddEventMenu',
        props:{
            dispalyed:{
                type:Boolean,
                required:true,
            },
        },
      data () {
        return {
          dialog: false,
          notifications: false,
          sound: true,
          widgets: false,
          
          newEvent:{
              nameEvent:'',
              dateDebut:'',
              dateFin:'',
              timeDebut:'',
              timeFin:'',
              description:'',
              location:'',
          },
        }
      },
      methods: {
          CreateNewEvent(){
              console.log(this.newEvent);
          }
      },
    }
  </script>
  <style>
  .dialog-bottom-transition-enter-active,
  .dialog-bottom-transition-leave-active {
    transition: transform .2s ease-in-out;
  }
  .modalCreateEvent{
      position: absolute;
      bottom: 0;
      right: 0;

  }
  .button-row{
      display: flex;
      justify-content: flex-end;
  }
  .button-container{
      display: flex;
      justify-content: space-between;
  }
  .btn-create{
      background-color: #56b1ee !important;
      color: #ffffff !important;
      margin-left: 5px !important;
  }
  .btn-close{
      background-color: #fc574c !important;
      color: #ffffff !important;
      margin-left: 5px !important;
  }
  </style>