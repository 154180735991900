<template>
  <div style="background-color: white">
    <pageDeGardeTemplate
      id="pageGardePDF"
      class="with-page-break page"
      :societe="societe"
    />
    <testSejda
      id="pageTablePDF"
      class="with-page-break"
      v-for="(item, index) in items"
      :items="item"
      :key="index"
    />
    <recapTemplate
      id="pageRecapPDF"
      class="with-page-break"
      :itemsProps="itemsProps"
      :societe="societe"
    />
  </div>
</template>
<script>
import pageDeGardeTemplate from "./pageDeGardeTemplate.vue";
import testSejda from "./testSejda.vue";
import recapTemplate from "./recapTemplate.vue";
export default {
  name: "pdfCompletTest",
  props: ["societe", "items", "itemsProps"],
  components: { pageDeGardeTemplate, testSejda, recapTemplate },
};
</script>
<style scoped>
.with-page-break {
  page-break-before: always;
  page-break-after: always;
}
@page {
  size: A4;
  margin: 0;
}
.page {
  page-break-before: always;
  page-break-after: always;
  height: 100%;
  overflow: hidden;
  margin: 0;
  position: relative;
}

@font-face {
  font-family: "Poppins";
  src: local("Poppins"),
    url("../../assets/fonts/Poppins/Poppins-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins-Bold";
  src: local("Poppins-Bold"),
    url("../../assets/fonts/Poppins/Poppins-Bold.ttf") format("truetype");
}

.Poppins {
  font-family: "Poppins";
}

.Poppins-Bold {
  font-family: "Poppins-Bold";
}
</style>
