import axios from "axios";

import Vue from "vue";

axios.interceptors.request.use((request) => {
  // do something with request meta data, configuration, etc
  // dont forget to return request object,
  // otherwise your app will get no answer
  return request;
});

// doing something with the response
axios.interceptors.response.use(
  (response) => {
    // all 2xx/3xx responses will end here
    if (response.data.toastMessage) {
      switch (response.data.toastType) {
        case "success": {
          Vue.$toast.success(response.data.toastMessage);
          break;
        }
        case "info": {
          Vue.$toast.info(response.data.toastMessage);
          break;
        }
        case "warning": {
          Vue.$toast.warning(response.data.toastMessage);
          break;
        }
        case "error": {
          Vue.$toast.error(response.data.toastMessage);
          break;
        }
        default: {
          Vue.$toast(response.data.toastMessage);
          break;
        }
      }
    }

    return response;
  },
  (error) => {
    // all 4xx/5xx responses will end here
    if (error.response.data.toastMessage) {
      switch (error.response.data.toastType) {
        case "success": {
          Vue.$toast.success(error.response.data.toastMessage);
          break;
        }
        case "info": {
          Vue.$toast.info(error.response.data.toastMessage);
          break;
        }
        case "warning": {
          Vue.$toast.warning(error.response.data.toastMessage);
          break;
        }
        case "error": {
          Vue.$toast.error(error.response.data.toastMessage);
          break;
        }
        default: {
          Vue.$toast(error.response.data.toastMessage);
          break;
        }
      }
    }
    return Promise.reject(error);
  }
);

export default axios;
