<template>
  <!-- loading -->
  <div v-if="loadingPage" class="d-flex justify-content-center align-items-center" style="height:100%">
    <v-card elevation='0' class="d-flex flex-column justify-content-center align-items-center" height="100%" width="100%">
      <v-progress-circular indeterminate color='primary' size="100"></v-progress-circular>
    </v-card>
  </div>
  <!-- error: token or invalid -->
  <PageError v-else-if="isPageError" :message="pageMessageError" />

  <!-- success: token is valid -->
  <div v-else-if="apiSuccess" class="presentation-viewer-wrapper">
    <div class="scale-btns-wrapper">
      <v-btn class="scale-btn" fab dark color="white" @click="scaleDown">
        <v-icon color="black" class="scale-btn-icon">
          mdi-minus
        </v-icon>
      </v-btn>
      <v-btn class="scale-btn" fab dark color="white" @click="scaleUp">
        <v-icon color="black" class="scale-btn-icon">
          mdi-plus
        </v-icon>
      </v-btn>
    </div>
    <div :style="`transform: scale(${scale})`">
      <div class="presentation-content">
        <PresentationViewer 
        :pageDeGardeTitle="'OFFRE ' + societe?.raison"
        :vendeur="vendeur"
        :societe="societe"

        :puissanceKwc="puissanceKwc"
        :productionAttendueKwh="productionAttendueKwh"
        :nombrePanneaux="nombrePanneaux"

        :address="address"
        :codePostale="codePostale"
        :imageSrc="imageSrc"

        :seriesConsoProd="seriesConsoProd"

        :seriesGains="seriesGains"
        :seriesAmortissement="seriesAmortissement"

        :coutTotalInstallation="coutTotalInstallation"
        :revenusExploitation="revenusExploitation"
        :chargesExploitation="chargesExploitation"
        :tempsDeRetour="tempsDeRetour"

        :budgetPhotovoltaique="budgetPhotovoltaique"
        :primeInvestissement="primeInvestissement"
        :gainAnnuelMoyen="gainAnnuelMoyen"
        :totalEconomie="totalEconomie"
        :totalSurplus="totalSurplus"
        :totalGain="totalGain"
        :tableauAmourtisement="tableauAmourtisement"
        />
      </div>
    </div>
  </div>

  <!-- other error -->
  <PageError v-else message="Erreur. Veuillez réessayer." />
</template>

<script>
import PresentationViewer from "./PresentationViewer.vue";
import PageError from "./components/PageError.vue";
import axios from "axios";

export default {
  name: "SolarPresentation",
  components: {
    PresentationViewer,
    PageError
  },
  data() {
    return {
      // for validating access to presentation content
      loadingPage: false,
      apiSuccess: false,
      isPageError: false,
      pageMessageError: "",
      // for api requesting
      loading: false,
      // to control scale of the presentation
      scale: 0.95,

      // *********** presentation content *********** 
      // page de Garde
      vendeur: {
        prenom: "",
        nom: "",
        fonction: "",
        email: "",
        telephone: "",
      },
      societe: {
        prenom: "",
        nom: "",
        raison: "",
        siret: "",
      },

      puissanceKwc: 0,
      productionAttendueKwh: 0,
      nombrePanneaux: 0,

      // page: Synthèse technique de mon projet (Address image)
      address: null,
      codePostale: null,
      imageSrc: null,

      // page: Synthèse de consommation de mon projet (1 Charts)
      seriesConsoProd: [
        {
          name: 'Votre consommation réalisée',
          type: 'column',
          data: [0,0,0,0,0,0,0,0,0,0,0,0]
        }, {
          name: 'Production solaire',
          type: 'area',
          data: [0,0,0,0,0,0,0,0,0,0,0,0]
        }
      ],

      // page: Synthèse de financière de mon projet (2 Charts)
      seriesGains: [{
        name: 'ECONOMIES SUR FACTURES',
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0]
      }, {
        name: 'VENTE DE SURPLUS',
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0]
      }],
      seriesAmortissement: [{
        name: 'Amortissement',
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0]
      }],

      // page: Coût de mon projet
      coutTotalInstallation: 0,
      revenusExploitation: 0,
      chargesExploitation: 0,
      tempsDeRetour: null,

      // page: Synthèse financière de mon projet
      gainAnnuelMoyen: 0,
      primeInvestissement: 0,
      budgetPhotovoltaique: 0,
      totalEconomie: 0,
      totalSurplus: 0,
      totalGain: 0,
      tableauAmourtisement: [],
    }
  },
  async created() {
    this.loadingPage = true;
    // check if there is token in the url or not
    if (this.$route.params.token) {
      await this.getData();
    } else {
      // error for missing token
      this.isPageError = true;
      this.pageMessageError = 'Lien est invalide';
    }
    this.loadingPage = false;
  },
  methods: {
    async getData() {
      try {
        const res = await axios.post(`${process.env.VUE_APP_URL_API_CLIENT}getPdfIndvPreviewContent/`, {
          token: this.$route.params.token,
        });
        if (res?.status == 200 && res?.data) {
          this.vendeur = {
            prenom: res.data.vendeur?.prenom,
            nom: res.data.vendeur?.nom,
            fonction: res.data.vendeur?.fonction,
            email: res.data.vendeur?.email,
            telephone: res.data.vendeur?.telephone,
          };
          this.societe = {
            prenom: res.data.societe?.prenom,
            nom: res.data.societe?.nom,
            raison: res.data.societe?.raison,
            siret: res.data.societe?.siret,
          };
          this.vendeur = res.data.vendeur;
          this.societe = res.data.societe;
          this.puissanceKwc = res.data.puissanceKwc;
          this.productionAttendueKwh = res.data.productionAttendueKwh;
          this.nombrePanneaux = res.data.nombrePanneaux;
          this.address = res.data.address;
          if (res.data.codePostale) {
            this.codePostale = res.data.codePostale;
          }
          // address image
          if (res.data.address_image) {
            this.imageSrc = process.env.VUE_APP_URL_API_CLIENT_BASE_URL + res.data.address_image;
          }
          this.seriesConsoProd = res.data.seriesConsoProd;
          this.seriesGains = res.data.seriesGains;
          this.seriesAmortissement = res.data.seriesAmortissement;
          this.coutTotalInstallation = res.data.coutTotalInstallation;
          this.revenusExploitation = res.data.revenusExploitation;
          this.chargesExploitation = res.data.chargesExploitation;
          this.tempsDeRetour = res.data.tempsDeRetour;
          this.gainAnnuelMoyen = res.data.gainAnnuelMoyen;
          this.primeInvestissement = res.data.primeInvestissement;
          this.budgetPhotovoltaique = res.data.budgetPhotovoltaique;
          this.totalEconomie = res.data.totalEconomie;
          this.totalSurplus = res.data.totalSurplus;
          this.totalGain = res.data.totalGain;
          this.tableauAmourtisement = res.data.tableauAmourtisement;
        }
        this.apiSuccess = true;
      } catch (error) {
        console.log(error);
        // error for missing token
        if (error?.response?.data?.message) {
          this.isPageError = true;
          this.pageMessageError = error.response.data.message;
        }
        this.apiSuccess = false;
      }
    },
    // controls
    scaleUp() {
      let x = this.scale + 0.15;
      if (x > 1) {
        this.scale = 1;
      } else {
        this.scale = x;
      }
    },
    scaleDown() {
      let x = this.scale - 0.15;
      if (x < 0.3) {
        this.scale = 0.3;
      } else {
        this.scale = x;
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.presentation-viewer-wrapper{
  position: relative;
  width: 100%;
  height: calc(100vh - 0px);
  overflow: auto;
  background-color: #525659;
  display: flex;
  justify-content: center;
}
.scale-btns-wrapper{
  position: fixed;
  bottom: 20px;
  right: 32px;
  z-index: 1;
  display: flex;
  flex-direction: column;
}
.scale-btn{
  width: calc(100vh * 0.05);
  height: calc(100vh * 0.05);
  min-width: 32px;
  min-height: 32px;
  margin: 8px
}

/* large screen */
.presentation-content{
  --scale-value: 0.95;
  width: fit-content;
  height: fit-content;
  margin-top: -30px;
  transform: scale(var(--scale-value));
}
/* others screen */
@media screen and (max-width: 1440px) {
  .presentation-content{
    --scale-value: 0.6;
    margin-top: -280px;
    transform: scale(var(--scale-value));
  }
}

@media screen and (max-width: 1280px) {
  .presentation-content{
    --scale-value: 0.6;
    margin-top: -280px;
    transform: scale(var(--scale-value));
  }
}

@media screen and (max-width: 992px) {
  .presentation-content{
    --scale-value: 0.6;
    margin-top: -280px;
    transform: scale(var(--scale-value));
  }
}

@media screen and (max-width: 768px) {
  .presentation-content{
    --scale-value: 0.6;
    margin-top: -280px;
    transform: scale(var(--scale-value));
  }
}

@media screen and (max-width: 576px) {
  .presentation-content{
    --scale-value: 0.4;
    margin-top: -420px;
    transform: scale(var(--scale-value));
  }
}

@media screen and (max-width: 425px) {
  .presentation-content{
    --scale-value: 0.35;
    margin-top: -450px;
    transform: scale(var(--scale-value));
  }
}
</style>