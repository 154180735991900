<template>
  <v-row>
    <v-col md="12" lg="12" xl="12" cols="12" v-if="dialogProps == true">
      <v-dialog v-model="dialogProps" persistent width="400">
        <div
          class="text-center"
          v-if="!errorProps && loadingProps == true"
        >
         <div class="center" :style="{background:$styles.background}">
  <div class="wave"></div>
  <div class="wave"></div>
  <div class="wave"></div>
  <div class="wave"></div>
  <div class="wave"></div>
  <div class="wave"></div>
  <div class="wave"></div>
  <div class="wave"></div>
  <div class="wave"></div>
  <div class="wave"></div>
</div>
        </div>
        <v-card v-else>
          <v-alert
            class="ma-0"
            v-if="errorProps == true && loadingProps == false"
            type="error"
          >
            <div class="d-flex flex-column">
              <p>{{ messageDialogeProps }}</p>
              <v-btn
                class="align-self-end white--text"
                v-if="loadingProps == false"
                text
                @click="$emit('closeDialog', false)"
              >
                FERMER
              </v-btn>
            </div>
          </v-alert>
          <v-alert class="ma-0" v-if="errorProps == false && loadingProps == false" type="success">
            <div class="d-flex flex-column">
              <p>{{ messageDialogeProps }}</p>
              <v-btn
                class="align-self-end white--text"
                v-if="loadingProps == false"
                text
                @click="$emit('closeDialog', false)"
              >
                FERMER
              </v-btn>
            </div>
          </v-alert>
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>
<script>
export default {
  name: "loadingComponent",
  props: ["dialogProps", "messageDialogeProps", "loadingProps", "errorProps"],
  data() {
    return {
      dialog: this.dialogProps,
      loading: this.loadingProps,
      error: this.errorProps,
      messageDialoge: this.messageDialogeProps,
    };
  },
};
</script>
<style>
.center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.wave {
  width: 5px;
  height: 100px;
  background: linear-gradient(45deg, cyan, #fff);
  margin: 10px;
  animation: wave 1s linear infinite;
  border-radius: 20px;
}
.wave:nth-child(2) {
  animation-delay: 0.1s;
}
.wave:nth-child(3) {
  animation-delay: 0.2s;
}
.wave:nth-child(4) {
  animation-delay: 0.3s;
}
.wave:nth-child(5) {
  animation-delay: 0.4s;
}
.wave:nth-child(6) {
  animation-delay: 0.5s;
}
.wave:nth-child(7) {
  animation-delay: 0.6s;
}
.wave:nth-child(8) {
  animation-delay: 0.7s;
}
.wave:nth-child(9) {
  animation-delay: 0.8s;
}
.wave:nth-child(10) {
  animation-delay: 0.9s;
}

@keyframes wave {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
</style>
