var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-col',{style:({ borderRight: `2px solid ${_vm.$colors[0]}` }),attrs:{"justify":"center","align":"center"}},[_c('h6',{staticClass:"mb-4 pa-2 Poppins-Bold white--text",style:({ backgroundColor: _vm.$colors[0] })},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c('v-row',{staticStyle:{"width":"100%"}},_vm._l((_vm.listExpansion),function(item){return (
        !!_vm.listupdatePrix[item] ||
        !!_vm.listupdatePrix[`prixAchat${item}`] ||
        _vm.title == 'Informations Générales'
      )?_c('div',{staticClass:"ma-2 py-4 d-flex flex-column align-items-center",style:({
        width: '100%',
      })},[_c('h6',{staticClass:"Poppins-Bold mb-0",staticStyle:{"color":"#103a5e","font-size":"larger"}},[_vm._v(" "+_vm._s(item)+" ")]),(item == 'Fournisseur')?_c('span',{staticClass:"black--text mb-0"},[_vm._v(_vm._s(_vm.listupdatePrix.typeFournisseur))]):(item == 'TURPE INCLUS')?_c('span',{staticClass:"black--text mb-0"},[_vm._v(_vm._s(_vm.listupdatePrix.typeTurpe))]):(item == 'Durée de contrat')?_c('span',{staticClass:"black--text mb-0"},[_vm._v(_vm._s(_vm.listupdatePrix.DUREE)+" mois")]):(
          _vm.title == 'Prix d\'achat' && _vm.listupdatePrix[`prixAchat${item}`] !== null
        )?_c('span',{staticClass:"black--text mb-0"},[_vm._v(" "+_vm._s(Number(_vm.listupdatePrix[`prixAchat${item}`]).toLocaleString("fr-FR"))+" €/mois")]):(_vm.title == 'CAPA' && _vm.listupdatePrix[`prixAchatCapa${item}`] !== null)?_c('span',{staticClass:"black--text mb-0"},[_vm._v(" "+_vm._s(Number(_vm.listupdatePrix[`prixAchatCapa${item}`]).toLocaleString("fr-FR"))+" €/mois")]):(_vm.title != 'Prix d\'achat')?_c('span',{staticClass:"black--text mb-0"},[_vm._v(_vm._s(Number(_vm.listupdatePrix[item]).toLocaleString("fr-FR"))+" €/mois")]):_vm._e()]):_vm._e()}),0)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }