import cookie from "@/services/cookie";
import { whoIAm } from "@/services/userRole";
import router from "@/router";
import Vue from "vue";
import Vuex from "vuex";
import { check,checkPermissionByStatus } from "@/utils/permissions";
Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    // État initial du store
    userRole: "",
    userToken: "",
    userId: "",
    compte: null,
  },
  mutations: {
    // Mutations pour mettre à jour l'état du store
    setUserRole(state, role) {
      state.userRole = role;
    },

    setUserToken(state, token) {
      state.userToken = token;
    },
    setCompte(state, compte){
      state.compte = compte
    }
    ,

    setUserId(state, id) {
      state.userId = id;
    },
  },
  actions: {
    // Actions pour effectuer des opérations asynchrones ou appeler des mutations
    setActionUserId({ commit }) {
      let id = cookie.getCookie("id");
      commit("setUserId", id);
    },
    setActionUserToken({ commit }) {
      let token = cookie.getCookie("token");
      commit("setUserToken", token);
    },
    logout({ commit }) {
      commit("setUserId", null);
      commit("setCompte",null);
      commit("setUserRole", "");
      commit("setUserToken", "");
      cookie.setCookie("token", "", 0);
      router.push("/login");
    },

    async setActionUserRole({ commit }) {
      let role = "";
      let token = store.getters.getUserToken;
      await whoIAm(token).then((data) => {
        role = data;
      });
      if (role === "noToken") {
        store.dispatch("logout");
      } else {
        commit("setUserRole", role);
      }
    },
    async setActionCompte({commit}){
      let compte = cookie.getCookie("compte");
      commit("setCompte",compte);
    }
    ,
    async setActionRedirect({ commit }) {
      let role = "";
      let token = store.getters.getUserToken;
      await whoIAm(token).then((data) => {
        role = data;
      });
      if (role === "noToken") {
        store.dispatch("logout");
      } else {
        commit("setUserRole", role);
        if (role === "technicien") {
          router.push("/visite-technique/liste");
        } else if (role === "ingenieur") {
          router.push("/visite-technique/liste");
        } else if (role === "chefIngenieur") {
          router.push("/visite-technique/liste");
        } else {
          router.push("/nouvelle-opportunite");
        }
      }
    },
  },
  getters: {
    // Getters pour récupérer des données du store
    getUserRole(state) {
      if (state.userRole == "") {
        store.dispatch("setActionUserRole");
        return state.userRole;
      } else {
        return state.userRole;
      }
    },
    getCompte(state){
      if (!state.compte) {
        store.dispatch("setActionCompte");
        return state.compte;
      } else {
        return state.compte;
      }
    }
    ,

    getUserId(state) {
      if (state.userId == "") {
        store.dispatch("setActionUserId");
        return state.userId;
      } else {
        return state.userId;
      }
    },
    getUserToken(state) {
      if (state.userToken == "") {
        store.dispatch("setActionUserToken");
        return state.userToken;
      } else {
        return state.userToken;
      }
    },
    hasPermission: (state) => (section, action) => {
      const userRole = state.userRole;
      return check(userRole, section, action);
    },
    hasPermissionByStatus: (state) => (section, status, action) => {
      const userRole = state.userRole;
      return checkPermissionByStatus(userRole, section, status, action);
    },
  },
});

export default store;
