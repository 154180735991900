<template>
  <div @dragover="dragover" @dragleave="dragleave" @drop="drop">
    <v-alert v-if="maxSizeFile" type="warning" icon="mdi-alert-outline">
      <div style="width: 100%" class="d-flex flex-column justify-space-between">
        <div style="width: 100%" class="d-flex flex-row justify-space-between">
          Nombre de fichier atteint
          <span class="justify-self-end">{{
            `${files.length} / ${maxNumberFileProps}`
          }}</span>
        </div>
      </div>
    </v-alert>
    <input
      :accept="acceptFileType"
      type="file"
      style="display: none"
      name="fields"
      id="assetsFieldHandle"
      class="w-px h-px opacity-0 overflow-hidden absolute"
      @change="onChange()"
      ref="file"
    />
    <v-snackbar color="error" v-model="snackbar">
      {{ messageError }}
    </v-snackbar>
    <h4 class="text-center text-h6 font-weight-bold">{{ name }}</h4>
    <label for="assetsFieldHandle" class="block cursor-pointer">
      <div :class="fileRequiredData ? 'requireddropzone' : 'dropzone'">
        <v-icon :color="$colors[0]" size="60">mdi-cloud-upload</v-icon>
        <p :style="{ color: $colors[0] }" class="text-center">
          Déposer votre/vos fichier(s) ici
          <span :style="{ color: $colors[0] }"><br />ou</span>
        </p>
        <p :style="{ color: $colors[0] }" class="text-center">
          Séléctionnez un fichier à importer
        </p>
        <p class="Poppins-Bold mx-2" :style="{ color: $colors[0] }">
          <span class="Poppins-Bold"
            >Types de fichier supportés :
            {{
              onlyImageProps
                ? "jpeg, png, gif, bmp, jpg, svg,webp"
                : "pdf, eml, msg, png, jpg, jpeg"
            }}</span
          >
        </p>
        <div
          v-if="files?.length > 0"
          :style="overflowProps ? 'height: 110px;width:100%' : 'height:100%'"
          :class="overflowProps ? 'd-flex flex-column overflow-y-auto pa-4' : 'my-4'"
        >
          <div
            class="d-flex flex-row justify-start align-center"
            v-for="(f, index) in files"
          >
            <h6 class="pa-0 ma-0">{{ !!f?.name ? f.name : `Fichier ${index + 1}` }}</h6>
            <v-icon color="error" class="ml-2" @click.prevent="removeElement(index)"
              >mdi-close</v-icon
            >
          </div>
        </div>
        <div
          v-if="hasValidation"
          style="width: 100%"
          class="d-flex flex-row justify-end pa-2 mt-4"
        >
          <v-btn
            small
            @click="addFiles()"
            :disabled="fileProps?.length == files?.length ? true : false"
            :color="$colors[0]"
          >
            <span class="white--text">télécharger</span>
          </v-btn>
        </div>
      </div>
    </label>
  </div>
</template>
<script>
export default {
  name: "uploadFile",
  props: [
    "name",
    "fileProps",
    "maxNumberFileProps",
    "fileRequired",
    "hasValidation",
    "overflowProps",
    "onlyImageProps",
  ],
  data() {
    return {
      messageError: "",
      snackbar: false,
      files: null,
      maxSizeFile: false,
      fileRequiredData: this.fileRequired,
    };
  },
  created() {
    this.files = this.fileProps.slice(); // pour copier les valeurs et non pas la référence (si référence prise alors possible de modifier files parent sans envoyer d'emit => modification de file enfant)
  },
  watch: {
    fileProps: function (newValue, oldValue) {
      this.files = newValue.slice();
    },
  },
  computed: {
    acceptFileType: function () {
      return this.onlyImageProps ? "image/*" : "*/*";
    },
  },
  methods: {
    isImage(file) {
      const typesMimesImages = [
        "image/jpeg",
        "image/png",
        "image/gif",
        "image/bmp",
        "image/svg+xml",
        "image/webp" /* Ajoutez d'autres types  d'images si nécessaire */,
      ];

      return typesMimesImages.includes(file.type);
    },
    addFiles() {
      this.$emit("FileData", this.files);
    },
    removeElement(index) {
      this.files.splice(index, 1);
      this.maxSizeFile = false;
      this.fileRequiredData = false;
      this.$emit("maxNumberFile", this.maxSizeFile);

      this.$emit("FileData", this.files);
    },
    dragover(event) {
      event.preventDefault();
      // Add some visual fluff to show the user can drop its files
      if (!event.currentTarget.classList.contains("bg-green-300")) {
        event.currentTarget.classList.remove("bg-gray-100");
        event.currentTarget.classList.add("bg-green-300");
      }
    },
    dragleave(event) {
      // Clean up
      event.currentTarget.classList.add("bg-gray-100");
      event.currentTarget.classList.remove("bg-green-300");
    },
    drop(event) {
      const newFileList = new DataTransfer();
      event.preventDefault();
      if (this.onlyImageProps) {
        for (let i = 0; i < event.dataTransfer.files.length; i++) {
          const file = event.dataTransfer.files[i];

          if (this.isImage(file)) {
            newFileList.items.add(file);
          } else {
            this.fileRequiredData = true;
            this.messageError = "Image uniquement !";
            this.snackbar = true;
            setTimeout(() => {
              this.fileRequiredData = false;
            }, 5000);
          }
        }
        this.$refs.file.files = newFileList.files;
      } else {
        this.$refs.file.files = event.dataTransfer.files;
      }

      this.onChange();
      // Clean up
      event.currentTarget.classList.add("bg-gray-100");
      event.currentTarget.classList.remove("bg-green-300");
    },
    onChange() {
      let isPresent;
      if (this.files.length < this.maxNumberFileProps) {
        if (this.files.length > 0) {
          this.$refs.file.files.forEach((element) => {
            isPresent = this.files.some((file) => file?.name == element.name);
            if (isPresent) {
              return;
            }
          });
        } else {
          isPresent = false;
        }
        if (isPresent) {
          this.fileRequiredData = true;
          this.messageError = "Fichier déjà présent dans la liste !";
          this.snackbar = true;
          setTimeout(() => {
            this.fileRequiredData = false;
          }, 5000);
        } else {
          this.$refs.file.files.forEach((element) => {
            if (this.isImage(element)) {
              const imageURL = URL.createObjectURL(element);
              element.path = imageURL;
            }
            this.files.push(element);
          });
          if (!this.hasValidation) {
            this.$emit("FileData", this.files);
          }
        }
      } else {
        this.maxSizeFile = true;
        this.fileRequiredData = true;
        this.$emit("maxNumberFile", this.maxSizeFile);
      }
    },
  },
};
</script>
<style scoped lang="scss">
.dropzone {
  border: 2px dashed #90a4ae;
  border-radius: 8px;
  min-height: 5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  &:hover {
    cursor: pointer;
  }
  p {
    margin-bottom: 0;
    &:first-of-type {
      font-weight: 500;
      font-size: 1rem;
      color: #263238;
      span {
        color: gray;
      }
    }
    &:last-of-type {
      font-size: 0.8rem;
    }
  }
}
.requireddropzone {
  border: 2px dashed red;
  border-radius: 8px;
  min-height: 5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  &:hover {
    cursor: pointer;
  }
  p {
    margin-bottom: 0;
    &:first-of-type {
      font-weight: 500;
      font-size: 1rem;
      color: #263238;
      span {
        color: gray;
      }
    }
    &:last-of-type {
      font-size: 0.8rem;
    }
  }
}
/* The Modal (background) */
.modall {
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  padding-top: 100px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}
/* Modal Content */
.modall-content {
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: 50%;
}
.loader {
  margin: auto;
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}
/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
