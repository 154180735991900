<template>
<div style="height:100%;width:100%" class="d-flex flex-column justify-center">
  <div style='width:100%' class="d-flex flex-row justify-center">    
    <v-card style='min-width:20%' class="pa-4 mr-4">
      <div class='font-weight-bold'>
        <v-data-table
            id="tableCompteur"
            :headers="headerNumCompteur"
            :items="itemsTable"
            :items-per-page="5"
            hide-default-footer
            hide-default-header
            >
             <template v-slot:header="{ props: { headers } }">
                <thead style='background-color:#007991;border-radius:15px !important;'>
                  <tr>
                    <th v-for="(h,index) in headers" :key="index" class="text-center white--text" style="border-top-left-radius:5px !important;border-top-right-radius:5px">
                      <span style="font-size:medium">{{h.text}}</span>
                    </th>
                  </tr>
                </thead>
            </template>
        </v-data-table>
      </div>
    </v-card>
    <v-card style="min-width:50%" class="d-flex flex-column pa-4">
      <v-window
        v-model="onboarding"
      >
        <v-window-item
          v-for="(n,index) in headersTables"
          :key="`card-${index}`"
          class="ma-3"
        >
          <v-card>
            <v-row>
              <div style="width:100%" class='font-weight-bold'>
                <v-data-table
                    :headers="n"
                    hide-default-header
                    :items="itemsTable"
                    hide-default-footer
                    :items-per-page="5"
                    
                    >
                    <template v-slot:header="{ props: { headers } }">
                          <thead style='background-color:#007991'>
                            <tr>
                              <th v-for="(h,index) in headers" :key="index" class="text-center white--text">
                                <span style="font-size:medium;">{{h.text}}</span>
                              </th>
                            </tr>
                          </thead>
                      </template>
                    </v-data-table>
              </div>
            </v-row>
          </v-card>
        </v-window-item>
      </v-window>

      <v-card-actions class="justify-space-between">
        <v-btn
          text
          @click="prev"
        >
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
        <v-item-group
          v-model="onboarding"
          class="text-center"
          mandatory
        >
          <v-item
            v-for="(icon,index) in iconList"
            :key="`btn-${icon}${index}`"
            v-slot="{ active, toggle }"
          >
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="mx-4"
                  :input-value="active"
                  icon
                  v-bind="attrs"
                  v-on="on"
                  @click="toggle"
                >
                  <v-icon :color="$colors[0]" size="30">{{icon}}</v-icon>
                </v-btn>
              </template>
              <span>{{iconTooltip[index]}}</span>
            </v-tooltip>
          </v-item>
        </v-item-group>
        <v-btn
          text
          @click="next"
        >
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
    <!--<div class="d-flex flex-column">  
      <v-card class="pa-4">
          <div class="d-flex flex-column">
              <v-tabs
                    id="tabCourbe"
                    show-arrows
                    :background-color="$colors[0]"
                    dark
                    v-model="tabs"
                  >
                  <v-tabs-slider :color="$colors[2]"></v-tabs-slider>
                  <v-tab
                    class="white--text font-weight-bold"
                    v-for="n in 3"
                    :key="n"
                    >
                    <span class="Poppins-Bold">{{ n == 1 ? "Compteur" : (n==2 ? "Consommations" : "Mesures") }}</span>
                  </v-tab>
              </v-tabs>-->
                <!---->
              <!--<div class="d-flex flex-row">
                <v-data-table
                  :headers="headersTable"
                  :items="itemsTable"
                  hide-default-footer
                  :items-per-page="5"
                  class="elevation-1"
                  >
                </v-data-table>
                v-tabs-items v-model="tabs">
                  <v-tab-item>
                  </v-tab-item>
                  <v-tab-item>
                  </v-tab-item>
                  <v-tab-item>
                  </v-tab-item>
                </v-tabs-items>        
              </div>
          </div>
      </v-card>
      <v-card class="pa-4">
        <v-data-table
          :headers="headersConso"
          :items="itemsTable"
          hide-default-footer
          :items-per-page="5"
          class="elevation-1"
          >
        </v-data-table>
      </v-card>
      <v-card class="pa-4">
        <v-data-table
          :headers="headersMesures"
          :items="itemsTable"
          hide-default-footer
          :items-per-page="5"
          class="elevation-1">
        </v-data-table>
      </v-card>
    </div>-->
</div>
</template>
<script>
    export default {
        name:"historiqueCalcul",
        data(){
            return{
              onboarding:0,
            window:0,
            tabs: 0,
            iconTooltip:['Compteur','Consommations','Mesures'],
            iconList:[
              'mdi-clipboard-text',
              'mdi-chart-timeline-variant',
              'mdi-calculator'
            ],
            headerNumCompteur:[{
            text: 'Compteur',
            align: 'center',
            sortable: false,
            value: 'name',
            class:'my-header-style'
          },],
        headersTables:[

          [
            { text: 'Puissance Souscrite', align: 'center', value: 'calories' },
            { text: 'Puissance Atteinte maximale', align: 'center', value: 'fat' },
            { text: 'Heure creuse matin', align: 'center', value: 'carbs' },
            { text: 'Heure creuse apres-midi', align: 'center', value: 'protein' },
            
          ],
          [
            { text: 'Consommation HP', align: 'center', value: 'calories' },
            { text: 'Consommation HC', align: 'center', value: 'fat' },
            { text: 'Date de debut', align: 'center', value: 'carbs' },
            { text: 'Date de fin', align: 'center', value: 'protein' },
            
          ],
          [
            { text: 'Nombre de mois', align: 'center', value: 'calories' },
            { text: 'Somme HC', align: 'center', value: 'fat' },
            { text: 'Somme HP', align: 'center', value: 'carbs' },
            
          ],
        ],
        itemsTable: [
          {
            name: '07139073795897',
            calories: 159,
            fat: 6.0,
            carbs: 24,
            protein: 4.0,
            iron: 1,
          },
          {
            name: '07139073796075',
            calories: 237,
            fat: 9.0,
            carbs: 37,
            protein: 4.3,
            iron: 1,
          },
          {
            name: '07139073794120',
            calories: 262,
            fat: 16.0,
            carbs: 23,
            protein: 6.0,
            iron: 7,
          },
          {
            name: '07139073795897',
            calories: 305,
            fat: 3.7,
            carbs: 67,
            protein: 4.3,
            iron: 8,
          },
          {
            name: '07139073795897',
            calories: 356,
            fat: 16.0,
            carbs: 49,
            protein: 3.9,
            iron: 16,
          },
          {
            name: 'Jelly bean',
            calories: 375,
            fat: 0.0,
            carbs: 94,
            protein: 0.0,
            iron: 0,
          },
          {
            name: 'Lollipop',
            calories: 392,
            fat: 0.2,
            carbs: 98,
            protein: 0,
            iron: 2,
          },
          {
            name: 'Honeycomb',
            calories: 408,
            fat: 3.2,
            carbs: 87,
            protein: 6.5,
            iron: 45,
          },
          {
            name: 'Donut',
            calories: 452,
            fat: 25.0,
            carbs: 51,
            protein: 4.9,
            iron: 22,
          },
          {
            name: 'KitKat',
            calories: 518,
            fat: 26.0,
            carbs: 65,
            protein: 7,
            iron: 6,
          },
        ],
            }
        },
        methods:{
          next () {
        this.onboarding = this.onboarding + 1 === this.headersTables.length
          ? 0
          : this.onboarding + 1
      },
      prev () {
        this.onboarding = this.onboarding - 1 < 0
          ? this.headersTables.length - 1
          : this.onboarding - 1
      },
        }
    }
</script>