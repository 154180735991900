<template>
  <div class="d-flex flex-column w-100">
    <v-row>
      <!-- section questions list -->
      <v-col cols="8">
        <b-card no-body v-if="filteredQuestionsList.length>0">
            <b-tabs v-model="tabIndex" card>
              <b-tab 
              :title-link-class="linkClass(index)" 
              v-for="(tabObj, index) in tabs" 
              :key="index">
                <template #title>
                  <div class="d-flex flex-row">
                    <span>{{ tabObj.name }}</span>
                    <b-icon v-if="checkTabQuestions(tabObj.groups)" class="ml-2" icon="exclamation-circle-fill" variant="danger"></b-icon>
                  </div>
                </template>
                <div v-for="(keyTabGroup,index) in tabObj.groups" :key="index">
                  <!-- section title -->
                  <div v-if="tabObj.groups.length>1" class="section-card">
                    <h3 class="section-card-title">
                      {{ keyTabGroup }}
                    </h3>
                  </div>
                  <!-- section questions -->
                  <div 
                  class="question-card" 
                  :style="question.borderWarning && 'border-color: rgb(8, 180, 80); border-width: 3px;'" 
                  v-for="(question, questionIndex) in getSectionDataToDisplay(keyTabGroup)" 
                  :key="questionIndex"> 
                    <div>
                      <h3 class="question-card-title">
                        {{ question.q }}
                        <span v-if="question.obligatoire" style="color: red">*</span>
                      </h3>
                      <v-radio-group hide-details column v-model="choiceSelected[keyTabGroup][questionIndex].id">
                        <v-radio label="Non" :value="-1" v-if="question.obligatoire === false">
                        </v-radio>
                        <v-radio v-for="(opt, index) in question.options" :key="`${opt.name}-${index}`" :label="opt.name"
                          :value="opt.id">
                        </v-radio>
                      </v-radio-group>
                      <!-- Show input fields based on the selected option -->
                      <v-text-field v-if="choiceSelected[keyTabGroup][questionIndex].id &&
                        choiceSelected[keyTabGroup][questionIndex].id != -1
                        " outlined dense hide-details style="max-width: 150px" class="mt-4"
                        v-model="choiceSelected[keyTabGroup][questionIndex].value" :rules="getValidationRules(
                          question.options,
                          choiceSelected[keyTabGroup][questionIndex].id)
                          " :suffix="getUnite(
                              question.options,
                              choiceSelected[keyTabGroup][questionIndex].id
                            )
                              " :label="getLabelUnite(
                              question.options,
                              choiceSelected[keyTabGroup][questionIndex].id
                            )
                              ">
                      </v-text-field>
                      <!-- warning message -->
                      <div v-if="isCurrentDistanceHeigher(question.options)" class="alert alert-warning" role="alert">
                        La distance TD IRVE / Borne entrer n'est pas joignable
                      </div>
                    </div>   
                  </div>
                </div>
                
            </b-tab>
          </b-tabs>
        </b-card>
      </v-col>
      <!-- Borne / TD questions -->
      <v-col :cols="borneSelected['id'] === '' ? 12 : 4">
        <div style="position: sticky; top: 73px; z-index: 5;">
          <div class="section-card">
            <v-row>
              <v-col cols="12">
                <h3 class="section-card-title">Borne / TD IRVE</h3>
              </v-col>
              <v-col cols="12">
                <div>
                  <v-row cols="12" md="12" lg="12" xl="12">
                    <v-col :cols="borneSelected['id'] === '' ? 4 : 6">
                      <v-row>
                        <v-col cols="12">
                          <v-autocomplete dense hide-details outlined style="max-width: 350px" class="ma-0"
                            :rules="[rules.required]" :items="listBornes" item-text="nom" item-value="id"
                            v-model="borneSelected.id" @change="handleBorneChange" label="select Borne" />
                        </v-col>
                        <v-col cols="12">
                          <v-text-field outlined dense hide-details style="max-width: 350px" class="ma-0"
                            v-model="borneSelected.qte" :rules="[rules.required, rules.isNumber]" label="Quantité" />
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col :cols="borneSelected['id'] === '' ? 4 : 6">
                      <v-row>
                        <v-col cols="12">
                          <v-select dense hide-details outlined style="max-width: 150px" class="ma-0"
                            :items="['intérieur', 'extérieur']" v-model="type_TD" label="Type de TD" />
                        </v-col>
                        <v-col cols="12">
                          <v-text-field outlined dense hide-details style="max-width: 150px" v-model="distance_TD"
                            :rules="[rules.required, rules.isNumber]" label="distance" />
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col :cols="borneSelected['id'] === '' ? 4 : 12">
                      <v-select dense hide-details outlined style="max-width: 390px" class="ma-0"
                        :items="['monophasé', 'Triphasé']" v-model="type_phase" :disabled="typePhaseDisabled"
                        label="Type de phase" />
                    </v-col>
                    
                  </v-row>
                  
                </div>
              </v-col>
            </v-row>
          </div>
        </div>
      </v-col>
      <!-- all questions list -->
      
      
    </v-row>
  </div>
</template>

<script>
import axios from "@/plugins/axios";

export default {
  name: "FormulaireDevis",
  props: {
    maxRechedValue: {
      type: Number,
      default: 0,
    },
    selectedCompteur:{}
  },
  data() {
    return {
      tabIndex: 0,
      tabs: [],
      panel: [],
      typePhaseDisabled: true,
      listAllQuestions: [],
      filteredQuestionsList: [],
      listBornes: [],
      borneSelected: {
        id: "",
        qte: 0,
      },
      type_phase: "monophasé",
      type_TD: "intérieur",
      distance_TD: 0,
      choiceSelected: {},

      // rules
      rules: {
        isNumberOrFloat: (value) =>
          /^[0-9]+(\.\d+)?$/.test(value) || "Ce champ n'accepte que les chiffres ou les nombres décimaux valides (ex. 50 ou 50.3)",
        isString: (value) =>
          !/\d/.test(value) || "Ce champ ne peut pas etre composé de chiffres",
        required: (value) => !!value || "Ce champ est obligatoire.",
        siretLength: (v) =>
          v.length == 14 || "Le siret doit comporter 14 chiffres",
        isNumber: (v) =>
          /^[0-9]+$/.test(v) || "Ce champ n'accepte que les chiffres",
        isFloat: (v) =>
          /^-?\d*(\.\d+)?$/.test(v) ||
          "Ce champ doit être un nombre décimal valide(50.3)",
        hasItemSelected: (v) =>
          v.length >= 1 || "Vous devez selectionner aux moins un compteur",
      },
    };
  },
  watch: {
    borneSelected: {
      deep: true,
      handler(newVal) {
        this.filterQuestionsList(this.listAllQuestions);
        // the borneSelected.qt if it's changed
        if (newVal.qte || newVal.id) {
          console.log(this.selectedCompteur);
         var puissance = this.listBornes.find((b) => b.id == this.borneSelected.id).puissance;
         console.log("puissance_ft",puissance);
         var qte = this.borneSelected.qte;
         // convert puissance to float with 1 decimal
         puissance = puissance.replace(',', '.');
         puissance = parseFloat(puissance).toFixed(1);
         var total = puissance * qte + this.maxRechedValue;
         console.log("total",total);
         console.log("puissance",puissance);
         if(total > this.selectedCompteur.kva){
            if(total > this.selectedCompteur.puissanceAtteinteMaximale){
              // sweet alert with confirm and cancel button
              this.$swal({
                title: "La puissance de la borne dépasse la puissance maximale. Vous devez changer le compteur.",
                text: "Voulez vous changer le compteur ?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "Oui",
                cancelButtonText: "Non",          
                dangerMode: true,
              }).then((willChange) => {
                if(willChange) {
                  this.$emit("compteurChanged", willChange.isConfirmed);
                }
              });
              
            }else{
              // warning toast
              this.$toast.warning("La puissance de la borne dépasse la limite maximale. Veuillez envisager une mise à jour de votre contrat."); 
              this.$emit("compteurChanged", false);
            }
         } else {
            this.$emit("compteurChanged", false);
         }
         
        } else {
          console.log("no borne selected");
        }
      },
    },
    type_TD: {
      deep: true,
      handler(newVal) {
        this.filterQuestionsList(this.listAllQuestions);
      },
    },
    distance_TD: {
      deep: true,
      handler(newVal) {
        this.filterQuestionsList(this.listAllQuestions);
      },
    },
  },
  computed: {},
  async created() {
    // get list Questions
    await this.getQuestions();
    // get list bornes
    await this.getBornes();
  },
  methods: {
    //tabs class
    linkClass(idx) {
      if (this.tabIndex === idx) {
        return ['bg-primary', 'text-light']
      } else {
        return ['bg-light', 'text-info']
      }
    },
    // tabs error
    checkTabQuestions(keyList) {
      let isNotFilled = false;
      keyList.forEach(key => {
        Object.values(this.choiceSelected[key]).forEach((question, index) => {
          if (question.id && question.id != -1 && !(question.value > 0)) {
            isNotFilled = true;
          }
        });
      });
      return isNotFilled;
    },
    async getQuestions() {
      await axios({
        url: process.env.VUE_APP_URL_API_CLIENT + "get_materiel_data/",
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
        data: JSON.stringify({
          token: this.$store.getters.getUserToken,
        }),
      }).then((res) => {
        if (res?.status == 200 && res?.data?.grouped_data) {
          const objects = [];
          for (const key in res.data.grouped_data) {
            if (res.data.grouped_data.hasOwnProperty(key)) {
              objects.push({ key, value: res.data.grouped_data[key] });
            }
          }
          // set all the questions in as default in a list example transformed result will be:
          // objects = [ {  key: 'Cheminement', value: { '1': { ..... question key/value data .....  options:[...] } } }, ... ]

          // Initialize choiceSelected for each question
          const initialChoiceSelected = {};
          objects.forEach((item) => {
            initialChoiceSelected[item.key] = {};
            for (const questionPart in item.value) {
              if (item.value.hasOwnProperty(questionPart)) {
                initialChoiceSelected[item.key][questionPart] = {
                  id: null,
                  value: 0,
                };
              }
            }
          });

          // Set the initialized choiceSelected object
          this.tabs = res.data.tabs;
          this.choiceSelected = initialChoiceSelected;
          this.listAllQuestions = objects;
          // filter the questions as needed
          this.filterQuestionsList(objects);
        }
      });
    },
    async getBornes() {
      await axios({
        url: process.env.VUE_APP_URL_API_CLIENT + "availableDevisItemsView/",
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
        data: JSON.stringify({
          token: this.$store.getters.getUserToken,
        }),
      }).then((res) => {
        this.listBornes = res.data.bornes;
      });
    },
    //filter questions
    filterQuestionsList(data) {
      // ATTENTION: "data" contain all questions from first api call
      // ATTENTION: "this.filteredQuestionsList" contain the previous questions filtered
      const newQuestionsList = [];
      data.forEach((item) => {
        let newItemValue = {};
        for (const questionPart in item.value) {
          if (item.value.hasOwnProperty(questionPart)) {
            const questionObject = item.value[questionPart]; // {"q": "Question num 1", ..., "options": []}
            const options = questionObject.options; // [{...},{...}]
            const selectedBorne = this.listBornes.find(
              (b) => b.id == this.borneSelected.id
            ); // { id: 21, nom: 'Départ pdc 3.7kVA', type: 'pdc 3.7kVA',... } or null

            // if no borne selected Skip this iteration and move to the next one
            if (!selectedBorne) {
              continue;
            }

            // filter options
            const newOptions = options.filter((opt) => {
              if (
                selectedBorne.type &&
                this.borneSelected.qte >= 0 &&
                this.type_phase &&
                this.type_TD
              ) {
                if (
                  (selectedBorne.type == opt.borne || opt.borne == "tout") &&
                  (this.type_phase == opt.phase || opt.phase == "tout") &&
                  (this.type_TD == opt.positionTD ||
                    opt.positionTD == "tout") &&
                  this.borneSelected.qte >= opt.nbBorne
                ) {
                  // option is allowed return it
                  return true;
                }
              }
              return false;
            });

            // set only question that have options
            if (newOptions.length > 0) {
              let newObjectValue = { ...questionObject, options: newOptions };
              newItemValue[questionPart] = newObjectValue;
            }
          }
        }

        // set group questions { key:'Cheminement', value:{'1':{...}}}
        if (Object.keys(newItemValue).length > 0) {
          newQuestionsList.push({ key: item.key, value: newItemValue });
        }
      });
      const prevQuestionsList = [...this.filteredQuestionsList];
      this.calculateChoiceSelected(prevQuestionsList, newQuestionsList);

      // add warning border
      const listWithWarning = this.findAndBorderWarning(
        prevQuestionsList,
        newQuestionsList
      );
      this.filteredQuestionsList = listWithWarning;
    },

    // calcutale choice selectedByCondition
    calculateChoiceSelected(prevQuestionsList, newQuestionsList) {
      const prevQuestionsMap = new Map();

      // Populate the map with questions from prevQuestionsList
      prevQuestionsList.forEach((category) => {
        for (const key in category.value) {
          if (category.value.hasOwnProperty(key)) {
            prevQuestionsMap.set(key, category.value[key]);
          }
        }
      });

      // Iterate through questions in newQuestionsList
      newQuestionsList.forEach((category) => {
        for (const questionKeyNumber in category.value) {
          if (category.value.hasOwnProperty(questionKeyNumber)) {
            const newQuestion = category.value[questionKeyNumber];
            const highestPricedOption = newQuestion.options.reduce(
              (prev, current) => (prev.prix > current.prix ? prev : current)
            );
            const closestDistanceOption = this.findClosestDistance(
              newQuestion.options,
              this.distance_TD
            );
            const prevCurrentQuestionChoiceSelected =
              this.choiceSelected[category.key][questionKeyNumber];
            // Check if the same question exists in prevQuestionsList
            if (prevQuestionsMap.has(questionKeyNumber)) {
              // Logic for questions that exist in prevQuestionsList
             
              // make the selecting based on the price and distance as default
              let selectedOptionId = prevCurrentQuestionChoiceSelected.id;
              newQuestion.options.forEach((opt) => {
                if (
                  opt.use_filter == "condition" &&
                  opt.id == closestDistanceOption.id
                ) {
                  selectedOptionId = opt.id;
                }
              });
              this.choiceSelected[category.key][questionKeyNumber] = {
                id: selectedOptionId,
                value: prevCurrentQuestionChoiceSelected.value,
              };
            } else {
              // Logic for questions that don't exist in prevQuestionsList
              if (newQuestion.obligatoire === false) {
                // select Non choice as default
                this.choiceSelected[category.key][questionKeyNumber] = {
                  id: -1,
                  value: 0,
                };
              } else {
                // make the selecting based on the price and distance as default
                let selectedOptionId = prevCurrentQuestionChoiceSelected.id;
                newQuestion.options.forEach((opt) => {
                  if (
                    opt.use_filter == "condition" &&
                    opt.id == closestDistanceOption.id
                  ) {
                    selectedOptionId = opt.id;
                  } else if (
                    opt.use_filter == "price" &&
                    opt.id == highestPricedOption.id
                  ) {
                    selectedOptionId = opt.id;
                  }
                });
                // this.choiceSelected[category.key][questionKeyNumber] = { id: selectedOptionId, value: prevCurrentQuestionChoiceSelected.value };
                this.choiceSelected[category.key][questionKeyNumber] = {
                  id: selectedOptionId,
                  value: 0,
                };
              }
            }
          }
        }
      });
    },

    // get section data
    getSectionDataToDisplay(key) {
      const found = this.filteredQuestionsList.find(obj => obj.key == key);
      return found.value;
    },

    // warning for new question
    findAndBorderWarning(prevQuestionsList, newQuestionsList) {
      const listWithWarning = [];

      newQuestionsList.forEach((newCategory) => {
        const categoryWithWarning = { key: newCategory.key, value: {} };

        for (const newQuestionKey in newCategory.value) {
          if (newCategory.value.hasOwnProperty(newQuestionKey)) {
            const newQuestion = newCategory.value[newQuestionKey];
            const prevCategory = prevQuestionsList.find(
              (prevCategoryItem) => prevCategoryItem.key === newCategory.key
            );
            if (
              prevQuestionsList.length > 0 &&
              (!prevCategory || !prevCategory.value[newQuestionKey])
            ) {
              // Question does not exist in the previous list, so add borderWarning: true
              newQuestion.borderWarning = true;
            } else {
              // Question exists in the previous list, so add borderWarning: false
              newQuestion.borderWarning = false;
            }

            categoryWithWarning.value[newQuestionKey] = newQuestion;
          }
        }

        listWithWarning.push(categoryWithWarning);
      });

      return listWithWarning;
    },

    // find closest distance in a given arr
    findClosestDistance(arr, givenDistance) {
      let closestDistanceObject = null;

      for (let i = 0; i < arr.length; i++) {
        if (parseFloat(arr[i].distanceTD) >= parseFloat(givenDistance)) {
          if (
            !closestDistanceObject ||
            parseFloat(arr[i].distanceTD) <
            parseFloat(closestDistanceObject.distanceTD)
          ) {
            closestDistanceObject = arr[i];
          }
        }
      }

      // If closestDistanceObject is still null, return the last element in the array
      if (!closestDistanceObject && arr.length > 0) {
        closestDistanceObject = arr[arr.length - 1];
      }

      return closestDistanceObject;
    },

    // handle Borne Change and change type_phase depend on the (selected Borne >= 11) => Triphasé or monophasé
    handleBorneChange() {
      const selectedBorne = this.listBornes.find(
        (b) => b.id == this.borneSelected.id
      );
      if (selectedBorne?.type) {
        // Use regular expression to match numbers (including commas and periods)
        var numbers = selectedBorne.type.match(/[0-9,]+/g);

        // remove commas and convert to a numeric value
        var numericValue = parseFloat(numbers[0].replace(/,/g, "."));
        if (numericValue >= 11) {
          this.type_phase = "Triphasé";
        } else {
          this.type_phase = "monophasé";
        }
        this.typePhaseDisabled = true;
      }
    },
    // rules for unité 'isFloat' or 'isNumber'
    getValidationRules(options, optionId) {
      const obj = options.find((o) => o.id == optionId); // object selected
      const unite = obj?.unite; // Replace with the actual property name that contains 'ml' or 'u'
      if (unite === "ml") {
        return [this.rules.required, this.rules.isFloat];
      } else if (unite === "u") {
        return [this.rules.required, this.rules.isNumber];
      } else {
        return [this.rules.required, this.rules.isNumberOrFloat]; // Default validation rules if unite is neither 'ml' nor 'u'
      }
    },
    // get unité 'ml' or 'u'
    getUnite(options, optionId) {
      const obj = options.find((o) => o.id == optionId); // option selected
      const unite = obj?.unite; // Replace with the actual property name that contains 'ml' or 'u'
      return unite || "";
    },
    // get Label 'mètre linéaire' or 'Quantité'
    getLabelUnite(options, optionId) {
      const obj = options.find((o) => o.id == optionId); // option selected
      const unite = obj?.unite; // Replace with the actual property name that contains 'ml' or 'u'
      if (unite === "ml") {
        return "mètre linéaire";
      } else if (unite === "u") {
        return "Quantité";
      } else {
        return "valeur";
      }
    },
    // handle warning text visibility by the option selected and distanceTD
    isCurrentDistanceHeigher(options) {
      // Find the option with the highest distance
      const highestDistanceOption = options.reduce((prev, current) =>
        parseFloat(prev.distanceTD) > parseFloat(current.distanceTD)
          ? prev
          : current
      );
      // check if the entered distance_TD by user is fall of the current options highest Distance or not
      if (highestDistanceOption?.distanceTD) {
        const highestDistance = parseFloat(highestDistanceOption.distanceTD);
        if (highestDistance > 0 && this.distance_TD > highestDistance) {
          return true;
        }
      }
      return false;
    },
  },
};
</script>

<style scoped>
.section-card {
  width: 100%;
  display: flex;
  flex-direction: row;
  background-color: #fff;
  border: 1px solid #dadce0;
  border-left: 5px solid #05a36e;
  border-radius: 8px;
  padding: 20px;
  box-shadow: inset 1px 1px 0 rgba(0, 0, 0, 0.1),
    inset 0 -1px 0 rgba(0, 0, 0, 0.07);
  margin-bottom: 20px;
}

.section-card-title {
  width: 100%;
  font-family: "docs-Roboto", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 14pt;
  color: #202124;
}

.question-card {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border: 1px solid #dadce0;
  box-shadow: inset 1px 1px 0 rgba(78, 78, 78, 0.1),
    inset 0 -1px 0 rgba(78, 78, 78, 0.07);
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
}

.question-card-title {
  font-size: 12pt;
  font-weight: 600;
  font-family: "docs-Roboto", Helvetica, Arial, sans-serif;
  letter-spacing: 0;
  margin-bottom: 15px;
}
</style>
