<template>
  <div>
    <v-form ref="formDaysPicker">
      <v-menu
        ref="menu"
        v-model="menuModel"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        max-width="290px"
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            outlined
            dense
            v-model="dateModel"
            :rules="[$rules.dateRules]"
            :label="labelDate"
            persistent-hint
            prepend-inner-icon="mdi-calendar"
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker v-model="dateModel" no-title @input="sendDate()"></v-date-picker>
      </v-menu>
    </v-form>
  </div>
</template>
<
<script>
export default {
  name: "DayPicker",
  props: ["labelDate", "date"],
  data() {
    return {
      dateModel: this.date,
      menuModel: false,
    };
  },
  created(){
    console.log(this.dateModel,this.date)
  },
  watch:{
    date:function(newValue,oldValue){
        console.log(this.dateModel)
        this.dateModel = newValue
    }
  },
  methods: {
    sendDate() {
        if(this.$refs.formDaysPicker.validate()){
            this.$emit("DaysPicker", this.dateModel);
        }
    },
  },
};
</script>
