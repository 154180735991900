<template>
  <v-app>
    <app-bar v-if="$store.getters.getUserToken" />
    <v-main id="main" :style="{ background: $vuetify.theme.themes.light.background }">
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
import appBar from "./components/app-bar";
export default {
  name: "App",
  components: { appBar },
  data: () => ({}),
};
</script>
<style>
@font-face {
  font-family: "Poppins";
  src: local("Poppins"),
    url("./assets/fonts/Poppins/Poppins-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins-Bold";
  src: local("Poppins-Bold"),
    url("./assets/fonts/Poppins/Poppins-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Outfit";
  src: local("Poppins"),
    url("./assets/fonts/Outfit/Outfit-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Outfit-Bold";
  src: local("Outfit-Bold"),
    url("./assets/fonts/Outfit/Outfit-Bold.ttf") format("truetype");
}

.Outfit {
  font-family: "Outfit";
}

.Outfit-Bold {
  font-family: "Outfit-Bold";
}

.Poppins {
  font-family: "Poppins";
}

.Poppins-Bold {
  font-family: "Poppins-Bold";
}
.fa-caret-down:before {
  font-size: initial !important;
}
.fa-chevron-down:before {
  font-size: small !important;
}
* {
  font-family: "Poppins";
}
</style>
