<template>
  <div
    class="d-flex flex-row justify-content-center align-items-center"
    style="height: 100%; width: 100%"
  >
    <v-card
      width="100%"
      elevation="0"
      class="d-flex flex-column justify-content-center align-items-center"
    >
      <v-icon size="100">mdi-database-off</v-icon>
      <span class="text-lg-h5 text-center my-4"
        >Aucune donnée disponible pour le moment</span
      >
    </v-card>
  </div>
</template>
<script>
export default {
  name: "no-data-table",
};
</script>
