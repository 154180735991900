<template>
  <div class="page-blanc">
    <img class="center-shape" src="../images/shapes/shape-center.png" />
    <img class="right-top-shape" src="../images/shapes/shape-3.png" />
    <img class="bottom-left-shape" src="../images/shapes/shape-2.png" />

    <img 
    class="logo-enffic-pdf"
    src="../images/logo-eneffic.png"
    />

    <p class="page-title">
      <span>Détail du processus</span>
    </p>
    <div class="content">
      <div class="cards-wrapper">
        <div class="card">
          <div class="number">
            <span>1</span>
          </div>
          <div class="icon-wrapper" style="top: -20px;">
            <img src="../images/detailProcessus/person-help.png" />
          </div>
          <p class="titleOfCard">Contact</p>
          <p class="subtitle">Prise de contact et analyse des besoins et des envies</p>
        </div>
        <div class="card">
          <div class="number">
            <span>2</span>
          </div>
          <div class="icon-wrapper" style="transform: rotate(25deg);">
            <img src="../images/detailProcessus/pre-etude.png" />
          </div>
          <p class="titleOfCard">Pré-étude à distance</p>
          <p class="subtitle"></p>
        </div>
        <div class="card">
          <div class="number">
            <span>3</span>
          </div>
          <div class="icon-wrapper" style="transform: rotate(12deg);">
            <img src="../images/detailProcessus/validation-offre.png" />
          </div>
          <p class="titleOfCard">Validation de l'offre</p>
          <p class="subtitle"></p>
        </div>
        <div class="card">
          <div class="number">
            <span>4</span>
          </div>
          <div class="icon-wrapper" style="right: -24px;transform: rotate(-30deg);">
            <img src="../images/detailProcessus/preparation-dossier.png" />
          </div>
          <p class="titleOfCard">Préparation dossier</p>
          <p class="subtitle">Détail technique environnemental ect..</p>
        </div>
        <div class="card">
          <div class="number">
            <span>5</span>
          </div>
          <div class="icon-wrapper">
            <img src="../images/detailProcessus/permis-de-construire.png" />
          </div>
          <p class="titleOfCard">Permis de construire</p>
          <p class="subtitle">Dépot de permis</p>
        </div>

        <div class="card">
          <div class="number">
            <span>6</span>
          </div>
          <div class="icon-wrapper">
            <img src="../images/detailProcessus/construction-exploitation.png" />
          </div>
          <p class="titleOfCard">Construction & exploitation</p>
          <p class="subtitle"></p>
        </div>

      </div>
    </div>
    <div class="footer">
      <ul>
        <li>ENEFFIC - 2 Aliée du Levant 34970 Lattes</li>
        <li>Téléphone: +33 (0)1 41 221 410 - E-mail: contact@eneffic.fr</li>
        <li>SIREN: 951 979 798</li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "DetailProcessus",
};
</script>

<style lang="scss" scoped>
.page-blanc{
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  padding: 32px;

  .center-shape{
    position: absolute;
    width: 650px;
    height: 650px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .right-top-shape{
    position: absolute;
    width: 75px;
    height: 75px;
    top: 8%;
    right: 0;
  }

  .bottom-left-shape{
    position: absolute;
    width: 150px;
    height: 150px;
    bottom: 0;
    left: 0;
  }

  .logo-enffic-pdf{
    height: 24px;
    margin-top: 40px;
    margin-left: 40px;
  }
  
  .page-title{
    width: fit-content;
    position: relative;
    font-size: 18pt;
    font-weight: 600;
    margin: 100px auto 200px;
    span{
      position: relative;
    }
    &::before{
      content: "";
      position: absolute;
      display: block;
      width: 90%;
      height: 16px;
      top: 60%;
      left: 0;
      background: #00f7bd;
    }
  }

  .content{
    position: relative;
    width: 100%;
    z-index: 1;
    padding: 48px 80px;

    .cards-wrapper{
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: flex-start;
      gap: 80px;
      margin: 0px 0px;

      .card{
        position: relative;
        display: flex;
        flex-direction: column;
        padding: 20px 40px;
        width: 320px;
        height: 150px;
        border-radius: 20px;
        border: none;
        // border: 5px solid rgba(218, 218, 218, 0.3);
        outline: none;
        text-align: left;
        box-shadow: -4px 4px 13px 0px rgba(151, 151, 151, 0.5), -4px 4px 0px 0px #00f7bd;

        .titleOfCard{
          font-size: 14pt;
          font-weight: 600;
          color:#075094;
          margin-bottom: 10px;
        }
        .subtitle{
          font-size: 12pt;
          font-weight: 500;
          color:#14324F;
          box-shadow: none;
        }

        .number{
          font-size: 20pt;
          font-weight: 600;
          text-align: center;
          background-color: #00f7bd;
          padding: 4px 4px;
          margin: 0px;
          width: 54px;
          height: 54px;
          position: absolute;
          top: 50%;
          left: -24px;
          transform: translateY(-50%);
        }

        .icon-wrapper{
          position: absolute;
          top: 10px;
          right: -35px;
          width: 75px;
          height: 75px;

          img{
            height: 100%;
          }
        }
      }
    }

  }
  .footer{
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0px;
    z-index: 2;
    font-size: 10pt;
    color: #14324F;
    font-weight: 400;
    text-align: center;
    ul{
      list-style-type: none;
    }
  }
}
</style>