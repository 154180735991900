<template>
  <v-card elevation="0" class="ma-4 pb-4">
    <v-dialog width="60%" v-if="openDialogPhoto" v-model="openDialogPhoto">
      <v-card>
        <v-img :src="pathImage"> </v-img>
      </v-card>
    </v-dialog>

    <v-dialog width="60%" v-model="pdfDialog">
      <v-card>
          <vue-pdf-app style="height: 100vh;" :pdf="pathPdf" :page-scale="50" v-if="pdfDialog"></vue-pdf-app>
      </v-card>
    </v-dialog>
        <!-- display all pages-->
    <v-row v-if="addImagesBool || onlyImage == false" class="mb-8" justify="center">
      <uploadFilesProfilClient :idSociete="idSociete" :selectedFile="fileDetails" @updateFiles="handelUpdateList()" />
    </v-row>
    <v-row v-if="addImagesBool || onlyImage == false" class="px-4 mb-8">
      <v-col
        v-for="(file, index) in filesParent"
        :key="index"
        class="d-flex child-flex"
        cols="3" >
        <!--generation images-->
        <v-tooltip v-if="file?.path && imageExtention.includes(file.extention)" bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-card v-bind="attrs" v-on="on" class="d-flex flex-row">
              <v-icon
                color="grey"
                @click.stop="removeElement(index,file.id)"
                style="position: absolute; top: 3px; right: 3px;
                 z-index: 100;background-color: #ffffff;
                 border-radius: 30px;
                 padding: 2px 2px;"
                >"mdi mdi-delete</v-icon
              >
              <v-img               
                @click="openDialogShowPhoto(file.path)"
                style="cursor: pointer"
                height="125px"
                :src="VUE_APP_URL_API_CLIENT_BASE_URL+file.path">
                <template v-slot:placeholder>
                  <v-row class="fill-height ma-0" align="center" justify="center">
                    <v-progress-circular
                      indeterminate
                      color="grey lighten-5"
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
            </v-card>
          </template>
          <span>{{ file.name }}</span>
        </v-tooltip>
        <!--generation elements non images (pdf,csv,excel....)-->
        <v-tooltip v-else-if="file?.path && file.extention=='pdf'" bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-card @click="openDialogShowPdf(file.path)" v-bind="attrs" v-on="on" class="d-flex flex-row" style="min-height: 125px; background-color: #fafafa">
              <v-icon
                color="grey"
                @click.stop="removeElement(index)"
                style="position: absolute; top: 3px; right: 3px; z-index: 15;box-shadow: 10px;"
                >"mdi mdi-delete</v-icon>
                <div class="icon mx-auto my-auto">
                  <v-icon
                    style="font-size: 65px; color: #f44336">
                    mdi mdi-file-pdf-box
                  </v-icon>
                </div>
            </v-card>
           
          </template>
          
        </v-tooltip>
        
        <div v-else class="d-flex flex-column">
          <v-icon
            color="grey"
            @click.stop="removeElement(index,file.id)"
              style="position: absolute; top: 16px;
              box-shadow: 10px;
              right: 19px; z-index: 100"
            >"mdi mdi-delete</v-icon>
          <!--generation d'une carte pour les elements avec extension enregistrés (voir les types présents dans fileList-->
         
          <!--generation d'une carte pour les elements avec extension non enregistrés (voir les types présents dans fileList-->
          <div >
            <v-card
              elevation="0"
              height="100%"
              style="min-height: 125px; background-color: #fafafa"
              class="d-flex flex-column align-items-center"
            >
              <div
                style="height: 100%; width: 100%"
                class="rounded-lg d-flex flex-column align-items-end justify-center"
              >
                <div
                  style="width: 100%"
                  class="d-flex flex-row align-center justify-space-around"
                >
                  <v-icon
                    style="font-size: 65px; color:rgb(202, 202, 202)"
                    >mdi mdi-file-outline</v-icon >
                </div>
              </div>
              <v-btn
                dense
                text
                width="100%"
                height="25%"
                
                
                @click="downloadRessource(index)"
                class="mt-2"
                style="font-size: 12px !important;
                background-color: rgb(190, 201, 204);
                "
                >
                <span
                  style="width: 95%;color: rgb(249, 252, 251);"
                  class="fontSizeMediaQuery"
                  v-bind="attrs"
                  v-on="on">Download <v-icon> mdi mdi-download </v-icon></span>
              
              </v-btn>
            </v-card>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
import uploadFilesProfilClient from "./uploadFilesProfilClient.vue";
import axios from "@/plugins/axios";
import VuePdfApp from "vue-pdf-app";
import "vue-pdf-app/dist/icons/main.css";
export default {
  name: "gallery",
  props: {
    filesParentProps: {
      type: Array,
      default: function () {
        return [];
      },
    },
    addImagesBool: {
      type: Boolean,
      default: false,
    },
    onlyImage: {
      type: Boolean,
      default: false,
    },
    idSociete:{
      type: String,
    },
    fileDetails: {
      type: Object,
    },
    listLength: {
      type: Number,
      
    },
    

  },
  components: { uploadFilesProfilClient,VuePdfApp },
  data() {
    return {
      fileList: [
        { 
          type: "pdf", 
          color: "#f44336",
          classIcon: "far fa-file-pdf" 
        },
        {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          color: "#4caf50",
          classIcon: "far fa-file-excel",
        },
        { type: "text/csv", color: "#4caf50", classIcon: "far fa-file-excel" },
        {
          type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
          color: "#1b5ebe",
          classIcon: "far fa-file-word",
        },
        {
          type: "application/zip",
          color: "#000000",
          classIcon: "far fa-file-archive",
        },
        {
          type: "application/x-zip-compressed",
          color: "#000000",
          classIcon: "far fa-file-archive",
        },
      ],
      imageExtention : ["jpg", "jpeg", "png", "gif"],
      pdfDialog:false,
      pathImage: "",
      pathPdf: "",
      VUE_APP_URL_API_CLIENT_BASE_URL: process.env.VUE_APP_URL_API_CLIENT_BASE_URL,
      openDialogPhoto: false,
      fileRequiredBool: false,
      maxNumberFile: 10,
      filesParent: [],
      dialogAddPhoto: false,
      

    };
  },
   created() {
    this.filesParent = [...this.filesParentProps];
    this.filesParent.forEach((element) => {
        console.log(this.uniqueTypes);
        let index = this.uniqueTypes.findIndex((el) => el == element.extention);
        console.log(index);
        if (index != -1) {
          console.log(this.fileList[index]);
          element.color = this.fileList[index].color;
          element.classIcon = this.fileList[index].classIcon;
          console.log(element);
        }
      });
  },
  computed: {
    uniqueTypes() {
      const types = new Set();
      this.fileList.forEach((obj) => {
        types.add(obj.type);
      });
      return Array.from(types);
    },
    
  },
  watch: {
    filesParentProps: function (newValue) {
      this.filesParent = [...newValue];
      console.log(this.filesParent);
      this.filesParent.forEach((element) => {
        console.log(this.uniqueTypes);
        let index = this.uniqueTypes.findIndex((el) => el == element.extention);
        console.log(index);
        if (index != -1) {
          console.log(this.fileList[index]);
          element.color = this.fileList[index].color;
          element.classIcon = this.fileList[index].classIcon;
          console.log(element);
        }
      });
      console.log(this.filesParent);
    },
    fileDetails : function(newValue, oldValue){
      console.log(newValue, oldValue);
      getImagesFromServerSide();
     
    },
    
  },
  methods: {
    downloadRessource(index) {
      console.log(this.filesParent[index]);
      let fileURL = "";
      fileURL = this.filesParent[index].path;
      const link = document.createElement("a");
      link.href = process.env.VUE_APP_URL_API_CLIENT_BASE_URL + fileURL;
      link.download = `fichier.${this.filesParent[index].extention}`;
      link.click();
    },
    removeElement(index,fichier) {
      this.filesParent.splice(index, 1);
      this.deleteFileServerSide(fichier);
    },
  
    openDialogShowPhoto(path) {

      path= process.env.VUE_APP_URL_API_CLIENT_BASE_URL + path;
      this.openDialogPhoto = true;
      this.pathImage = path;
    },
    openDialogAddPhoto() {
      this.dialogAddPhoto = true;
    },
    openDialogShowPdf(path) {
     
      path= process.env.VUE_APP_URL_API_CLIENT_BASE_URL + path;
      this.pdfDialog = true;
      this.pathPdf = path;
      console.log(path);

    },
    retrieveFile(e, fichier) {
      this.filesParent = e.slice();
      
    },

    async getImagesFromServerSide(){
      console.log(this.listLength);
 
      await axios({
        url: process.env.VUE_APP_URL_API_CLIENT + this.fileDetails.url,
        method: "POST",
        data:{
          id_societe: this.idSociete,
          token: this.$store.getters.getUserToken,
          length: this.listLength,

        },
      }).then((res) => {
        if (res.data.status) {
          this.filesParent = res.data.data.data;
          console.log("pagination", res.data.data.pagination);
          this.$emit("updatePagination", res.data.data.pagination);
         
        }else{
          this.filesParent = [];

        }
      });
    },

    handelUpdateList(){
      this.getImagesFromServerSide();
    },
    async deleteFileServerSide(file_id){
      // delete file from server
      await axios({
        url: process.env.VUE_APP_URL_API_CLIENT + "deleteDocumentProfilClient/",
        method: "POST",
        data:{
          id_societe: this.idSociete,
          token: this.$store.getters.getUserToken,
          id_profil_client: file_id,

        },
      }).then((res) => {
        if (res.data.status) {
          this.getImagesFromServerSide();
        }else{
          
          console.log(res.data.message);
        }
      });
      
    },
  },
};
</script>
<style scoped>
@media screen and (max-width: 1920px) {
  .fontSizeMediaQuery {
    font-size: 11px;
  }
}
</style>