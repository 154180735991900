<template>
  <div
    style="
      background-color: white;
      text-align: center;
      font-family: 'Poppins', sans-serif;
      padding: 60px;
      margin: 0px;
    "
  >
    <div
      style="
        height: 150px;
        width: 100%;
        background-color: #14324f;
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: white;
        padding: 0 20px;
      "
    >
      <div
        style="
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          width: 100%;
        "
      >
        <img
          class="logo"
          src="../../assets/logo/ENEFFIC-logo-white.png"
          alt="Logo"
          style="max-height: 100px; max-width: 200px"
        />
        <div
          class="address"
          style="
            color: white;
            display: flex;
            flex-direction: column;
            align-items: flex-end;
          "
        >
          <span>2 Allée du Levant 34970 Lattes</span>
          <span>+33 (0)1 41 22 1 4 10</span>
          <span>contact@eneffic.fr</span>
        </div>
      </div>
    </div>

    <div
      class="main-title"
      style="color: #14324f; font-size: 55px; font-weight: bold; margin-top: 5rem"
    >
      ESTIMATIF BUDGETAIRE
    </div>
    <div
      class="sub-title"
      style="color: #14324f; font-size: 20px; font-weight: bold; margin-top: 2rem"
    >
      Infrastructure de Recharge de Véhicule Electrique
    </div>

    <div
      class="info-container"
      style="
        border: 2px solid black;
        width: 100%;
        height: 150px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 10rem;
      "
    >
      <img
        class="info-image"
        src="https://picsum.photos/id/11/500/300"
        alt="Sample Image"
        style="max-width: 200px; max-height: 100px; margin-right: 8px; margin-left: 8px"
      />
      <div class="info-text" style="color: #14324f; font-size: 20px">
        <span>{{ societe?.raison.toUpperCase() }}</span>
        <span>{{ societe?.adressePostal }}</span>
      </div>
    </div>

    <div class="" style="width: 100%; margin-top: 10rem">
      <div style="display: flex; flex-direction: row; justify-content: space-between">
        <div
          class="signature"
          style="
            width: fit-content;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 8px;
          "
        >
          <span style="font-style: italic; color: #14324f">Bon pour Accord</span>
          <span style="color: #14324f; font-weight: bold">Client</span>
          <span style="color: #14324f">Date, Nom, Signature, Cachet</span>
        </div>
        <div
          class=""
          style="
            width: fit-content;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 8px;
          "
        >
          <span style="font-style: italic; color: #14324f">Bon pour Accord</span>
          <span style="color: #14324f; font-weight: bold">Entreprise</span>
          <span style="color: #14324f">Date, Nom, Signature, Cachet</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["societe"],
};
</script>
<style scoped>
.page {
  page-break-before: always;
  page-break-after: always;

  margin: 0;
}
</style>
