<template>
  <div class="d-flex flex-row flex-wrap">
    <div class="item-wrapper" v-for="(file, index) in fileCopy" :key="index">
      <a :href="baseURL + file.path" target="_blank" class="item" style="background-color: transparent">
        <div
          style="
            align-items: center;
            justify-content: center;
            align-self: center;
            margin: 8px;
          "
        >
          <v-icon color="red" size="48">mdi-file-pdf-box</v-icon>
        </div>
        <div>
          <span>PDF n°{{ ++index }}</span>
        </div>
        <v-btn
          v-if="isRemovable"
          class="mx-2"
          icon
          color="error"
          @click="remove(index)"
          title="Remove file"
        >
          <v-icon dark> mdi-delete-forever </v-icon>
        </v-btn>
      </a>
    </div>
  </div>
</template>
<script>
export default {
  name: "listFile",
  props: ["isRemovable", "file"],
  data() {
    return {
      baseURL: "",
      fileCopy: this.file,
    };
  },
  mounted() {
    this.baseURL = process.env.VUE_APP_URL_API_CLIENT_BASE_URL;
  },
  watch: {
    file: function () {
      this.fileCopy = this.file;
    },
  },
  methods: {
    remove(index) {
      this.fileCopy.splice(index, 1);
      this.$emit("remove", this.fileCopy);
    },
  },
};
</script>

<style scoped>
.item-wrapper{
  width: 100px;
  background: #fff;
  margin: 5px;
  border-radius: 3px;
  border: 1px solid lightgray;
  cursor: pointer;
}
.item {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background: #fff;
}
</style>
