<template>
  <div class="p-4">
    <div class="shadow-sm">
      <breadcrumb :listNavs="listNavs" />
    </div>

    <v-card class="p-4">
      <datatable
        id="table-validation-cotation"
        :columns="columns"
        :sortKey="sortKey"
        :sortOrders="sortOrders"
        @sort="sortBy"
        @search="rechercheData"
      >
        <template v-slot:header>
          <div style="width: 200px" class="float-end container-div mr-2">
            <select
              v-model="filterStatus"
              class="form-control"
              id="sel1"
              @change="filterByStatus"
            >
              <option value="tout">Toutes les demandes</option>
              <option value="1">acceptée</option>
              <option value="0">en attente</option>
              <option value="2">refusée</option>
            </select>
          </div>
        </template>
        <template v-slot:body>
          <tbody class="text-center" v-if="projects.length > 0 && loadingTable == false">
            <tr v-for="(project, index) in projects" :key="project.id">
              <td>
                <!-- redirect to profil id -->
                <div class="d-flex flex-row justify-content-center">
                  <v-btn
                    @click="redirectProfilDevis(project.id)"
                    class="mr-2"
                    icon
                    small
                    plain
                  >
                    <v-icon :color="$colors[0]"> mdi-eye </v-icon>
                  </v-btn>
                </div>
              </td>
              <td>{{ project.dateAjout }}</td>
              <td>
                <v-tooltip v-if="project.societe.raison?.length >= 15" bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <small class="td-table" v-bind="attrs" v-on="on">
                      <router-link :to="`/detail-societe/${project.societe.id}`">{{
                        project.societe.raison.substring(0, 15) + "..."
                      }}</router-link>
                    </small>
                  </template>
                  <span>{{ project.societe.raison }}</span>
                </v-tooltip>
                <small class="td-table" v-else>
                  <router-link :to="`/detail-societe/${project.societe.id}`">{{
                    project.societe.raison
                  }}</router-link>
                </small>
              </td>
              <td class="text-left">
                <div style="max-width: 200px">
                  <router-link :to="`/detail-compteur/${project.compteur.id}`">
                    <small>{{ project.compteur.numCompteur }}</small>
                  </router-link>
                </div>
              </td>
              <td>{{ project.borneCount ? project.borneCount : "manquant" }}</td>

              <td>{{ project.nombrePointDeRechargeCount }}</td>

              <td>
                {{
                  $store.getters.hasPermission("DEVIS_APD", "consulter_prix")
                    ? `${Number(project.prixTotal).toLocaleString("fr-FR")} €`
                    : "*****"
                }}
              </td>

              <td>
                <div class="d-flex flex-column btn-group">
                  <div class="d-flex flex-row">
                    <small class="small-status" v-if="project.status == 'refusee'"
                      ><v-icon dense style="color: #ff0000">mdi-close-circle</v-icon
                      ><b class="ml-2">Refusé</b></small
                    >
                    <small class="small-status" v-if="project.status == 'annuler'"
                      ><v-icon dense style="color: #ff0000"
                        >mdi-file-cancel-outline</v-icon
                      ><b class="ml-2">Annulé</b></small
                    >
                    <small class="small-status" v-if="project.status == 'en_attend'"
                      ><v-icon dense style="color: #2080ee">mdi-clock-outline</v-icon
                      ><b class="ml-2" style="word-break: keep-all">en attente</b></small
                    >
                    <small class="small-status" v-if="project.status == 'en_cours'"
                      ><v-icon dense style="color: orange">mdi-progress-clock</v-icon
                      ><b class="ml-2">en cours</b></small
                    >
                    <small class="small-status" v-if="project.status == 'pre_valider'"
                      ><v-icon dense style="color: #9c27b0">mdi-progress-check</v-icon
                      ><b class="ml-2">pré validé</b></small
                    >
                    <small class="small-status" v-if="project.status == 'valider'"
                      ><v-icon dense style="color: #00e676"
                        >mdi-check-circle-outline</v-icon
                      ><b class="ml-2" style="word-wrap: normal">Validé</b></small
                    >
                    <small class="small-status" v-if="project.status == 'signer'"
                      ><v-icon dense style="color: rgb(134, 29, 90)">mdi-file-sign</v-icon
                      ><b class="ml-2" style="word-wrap: normal">Signé</b></small
                    >
                    <small
                      class="small-status"
                      v-if="project.status == 'envoyer_signiature'"
                      ><v-icon dense style="color: rgb(114, 85, 7)"
                        >mdi mdi-send-clock</v-icon
                      ><b class="ml-2" style="word-wrap: normal"
                        >envoie signature</b
                      ></small
                    >
                    <small class="small-status" v-if="project.status == 'renitialiser'"
                      ><v-icon dense style="color: rgb(121, 166, 196)"
                        >mdi mdi-lock-reset</v-icon
                      ><b class="ml-2" style="word-wrap: normal">réinitialiser</b></small
                    >

                    <div class="d-flex flex-row" v-if="showActionsBtns(project.status)">
                      <v-icon
                        class="dropdown-toggle"
                        data-toggle="collapse"
                        :data-target="'#collapseStatut' + index"
                        aria-expanded="false"
                        :aria-controls="'collapseStatut' + index"
                        size="20"
                        left
                      >
                        mdi-menu-down
                      </v-icon>
                    </div>
                  </div>
                  <div
                    class="collapse"
                    :id="'collapseStatut' + index"
                    style="border: 0px"
                    v-if="showActionsBtns(project.status)"
                  >
                    <div class="d-flex flex-row mt-4 mt-lg-2">
                      <!-- Refuser Devis -->
                      <v-btn
                        title="Refus de devis"
                        @click="
                          ActionsBtns(
                            project.id,
                            'refusee',
                            `Vous êtes sur le point de refuser ce devis`
                          )
                        "
                        class="dropdown-project"
                        v-if="
                          $store.getters.hasPermissionByStatus(
                            'DEVIS_APD',
                            project.status,
                            'refusee'
                          )
                        "
                        icon
                        small
                        plain
                      >
                        <v-icon color="red"> mdi-close-circle </v-icon>
                      </v-btn>

                      <!-- annulé -->
                      <v-btn
                        title="Annulation de devis"
                        @click="
                          ActionsBtns(
                            project.id,
                            'annuler',
                            `Vous êtes sur le point d'annuler ce devis`
                          )
                        "
                        class="dropdown-project"
                        v-if="
                          $store.getters.hasPermissionByStatus(
                            'DEVIS_APD',
                            project.status,
                            'annuler'
                          )
                        "
                        icon
                        small
                        plain
                      >
                        <v-icon color="red"> mdi-cancel </v-icon>
                      </v-btn>

                      <!-- renitialiser-->
                      <v-btn
                        title="Réinitialisation de devis"
                        @click="
                          ActionsBtns(
                            project.id,
                            'en_attend',
                            `Vous êtes sur le point de réinitialiser ce devis`
                          )
                        "
                        class="dropdown-project"
                        v-if="
                          $store.getters.hasPermissionByStatus(
                            'DEVIS_APD',
                            project.status,
                            'renitialiser'
                          )
                        "
                        icon
                        small
                        plain
                      >
                        <v-icon color="grey accent-5"> mdi mdi-lock-reset </v-icon>
                      </v-btn>

                      <!-- signé button -->
                      <v-btn
                        title="signer un devis"
                        @click="
                          ActionsBtns(
                            project.id,
                            'signer',
                            `Vous êtes sur le point de signer ce devis`
                          )
                        "
                        class="dropdown-project"
                        v-if="
                          $store.getters.hasPermissionByStatus(
                            'DEVIS_APD',
                            project.status,
                            'signer'
                          )
                        "
                        icon
                        small
                        plain
                      >
                        <v-icon color="rgb(134, 29, 90)"> mdi mdi-draw </v-icon>
                      </v-btn>

                      <!-- envoyer signiature button -->
                      <v-btn
                        title="Envoie de signature"
                        @click="
                          ActionsBtns(
                            project.id,
                            'envoyer_signiature',
                            `Vous êtes sur le point d'envoyer une signature pour ce devis`
                          )
                        "
                        class="dropdown-project"
                        v-if="
                          $store.getters.hasPermissionByStatus(
                            'DEVIS_APD',
                            project.status,
                            'envoyer_signiature'
                          )
                        "
                        icon
                        small
                        plain
                      >
                        <v-icon color="grey accent-5"> mdi mdi-send-clock </v-icon>
                      </v-btn>

                      <!-- Pré Valider Devis -->
                      <v-btn
                        title="Pré-validation de devis"
                        @click="
                          ActionsBtns(
                            project.id,
                            'pre_valider',
                            `Vous êtes sur le point de pré-valider ce devis`
                          )
                        "
                        v-if="
                          $store.getters.hasPermissionByStatus(
                            'DEVIS_APD',
                            project.status,
                            'pre_validation'
                          )
                        "
                        class="dropdown-project"
                        icon
                        small
                        plain
                      >
                        <v-icon color="#9C27B0"> mdi-eye-check </v-icon>
                      </v-btn>

                      <!-- Valider Devis -->
                      <v-btn
                        title="Validation de devis"
                        @click="
                          ActionsBtns(
                            project.id,
                            'valider',
                            `Vous êtes sur le point de valider ce devis`
                          )
                        "
                        v-if="
                          $store.getters.hasPermissionByStatus(
                            'DEVIS_APD',
                            project.status,
                            'valider'
                          )
                        "
                        class="dropdown-project"
                        icon
                        small
                        plain
                      >
                        <v-icon color="green accent-3"> mdi-check-circle </v-icon>
                      </v-btn>

                      <!-- Creer Avenant -->
                      <v-btn
                        title="Création Avenant"
                        @click="CreerAvenant(project.id)"
                        class="dropdown-project"
                        v-if="
                          $store.getters.hasPermissionByStatus(
                            'DEVIS_APD',
                            project.status,
                            'create_avenant'
                          )
                        "
                        icon
                        small
                        plain
                      >
                        <v-icon color="blue accent-5">
                          mdi mdi-file-document-edit-outline
                        </v-icon>
                      </v-btn>

                      <!-- Creer Viste Technique -->
                      <v-btn
                        title="Création d'une visite technique"
                        @click="CreerUneVisteTechnique(project.id)"
                        class="dropdown-project"
                        v-if="
                          $store.getters.hasPermissionByStatus(
                            'DEVIS_APD',
                            project.status,
                            'create_VT'
                          )
                        "
                        icon
                        small
                        plain
                      >
                        <v-icon color="blue accent-5"> mdi mdi-toolbox </v-icon>
                      </v-btn>

                      <!-- Creer Viste Installation -->
                      <v-btn
                        title="Création d'une visite d'installation"
                        @click="CreerUneVisteInstallation(project.id)"
                        class="dropdown-project"
                        v-if="
                          $store.getters.hasPermissionByStatus(
                            'DEVIS_APD',
                            project.status,
                            'create_VI'
                          )
                        "
                        icon
                        small
                        plain
                      >
                        <v-icon color="green accent-5"> mdi-hammer-screwdriver </v-icon>
                      </v-btn>
                    </div>
                  </div>
                </div>
              </td>

              <!-- button voir liste avenant -->
              <td>
                <v-btn
                  title="Voir Liste Avenant"
                  @click="voirListeAvenant(project.id)"
                  class="text-primary px-0"
                  small
                  plain
                >
                  Voir Liste Avenant
                </v-btn>
              </td>
            </tr>
          </tbody>
          <noDataTable
            class="ma-4"
            v-if="projects.length == 0 && loadingTable == false"
          />
          <progressCircular class="ma-8" v-if="loadingTable == true" />
        </template>
      </datatable>
      <div
        class="d-flex flex-row justify-content-center justify-sm-start align-items-center"
      >
        <pagination
          v-if="projects.length > 0"
          :pagination="pagination"
          @selectNumberOfRecords="rechercheData"
          @first="getData(pagination.firstPage)"
          @last="getData(pagination.lastPage)"
          @prev="getData(pagination.prevPageUrl)"
          @current="getData(pagination.currentPage)"
          @next="getData(pagination.nextPageUrl)"
        >
        </pagination>
      </div>
    </v-card>
  </div>
</template>

<script>
import "jquery/dist/jquery.min.js";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.bundle";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import Datatable from "../../components/dataTable/Datatable.vue";
import Pagination from "../../components/dataTable/Pagination.vue";
import noDataTable from "../../components/dataTable/no-data-table.vue";
import progressCircular from "../../components/dataTable/progress-circular.vue";
import axios from "@/plugins/axios";
import breadcrumb from "@/components/breadcrumb.vue";
export default {
  name: "listeAPD",
  components: {
    datatable: Datatable,
    pagination: Pagination,
    noDataTable,
    progressCircular,
    breadcrumb,
  },
  data() {
    let sortOrders = {};
    let columns = [
      { width: "5vw", label: "#", name: "profil-devis", sortable: true },
      { width: "14vw", label: "Date d'ajout", name: "date", sortable: true },
      { width: "14vw", label: "Raison Sociale", name: "Raison", sortable: true },
      { width: "14vw", label: "Compteur", name: "numCompteur", sortable: true },
      { width: "14vw", label: "Borne", name: "nbr", sortable: true },
      { width: "14vw", label: "PDR", name: "recharge", sortable: true },
      { width: "14vw", label: "Total", name: "prixTotal", sortable: true },
      { width: "14vw", label: "Statut", name: "status", sortable: true },
      { width: "14vw", label: "Actions", name: "actions", sortable: false },
    ];
    columns.forEach((column) => {
      sortOrders[column.name] = -1;
    });
    return {
      listNavs: [
        {
          name: "Devis",
          link: "#",
        },
        {
          name: "Liste APD(s)",
          link: "#",
        },
      ],
      loadingTable: false,
      filterStatus: "tout",
      projects: [],
      columns: columns,
      sortKey: "date",
      sortOrders: sortOrders,
      perPage: ["15", "20", "30"],
      tableData: {
        statusFilter: "",
        draw: 0,
        length: 15,
        search: "",
        column: 0,
        dir: "desc",
      },
      pagination: {
        perPage: ["10", "15", "30"],
        firstPage: 1,
        lastPage: "",
        currentPage: "",
        total: "",
        lastPageUrl: "",
        nextPageUrl: "",
        prevPageUrl: "",
        from: "",
        to: "",
      },
    };
  },
  async created() {
    await this.getData();
    console.log(this.loadingTable);
  },
  computed: {
    roleUser() {
      return this.$store.getters.getUserRole;
    },
    showActionsBtns() {
      return (status) => {
        return (
          this.$store.getters.hasPermissionByStatus("DEVIS_APD", status, "valider") ||
          this.$store.getters.hasPermissionByStatus(
            "DEVIS_APD",
            status,
            "renitialiser"
          ) ||
          this.$store.getters.hasPermissionByStatus("DEVIS_APD", status, "annuler") ||
          this.$store.getters.hasPermissionByStatus("DEVIS_APD", status, "signer") ||
          this.$store.getters.hasPermissionByStatus(
            "DEVIS_APD",
            status,
            "envoyer_signiature"
          ) ||
          this.$store.getters.hasPermissionByStatus("DEVIS_APD", status, "refusee") ||
          this.$store.getters.hasPermissionByStatus(
            "DEVIS_APD",
            status,
            "pre_validation"
          ) ||
          this.$store.getters.hasPermissionByStatus("DEVIS_APD", status, "create_VT") ||
          this.$store.getters.hasPermissionByStatus("DEVIS_APD", status, "create_VI") ||
          this.$store.getters.hasPermissionByStatus("DEVIS_APD", status, "create_avenant")
        );
      };
    },
  },
  methods: {
    voirListeAvenant(id) {
      this.$router.push({ path: `/Devis/listeAvenantByAPD/${id}` });
    },
    CreerAvenant(id) {
      const props = {
        id: id,
        source: "apd",
      };
      this.$router.push({ path: `/Devis/FormulaireAvenant`, query: props });
    },
    CreerUneVisteTechnique(id_devis) {
      const props = {
        id_devis: id_devis,
      };
      this.$router.push({ path: "/visite-technique/nouveau-formulaire", query: props });
    },
    CreerUneVisteInstallation(id_devis) {
      const props = {
        id_devis: id_devis,
      };
      this.$router.push({
        path: "/visite-installation/ajoutVisiteInstallation",
        query: props,
      });
    },
    filterByStatus() {
      var pageNumber = 1;
      if (this.filterStatus != "tout") {
        this.tableData.statusFilter = this.filterStatus;
        this.getData(1);
      } else {
        this.tableData.statusFilter = "";
        this.getData(1);
      }
    },
    rechercheData({ type, key }) {
      if (type == "search") {
        var pageNumber = 1;
        this.tableData.search = key;
      } else if (type == "nbrRecords") {
        this.tableData.length = key;
      }
      this.tableData.draw++;
      axios({
        url: process.env.VUE_APP_URL_API_CLIENT + "get_valide_devis/",
        method: "POST",
        data: {
          dataTable: this.tableData,
          pageNumber: pageNumber,
          token: this.$cookies.get("token"),
        },
      })
        .then((response) => {
          if (response.data.result == true) {
            let data = response.data.data;
            if (this.tableData.draw == data.draw) {
              this.projects = data.data.data;
              this.configPagination(data.data);
            }
          }
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    async getData(pageNumber = 1) {
      this.loadingTable = true;
      this.tableData.draw++;
      await axios({
        url: process.env.VUE_APP_URL_API_CLIENT + "get_valide_devis/",
        method: "POST",
        data: {
          dataTable: this.tableData,
          pageNumber: pageNumber,
          token: this.$cookies.get("token"),
        },
      })
        .then((response) => {
          if (response.data.result == true) {
            let data = response.data.data;
            console.log(data);
            if (this.tableData.draw == data.draw) {
              this.projects = data.data.data;
              this.configPagination(data.data);
            }
          }
        })
        .catch((errors) => {
          console.log(errors);
        });
      this.loadingTable = false;
    },
    ActionsBtns(id, status, actionMessage) {
      this.$swal({
        title: "Êtes-vous sûr?",
        text: actionMessage,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#007991",
        cancelButtonColor: "#d33",
        cancelButtonText: "Annuler",
        confirmButtonText: "Je Confirme",
      }).then((result) => {
        if (result.isConfirmed) {
          this.dialog = true;
          this.loading = true;
          this.error = false;
          var formData = new FormData();
          formData.append("id", id);
          formData.append("status", status);
          formData.append("token", this.$store.getters.getUserToken);
          axios({
            url: process.env.VUE_APP_URL_API_CLIENT + "changeAPDStatus/",
            method: "POST",
            data: formData,
          })
            .then((res) => {
              if (res.status == 200) {
                this.getData(this.pagination.currentPage);
                console.log(res.data);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }
      });
    },
    spliteList(list) {
      return list.split(",");
    },
    spliteListSlice(list) {
      return list.split(",").slice(1);
    },
    configPagination(data) {
      this.pagination.lastPage = data.last_page;
      this.pagination.currentPage = data.current_page;
      this.pagination.total = data.total;
      this.pagination.lastPageUrl = data.last_page_url;
      this.pagination.nextPageUrl = data.next_page_url;
      this.pagination.prevPageUrl = data.prev_page_url;
      this.pagination.from = data.from;
      this.pagination.to = data.to;
    },
    sortBy(key) {
      this.sortKey = key;
      this.sortOrders[key] = this.sortOrders[key] * -1;
      this.tableData.column = this.getIndex(this.columns, "name", key);
      this.tableData.dir = this.sortOrders[key] === 1 ? "asc" : "desc";
      this.getData();
    },
    getIndex(array, key, value) {
      return array.findIndex((i) => i[key] == value);
    },
    redirectProfilDevis(id) {
      this.$router.push({ path: `/profilAPD/${id}` });
    },
  },
};
</script>
<style scoped>
#table-validation-cotation th {
  color: white;
  font-weight: bold;
}
@media only screen and (max-width: 400px) {
  .container-div {
    width: 125px !important;
    padding: 0px;
    margin: 0;
  }
}
@media only screen and (max-width: 760px),
  (min-device-width: 768px) and (max-device-width: 1024px) {
  @import "../../components/dataTable/style.css";
  #table-validation-cotation td:nth-of-type(1):before {
    content: "Date d'ajout";
  }
  #table-validation-cotation td:nth-of-type(2):before {
    content: "Commercial";
  }
  #table-validation-cotation td:nth-of-type(3):before {
    content: "Raison Sociale";
  }
  #table-validation-cotation td:nth-of-type(4):before {
    content: "Energie";
  }
  #table-validation-cotation td:nth-of-type(5):before {
    content: "Compteur";
  }
  #table-validation-cotation td:nth-of-type(6):before {
    content: "Pièce jointe";
  }
  #table-validation-cotation td:nth-of-type(7):before {
    content: "Début de contrat";
  }
  #table-validation-cotation td:nth-of-type(8):before {
    content: "Statut";
  }
}
.small-status {
  width: max-content;
}
</style>
