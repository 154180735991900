<template>
  <section>
    <Loading
      v-if="loading"
      :dialogProps="dialogLoading"
      :messageDialogeProps="loadingMessage"
      :loadingProps="loading"
      :errorProps="loadingError"
    />
    <div class="ma-8">
      <nav style="--bs-breadcrumb-divider: '>'" aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="#">Devis</a></li>
          <li class="breadcrumb-item active" aria-current="page">Details valide Devis</li>
        </ol>
      </nav>
      <div class="row">
        <div class="col-lg-4 side-info">
          <div class="card-block">
            <!--Detail societe-->
            <h6 class="m-b-10 pb-2 b-b-default f-w-600">Information Societe</h6>
            <div class="row">
              <div class="col-sm-6">
                <div class="row">
                  <div class="col-sm-3">
                    <v-icon
                      style="background-color: #f5f5f5"
                      :color="$colors[0]"
                      class="pa-3 mr-4 rounded-lg"
                      >mdi-identifier</v-icon
                    >
                  </div>
                  <div class="col-sm-9">
                    <p class="m-b-10 f-w-600 my-1">Raison Sociale</p>
                    <h6 class="text-muted f-w-400">{{ devisDetail?.societe?.raison }}</h6>
                  </div>
                </div>
              </div>

              <!-- siret -->

              <div class="col-sm-6">
                <div class="row">
                  <div class="col-sm-3">
                    <v-icon
                      style="background-color: #f5f5f5"
                      :color="$colors[0]"
                      class="pa-3 mr-4 rounded-lg"
                      >mdi-domain</v-icon
                    >
                  </div>
                  <div class="col-sm-9">
                    <p class="m-b-10 f-w-600 my-1">Siret</p>
                    <h6 class="text-muted f-w-400">{{ devisDetail?.societe?.siret }}</h6>
                  </div>
                </div>
              </div>
            </div>
            <!-- adresse -->
            <div class="row">
              <div class="col-sm-6">
                <div class="row">
                  <div class="col-sm-3">
                    <v-icon
                      style="background-color: #f5f5f5"
                      :color="$colors[0]"
                      class="pa-3 mr-4 rounded-lg"
                      >mdi-email-outline</v-icon
                    >
                  </div>
                  <div class="col-sm-9">
                    <p class="m-b-10 f-w-600 my-1">Email</p>
                    <h6 class="text-muted f-w-400">
                      {{ devisDetail?.societe?.adresseEmail }}
                    </h6>
                  </div>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="row">
                  <div class="col-sm-3">
                    <v-icon
                      style="background-color: #f5f5f5"
                      :color="$colors[0]"
                      class="pa-3 mr-4 rounded-lg"
                      >mdi-phone-outline</v-icon
                    >
                  </div>
                  <div class="col-sm-9">
                    <p class="m-b-10 f-w-600 my-1">Telephone</p>
                    <h6 class="text-muted f-w-400">
                      {{ devisDetail?.societe?.numTele }}
                    </h6>
                  </div>
                </div>
              </div>
            </div>
            <!-- nom et prenom -->
            <div class="row">
              <div class="col-sm-6">
                <div class="row">
                  <div class="col-sm-3">
                    <v-icon
                      style="background-color: #f5f5f5"
                      :color="$colors[0]"
                      class="pa-3 mr-4 rounded-lg"
                      >mdi-card-account-details-outline</v-icon
                    >
                  </div>
                  <div class="col-sm-9">
                    <p class="m-b-10 f-w-600 my-1">Nom</p>
                    <h6 class="text-muted f-w-400">{{ devisDetail?.societe?.nom }}</h6>
                  </div>
                </div>
              </div>

              <div class="col-sm-6">
                <div class="row">
                  <div class="col-sm-3">
                    <v-icon
                      style="background-color: #f5f5f5"
                      :color="$colors[0]"
                      class="pa-3 mr-4 rounded-lg"
                      >mdi-card-account-details-outline</v-icon
                    >
                  </div>
                  <div class="col-sm-9">
                    <p class="m-b-10 f-w-600 my-1">Prenom</p>
                    <h6 class="text-muted f-w-400">{{ devisDetail?.societe?.prenom }}</h6>
                  </div>
                </div>
              </div>
            </div>
            <!-- adresse postal -->

            <div class="row">
              <div class="col-sm-12 ms-0">
                <div class="row">
                  <div class="col-sm-1">
                    <v-icon
                      style="background-color: #f5f5f5"
                      :color="$colors[0]"
                      class="pa-3 mr-4 rounded-lg"
                      >mdi-map-marker-outline</v-icon
                    >
                  </div>
                  <div class="col-sm-9 ms-5">
                    <p class="m-b-10 f-w-600 my-1 ms-2">Adresse Postal</p>
                    <h6 class="text-muted f-w-400 ms-2">
                      <small class="td-table">{{
                        devisDetail?.societe?.adressePostal
                      }}</small>
                    </h6>
                  </div>
                </div>
              </div>
            </div>
            <!-- code postal -->

            <h6 class="m-b-0 m-t-40 pb-3 b-b-default f-w-600">Informations Compteur</h6>
            <!-- create divider-->
            <div class="row my-2">
              <div class="col-sm-6">
                <div class="row">
                  <div class="col-sm-3">
                    <v-icon
                      style="background-color: #f5f5f5"
                      :color="$colors[0]"
                      class="pa-3 mr-4 rounded-lg"
                      >mdi-lightning-bolt-outline</v-icon
                    >
                  </div>
                  <div class="col-sm-9">
                    <p class="m-b-10 f-w-600 my-1">Numero Compteur</p>
                    <h6 class="text-muted f-w-400">
                      {{ devisDetail?.compteur?.numCompteur }}
                    </h6>
                  </div>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="row">
                  <div class="col-sm-3">
                    <v-icon
                      style="background-color: #f5f5f5"
                      :color="$colors[0]"
                      class="pa-3 mr-4 rounded-lg"
                      >mdi-meter-electric-outline</v-icon
                    >
                  </div>
                  <div class="col-sm-9">
                    <p class="m-b-10 f-w-600 my-1">Numero Voix</p>
                    <h6 class="text-muted f-w-400">
                      {{ devisDetail?.compteur?.adresse }}
                    </h6>
                  </div>
                </div>
              </div>
            </div>
            <!-- option et parametre   -->
            <div class="row">
              <div class="col-sm-6">
                <div class="row">
                  <div class="col-sm-3">
                    <v-icon
                      style="background-color: #f5f5f5"
                      :color="$colors[0]"
                      class="pa-3 mr-4 rounded-lg"
                      >mdi-credit-card-outline</v-icon
                    >
                  </div>

                  <div class="col-sm-9">
                    <p class="m-b-10 f-w-600 my-1">Option Tarifaire</p>
                    <h6 class="text-muted f-w-400">
                      {{ devisDetail?.compteur?.optionTarifaire }}
                    </h6>
                  </div>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="row">
                  <div class="col-sm-3">
                    <v-icon
                      style="background-color: #f5f5f5"
                      :color="$colors[0]"
                      class="pa-3 mr-4 rounded-lg"
                    >
                      mdi mdi-tune-vertical-variant</v-icon
                    >
                  </div>
                  <div class="col-sm-9">
                    <p class="m-b-10 f-w-600 my-1">Segment</p>
                    <h6 class="text-muted f-w-400">
                      {{ devisDetail?.compteur?.parametreCompteur }}
                    </h6>
                  </div>
                </div>
              </div>
            </div>
            <!-- code de la compteur   -->
            <div class="row">
              <div class="col-sm-6">
                <div class="row">
                  <div class="col-sm-3">
                    <v-icon
                      style="background-color: #f5f5f5"
                      :color="$colors[0]"
                      class="pa-3 mr-4 rounded-lg"
                      >mdi-help-circle-outline</v-icon
                    >
                  </div>
                  <div class="col-sm-9">
                    <p class="m-b-10 f-w-600 my-1">Code Postal</p>
                    <h6 class="text-muted f-w-400">
                      {{ devisDetail?.compteur?.codePostal }}
                    </h6>
                  </div>
                </div>
              </div>

              <div class="col-sm-6">
                <div class="row">
                  <div class="col-sm-3">
                    <v-icon
                      style="background-color: #f5f5f5"
                      :color="$colors[0]"
                      class="pa-3 mr-4 rounded-lg"
                      >mdi mdi-alpha-i</v-icon
                    >
                  </div>
                  <div class="col-sm-9">
                    <p class="m-b-10 f-w-600 my-1">Type</p>
                    <h6 class="text-muted f-w-400">{{ devisDetail?.compteur?.type }}</h6>
                  </div>
                </div>
              </div>
            </div>
            <ul class="social-link list-unstyled m-t-40 m-b-10">
              <li>
                <a
                  href="#!"
                  data-toggle="tooltip"
                  data-placement="bottom"
                  title=""
                  data-original-title="facebook"
                  ><i class="feather icon-facebook facebook" aria-hidden="true"></i
                ></a>
              </li>
              <li>
                <a
                  href="#!"
                  data-toggle="tooltip"
                  data-placement="bottom"
                  title=""
                  data-original-title="twitter"
                  ><i class="feather icon-twitter twitter" aria-hidden="true"></i
                ></a>
              </li>
              <li>
                <a
                  href="#!"
                  data-toggle="tooltip"
                  data-placement="bottom"
                  title=""
                  data-original-title="instagram"
                  ><i class="feather icon-instagram instagram" aria-hidden="true"></i
                ></a>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-lg-8">
          <!-- CARD BODY IF THE INFO BUTTON CLICKED TO DISPLAY SOCIETE INFO -->
          <div>
            <div class="card">
              <div class="card-body">
                <div class="row">
                  <div
                    class="card-title d-flex flex-row justify-center align-center"
                    style="width: 100%"
                  >
                    <h5 class="Poppins-Bold mr-1 mb-0">{{ devisDetail?.dateAjout }} -</h5>
                    <div class="text-95 align-start d-sm-flex justify-center">
                      <div class="d-flex flex-column align-center btn-group">
                        <div class="d-flex flex-row justify-center align-center">
                          <small
                            class="small-status"
                            v-if="devisDetail.status == 'refusee'"
                            ><v-icon dense style="color: #ff0000">mdi-close-circle</v-icon
                            ><b class="ml-1 Poppins-Bold">refusée</b></small
                          >
                          <small
                            class="small-status"
                            v-if="devisDetail.status == 'annuler'"
                            ><v-icon dense style="color: #ff0000"
                              >mdi-file-cancel-outline</v-icon
                            ><b class="ml-1 Poppins-Bold">Annulé</b></small
                          >
                          <small
                            class="small-status"
                            v-if="devisDetail.status == 'en_attend'"
                            ><v-icon dense style="color: #2080ee"
                              >mdi-clock-outline</v-icon
                            ><b class="ml-1 Poppins-Bold" style="word-break: keep-all"
                              >en attente</b
                            ></small
                          >
                          <small
                            class="small-status"
                            v-if="devisDetail.status == 'en_cours'"
                            ><v-icon dense style="color: orange"
                              >mdi-progress-clock</v-icon
                            ><b class="ml-1 Poppins-Bold">en cours</b></small
                          >
                          <small
                            class="small-status"
                            v-if="devisDetail.status == 'terminer'"
                            ><v-icon dense style="color: #00e676">mdi-check</v-icon
                            ><b class="ml-1 Poppins-Bold">Terminer</b></small
                          >
                          <small
                            class="small-status"
                            v-if="devisDetail.status == 'pre_valider'"
                            ><v-icon dense style="color: #9c27b0">mdi-eye-check</v-icon
                            ><b class="ml-1 Poppins-Bold">pré validé</b></small
                          >
                          <div
                            class="d-flex flex-row justify-center align-center"
                            v-if="devisDetail.status == 'valider'"
                          >
                            <v-icon style="color: #00e676"
                              >mdi-check-circle-outline</v-icon
                            ><span class="ml-1 Poppins-Bold">Validé</span>
                          </div>
                          <small
                            class="small-status"
                            v-if="devisDetail.status == 'signer'"
                            ><v-icon dense style="color: rgb(134, 29, 90)"
                              >mdi-file-sign</v-icon
                            ><b class="ml-1 Poppins-Bold" style="word-wrap: normal"
                              >Signé</b
                            ></small
                          >
                          <small
                            class="small-status"
                            v-if="devisDetail.status == 'envoyer_signiature'"
                            ><v-icon dense style="color: rgb(114, 85, 7)"
                              >mdi mdi-send-clock</v-icon
                            ><b class="ml-1 Poppins-Bold" style="word-wrap: normal"
                              >envoyer signiature</b
                            ></small
                          >
                          <small
                            class="small-status Poppins-Bold"
                            v-if="devisDetail.status == 'renitialiser'"
                            ><v-icon dense style="color: rgb(121, 166, 196)"
                              >mdi mdi-lock-reset</v-icon
                            ><b class="ml-1" style="word-wrap: normal"
                              >renitialiser</b
                            ></small
                          >

                          <div
                            class="d-flex flex-row"
                            v-if="showActionsBtns(devisDetail.status)"
                          >
                            <v-icon
                              class="dropdown-toggle"
                              data-toggle="collapse"
                              :data-target="'#collapseStatut'"
                              aria-expanded="false"
                              :aria-controls="'collapseStatut'"
                              size="20"
                              left
                            >
                              mdi-menu-down
                            </v-icon>
                          </div>
                        </div>
                        <div
                          class="collapse"
                          :id="'collapseStatut'"
                          style="border: 0px"
                          v-if="showActionsBtns(devisDetail.status)"
                        >
                          <div class="d-flex flex-row mt-4 mt-lg-2">
                            <!-- Refuser -->
                            <v-btn
                              title="Refuser Devis"
                              @click="
                                ActionsBtns(
                                  devisDetail.id,
                                  'refusee',
                                  `Vous êtes sur le point de refuser ce devis!`
                                )
                              "
                              class="dropdown-devisDetail"
                              v-if="
                                $store.getters.hasPermissionByStatus(
                                  'DEVIS_AVENANT',
                                  devisDetail.status,
                                  'refusee'
                                )
                              "
                              icon
                              small
                              plain
                            >
                              <v-icon color="red"> mdi-close-circle </v-icon>
                            </v-btn>
                            <!-- annulé -->
                            <v-btn
                              title="Annuler Devis"
                              @click="
                                ActionsBtns(
                                  devisDetail.id,
                                  'annuler',
                                  `Vous êtes sur le point d'annuler ce devis!`
                                )
                              "
                              class="dropdown-devisDetail"
                              v-if="
                                $store.getters.hasPermissionByStatus(
                                  'DEVIS_AVENANT',
                                  devisDetail.status,
                                  'annuler'
                                )
                              "
                              icon
                              small
                              plain
                            >
                              <v-icon color="red"> mdi-cancel </v-icon>
                            </v-btn>
                            <!-- renitialiser-->
                            <v-btn
                              title="renitialiser"
                              @click="
                                ActionsBtns(
                                  devisDetail.id,
                                  'en_attend',
                                  `Vous êtes sur le point de renitialiser ce devis!`
                                )
                              "
                              class="dropdown-project"
                              v-if="
                                $store.getters.hasPermissionByStatus(
                                  'DEVIS_AVENANT',
                                  devisDetail.status,
                                  'renitialiser'
                                )
                              "
                              icon
                              small
                              plain
                            >
                              <v-icon color="grey accent-5"> mdi mdi-lock-reset </v-icon>
                            </v-btn>
                            <!-- signé button -->
                            <v-btn
                              title="signer un devis"
                              @click="
                                ActionsBtns(
                                  devisDetail.id,
                                  'signer',
                                  `Vous êtes sur le point de signer ce devis!`
                                )
                              "
                              class="dropdown-devisDetail"
                              v-if="
                                $store.getters.hasPermissionByStatus(
                                  'DEVIS_AVENANT',
                                  devisDetail.status,
                                  'signer'
                                )
                              "
                              icon
                              small
                              plain
                            >
                              <v-icon color="rgb(134, 29, 90)"> mdi mdi-draw </v-icon>
                            </v-btn>

                            <!-- envoyer signiature button -->
                            <v-btn
                              title="envoyer signiature"
                              @click="
                                ActionsBtns(
                                  devisDetail.id,
                                  'envoyer_signiature',
                                  `Vous êtes sur le point de envoyer un signiature de ce devis!`
                                )
                              "
                              class="dropdown-devisDetail"
                              v-if="
                                $store.getters.hasPermissionByStatus(
                                  'DEVIS_AVENANT',
                                  devisDetail.status,
                                  'envoyer_signiature'
                                )
                              "
                              icon
                              small
                              plain
                            >
                              <v-icon color="grey accent-5"> mdi mdi-send-clock </v-icon>
                            </v-btn>
                            <!-- Pré Valider -->
                            <v-btn
                              title="Pré Valider Devis"
                              @click="
                                ActionsBtns(
                                  devisDetail.id,
                                  'pre_valider',
                                  `Vous êtes sur le point de pré valider ce devis!`
                                )
                              "
                              v-if="
                                $store.getters.hasPermissionByStatus(
                                  'DEVIS_AVENANT',
                                  devisDetail.status,
                                  'pre_validation'
                                )
                              "
                              class="dropdown-devisDetail"
                              icon
                              small
                              plain
                            >
                              <v-icon color="#9C27B0"> mdi-eye-check </v-icon>
                            </v-btn>
                            <!-- Valider -->
                            <v-btn
                              title="Valider Devis"
                              @click="
                                ActionsBtns(
                                  devisDetail.id,
                                  'valider',
                                  `Vous êtes sur le point de valider ce devis!`
                                )
                              "
                              v-if="
                                $store.getters.hasPermissionByStatus(
                                  'DEVIS_AVENANT',
                                  devisDetail.status,
                                  'valider'
                                )
                              "
                              class="dropdown-devisDetail"
                              icon
                              small
                              plain
                            >
                              <v-icon color="green accent-3"> mdi-check-circle </v-icon>
                            </v-btn>
                            <!-- Creer Avenant -->
                            <v-btn
                              title="Creer Avenant"
                              @click="CreerAvenant(devisDetail.id)"
                              class="dropdown-project"
                              v-if="
                                $store.getters.hasPermissionByStatus(
                                  'DEVIS_APD',
                                  devisDetail.status,
                                  'create_avenant'
                                )
                              "
                              icon
                              small
                              plain
                            >
                              <v-icon color="blue accent-5">
                                mdi mdi-file-document-edit-outline
                              </v-icon>
                            </v-btn>

                            <!-- Creer Viste Technique -->
                            <v-btn
                              title="Creer Viste Technique"
                              @click="
                                CreerUneVisteTechnique(
                                  devisDetail.compteur.DemandeCotation
                                )
                              "
                              class="dropdown-devisDetail"
                              v-if="
                                $store.getters.hasPermissionByStatus(
                                  'DEVIS_AVENANT',
                                  devisDetail.status,
                                  'create_VT'
                                )
                              "
                              icon
                              small
                              plain
                            >
                              <v-icon color="blue accent-5"> mdi mdi-toolbox </v-icon>
                            </v-btn>
                            <!-- Creer Viste Installation -->
                            <v-btn
                              title="Creer Viste Installation"
                              @click="
                                CreerUneVisteInstallation(
                                  devisDetail.compteur.DemandeCotation
                                )
                              "
                              class="dropdown-devisDetail"
                              v-if="
                                $store.getters.hasPermissionByStatus(
                                  'DEVIS_AVENANT',
                                  devisDetail.status,
                                  'create_VI'
                                )
                              "
                              icon
                              small
                              plain
                            >
                              <v-icon color="green accent-5">
                                mdi-hammer-screwdriver
                              </v-icon>
                            </v-btn>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- DATA TABLE FOR VISITE -->
                <div class="">
                  <div class="px-0">
                    <div class="row">
                      <div class="col-sm-6">
                        <div>
                          <span class="text-sm Poppins-Bold align-middle mx-1"
                            >Période souhaitée :</span
                          >
                          <span class="text-400 text-grey-m2 align-middle">{{
                            $dayjs(devisDetail?.periodeInstallationSouhaitee).format(
                              "DD/MM/YYYY"
                            )
                          }}</span>
                        </div>
                        <div>
                          <span class="text-sm Poppins-Bold align-middle mx-1"
                            >Nouveau PDL :</span
                          >
                          <span class="text-400 text-grey-m2 align-middle">
                            {{ devisDetail?.nouveauPDL }}</span
                          >
                        </div>
                        <div class="">
                          <div class="my-1">
                            <span class="text-sm Poppins-Bold align-middle mx-1">
                              Nombre Point De Recharge :
                            </span>
                            <span class="text-sm text-grey-m2 align-middle">{{
                              devisDetail?.nombrePointDeRechargeCount
                            }}</span>
                          </div>
                        </div>

                        <div class="">
                          <div class="my-1">
                            <span class="text-sm Poppins-Bold align-middle mx-1">
                              Prix Total :
                            </span>
                            <span class="text-sm text-grey-m2 align-middle"
                              >{{
                                userRole === "chefIngenieur"
                                  ? "****"
                                  : Number(prix_total).toLocaleString("fr-FR")
                              }}
                              €</span
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row mt-0">
                      <div class="col-12 col-lg-12">
                        <table
                          class="table"
                          style="border-radius: 7px !important; box-shadow: 1px #e1e2e2"
                        >
                          <thead
                            class="table-text-600 text-white text-center bgc-default-tp1 py-25"
                          >
                            <th style="width: 18vw" class="py-3 text-left">Produit</th>
                            <th style="width: 6vw" class="py-3">Quantité</th>
                            <th style="width: 6vw" class="py-3">Unité</th>
                            <th style="width: 6vw" class="py-3">Prix</th>
                            <th style="width: 6vw" class="py-3 text-right">Total</th>
                          </thead>
                          <tbody style="border: 1px solid #dee2e6" class="text-center">
                            <tr
                              :class="{ 'rotate-tr': borne_tap }"
                              @click="toggelBornes"
                              style="cursor: pointer"
                              class="subtitle"
                            >
                              <td colspan="5">
                                <div class="tr-content">
                                  <span style="font-weight: 200" class="sub-row"
                                    >Borne</span
                                  >
                                  <span class="toggle-icon">
                                    {{
                                      !!devisDetail.prixBornes
                                        ? Number(devisDetail.prixBornes).toLocaleString(
                                            "fr-FR"
                                          )
                                        : 0
                                    }}
                                    €
                                  </span>
                                </div>
                              </td>
                            </tr>
                            <!-- borne items -->
                            <template
                              
                              v-for="(born, index) in bornes"
                              >
                            <tr
                              :class="borne_tap ? 'show_borne' : 'hid_borne'"
                              class="born"
                            >
                              <!--<td class="py-3">{{ born?.borne?.id }}</td>-->
                              <td class="text-left py-3">{{ born?.borne.nom }}</td>
                              <td class="py-3">{{ born?.quantity }}</td>
                              <td class="py-3">QTE</td>
                              <td class="py-3">
                                {{
                                  born?.borne?.prix
                                    ? parseFloat(born?.borne?.prix).toLocaleString(
                                        "fr-FR"
                                      )
                                    : 0
                                }}
                                €
                              </td>
                              <td class="text-right py-3">
                                {{
                                  born?.borne?.prix
                                    ? Number(
                                        born?.borne.prix * born?.quantity
                                      ).toLocaleString("fr-FR")
                                    : 0
                                }}
                                €
                              </td>
                            </tr>
                            <tr
                                @click="toggelArmoireDistribution('Borne ' + born.id)"
                                style="cursor: pointer"
                                class="subtitle"
                              >
                                <td colspan="5">
                                  <div class="tr-content">
                                    <span style="font-weight: 200" class="sub-row">{{
                                      "Matériel de travaux pour la borne " + born?.borne.nom
                                    }}</span>
                                    <span class="toggle-icon">
                                      {{
                                        Number(
                                          prixMaterielDeTravauxGrouped['Borne ' + born.id]
                                        ).toLocaleString("fr-FR")
                                      }}
                                      €
                                    </span>
                                  </div>
                                </td>
                              </tr>
                              <tr
                                v-for="item in materielDeTravaux['Borne ' + born.id]"
                                class="born"
                                colspan="5"
                                v-show="materielDeTravaux['Borne ' + born.id]['selected']"
                              >
                                <!-- add an other v-for to display 'materiels' -->
                                <td class="text-left py-3">{{ item.materiel.name }}</td>
                                <td class="py-3">
                                  {{ item.quantity.toLocaleString("fr-FR") }}
                                </td>
                                <td class="text-left py-3">{{ item.materiel.unite }}</td>
                                <td>{{ item.prixUnitaire.toLocaleString("fr-FR") }}</td>
                                <td>
                                  {{
                                    Number(
                                      item.prixUnitaire * item.quantity
                                    ).toLocaleString("fr-FR")
                                  }}
                                </td>
                              </tr>
                              <tr style="height: 50%;"
                              v-show="materielDeTravaux['Borne ' + born.id]['selected']">
                                <td colspan="5" style="height: 50%;">
                              <hr  width="100%" style ="height: 10px;"></td>
                              </tr>
                            </template>
                            <!-- one item -->
                            <template
                              class=""
                              v-for="(sectionKey, index) in Object.keys(
                                materielDeTravaux
                              // added this line to filter the materielDeTravaux that related to borne so they don't get displayed twice
                               ).filter((key) => (!( bornes.map((b) => 'Borne ' + b.id).includes(key))))"
                            
                            >
                              <tr
                                @click="toggelArmoireDistribution(sectionKey)"
                                style="cursor: pointer"
                                class="subtitle"
                              >
                                <td colspan="5">
                                  <div class="tr-content">
                                    <span style="font-weight: 200" class="sub-row">{{
                                      sectionKey
                                    }}</span>
                                    <span class="toggle-icon">
                                      {{
                                        Number(
                                          prixMaterielDeTravauxGrouped[sectionKey]
                                        ).toLocaleString("fr-FR")
                                      }}
                                      €
                                    </span>
                                  </div>
                                </td>
                              </tr>
                              <tr
                                v-for="item in materielDeTravaux[sectionKey]"
                                class="born"
                                colspan="5"
                                v-show="materielDeTravaux[sectionKey]['selected']"
                              >
                                <!-- add an other v-for to display 'materiels' -->
                                <td class="text-left py-3">{{ item.materiel.name }}</td>
                                <td class="py-3">
                                  {{ item.quantity.toLocaleString("fr-FR") }}
                                </td>
                                <td class="text-left py-3">{{ item.materiel.unite }}</td>
                                <td>{{ item.prixUnitaire.toLocaleString("fr-FR") }}</td>
                                <td>
                                  {{
                                    Number(
                                      item.prixUnitaire * item.quantity
                                    ).toLocaleString("fr-FR")
                                  }}
                                </td>
                              </tr>
                            </template>
                          </tbody>
                        </table>
                        <pdfCompletTest
                          style="display: none"
                          id="pagePDF"
                          :societe="this.devisDetail?.societe"
                          :items="listTable"
                          :itemsProps="listTableRecap"
                        />
                        <div class="d-flex flex-row justify-end">
                          <v-btn
                            :color="$colors[0]"
                            class="white--text"
                            @click="generatePDFAPI()"
                            >Generer PDF</v-btn
                          >
                        </div>

                        <div class="row border-b-2 brc-default-l2"></div>
                        <div class="row mt-3">
                          <div
                            class="col-12 col-sm-12 text-grey text-90 order-first order-sm-last"
                          >
                            <div class="row my-2 p-0 m-0 bgc-primary-l3">
                              <div class="col-6 text-left">
                                <button
                                  v-if="
                                    userRole === 'chefIngenieur' &&
                                    devisDetail.status !== 'en_cours' &&
                                    devisDetail.status !== 'terminer'
                                  "
                                  class="btn btn-outline-success btn-bold btn-sm px-4"
                                  @click=""
                                >
                                  Valider
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import axios from "@/plugins/axios";
import pdfCompletTest from "../testSejda/pdfCompletTest.vue";
import Loading from "@/components/Loading.vue";
export default {
  components: { pdfCompletTest, Loading },
  name: "ProfilAvenant",
  props: ["id"],
  data() {
    return {
      loading: false,
      loadingError: false,
      dialogLoading: false,
      loadingMessage: false,
      prix_total: 0,
      listTable: [],
      listTableRecap: [],
      informations_societe_tap: true,
      informations_compteur_tap: true,
      armoire_distribution_tap: true,
      selected: null,
      devisDetail: {},
      loaded: false,
      donnerMesure: [],
      cables: null,
      bornes: null,
      materielAuxiliere: null,
      options: null,
      totalPrix: 0,
      totalBorne: 0,
      totalMaterielAuxiliere: 0,
      totalCables: 0,
      totalOptions: 0,
      totalItems: 0,
      borne_tap: true,
      aux_tap: true,
      cable_tap: true,
      option_tap: true,
      materielDeTravaux: {},
      prixMaterielDeTravauxGrouped: {},
    };
  },
  created() {
    //console.log(this.devisDetail);
  },
  computed: {
    userRole() {
      return this.$store.getters.getUserRole;
    },
    showActionsBtns() {
      return (status) => {
        return (
          this.$store.getters.hasPermissionByStatus("DEVIS_AVENANT", status, "valider") ||
          this.$store.getters.hasPermissionByStatus(
            "DEVIS_AVENANT",
            status,
            "renitialiser"
          ) ||
          this.$store.getters.hasPermissionByStatus("DEVIS_AVENANT", status, "annuler") ||
          this.$store.getters.hasPermissionByStatus("DEVIS_AVENANT", status, "signer") ||
          this.$store.getters.hasPermissionByStatus(
            "DEVIS_AVENANT",
            status,
            "envoyer_signiature"
          ) ||
          this.$store.getters.hasPermissionByStatus("DEVIS_AVENANT", status, "refusee") ||
          this.$store.getters.hasPermissionByStatus(
            "DEVIS_AVENANT",
            status,
            "pre_validation"
          ) ||
          this.$store.getters.hasPermissionByStatus(
            "DEVIS_AVENANT",
            status,
            "create_VT"
          ) ||
          this.$store.getters.hasPermissionByStatus(
            "DEVIS_AVENANT",
            status,
            "create_VI"
          ) ||
          this.$store.getters.hasPermissionByStatus(
            "DEVIS_AVENANT",
            status,
            "create_avenant"
          )
        );
      };
    },
  },
  async mounted() {
    this.loaded = true;
    await this.fetchDevisDetail();
    let listParafoudre =
      this.materielDeTravaux.parafoudre?.length > 0
        ? this.materielDeTravaux.parafoudre
        : [];
    let listDifferentiel =
      this.materielDeTravaux.differentiel?.length > 0
        ? this.materielDeTravaux.differentiel
        : [];
    let listDepart =
      this.materielDeTravaux.depart?.length > 0 ? this.materielDeTravaux.depart : [];
    let listDijoncteur =
      this.materielDeTravaux.disjoncteur?.length > 0
        ? this.materielDeTravaux.disjoncteur
        : [];
    let listProtectionEtSchemas =
      this.materielDeTravaux.protectionEtSchemasDeCables?.length > 0
        ? this.materielDeTravaux.protectionEtSchemasDeCables
        : [];
    let listTableElec = listParafoudre.concat(
      listDifferentiel,
      listDepart,
      listDijoncteur,
      listProtectionEtSchemas
    );
    let sommeTotalElec =
      (this.prixMaterielDeTravauxGrouped.parafoudre
        ? this.prixMaterielDeTravauxGrouped.parafoudre
        : 0) +
      (this.prixMaterielDeTravauxGrouped.differentiel
        ? this.prixMaterielDeTravauxGrouped.differentiel
        : 0) +
      (this.prixMaterielDeTravauxGrouped.depart
        ? this.prixMaterielDeTravauxGrouped.depart
        : 0) +
      (this.prixMaterielDeTravauxGrouped.disjoncteur
        ? this.prixMaterielDeTravauxGrouped.disjoncteur
        : 0) +
      (this.prixMaterielDeTravauxGrouped.protectionEtSchemasDeCables
        ? this.prixMaterielDeTravauxGrouped.protectionEtSchemasDeCables
        : 0);

    //-----------------------------------------------------------------------------------------------------

    let listBornes = this.bornes?.length > 0 ? this.bornes : [];
    let listSignalisation =
      this.materielDeTravaux.signalisation?.length > 0
        ? this.materielDeTravaux.signalisation
        : [];
    let listTelecom =
      this.materielDeTravaux.telecomObject?.length > 0
        ? this.materielDeTravaux.telecomObject
        : [];
    let listeTableBorne = listBornes.concat(listSignalisation, listTelecom);
    let sommeTotalBorne =
      (this.prix_bornes ? this.prix_bornes : 0) +
      (this.prixMaterielDeTravauxGrouped.signalisation
        ? this.prixMaterielDeTravauxGrouped.signalisation
        : 0) +
      (this.prixMaterielDeTravauxGrouped.telecomObject
        ? this.prixMaterielDeTravauxGrouped.telecomObject
        : 0);
    //-----------------------------------------------------------------------------------------------------

    let listPoseIRVE =
      this.materielDeTravaux.PoseIRVESig?.length > 0
        ? this.materielDeTravaux.PoseIRVESign
        : [];
    let listProtectionTerre =
      this.materielDeTravaux.ProtectionTerre?.length > 0
        ? this.materielDeTravaux.ProtectionTerre
        : [];
    let listBtnUrgence =
      this.materielDeTravaux.bottonsDarretUrgance?.length > 0
        ? this.materielDeTravaux.bottonsDarretUrgance
        : [];
    let listRepartiteur =
      this.materielDeTravaux.Repartiteur?.length > 0
        ? this.materielDeTravaux.Repartiteur
        : [];
    let listEmplaceTDIRVE =
      this.materielDeTravaux.emplacementDeTdIrve?.length > 0
        ? this.materielDeTravaux.emplacementDeTdIrve
        : [];
    let listePorteTDIRVE =
      this.materielDeTravaux.porteTdIrve?.length > 0
        ? this.materielDeTravaux.porteTdIrve
        : [];
    let listBobineMNX =
      this.materielDeTravaux.bobineMnx?.length > 0
        ? this.materielDeTravaux.bobineMnx
        : [];

    let listTableIRVE = listPoseIRVE.concat(
      listProtectionTerre,
      listBtnUrgence,
      listRepartiteur,
      listEmplaceTDIRVE,
      listePorteTDIRVE,
      listBobineMNX
    );

    let sommeTotalIRVE =
      (this.prixMaterielDeTravauxGrouped.PoseIRVESign
        ? this.prixMaterielDeTravauxGrouped.PoseIRVESign
        : 0) +
      (this.prixMaterielDeTravauxGrouped.ProtectionTerre
        ? this.prixMaterielDeTravauxGrouped.ProtectionTerre
        : 0) +
      (this.prixMaterielDeTravauxGrouped.bottonsDarretUrgance
        ? this.prixMaterielDeTravauxGrouped.bottonsDarretUrgance
        : 0) +
      (this.prixMaterielDeTravauxGrouped.Repartiteur
        ? this.prixMaterielDeTravauxGrouped.Repartiteur
        : 0) +
      (this.prixMaterielDeTravauxGrouped.emplacementDeTdIrve
        ? this.prixMaterielDeTravauxGrouped.emplacementDeTdIrve
        : 0) +
      (this.prixMaterielDeTravauxGrouped.porteTdIrve
        ? this.prixMaterielDeTravauxGrouped.porteTdIrve
        : 0) +
      (this.prixMaterielDeTravauxGrouped.bobineMnx
        ? this.prixMaterielDeTravauxGrouped.bobineMnx
        : 0);

    //-----------------------------------------------------------------------------------------------------

    let listProtectionTravail =
      this.materielDeTravaux.ProtectionTravail?.length > 0
        ? this.materielDeTravaux.ProtectionTravail
        : [];
    let listOptionCivile =
      this.materielDeTravaux.OptionsCivile?.length > 0
        ? this.materielDeTravaux.OptionsCivile
        : [];
    let listTableCivile = listProtectionTravail.concat(listOptionCivile);
    let sommeTotalCivile =
      (this.prixMaterielDeTravauxGrouped.ProtectionTravail
        ? this.prixMaterielDeTravauxGrouped.ProtectionTravail
        : 0) +
      (this.prixMaterielDeTravauxGrouped.OptionsCivile
        ? this.prixMaterielDeTravauxGrouped.OptionsCivile
        : 0);

    //-----------------------------------------------------------------------------------------------------

    let listOptionEtude =
      this.materielDeTravaux.OptionEtude?.length > 0
        ? this.materielDeTravaux.OptionEtude
        : [];
    let listTableEtude = listOptionEtude;

    let sommeTotalEtude = this.prixMaterielDeTravauxGrouped.OptionEtude
      ? this.prixMaterielDeTravauxGrouped.OptionEtude
      : 0;
    //-----------------------------------------------------------------------------------------------------

    let listTranchements =
      this.materielDeTravaux.tranchement?.length > 0
        ? this.materielDeTravaux.tranchement
        : [];
    let listPercements =
      this.materielDeTravaux.Percements?.length > 0
        ? this.materielDeTravaux.Percements
        : [];
    let listChambresTirages =
      this.materielDeTravaux.ChambresDesTirages?.length > 0
        ? this.materielDeTravaux.ChambresDesTirages
        : [];
    let listFouillesEtMassifs =
      this.materielDeTravaux.FouillesetMassifs?.length > 0
        ? this.materielDeTravaux.FouillesetMassifs
        : [];

    let listTableMaçonnerie = listTranchements.concat(
      listPercements,
      listChambresTirages,
      listFouillesEtMassifs
    );

    let sommeTotalMaçonnerie =
      (this.prixMaterielDeTravauxGrouped.tranchement
        ? this.prixMaterielDeTravauxGrouped.tranchement
        : 0) +
      (this.prixMaterielDeTravauxGrouped.Percements
        ? this.prixMaterielDeTravauxGrouped.Percements
        : 0) +
      (this.prixMaterielDeTravauxGrouped.ChambresDesTirages
        ? this.prixMaterielDeTravauxGrouped.ChambresDesTirages
        : 0) +
      (this.prixMaterielDeTravauxGrouped.FouillesetMassifs
        ? this.prixMaterielDeTravauxGrouped.FouillesetMassifs
        : 0);

    //-----------------------------------------------------------------------------------------------------
    let listTableAuxiliaire =
      this.materielAuxiliere?.length > 0 ? this.materielAuxiliere : [];
    console.log(listTableAuxiliaire);
    let sommeTotalAuxiliaire = 0;
    if (listTableAuxiliaire.length > 0) {
      listTableAuxiliaire.forEach((element) => {
        sommeTotalAuxiliaire += Number(element.quantite) * Number(element.prix);
      });
    }
    //-----------------------------------------------------------------------------------------------------
    this.listTableRecap = [
      { title: "Éléctricité", prixTotal: sommeTotalElec },
      { title: "Borne", prixTotal: sommeTotalBorne },
      { title: "IRVE", prixTotal: sommeTotalIRVE },
      { title: "Civile", prixTotal: sommeTotalCivile },
      { title: "Etude", prixTotal: sommeTotalEtude },
      { title: "Maçonnerie", prixTotal: sommeTotalMaçonnerie },
      { title: "Auxiliaire", prixTotal: sommeTotalAuxiliaire },
    ];
    this.listTable = [
      { title: "Électricité", data: listTableElec },
      { title: "Borne", data: listeTableBorne },
      { title: "IRVE", data: listTableIRVE },
      { title: "Civile", data: listTableCivile },
      { title: "Etude", data: listTableEtude },
      { title: "Maçonnerie", data: listTableMaçonnerie },
      { title: "Auxiliaire", data: listTableAuxiliaire },
    ];
  },
  watch: {},
  methods: {
    async generatePDFAPI() {
      this.dialogLoading = true;
      this.loading = true;
      const linkElements = document.querySelectorAll(
        'link[href*="fonts.googleapis.com/css?family=Roboto"]'
      );

      // Modifier le href pour charger la police Poppins à la place de Roboto
      linkElements.forEach((link) => {
        link.href = "https://fonts.googleapis.com/css?family=Poppins&display=swap";
      });

      const requestData = {
        pageSize: "a4",
        htmlCode: `
          <html>
          ${document.head.innerHTML}
          <body>
            ${document.querySelector("#pagePDF").innerHTML}
          </body>
          </html>`,

        // Autres paramètres requis selon la documentation Sejda
      };
      console.log(document.head);
      await axios
        .post("https://api.sejda.com/v2/html-pdf", requestData, {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Token: api_public_1413ab387b614aa3b02cdbf4d6df8e3b",
          },
          responseType: "arraybuffer",
        })
        .then((response) => {
          const blob = new Blob([response.data], { type: "application/pdf" });

          // Utiliser l'objet URL pour télécharger le PDF
          const url = window.URL.createObjectURL(blob);

          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "Avenant.pdf");
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);

          window.URL.revokeObjectURL(url); // Nettoyer l'objet URL
          this.dialogLoading = false;
          this.loading = false;
        })
        .catch((error) => {
          console.error(error);
          this.loading = false;
          this.loadingMessage = error;
          this.loadingError = true;
        });
    },
    CreerAvenant(id) {
      const props = {
        id: id,
        source: "avenant",
      };
      this.$router.push({ path: `/Devis/FormulaireAvenant`, query: props });
    },
    CreerUneVisteTechnique(id_devis) {
      const props = {
        id_devis: id_devis,
      };
      this.$router.push({ path: "/visite-technique/nouveau-formulaire", query: props });
    },
    CreerUneVisteInstallation(id_devis) {
      const props = {
        id_devis: id_devis,
      };
      this.$router.push({
        path: "/visite-installation/ajoutVisiteInstallation",
        query: props,
      });
    },
    async fetchDevisDetail() {
      // get compteur detail with id using axios
      await axios({
        url: process.env.VUE_APP_URL_API_CLIENT + "getAvenant/",
        method: "POST",
        data: {
          avenant_id: this.id,
          token: this.$cookies.get("token"),
        },
      })
        .then((response) => {
          //console.log(response.data);
          this.devisDetail = response.data;
          //this.cables = response.data.cables;
          this.bornes = response.data.bornes;
          this.materielAuxiliere = response.data.materielAuxiliaire;

          // les prix total
          this.prix_alimentation_protection = this.devisDetail.prixMaterielDeTravauxGrouped[
            "Alimentation et protection"
          ];
          this.prix_armoire_distribution = this.devisDetail.prixMaterielDeTravauxGrouped[
            "Armoire de distribution"
          ];
          this.prix_cheminements = this.devisDetail.prixMaterielDeTravauxGrouped[
            "Cheminements"
          ];
          this.prix_total = this.devisDetail.prixTotal;
          this.prix_bornes = this.devisDetail.prixBornes;
          this.prixMaterielDeTravauxGrouped = this.devisDetail.prixMaterielDeTravauxGrouped;
          this.materielDeTravaux = this.devisDetail.materielDeTravaux;
          // add a new key name's selected to every key in materielDeTravaux
          Object.keys(this.materielDeTravaux).forEach((key) => {
            this.materielDeTravaux[key].selected = true;
          });
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    async getTotalPrix() {
      let totalItem = 0;
      // calculer le prix des bornes et options et cables
      this.bornes.forEach((borne) => {
        //console.log(borne);
        this.totalBorne += borne.borne.prix * borne.quantity;
        totalItem += borne.quantity;
      });
      this.options.forEach((option) => {
        this.totalOptions +=
          option.related == "BorneCount"
            ? option.prix * this.devisDetail.borneCount
            : option.prix * this.devisDetail.nombrePointDeRechargeCount;
        totalItem +=
          option.related == "BorneCount"
            ? this.devisDetail.borneCount
            : this.devisDetail.nombrePointDeRechargeCount;
      });
      this.cables.forEach((cable) => {
        this.totalCables += cable.cable.prix * cable.quantity;
        totalItem += cable.quantity;
        //console.log(total);
      });

      // calculer le prix des materiel Auxiliere
      this.materielAuxiliere.forEach((material) => {
        this.totalMaterielAuxiliere += material.prix * material.quantite;
        totalItem += material.quantite;
      });

      this.totalPrix = parseFloat(
        this.totalBorne + this.totalOptions + this.totalCables + this.materielAuxiliere
      ).toFixed(3);
      this.totalItems = totalItem;
    },
    toggleInformationsSociete() {
      this.informations_societe_tap = !this.informations_societe_tap;
    },
    toogleInformationsComtpeur() {
      this.informations_compteur_tap = !this.informations_compteur_tap;
    },
    toggelBornes() {
      this.borne_tap = !this.borne_tap;
    },
    toggleAux() {
      this.aux_tap = !this.aux_tap;
    },
    toggelCables() {
      this.cable_tap = !this.cable_tap;
    },
    toggelArmoireDistribution(sectionKey) {
      this.materielDeTravaux[sectionKey]["selected"] = !this.materielDeTravaux[
        sectionKey
      ]["selected"];
      console.log(this.materielDeTravaux[sectionKey]["selected"]);
      // refresh the liste of materielDeTravaux
      this.materielDeTravaux = { ...this.materielDeTravaux };
    },
    toggelOptions() {
      this.option_tap = !this.option_tap;
    },

    // creer un devis
    async ActionsBtns(id, status, actionMessage) {
      this.$swal({
        title: "Êtes-vous sûr?",
        text: actionMessage,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#007991",
        cancelButtonColor: "#d33",
        cancelButtonText: "Annuler",
        confirmButtonText: "Je Confirme",
      }).then((result) => {
        if (result.isConfirmed) {
          this.dialog = true;
          this.loading = true;
          this.error = false;
          var formData = new FormData();
          formData.append("id", id);
          formData.append("status", status);
          formData.append("token", this.$store.getters.getUserToken);
          axios({
            url: process.env.VUE_APP_URL_API_CLIENT + "changeAvenantStatus/",
            method: "POST",
            data: formData,
          })
            .then((res) => {
              if (res.status == 200) {
                // update the status of the devis
                this.devisDetail.status = status;
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }
      });
    },
  },
};
</script>
<style scoped>
@import "./style/profil.css";
</style>
