<template>
  <div>
    <!-- Modal Add Image and Download PDF -->
    <div
      class="modal fade"
      id="modalAddImageAndDownloadPdf"
      tabindex="-1"
      role="dialog"
      aria-labelledby="modalAddImageAndDownloadPdfLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="modalAddImageAndDownloadPdfLabel">
              Télécharger une Présentation PDF
            </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <v-radio-group v-model="showBudget">
              <v-radio label="Oui" :value="true"></v-radio>
              <v-radio label="Non" :value="false"></v-radio>
            </v-radio-group>

            <label 
            for="addImageAddress" 
            class="drop-container" 
            id="dropcontainer"
            @dragover.prevent="handleDragOver" 
            @dragenter="handleDragEnter" 
            @dragleave="handleDragLeave"
            @drop.prevent="handleDrop"
            >
              <span class="drop-title">Déposez Votre image ici</span>
              ou
              <p class="add-title">Ajouter une image</p>
              <input 
              ref="addImageAddress"
              id="addImageAddress" 
              class="inputImageAddress" 
              type="file" 
              @change="handleImageAddressClientChange" 
              accept="image/*" 
              style="display: none"
              />
            </label>

            <div v-if="addressImageToShow" class="image-preview-wrapper">
              <img :src="addressImageToShow" />
            </div>

            <!-- <v-btn class="button-download-pdf" :color="$colors[1]" @click="previewPdf()">
              <span class="white--text">preview</span>
            </v-btn> -->
            <v-btn class="button-download-pdf" :color="$colors[0]" @click="generatePdf()" :loading="loadingGeneratingPres">
              <v-icon class="white--text">mdi-download-outline</v-icon>
            </v-btn>
          </div>
        </div>
      </div>
    </div>
    <!-- ******************************* -->
    <!-- button toggle modal image input -->
    <!-- ******************************* -->
    <v-btn
      class="ml-4"
      data-toggle="modal"
      data-target="#modalAddImageAndDownloadPdf"
      :color="$colors[0]"
    >
      <v-icon class="white--text">mdi-download-outline</v-icon>
    </v-btn>

    <!-- pdf content to render inside pdf (chart) -->
    <div id="pdf-content">
      <div class="chart-wrapper">
        <apexchart 
        type="bar" 
        height="600px" 
        :options="RSIChartOptions" 
        :series="RSIChartSeries">
        </apexchart>
      </div>
      <div class="chart-wrapper">
        <apexchart 
        type="line" 
        height="500px" 
        :options="factureEnergieChartOptions" 
        :series="factureEnergieChartSeries">
        </apexchart>
      </div>
      <div class="chart-wrapper">
        <apexchart 
        type="donut" 
        width="1000px" 
        height="500px" 
        :options="repartitionEnergieProduiteChartOptions" 
        :series="repartitionEnergieProduiteChartSeries">
        </apexchart>
      </div>
      <div class="chart-wrapper">
        <apexchart 
        type="donut" 
        width="1000px" 
        height="500px" 
        :options="productionAnnuelleChartOptions" 
        :series="productionAnnuelleChartSeries">
        </apexchart>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import VueApexCharts from "vue-apexcharts";
import PDFSolarCollectifGenerator from './PDFSolarCollectifGenerator.js';
export default {
  props: {
    pdfData: Object,
    compteur: String,
  },
  components: {
    apexchart: VueApexCharts,
  },
  data() {
    return {
      loadingGeneratingPres: false,
      showBudget: false,
      // modal image
      addressImage: null,
      addressImageToShow: null,

      RSIChartSeries: [{
        name: 'Economie cumulée(€)',
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      }],
      RSIChartOptions: {
        chart: {
          id: 'RSIChart',
          type: 'bar',
          height: 600,
          zoom: {
            enabled: false
          },
          toolbar: {
            show: false,
          },
          election: {
            enabled: false,
          }
        },
        grid: {
          show: true,
          xaxis: {
            lines: {
              show: true
            }
          },   
          yaxis: {
            lines: {
              show: true
            }
          }, 
        },
        colors: ['#548235'],
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '55%',
          },
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent']
        },
        xaxis: {
          title: {
            text: 'Années',
          },
          type: "numeric",
          tickAmount: "dataPoints", // to all x axis numeric numbers
          labels: {
            formatter: function (value) {
              return value.toFixed(0);
            }
          },
        },
        yaxis: {
          title: {
            text: 'Economie cumulée(€)',
          },
          labels: {
            formatter: function (value) {
              return Number(parseFloat(value).toFixed(2)).toLocaleString("fr-FR") + " €";
            }
          }
        },
        fill: {
          opacity: 1
        },
      },
      factureEnergieChartSeries: [
        {
          name: 'Facture Energie (€) TTC avec PC',
          data: [0,0,0,0,0,0,0,0]
        },
        {
          name: 'Facture Energie (€) TTC sans PC',
          data: [0,0,0,0,0,0,0,0]
        }
      ],
      factureEnergieChartOptions:{
        chart: {
          id: 'factureEnergieChart',
          group: 'social',
          type: 'line',
          height: 500,
          zoom: {
            enabled: false
          },
          toolbar: {
            show: false,
          },
          election: {
            enabled: false,
          }
        },
        grid: {
          show: true,
          xaxis: {
            lines: {
              show: true
            }
          },   
          yaxis: {
            lines: {
              show: false
            }
          }, 
        },
        colors: ['#5a9bd2','#eb7d2d'],
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '55%',
          },
        },
        dataLabels: {
          enabled: false
        },
        markers: {
          size: 5,
        },
        stroke: {
          show: true,
          width: 3,
        },
        xaxis: {
          type: "numeric",
          tickAmount: "dataPoints", // to all x axis numeric numbers
          labels: {
            formatter: function (value) {
              return value.toFixed(0);
            }
          },
        },
        yaxis: {
          title: {
            text: 'MONTANT (€)',
          },
          labels: {
            formatter: function (value) {
              return Number(parseFloat(value).toFixed(2)).toLocaleString("fr-FR") + " €";
            }
          }
        },
        fill: {
          opacity: 1
        },
      },
      productionAnnuelleChartSeries: [44, 55, 41],
      productionAnnuelleChartOptions: {
        chart: {
          id: 'productionAnnuelleChart',
          type: 'donut',
          background: "#f5f5f5",
        },
        labels: ["Autoconsommation individuelle (ACI)", "Autoconsommation collective (ACC)", "Surplus de production"],
        title: {
          text: "Estimation de production (kWh par an)",
          align: 'center',
          margin: 20,
          style: {
            fontSize:  '20px',
            fontWeight:  'bold',
            color:  '#000'
          },
        },
        legend: {
          show: true,
          position: 'top',
        },
        stroke: {
          width: 0
        },
        colors: ["#416ec3","#eb7d2d","#a5a5a5","#fabe00","#5a9bd2","#6eaa46","#234178","#6e2da0"],
        dataLabels: {
          enabled: true,
          style: {
            fontSize: '18px',
            colors: ['#111']
          },
          background: {
            enabled: true,
            foreColor: '#fff',
            padding: 6,
            borderWidth: 0
          }
        },
      },
      repartitionEnergieProduiteChartSeries: [44, 55, 41],
      repartitionEnergieProduiteChartOptions: {
        chart: {
          id: 'repartitionEnergieProduiteChart',
          type: 'donut',
          background: "#f5f5f5",
        },
        labels: ["Autoconsommation individuelle (ACI)", "Autoconsommation collective (ACC)", "Surplus de production"],
        title: {
          text: "Répartition des consommations de l'énergie PV produite (en kWh par an)",
          align: 'center',
          margin: 20,
          style: {
            fontSize:  '20px',
            fontWeight:  'bold',
            color:  '#000'
          },
        },
        legend: {
          show: true,
          position: 'right',
          offsetY:  200,
        },
        stroke: {
          width: 0
        },
        colors: ["#416ec3","#eb7d2d","#a5a5a5","#fabe00","#5a9bd2","#6eaa46","#234178","#6e2da0"],
        dataLabels: {
          enabled: true,
          style: {
            fontSize: '18px',
            colors: ['#111']
          },
          background: {
            enabled: true,
            foreColor: '#fff',
            padding: 6,
            borderWidth: 0
          }
        },
      },
    };
  },
  watch: {
    pdfData: {
      immediate: true,
      deep: true,
      handler(newVal, oldVal) {
        this.fillPropsData();
      },
    },
  },
  methods: {
    fillPropsData() {
      this.RSIChartSeries[0].data = [-1500000, -1200000, -1000000, -800000, -600000, -400000, -100000, 200000, 300000, 400000, 500000, 600000, 700000, 800000, 900000, 1000000, 1100000, 1200000, 1300000, 1400000];
      this.factureEnergieChartSeries[0].data = [1900000, 2000000, 2100000, 2200000, 2300000, 2400000, 2500000, 2600000];
      this.factureEnergieChartSeries[1].data = [2000000,2100000,2200000,2300000,2400000,2500000,2600000,2700000];
    },
    // format pdf numbers
    to_fixed2LocaleString(num) {
      return num != null && num >= 0
        ? Number(parseFloat(num).toFixed(2))
            .toLocaleString("fr-FR")
            .replace(/\u202F/g, " ")
            .toString()
        : "--";
    },
    formatNumberOrText(num, tofFixedNumber) {
      if(!isNaN(num) && num !== null && num !== undefined && num !== ""){
          // num is number
          return Number(parseFloat(num).toFixed(tofFixedNumber)).toLocaleString("fr-FR").replace(/\u202F/g, ' ').toString() + "";
      }else{
          // num is text
          if(num !== null && num !== undefined && num !== ""){
              return num
          }
          return ""
      }
    },

    // modal image
    handleDragOver(event) {
      event.preventDefault();
    },
    handleDragEnter(event) {
      // Add a class to indicate dragging
      event.currentTarget.classList.add("drag-active");
    },
    handleDragLeave(event) {
      // Remove the class when dragging leaves the container
      event.currentTarget.classList.remove("drag-active");
    },
    handleDrop(event) {
      event.preventDefault();

      // Handle dropped files
      event.currentTarget.classList.remove("drag-active");

      const fileInput = this.$refs.addImageAddress;
      fileInput.files = event.dataTransfer.files;

      // Call your existing method to handle the change
      this.handleImageAddressClientChange();
    },
    handleImageAddressClientChange() {
      const file = this.$refs.addImageAddress.files[0];

      if (file) {
        // Ensure that the selected file is an image
        if (file.type.startsWith('image/')) {
          // set the image for pdf
          this.addressImage = file;
          // Convert the selected image to a data URL
          const reader = new FileReader();
          reader.onload = () => {
            this.addressImageToShow = reader.result;
          };
          reader.readAsDataURL(file);
        } else {
          // Display an error message or handle the invalid file type accordingly
          alert('Invalid file type. Please select an image.');
        }
      }
    },

    // pdf generation
    async generatePdf() {
      try {
        this.loadingGeneratingPres = true;
        // Initialize SharedMethods with component-specific data
        const PDFGenerator = new PDFSolarCollectifGenerator({
          addressImage: this.addressImage,
        });
        await PDFGenerator.generatePresentation();
      } catch (error) {
        console.log(error)
      } finally {
        this.loadingGeneratingPres = false;
      }
    },
  },
};
</script>

<style scoped>
#pdf-content {
  width: 0px;
  height: 0px;
  overflow: hidden;
}
.chart-wrapper {
  width: 1200px;
  height: 600px;
  background: #fff;
}
.modal-header {
  background: rgb(0, 121, 145);
  color: #fff !important;
}
.inputImageAddress::file-selector-button {
  margin-right: 20px;
  border: none;
  background: rgb(0, 121, 145);
  padding: 10px 20px;
  border-radius: 10px;
  color: #fff;
  cursor: pointer;
  transition: background 0.2s ease-in-out;
}

.inputImageAddress::file-selector-button:hover {
  background: rgb(0, 121, 145);
}
.drop-container {
  position: relative;
  display: flex;
  gap: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 200px;
  padding: 20px;
  border-radius: 10px;
  border: 2px dashed #555;
  color: #444;
  cursor: pointer;
  transition: background 0.2s ease-in-out, border 0.2s ease-in-out;
}

.drop-container:hover {
  background: #eee;
  border-color: #111;
}

.drop-container:hover .drop-title {
  color: #222;
}

.drop-title {
  color: #444;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  transition: color 0.2s ease-in-out;
}
.drop-container.drag-active {
  background: #eee;
  border-color: #111;
}

.drop-container.drag-active .drop-title {
  color: #222;
}

.add-title {
  color: #747777;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  transition: color 0.2s ease-in-out;
}

.image-preview-wrapper {
  width: 100%;
  height: 230px;
  border-radius: 4px;
  overflow: hidden;
  margin-top: 20px;
  img {
    width: 100%;
    height: 100%;
  }
}

.button-download-pdf {
  width: 100%;
  height: 44px;
  margin-top: 20px;
}
</style>
