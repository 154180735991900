<template>
  <div>
  <v-row class="mb-8" justify="center mb-4">
    <uploadFilesProfilClient v-if="photoSociete" :idSociete="idSociete" :selectedFile="fileDetails" @updateFiles="handelUpdateList()" />
    </v-row>
    <div
      v-if="filesImages.length == 0"
      style="height: 100%; width: 100%"
      class="d-flex flex-row justify-center align-center px-4 mt-4">
      <i
        :style="{ 'font-size': '40px', color: $colors[5] }"
        class="fas fa-folder-open"
      ></i>
      <span style="font-size: 30px" class="ml-2 align-self-end"> Dossier vide</span>
    </div>
    <div>
      <Photos :photoSociete="photoSociete" :files="filesImages" :idSociete="idSociete" class="px-4 mt-4"/>
    </div>
  </div>
</template>
<script>
import Photos from "@/views/Photos.vue";
import uploadFilesProfilClient from "./uploadFilesProfilClient.vue";

export default {
  name: "listeImages",
  props: ["filesImages","photoSociete","idSociete","fileDetails"],
  components: { Photos,uploadFilesProfilClient },
  data() {
    return {

    };
  },
  mounted() {
    console.log("filesImages", this.filesImages);
  },
  methods: {
    handelUpdateList() {
      this.$emit("updateList");
    },

   
  },
};
</script>
<style scoped>
@media screen and (max-width: 1920px) {
  .fontSizeMediaQuery {
    font-size: 12px;
  }
}
</style>
