<template>
    <div style="height:100%" class="d-flex flex-row justify-center align-center">
        <loadingComponent v-if="loadingEnedis" :dialogProps="dialogLoading" messageDialogeProps="erreur" :loadingProps="loadingEnedis" :errorProps="false"/>  
        <v-card class="d-flex flex-column pa-4" style="width:50%">
        <div class="d-flex flex-row justify-space-between align-stretch">
          <v-card style="width:50%">
            <v-toolbar
             :color='$colors[0]'
              dark>
              <v-icon size="30" class="mr-2">mdi-clipboard-text</v-icon>
              <span class="Poppins pl-0">Compteur</span>
              
            </v-toolbar>
            <div class="d-flex flex-column ma-4">
                <div v-for="(item,key,index) in donneTechniqueInfo" :key="index" class="d-flex flex-row mt-2">
                  <div class="mr-4 pa-1" :style="{background:$styles.background,borderRadius:'5px'}"><v-icon color="white" size="50">{{subicon[0][index]}}</v-icon></div>
                  <div class="d-flex flex-column justify-center">
                    <span class="Poppins text-caption">{{subtitle[0][index]}}</span>
                    <span class="Poppins-Bold">{{item}}</span>
                  </div>  
                </div>
            </div>
          </v-card>
          <v-card class="ml-2" style="width:50%">
            <v-toolbar
                :color='$colors[0]'
                dark>
                <v-icon size="30" class='mr-2'>mdi-chart-timeline-variant</v-icon>
                <span class="Poppins pl-0">Consommations</span>
                
            </v-toolbar>
            <div class="d-flex flex-column ma-4">
                <div v-for="(item,key,index) in courbeCharge" :key="index" class="d-flex flex-row mt-2">
                  <div class="mr-4 pa-1" :style="{background:$styles.background,borderRadius:'5px'}"><v-icon color="white" size="50">{{subicon[1][index]}}</v-icon></div>
                  <div class="d-flex flex-column justify-center">
                    <span class="Poppins text-caption">{{subtitle[1][index]}}</span>
                    <span class="Poppins-Bold">{{item}}</span>
                  </div>  
                </div>
            </div>
          </v-card>
        </div>
          <v-card style="width:100%" class="mt-2">
            <v-toolbar
                :color='$colors[0]'
                dark>
                <v-icon size="30" class="mr-2">mdi-calculator</v-icon>
                <span class="Poppins pl-0">Mesures</span>
                
            </v-toolbar>
            <div class="d-flex flex-row justify-space-between ma-4">
                <div class="d-flex flex-row mt-2">
                  <div class="mr-4 pa-1" :style="{background:$styles.background,borderRadius:'5px'}"><v-icon color="white" size="50">mdi-calendar-month-outline</v-icon></div>
                  <div class="d-flex flex-column justify-center">
                    <span class="Poppins text-caption">Nombre de mois</span>
                    <span class="Poppins-Bold">{{mesuresInfo.nbrMonth}}</span>
                  </div>  
                </div>
                <div class="d-flex flex-row mt-2">
                  <div class="mr-4 pa-1" :style="{background:$styles.background,borderRadius:'5px'}"><v-icon color="white" size="50">mdi-home-lightning-bolt-outline</v-icon></div>
                  <div class="d-flex flex-column justify-center">
                    <span class="Poppins text-caption">Somme HC</span>
                    <span class="Poppins-Bold">{{mesuresInfo.donne?.HC.somme}}</span>
                  </div>  
                </div>
                <div class="d-flex flex-row mt-2">
                  <div class="mr-4 pa-1" :style="{background:$styles.background,borderRadius:'5px'}"><v-icon color="white" size="50">mdi-home-lightning-bolt-outline</v-icon></div>
                  <div class="d-flex flex-column justify-center">
                    <span class="Poppins text-caption">Somme HP</span>
                    <span class="Poppins-Bold">{{mesuresInfo.donne?.HP.somme}}</span>
                  </div>  
                </div>
            </div>
          </v-card>
      </v-card>
    </div>
</template>
<script>
    export default{
        name:"InfosCourbeCharge",
        props:['numCompteurACDProps'],
        data(){
            return{
                loadingEnedis:false,
                dialogLoading:false,
                numCompteurACD:"",
                donneTechniqueInfo:{},
                subicon:[
                    ['mdi-home-lightning-bolt-outline','mdi-meter-electric-outline','mdi-meter-electric-outline','mdi-white-balance-sunny','mdi-weather-night'],
                    ['mdi-chart-line','mdi-chart-line','mdi-finance','mdi-timer-sand','mdi-timer-sand-complete'],
                ],
                subtitle: [
                    ['Type compteur','Puissance Souscrite','Puissance Atteinte Maximale','Heure creuse Matin','Heure creuse Apres-midi'],
                    ['Consommation HP','Consommation HC','Consommation Maximale','Date de debut','Date de fin'],
                ],
                mesuresInfo:{},
                parametreCompteur:"",
                courbeCharge:{},

            }
        },
        async created(){
            await this.getTraitementAvecDeCourbeDeCharge(this.numCompteurACD)
        },
        methods:{
          async getTraitementAvecDeCourbeDeCharge(PDL){
                this.loadingEnedis = true;
                this.dialogLoading = true;
                    /*
                await fetch(process.env.VUE_APP_URL_API_CLIENT+"getTraitementAvecDeCourbeDeCharge/",{
                        method: "POST",
                        body:JSON.stringify({
                        PDL: PDL,
                        token:this.$cookies.get('token')
                    }),
                    headers: {
                        "Content-type": "application/json; charset=UTF-8"
                        }
                    })
                .then(res=>res.json())
                .then(data => {
                    if(data.result.donneTechniqueInfo[0].result == "valide"){
                    this.donneTechniqueInfo = data.result.donneTechniqueInfo[0].data;
                    this.heuresCreuses = data.result.donneTechniqueInfo[0].data.heurHC.split(';')

                    }
                    if(data.result.mesuresInfo[0].result == "valide"){
                    this.mesuresInfo = data.result.mesuresInfo[0];
                    }
                    this.parametreCompteur = data.result.parametreDeCompteur;
                    this.courbeCharge = data.result.courbeDeCharge;
                    this.dateSplitDebut = this.courbeCharge.fromDebut.substring(0,10).split('-').reverse();
                    this.dateSplitEnd = this.courbeCharge.toEnd.substring(0,10).split('-').reverse();
                })
                */
                this.donneTechniqueInfo = {typeCompteur:"BTMUST",kva:"30",puissanceAttentMax:"45",heurHC:"1H-10H;14H-20H"};
                this.donneTechniqueInfo.heureAM = this.donneTechniqueInfo.heurHC.split(';')[0]
                this.donneTechniqueInfo.heurePM = this.donneTechniqueInfo.heurHC.split(';')[1]
                delete this.donneTechniqueInfo.heurHC;
                
                this.mesuresInfo = {nbrMonth:"12",donne:{HC:{somme:"25"},HP:{somme:"30"}}};
                
                this.parametreCompteur = "C5HP";

                this.courbeCharge = {hc:"20",hp:"25",max:"30",fromDebut:"2023-03-05T02:00:00Z",toEnd:"2025-03-05T02:00:00Z"};
                let dateSplitDebut = this.courbeCharge.fromDebut.substring(0,10).split('-').reverse();
                let dateSplitEnd = this.courbeCharge.toEnd.substring(0,10).split('-').reverse();
                this.courbeCharge.dateSplitDebut = `${dateSplitDebut[1]}/${dateSplitDebut[0]}/${dateSplitDebut[2]}`;
                this.courbeCharge.dateSplitEnd = `${dateSplitEnd[1]}/${dateSplitEnd[0]}/${dateSplitEnd[2]}`;
                delete this.courbeCharge.fromDebut;
                delete this.courbeCharge.toEnd;
                
                this.loadingEnedis = false;
                this.dialogLoading = false;
                this.infosCourbe = true;
          },
        }
    }
</script>