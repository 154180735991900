<template>
    <v-dialog
         v-model="isDraged"
         persistent
         max-width="50%" style="z-index: 1028;"
         >
         <v-card>
             <v-toolbar style="background-color: rgb(21, 191, 166); color: rgb(255, 255, 255);">
                 <span class="text-h6 pl-0">Informations Visite</span>
                 <v-spacer></v-spacer>
                <v-btn  @click="navigateToProfilVisite(editedEvent.id)" style="border: 1px solid rgb(211, 211, 211);"> 
                    <v-icon class="mr-2">mdi-hammer-screwdriver</v-icon>
                    <span v-if="parent == 'visite-installation'" >Visite Installation </span>
                    <span v-if="parent == 'visite-technique'" >Visite Technique</span>
                  </v-btn>
                </v-toolbar>
             <div class="modal-body">
            <div class="row g-0">
              <div class="col-md-3  border-right mt-5">
                    <div class=" pt-1 text-center">
                        <div class="profile ">
                            <img :src="require(`@/assets/images/electricien.png`)" width="100" class="rounded-circle img-thumbnail">
                            <span class="d-block mt-3 font-weight-bold">{{ editedEvent.owner }}</span>
                        </div>                       
                    </div> 
                </div>
                <div class="col-md-9">
                    <div class="status p-3">
                        <table class="table table-borderless">
                          <tbody>
                            <tr>
                              <td>
                                  <div class="d-flex flex-column">
                                    <span class="heading d-block"><i class="fa-solid fa-star"></i> Titre Visite </span>
                                    <span class="subheadings mt-2">{{ editedEvent.title }}</span>
                                  </div>
                              </td>
                              <td>
                                  <div class="d-flex flex-column">
                                    <span class="heading d-block"> <i class="fa-solid fa-calendar-days"></i> Date Debut </span>
                                    <span class="subheadings mt-2">{{ editedEvent.dateDebut }}  </span>                         
                                  </div>
                              </td>
                              <td>
                                  <div class="d-flex flex-column">
                                    <span class="heading d-block"><i class="fa-solid fa-calendar-days"></i> Date Fin</span>
                                    <span class="subheadings mt-2">{{ editedEvent.dateFin }}</span>
                                  </div>
                              </td>
                            </tr>
                            
                            <tr>
                              <td>
                                  <div class="d-flex flex-column">
                                    <span class="heading d-block"> <i class="fa-solid fa-clock"></i> Time Debut </span>
                                    <span class="subheadings mt-2">{{ editedEvent.timeStart }}  </span>                         
                                  </div>
                              </td>
                              <td>
                                  <div class="d-flex flex-column">
                                    <span class="heading d-block"><i class="fa-solid fa-clock"></i> Time Fin</span>
                                    <span class="subheadings mt-2">{{ editedEvent.timeEnd }}</span>
                                  </div>
                              </td> 
                            </tr>
                            <tr>
                                <td colspan="3">
                                  <div class="d-flex flex-column">
                                    <span class="heading d-block"><i class="fa-solid fa-prescription-bottle"></i> Description</span>
                                    <span class="d-flex flex-row subheadings">   
                                      {{ editedEvent.description }}                                 
                                    </span>
                                  </div>
                              </td>                              
                            </tr>
                          </tbody>
                        </table>    
                    </div>         
                </div>      
            </div>
            <div class="row">
              <div class="col-12 " style="text-align: right;">        
                <v-btn color="error" @click="close" >Annuler</v-btn>        
              </div>
            </div>    
             </div>
         </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: "eventDragModal",
    props: {
        eventDraged: {
            type: Object,
            required: true,
        },
        isDraged: {
            type: Boolean,
            required: true,
        },
        parent:{
            type: String,
        }
    },
    data() {
        return {
            editedEvent: this.eventDraged,
        };
    },
    methods: {
        close() {
            this.$emit("closeModalDragEvent", this.editedEvent);
        },
        updateDragedEvent() {
            this.editedEvent = this.eventDraged
            this.$emit("dragEditedEvent", this.eventDraged);
        },
        navigateToProfilVisite(id){
        if(this.parent =='visite-technique')
            this.$router.push({ path: `/visite-technique/profil/${id}` })
        else if(this.parent =='visite-installation'){
            this.$router.push({path: `/visite-installation/profil/${id}`})
        }
      
    },
    },
}
</script>

<style scoped>
</style>