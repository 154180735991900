<template>
  <div style="height: fit-content" class="col-xl-12 col-sm-12 col-12">
    <v-card style="box-shadow: none">
      <div class="card-content shadow-test" style="min-height: 140px">
        <div class="card-body">
          <div class="media d-flex">
            <div class="align-self-center">
              <v-icon color="#64CCC5" size="60" class="font-large-2 float-left">{{
                icon
              }}</v-icon>
            </div>
            <div class="media-body text-right">
              <h5>{{ title }}</h5>
              <span :style="{ fontSize: 'xx-large', color: color }"
                >{{ typeof total == 'number' ? Number(total).toLocaleString("fr-FR") : total }} {{unite}}</span
              >
            </div>
          </div>
        </div>
      </div>
    </v-card>
  </div>
</template>
<script>
export default {
  name: "cardCustom",
  props: ["icon", "title", "color", "total", "unite"],
};
</script>
