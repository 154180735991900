<template>
  <div
    class="d-flex flex-row justify-content-center align-items-center"
    style="height: 100%; width: 100%"
  >
    <v-card
      style="width: 100%"
      elevation="0"
      class="d-flex flex-column justify-content-center align-items-center"
      height="100%"
      width="100%"
    >
      <v-progress-circular indeterminate color="primary" size="100"></v-progress-circular>
    </v-card>
  </div>
</template>
<script>
export default {
  name: "progress-circular",
};
</script>
