
let eventGuid = 0
let todayStr = new Date().toISOString().replace(/T.*$/, '') // YYYY-MM-DD of today

export const INITIAL_EVENTS = [
  {
    id: createEventId(),
    title: 'All-rs event',
    start: '2023-05-01'
  },
  {
    id: createEventId(),
    title: 'init event',
    start:'2023-05-02',
    end: '2023-05-02'
  }
]

export function createEventId() {
  return String(eventGuid++)
}