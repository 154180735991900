<template>
  <v-dialog v-model="dialogModel" persistent width="450" style="z-index: 1028">
    <v-card>
      <v-toolbar class="backgroundColor">
        <div class="d-flex flex-row align-center">
          <v-icon size="32" class="mr-1" color="white"
            >mdi-account-multiple-plus-outline</v-icon
          >
          <span class="pl-0 white--text">- Affectation {{ typeAffectation }}</span>
        </div>
      </v-toolbar>
      <v-form ref="formAddEvent" class="pa-4">
        <v-row class="ma-0 pa-0" width="100%">
          <v-col cols="12" class="ma-0 pa-0">
            <v-autocomplete
              v-if="dialogModel"
              outlined
              dense
              :label="labelListe"
              :items="listeItems"
              :item-text="'name'"
              :item-value="'id'"
              v-model="modelListe"
              @change="$emit('change', modelListe)"
            >
            </v-autocomplete>
          </v-col>
          <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="ma-0 p-2">
            <v-row justify="end">
              <div class="d-flex justify-end px-4 py-1">
                <v-btn color="error" outlined @click="$emit('closeDialog')" class="me-2">
                  Fermer
                </v-btn>
                <v-btn :color="$colors[0]" class="white--text" @click="$emit('affecter')">
                  Modifier
                </v-btn>
              </div>
            </v-row>
          </v-col>
        </v-row>
      </v-form>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: "DialogAffectation",
  props: ["dialogModel", "typeAffectation", "labelListe", "listeItems"],
  data() {
    return {
      modelListe: "",
    };
  },
  methods: {},
};
</script>
<style scoped>
.backgroundColor {
  background: #007991 linear-gradient(45deg, #007991 0%, #18aea2 68%);
}

::v-deep .v-icon.notranslate.fas.fa-caret-down.theme--light {
  font-size: initial !important;
}
</style>
