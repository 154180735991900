<template>
     <v-card color="white" class="rounded-lg" flat>
        <v-dialog
            v-model="modalCreate"
            persistent
            max-width="80%"
            >
            <v-card>
                <v-toolbar
                    dark>
                    <span class="text-h6 pl-0">Informations Event</span>
                </v-toolbar>
                <v-form ref="formAddEvent" class="pa-4">
                    <v-row class="ma-0 pa-0">
                        <v-col cols="12" sm="4" md="4" lg="4" xl='4' class="ma-0 pa-0">
                            <v-text-field
                            dense
                            v-model="newEvent.nameEvent"
                            placeholder="25 rue Baltimore 1234"
                            prepend-inner-icon="mdi-map-search-outline"
                            label="Nom"
                            outlined/>
                        </v-col>
                        <v-col cols="12" sm="4" md="4" lg="4" xl='4' class="ma-0 pa-0">
                            <v-text-field
                            dense
                            type="date"
                            v-model="newEvent.dateDebut"
                            placeholder="25 rue Baltimore 1234"
                            prepend-inner-icon="mdi-map-search-outline"
                            label="Date Debut"
                            outlined/>
                        </v-col>
                        <v-col cols="12" sm="4" md="4" lg="4" xl='4' class="ma-0 pa-0">
                            <v-text-field
                            dense
                            type="date"
                            v-model="newEvent.dateFin"
                            placeholder="25 rue Baltimore 1234"
                            prepend-inner-icon="mdi-map-search-outline"
                            label="Date Fin"
                            outlined/>
                        </v-col>
                        <v-col cols="12" sm="4" md="4" lg="4" xl='4' class="ma-0 pa-0">
                            <v-text-field
                            dense
                            type="time"
                            v-model="newEvent.timeDebut"
                            placeholder="25 rue Baltimore 1234"
                            prepend-inner-icon="mdi-map-search-outline"
                            label="Time debut"
                            outlined/>
                        </v-col>
                        <v-col cols="12" sm="4" md="4" lg="4" xl='4' class="ma-0 pa-0">
                            <v-text-field
                            dense
                            type="time"
                            v-model="newEvent.timeFin"
                            placeholder="25 rue Baltimore 1234"
                            prepend-inner-icon="mdi-map-search-outline"
                            label="Time Fin"
                            outlined/>
                        </v-col>
                        <v-col cols="12" sm="12" md="12" lg="12" xl='12' class="ma-0 pa-0">
                            <v-row>
                                <v-col cols="12" sm="4" md="4" lg="4" xl='4' class="ma-0 pa-0">
                                    <v-btn @click="modalCreate = false"> Close </v-btn>
                                </v-col>
                                <v-col cols="12" sm="4" md="4" lg="4" xl='4' class="ma-0 pa-0">
                                    <v-btn @click="CreateNewEvent"> Créer </v-btn>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card>
        </v-dialog>
        <v-dialog
            v-model="modalUpdateEventMonthly"
            persistent
            max-width="80%"
            >
            <v-card>
                <v-toolbar
                    dark>
                    <span class="text-h6 pl-0">Informations Event</span>
                </v-toolbar>
                <v-form ref="formAddEvent" class="pa-4">
                    <v-row class="ma-0 pa-0">
                        <v-col cols="12" sm="4" md="4" lg="4" xl='4' class="ma-0 pa-0">
                            <v-text-field
                            dense
                            v-model="editedEvent.name"
                            placeholder="25 rue Baltimore 1234"
                            prepend-inner-icon="mdi-map-search-outline"
                            label="Nom"
                            outlined/>
                        </v-col>
                        <v-col cols="12" sm="4" md="4" lg="4" xl='4' class="ma-0 pa-0">
                            <v-text-field
                            dense
                            type="time"
                            v-model="editedEvent.timeDebut"
                            placeholder="25 rue Baltimore 1234"
                            prepend-inner-icon="mdi-map-search-outline"
                            label="Time Debut"
                            outlined/>
                        </v-col>
                        <v-col cols="12" sm="4" md="4" lg="4" xl='4' class="ma-0 pa-0">
                            <v-text-field
                            dense
                            type="time"
                            v-model="editedEvent.timeFin"
                            placeholder="25 rue Baltimore 1234"
                            prepend-inner-icon="mdi-map-search-outline"
                            label="Time Fin"
                            outlined/>
                        </v-col>
                        <v-col cols="12" sm="12" md="12" lg="12" xl='12' class="ma-0 pa-0">
                            <v-row>
                                <v-col cols="12" sm="4" md="4" lg="4" xl='4' class="ma-0 pa-0">
                                    <v-btn @click="modalUpdateEventMonthly = false"> Close </v-btn>
                                </v-col>
                                <v-col cols="12" sm="4" md="4" lg="4" xl='4' class="ma-0 pa-0">
                                    <v-btn @click="saveEditEvent(editedEvent)"> Modifier </v-btn>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card>
        </v-dialog>
        <v-sheet tile height="54" class="d-flex">
            <v-btn icon class="ma-2" @click="$refs.calendar.prev()">
            <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
            <v-select
            v-model="type"
            :items="types"
            dense
            outlined
            hide-details
            class="ma-2"
            label="type"
            ></v-select>
            <v-select
            v-model="mode"
            :items="modes"
            dense
            outlined
            hide-details
            label="event-overlap-mode"
            class="ma-2"
            ></v-select>
            <v-select
            v-model="weekday"
            :items="weekdays"
            dense
            outlined
            hide-details
            label="weekdays"
            class="ma-2"
            ></v-select>
            <v-spacer></v-spacer>
            <v-btn icon class="ma-2" @click="$refs.calendar.next()">
            <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
        </v-sheet>
        <v-sheet height="800">
            <v-calendar
                ref="calendar"
                v-model="value"
                :weekdays="weekday"
                :type="type"
                :events="events"
                :event-overlap-mode="mode"
                :event-overlap-threshold="30"
                :event-color="getEventColor"
                @change="getEvents"
                @mousedown:event="startDrag"
                @mousedown:day="startTime"
                @mousemove:day="mouseMove"
                @mouseup:day="endDrag"
                @mousedown:time="startTime"
                @mousemove:time="mouseMove"
                @mouseup:time="endDrag"
                @mouseleave.native="cancelDrag"
                class="custom-calendar"
                >
                <template v-slot:day-body="{ week }">
                    <div>
                        <h3>{{ week.start }}</h3>
                        <ul>
                            <li v-for="day in week.days" :key="day.date">
                                {{ day.date }}
                            </li>
                        </ul>
                    </div>
                </template>
                <template #event="{ event, timed }">
                    <div @click="clickEvent(event)">
                        <div class="pl-1">
                            {{ event.name }} <br>
                            <div
                                class="v-event-drag-bottom"
                                @mousedown.stop="extendBottom(event)"
                            ></div>
                        </div>
                    </div>
                </template>
                &nbsp;
            </v-calendar>
        </v-sheet>
    </v-card>
</template>
<script>
    import dayjs from "dayjs";
    function getBrowserLocales(options = {}) {
        const defaultOptions = {
            languageCodeOnly: false,
        };
        const opt = {
            ...defaultOptions,
            ...options,
        };
        const browserLocales =
            navigator.languages === undefined
            ? [navigator.language]
            : navigator.languages;
        if (!browserLocales) {
            return undefined;
        }
        return browserLocales.map((locale) => {
            const trimmedLocale = locale.trim();
            return opt.languageCodeOnly ? trimmedLocale.split(/-|_/)[0] : trimmedLocale;
        });
    }
    const locales = getBrowserLocales({ languageCodeOnly: true });
    const locale = locales ? locales[0] : "en";
    require(`dayjs/locale/en`);
    require(`dayjs/locale/fr`);
    require(`dayjs/locale/it`);
    require(`dayjs/locale/es`);
    dayjs.locale(locale);

    export default{
        name: "AgendaComponent",
         data(){
            return{
                type: "month",
                types: ["month", "week", "day", "4day"],
                mode: "stack",
                modes: ["stack", "column"],
                weekday: [0, 1, 2, 3, 4, 5, 6],
                weekdays: [
                    { text: "Sun - Sat", value: [0, 1, 2, 3, 4, 5, 6] },
                    { text: "Mon - Sun", value: [1, 2, 3, 4, 5, 6, 0] },
                    { text: "Mon - Fri", value: [1, 2, 3, 4, 5] },
                    { text: "Mon, Wed, Fri", value: [1, 3, 5] },
                ],
                value: "",
                events: [],
                colors: [
                    "blue",
                    "indigo",
                    "deep-purple",
                    "cyan",
                    "green",
                    "orange",
                    "grey darken-1",
                ],
                names: [
                    "Meeting",
                    "Holiday",
                    "PTO",
                    "Travel",
                    "Event",
                    "Birthday",
                    "Conference",
                    "Party",
                ],
                dragEvent: null,
                dragStart: null,
                lastEvent: '',
                createEvent: null,
                createStart: null,
                extendOriginal: null,
                isMounted: false,

                modalCreate: false,
                modalUpdateEventMonthly: false,
                startUpdateMonthly: false,
                ExtendEvent: null,
                editedEvent: {
                    name:'', 
                    start: "",
                    end: "",
                    timeDebut:'',
                    timeFin:'',
                    index:'',
                    color: "",
                },
                newEvent: {
                    nameEvent:'', 
                    dateDebut:'',
                    dateFin:'',
                    timeDebut:'',
                    timeFin:'',
                }
            }
        },
        computed: {
            nowY() {
                const cal = this.$refs.calendar;
                if (!cal && !this.isMounted) {
                    return -1;
                }
                
                return cal.timeToY(cal.times.now) + 'px';
            },
            nowTime() {
                const cal = this.$refs.calendar;
                if (!cal && !this.isMounted) {
                    return -1;
                }
                
                return cal.formatTime(cal.times.now);
            },
        },
        mounted() {
            const cal = this.$refs.calendar;
            window.app = this;
            window.cal = cal;
            this.isMounted = true;
            // scroll to the current time
            const minutes = cal.times.now.hour * 60 + cal.times.now.minute;
            const firstTime = Math.max(0, minutes - (minutes % 30) - 30);
            cal.scrollToTime(firstTime);
            // every minute update the current time bar
            setInterval(() => cal.updateTimes(), 60 * 1000); 
        },
        methods: {
            getEvents ({ start, end }) {
                const events = []
                const eventCount = 1

                for (let i = 0; i < eventCount; i++) {
                    events.push({
                        color: "orange",
                        start: "2023-5-22 2:45",
                        end: "2023-5-23 4:45",
                        name: "Travel",
                    })
                }
                this.events = events
            },
            getEventColor (event) {
                const rgb = parseInt(event.color.substring(1), 16);
                const r = (rgb >> 16) & 0xFF;
                const g = (rgb >>  8) & 0xFF;
                const b = (rgb >>  0) & 0xFF;
                
                return event === this.dragEvent
                    ? `rgba(${r}, ${g}, ${b}, 0.5)`
                    : event === this.createEvent
                    ? `rgba(${r}, ${g}, ${b}, 0.5)`
                    : event.color;
            },
            rnd (a, b) {
                return Math.floor((b - a + 1) * Math.random()) + a
            },
            formatDate (a, withTime) {
                return withTime
                ? `${a.getFullYear()}-${a.getMonth() + 1}-${a.getDate()} ${a.getHours()}:${a.getMinutes()}`
                : `${a.getFullYear()}-${a.getMonth() + 1}-${a.getDate()}`
            },
            eventMove(e) {
                console.log('eventMove', e);
            },
            startDrag(e) {
                console.log(this.type)
                if (e.event && e.timed) {
                    this.dragEvent = e.event;
                    this.editedEvent['start']= e.event.start
                    this.editedEvent['end']= e.event.end
                    this.dragTime = null;
                    this.extendOriginal = null;
                }else if (e.event) {
                    this.dragEvent = e.event;
                    this.editedEvent['start']= e.event.start
                    this.editedEvent['end']= e.event.end
                    this.dragTime = null;
                    this.extendOriginal = null;
                }

                this.lastEvent = 'startDrag';
            },
            startTime(e) {
                console.log('timing')
                const mouse = this.toDate(e);
                if (this.dragEvent && this.dragTime === null) {
                    const start = this.toDate(this.dragEvent.start);
                    
                    this.dragTime = mouse.getTime() - start.getTime();
                    
                } else if(!this.dragEvent) {
                    this.modalCreate = true
                    /*this.createStart = this.roundTime(mouse.getTime());
                    this.createEvent = {
                    name: '(no title)',
                    start: this.toTimestamp(new Date(this.createStart)),
                    end: this.toTimestamp(new Date(this.createStart)),
                    color: this.colors[this.rnd(0, this.colors.length - 1)],
                    };
                    this.events.push(this.createEvent);*/
                }
                this.lastEvent = 'startTime';
            },
            EditEvent(event){
                this.editedEvent["name"] = event.name
                this.editedEvent["start"] = event.start
                this.editedEvent["end"] = event.end
                this.editedEvent["color"] = event.color
                this.editedEvent['index'] =this.events.indexOf(event)
                this.editedEvent['timeDebut'] = (""+event.start).split(' ')[1].split(':')[0].padStart(2, '0')+ ":" +(""+event.start).split(' ')[1].split(':')[1].padStart(2, '0')
                this.editedEvent['timeFin'] = (""+event.end).split(' ')[1].split(':')[0].padStart(2, '0')+ ":" +(""+event.end).split(' ')[1].split(':')[1].padStart(2, '0')
                this.modalUpdateEventMonthly = true
            },
            saveEditEvent(event){
                var newEvent = {
                    name: this.editedEvent["name"],
                    start: (""+this.editedEvent.start).split(' ')[0]+" "+ this.editedEvent['timeDebut'],
                    end: (""+this.editedEvent.end).split(' ')[0]+" "+ this.editedEvent['timeFin'],
                    color: this.editedEvent["color"],
                    
                }
                this.events.splice(this.editedEvent['index'], 1)
                this.events.push(newEvent);
                this.modalUpdateEventMonthly = false
            },
            CreateNewEvent(){
                var dateDebut = this.newEvent.dateDebut+" "+this.newEvent.timeDebut
                var dateFin = this.newEvent.dateFin+" "+this.newEvent.timeFin
                var event ={
                    name: this.newEvent.nameEvent,
                    start: this.toTimestamp(new Date(dateDebut)),
                    end: this.toTimestamp(new Date(dateFin)),
                    color: this.colors[this.rnd(0, this.colors.length - 1)],
                    };
                this.events.push(event);
                this.modalCreate = false
            },
            clickEvent(event){
                alert(event.name)
            },
            extendBottom(event) {
                this.createEvent = event;
                this.createStart = this.toDate(event.start).getTime();
                this.extendOriginal = event.end;
                this.editedEvent['start']= event.start
                this.editedEvent['end']= event.end
            },
            mouseMoveEvent(e) {
                console.log('mouseMoveEvent', e);
            },
            
            mouseMove(e) {
                if (this.dragEvent && this.dragTime !== null) {
                    const start = this.toDate(this.dragEvent.start);
                    const end = this.toDate(this.dragEvent.end);
                    const duration = end.getTime() - start.getTime();
                    const mouse = this.toDate(e);
                    
                    const newStartTime = mouse.getTime() - this.dragTime;
                    const newStart = new Date(this.roundTime(newStartTime));
                    const newEnd = new Date(newStart.getTime() + duration);
                    
                    this.dragEvent.start = this.toTimestamp(newStart);
                    this.dragEvent.end = this.toTimestamp(newEnd);
                }
                else if (this.createEvent && this.createStart !== null) {
                   
                    const mouse = this.toDate(e).getTime();
                    const mouseRounded = this.roundTime(mouse, false);
                    const min = Math.min(mouseRounded, this.createStart);
                    const max = Math.max(mouseRounded, this.createStart);
                    
                    this.createEvent.start = this.toTimestamp(new Date(min));
                    this.createEvent.end = this.toTimestamp(new Date(max));
                }
            },
            endDrag() {
                if(this.dragEvent){
                    if(this.type=="month" && (this.editedEvent['start'] != this.dragEvent['start'] || this.editedEvent['end'] != this.dragEvent['end'])){
                        this.EditEvent(this.dragEvent)
                    }
                }
                if(this.createEvent){
                    if(this.type=="month" && (this.editedEvent['start'] != this.createEvent['start'] || this.editedEvent['end'] != this.createEvent['end'])){
                        this.EditEvent(this.createEvent)
                    }
                }
                this.dragTime = null;
                this.dragEvent = null;
                this.createEvent = null;
                this.createStart = null;
                this.extendOriginal = null;
                this.lastEvent = 'endDrag';
            },
            cancelDrag() {
                if (this.createEvent) {
                    if (this.extendOriginal) {
                        this.createEvent.end = this.extendOriginal;
                    } else {
                    const i = this.events.indexOf(this.createEvent);
                    if (i !== -1) {
                        this.events.splice(i, 1);
                    }
                    }
                }
                
                this.createEvent = null;
                this.createStart = null;
                this.dragTime = null;
                this.dragEvent = null;
                
                this.lastEvent = 'cancelDrag';
            },
            roundTime(time, down = true) {
                const roundDownTime = 15 * 60 * 1000; // 15 minutes
                
                return down
                    ? time - time % roundDownTime
                    : time + (roundDownTime - (time % roundDownTime));
            },
            toDate(tms) {
                return typeof tms === 'string'
                    ? new Date(tms)
                    : new Date(tms.year, tms.month - 1, tms.day, tms.hour, tms.minute);
            },
            toTimestamp(date) {
                return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()} ${date.getHours()}:${date.getMinutes()}`;
            },
        }
    } 
</script>

<style scoped>

</style>