<template>
  <div class="vue-scheduler">
    <div class="header-container">
      <v-dialog
        v-model="modalUpdateEventMonthly"
        persistent
        max-width="50%"
        style="z-index: 1028"
      >
        <v-card>
          <v-toolbar style="background-color: rgb(21, 191, 166)">
            <span class="text-h6 pl-0" style="color: whitesmoke"
              >Informations Visite</span
            >
            <v-spacer></v-spacer>
            <v-btn
              @click="navigateToProfilVisite(editedEvent.id)"
              style="border: 1px solid rgb(211, 211, 211)"
            >
              <v-icon class="mr-2">mdi-hammer-screwdriver</v-icon>
              <span v-if="parent == 'visite-installation'">Visite Installation </span>
              <span v-if="parent == 'visite-technique'">Visite Technique</span>
            </v-btn>
          </v-toolbar>
          <!-- Modal -->
          <div class="modal-body">
            <div class="row g-0">
              <div class="col-md-3 border-right mt-5">
                <div class="pt-1 text-center">
                  <div class="profile">
                    <img
                      :src="require(`@/assets/images/electricien.png`)"
                      width="100"
                      class="rounded-circle img-thumbnail"
                    />
                    <span class="d-block mt-3 font-weight-bold">{{
                      editedEvent.owner
                    }}</span>
                  </div>
                </div>
              </div>
              <div class="col-md-9">
                <div class="status p-3">
                  <table class="table table-borderless">
                    <tbody>
                      <tr>
                        <td>
                          <div class="d-flex flex-column">
                            <span class="heading d-block"
                              ><i class="fa-solid fa-star"></i> Titre Visite
                            </span>
                            <span class="subheadings mt-2">{{ editedEvent.title }}</span>
                          </div>
                        </td>
                        <td>
                          <div class="d-flex flex-column">
                            <span class="heading d-block">
                              <i class="fa-solid fa-calendar-days"></i> Date Debut
                            </span>
                            <span class="subheadings mt-2"
                              >{{ editedEvent.dateDebut }}
                            </span>
                          </div>
                        </td>
                        <td>
                          <div class="d-flex flex-column">
                            <span class="heading d-block"
                              ><i class="fa-solid fa-calendar-days"></i> Date Fin</span
                            >
                            <span class="subheadings mt-2">{{
                              editedEvent.dateFin
                            }}</span>
                          </div>
                        </td>
                      </tr>

                      <tr>
                        <td>
                          <div class="d-flex flex-column">
                            <span class="heading d-block">
                              <i class="fa-solid fa-clock"></i> Time Debut
                            </span>
                            <span class="subheadings mt-2"
                              >{{ editedEvent.timeStart }}
                            </span>
                          </div>
                        </td>
                        <td>
                          <div class="d-flex flex-column">
                            <span class="heading d-block"
                              ><i class="fa-solid fa-clock"></i> Time Fin</span
                            >
                            <span class="subheadings mt-2">{{
                              editedEvent.timeEnd
                            }}</span>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td colspan="3">
                          <div class="d-flex flex-column">
                            <span class="heading d-block"
                              ><i class="fa-solid fa-prescription-bottle"></i>
                              Description</span
                            >
                            <span class="d-flex flex-row subheadings">
                              {{ editedEvent.description }}
                            </span>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12" style="text-align: right">
                <v-btn color="error" @click="modalUpdateEventMonthly = false"
                  >Annuler</v-btn
                >
              </div>
            </div>
          </div>
        </v-card>
      </v-dialog>
      <div
        class="header-container"
        style="display: flex; flex-direction: row; align-items: center"
      >
        <div class="arrow-btns-wrapper" style="text-align: left">
          <button class="nav" @click="onSelectPrevTimeslot">
            <span class="mdi mdi-chevron-left"></span>
          </button>
          <button class="nav" @click="onSelectNextTimeslot">
            <span class="mdi mdi-chevron-right"></span>
          </button>
        </div>
        <h2 class="vt-title">{{ getSelectedTimeslot() }}</h2>
      </div>
    </div>
    <div
      id="scheduler-container"
      class="scheduler-container"
      :style="{ 'max-width': timelineWidth + 50 + 'px' }"
    >
      <div class="timeline" :style="{ height: timelineHeight + 300 + 'px' }">
        <div class="resource-slot" v-if="attributeName">
          <b>Resource</b>
        </div>
        <div
          :class="`timeline-slot ${selectedSlots.includes(slot.id) ? 'selected' : ''}`"
          v-for="slot in slots"
          :key="slot.id"
          :style="{ width: timelineSlotWidth + 'px' }"
          :title="slot.tooltip"
          @click="onSelectSlot(slot.id)"
        >
          <span class="slotDay" @click="moveToDay(slot.fullDate)">{{ slot.title }}</span>
        </div>
      </div>

      <div class="resources" v-if="attributeName">
        <div
          class="resource-row"
          v-for="(resource, i) in resourceLines"
          :key="i"
          :style="{
            height:
              resource.lines.length < 0
                ? `${resource.height / 2}px`
                : `${resource.height}px`,
          }"
        >
          {{ resource.value }}
        </div>
      </div>
      <div class="events">
        <!-- <div class="event-row" v-for="(eventRow, i) in eventLines" :key="i">
          <div v-for="events in (eventRow)">
         <div 
          v-for="event in events"
            :title="getEventTooltip(event)"
            class="event"
            :style="{
              left: event.position + 'px',
              width: event.width + 'px',
            }"
           
            @mousedown="attachDrag(event, $event)"

          >
            <div
              :id="`event-cell-${i}`"
              :title="getEventTooltip(event)"
              :class="`cell ${selected === event.id ? 'selected' : ''}`"
              :style="'background-color:'+event.color+';'"
            >
            {{ `${event.title}` }}
           </div>
        </div>
          </div>
        </div> -->
        <div class="event-row" v-for="(eventRow, i) in eventLines" :key="i">
          <div
            :id="`event-container-${i}-${event.id}`"
            v-for="event in eventRow"
            :key="event.id"
            :title="getEventTooltip(event)"
            class="event"
            :style="{
              left: event.position + 'px',
              width: event.width + 'px',
            }"
            @click="clickedEventInfos(event)"
          >
            <div
              :id="`event-cell-${i}-${event.id}`"
              :title="getEventTooltip(event)"
              :class="`cell ${selected === event.id ? 'selected' : ''}`"
              :style="'background-color:' + event.color + ';'"
            >
              {{ `${event.title}` }}
            </div>
          </div>
        </div>
      </div>

      <div class="now" :style="{ left: nowMarkerPosition + 'px' }" ref="now"></div>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";
import { v4 as uuidv4 } from "uuid";
var weekOfYear = require("dayjs/plugin/weekOfYear");
dayjs.extend(weekOfYear);
var weekday = require("dayjs/plugin/weekday");
dayjs.extend(weekday);
var quarterOfYear = require("dayjs/plugin/quarterOfYear");
dayjs.extend(quarterOfYear);
var localizedFormat = require("dayjs/plugin/localizedFormat");
dayjs.extend(localizedFormat);

function isIterable(obj) {
  if (obj == null) {
    return false;
  }
  return typeof obj[Symbol.iterator] === "function";
}

const validatePeriod = function (value) {
  return ["quarter", "week", "day"].includes(value.toLowerCase());
};

const validateEvents = function (value) {
  return Boolean(
    value.filter(
      (item) =>
        item.id !== undefined &&
        item.title !== undefined &&
        item.dateDebut !== undefined &&
        item.dateFin !== undefined &&
        item.timeStart !== undefined &&
        item.timeEnd !== undefined
    )
  );
};

export default {
  setup() {},
  components: {},
  props: {
    period: {
      type: String,
      validator: validatePeriod,
    },
    parent: {
      type: String,
    },
    events: {
      type: Array,
      validator: validateEvents,
    },
    currentDay: null,
    attributeName: String,
    selected: String,
  },
  data() {
    return {
      timelineSlotWidth: 150,
      selectedSlots: [],
      selectedTimeslot: dayjs(this.currentDay),
      targetDay: dayjs(this.currentDay),
      wichDateUpdated: "",
      modalUpdateEventMonthly: false,
      editedEvent: {
        id: "",
        title: "",
        dateDebut: "",
        dateFin: "",
        timeStart: "",
        timeEnd: "",
        index: "",
        start: "",
        end: "",
        description: "",
        owner: "",
        color: "",
        allDay: "",
      },
    };
  },

  methods: {
    async closeEtAnnuler() {
      this.$emit("annulerLaModification");
      this.modalUpdateEventMonthly = false;
    },
    navigateToProfilVisite() {
      this.$router.push({ path: "/visite-installation/listeVisiteInstallation" });
    },
    clickedEventInfos(event) {
      console.log(event);
      this.editedEvent.id = event.id;
      this.editedEvent.title = event.title;
      this.editedEvent.dateDebut = dayjs(event.start).format("YYYY-MM-DD");
      this.editedEvent.dateFin = dayjs(event.end).format("YYYY-MM-DD");
      this.editedEvent.timeStart = dayjs(event.star).format("HH:mm");
      this.editedEvent.timeEnd = dayjs(event.end).format("HH:mm");
      this.editedEvent.owner = event.owner;
      this.editedEvent.description = event.description;
      this.editedEvent.color = event.color;
      this.editedEvent.allDay = event.allDay;
      this.modalUpdateEventMonthly = true;
    },
    updateEventMonthly() {
      this.editedEvent.id = this.editedEvent.id;
      this.editedEvent.title = this.editedEvent.title;
      this.editedEvent.dateDebut = this.editedEvent.dateDebut;
      this.editedEvent.dateFin = this.editedEvent.dateFin;
      this.editedEvent.timeStart = this.editedEvent.timeStart;
      this.editedEvent.timeEnd = this.editedEvent.timeEnd;
      this.editedEvent.start = dayjs(
        new Date(this.editedEvent.dateDebut + " " + this.editedEvent.timeStart)
      ).format("YYYY-MM-DD HH:mm");
      this.editedEvent.end = dayjs(
        new Date(this.editedEvent.dateFin + " " + this.editedEvent.timeEnd)
      ).format("YYYY-MM-DD HH:mm");
      this.editedEvent.owner = this.editedEvent.owner;
      this.editedEvent.description = this.editedEvent.description;
      this.editedEvent.color = this.editedEvent.color;
      this.editedEvent.allDay = this.editedEvent.allDay;
      var event = {
        id: this.editedEvent.id,
        title: this.editedEvent.title,
        dateDebut: this.editedEvent.dateDebut,
        dateFin: this.editedEvent.dateFin,
        timeStart: this.editedEvent.timeStart,
        timeEnd: this.editedEvent.timeEnd,
        start: this.editedEvent.start,
        end: this.editedEvent.end,
        owner: this.editedEvent.owner,
        description: this.editedEvent.description,
        color: this.editedEvent.color,
        allDay: this.editedEvent.allDay,
      };
      this.modalUpdateEventMonthly = false;
      this.$emit("miseAjourEffectuer", event);
      //this.editEvent(event)
      //this.calendarApi.refetchEvents()
      //this.calendarApi.addEventSource(this.globalEvents);
    },
    async editEvent(newEvent) {
      var index = this.events.findIndex((item) => item.id == newEvent.id);
      if (index != -1) {
        this.events[index].title = newEvent.title;
        this.events[index].dateDebut = newEvent.dateDebut;
        this.events[index].dateFin = newEvent.dateFin;
        this.events[index].timeStart = newEvent.timeStart;
        this.events[index].timeEnd = newEvent.timeEnd;
        this.events[index].start = dayjs(
          new Date(newEvent.dateDebut + " " + newEvent.timeStart)
        ).format("YYYY-MM-DD HH:mm");
        this.events[index].end = dayjs(
          new Date(newEvent.dateFin + " " + newEvent.timeEnd)
        ).format("YYYY-MM-DD HH:mm");
        this.events[index].owner = newEvent.owner;
        this.events[index].description = newEvent.description;
        this.events[index].color = newEvent.color;
        this.events[index].allDay = newEvent.allDay;
      }
    },
    navigateToProfilVisite(id) {
      if (this.parent == "visite-technique")
        this.$router.push({ path: `/visite-technique/profil/${id}` });
      else if (this.parent == "visite-installation") {
        this.$router.push({ path: `/visite-installation/profil/${id}` });
      }
    },
    moveToDay(day) {
      console.log("moving to day", day);
      this.targetDay = dayjs(day).format("YYYY-MM-DD");
      this.$emit("toSelectedDay", this.targetDay);
    },
    getSelectedTimeslot() {
      if (this.periodName === "quarter") {
        return `Q${this.selectedTimeslot.quarter()}, ${this.selectedTimeslot.year()}`;
      } else if (this.periodName === "week") {
        return `Semaine ${this.selectedTimeslot.week()}, ${this.selectedTimeslot.year()}`;
      } else if (this.periodName === "day") {
        return `${this.selectedTimeslot.format("LL")}`;
      }
    },
    onSelectPrevTimeslot() {
      this.selectedTimeslot = this.selectedTimeslot.add(-1, this.periodName);
      var dateDebut = dayjs(this.selectedTimeslot).format("YYYY-MM-01");
      var dateFin = dayjs(this.selectedTimeslot).add(1, "month").format("YYYY-MM-DD");
      this.$emit("rechargeEvents", { dateDebut: dateDebut, dateFin: dateFin });
      //this.slotsChange("prev");
    },
    onSelectNextTimeslot() {
      this.selectedTimeslot = this.selectedTimeslot.add(1, this.periodName);
      var dateDebut = dayjs(this.selectedTimeslot).format("YYYY-MM-01");
      var dateFin = dayjs(this.selectedTimeslot).add(1, "month").format("YYYY-MM-DD");
      this.$emit("rechargeEvents", { dateDebut: dateDebut, dateFin: dateFin });
      //this.slotsChange("next");
    },
    attachDrag(eventObject, mouseDownEvent) {
      mouseDownEvent.preventDefault();
      //console.log("modifying edges");
      document.onmousemove = this.dragElement(mouseDownEvent.clientX, eventObject);

      //console.log("modifying edges");
    },
    dragElement(initialX, eventObject) {
      var isdraged = false;
      const event = this.events.find((event) => event.id == eventObject.id);
      if (event === undefined) {
        return null;
      }
      const eventstart = dayjs(event.start);
      const eventend = dayjs(event.end);
      const parentElement = document.getElementById("scheduler-container");
      const rectangle = parentElement.getBoundingClientRect();
      const clickPosition = parentElement.scrollLeft + initialX;
      const leftBorderDistance = Math.abs(
        clickPosition - (rectangle.left + eventObject.position)
      );
      const rightBorderDistance = Math.abs(
        clickPosition - (rectangle.left + eventObject.position + eventObject.width)
      );
      const what =
        leftBorderDistance < 8 ? "left" : rightBorderDistance < 8 ? "right" : "both";
      var that = this;
      const dragTo = function (moveEvent) {
        const gap = moveEvent.clientX - initialX;
        if (what === "left") {
          that.wichDateUpdated = "left";
          event.start = eventstart.add(
            gap / that.timelineSlotWidth,
            that.localUnit.toLowerCase()
          );
          event.dateDebut = dayjs(event.start).format("YYYY-MM-DD");
          event.dateFin = dayjs(event.end).format("YYYY-MM-DD");
          event.timeStart = dayjs(event.start).format("HH:mm");
          event.timeEnd = dayjs(event.end).format("HH:mm");
        }
        if (what === "right") {
          that.wichDateUpdated = "right";
          event.end = eventend.add(
            gap / that.timelineSlotWidth,
            that.localUnit.toLowerCase()
          );
          event.dateDebut = dayjs(event.start).format("YYYY-MM-DD");
          event.dateFin = dayjs(event.end).format("YYYY-MM-DD");
          event.timeStart = dayjs(event.start).format("HH:mm");
          event.timeEnd = dayjs(event.end).format("HH:mm");
        }
        if (what === "both") {
          that.wichDateUpdated = "both";
          event.start = eventstart.add(
            gap / that.timelineSlotWidth,
            that.localUnit.toLowerCase()
          );
          event.end = eventend.add(
            gap / that.timelineSlotWidth,
            that.localUnit.toLowerCase()
          );
          event.dateDebut = dayjs(event.start).format("YYYY-MM-DD");
          event.dateFin = dayjs(event.end).format("YYYY-MM-DD");
          event.timeStart = dayjs(event.start).format("HH:mm");
          event.timeEnd = dayjs(event.end).format("HH:mm");
        }
        document.onmouseup = that.detachDrag(eventObject.id, that.wichDateUpdated);
        event.start = dayjs(event.start).format("YYYY-MM-DD HH:mm");
        event.end = dayjs(event.end).format("YYYY-MM-DD HH:mm");
      };
      return dragTo;
    },
    detachDrag(eventId, what) {
      const that = this;
      return function () {
        console.log(what);

        const event = that.events.find((event) => event.id == eventId);
        that.clickedEventInfos(event);
        that.$emit("selectedEvent", event);
        document.onmouseup = null;
        document.onmousemove = null;
      };
    },
    onSelectSlot(slotId) {
      const index = this.selectedSlots.indexOf(slotId);
      //console.log("index", index);
      if (index > -1) {
        this.selectedSlots.splice(index, 1);
      } else {
        this.selectedSlots.push(slotId);
      }
      this.setToLocalStorage("selectedSlots", this.selectedSlots);
    },
    slotsCount() {
      if (this.periodName === "quarter") {
        return this.endOfPeriod.week() - this.beginningOfPeriod.week();
      } else if (this.periodName === "week") {
        return 7;
      }
      return 24;
    },
    totalSlots() {
      return this.slotsCount() + (this.attributeName ? 1 : 0);
    },
    getEventTooltip(event) {
      const format = this.periodName === "quarter" ? "MMM DD" : "H:mm A, MMM DD YYYY";

      return `${event.title} (${dayjs(event.start).format(format)} -> ${dayjs(
        event.end
      ).format(format)})`;
    },
    overlaps(event, events) {
      const es = dayjs(event.start);
      const ee = dayjs(event.end);
      for (let other of events) {
        const os = dayjs(other.start);
        const oe = dayjs(other.end);
        if (!(ee < os || oe < es)) {
          return other;
        }
      }
      return false;
    },
    accumulate(r, element, a, that) {
      r[element] = r[element] || { lines: [] };
      let event = that.convertEvent(a);
      for (const line of r[element].lines) {
        if (line.events === undefined) {
          line.events = [event];
          event = null;
        } else if (!that.overlaps(a, line.events)) {
          line.events.push(event);
          event = null;
          break;
        }
      }
      if (event) {
        r[element].lines.push({ events: [event] });
      }
    },
    openModal(event) {
      this.$emit("selectedEvent", event);
      this.$emit("openModal", event);
    },
    computeEventGrid(events, attributeName) {
      const that = this;
      if (events === undefined) return [];
      return events.reduce(function (r, a) {
        let value = a[attributeName];
        if (isIterable(value) && typeof value !== "string") {
          if (value.length == 0) {
            //console.log(`⚠️ the item ${a.title} (${a.id}) has no value for the property '${that.attributeName}'`);
            value.push("non assigned");
          }
        } else {
          value = [value];
        }

        value.forEach((element) => that.accumulate(r, element, a, that));

        return r;
      }, Object.create(null));
    },
    convertEvent(event) {
      //console.log(dayjs(event.start).format("YYYY-MM-DD"))
      const { title, start, end, id, color, allDay, description, owner } = event;
      if (this.periodName == "week") {
        var lesDonner = this.getPositionEvent(start, end);
        var position = lesDonner[0];
        var width = lesDonner[1];
      } else {
        var position = this.computePosition(start, "pos");
        var width = this.computePosition(end, "width") - position;
      }
      /*if(this.periodName == "week"){
          if(width >0){
            width = width+100
          }
          if(width == 0 && position == 700){
            position=position + 100
          }
        }*/
      return {
        title: title,
        position: position,
        start: dayjs(start).format("YYYY-MM-DD HH:mm"),
        end: dayjs(end).format("YYYY-MM-DD HH:mm"),
        width: width,
        color: color,
        allDay: allDay,
        id: id !== undefined ? id : uuidv4(),
        description: description,
        owner: owner,
      };
    },
    limit(instant) {
      if (instant < this.beginningOfPeriod) {
        return this.beginningOfPeriod;
      }
      if (instant > this.endOfPeriod) {
        return this.endOfPeriod;
      }
      return instant;
    },
    getPositionEvent(start, end) {
      const fixedMarginLeft = (this.attributeName ? 1 : 0) * this.timelineSlotWidth;
      const evaluatedstart = dayjs(start);
      const evaluatedend = dayjs(end);
      const start1 = this.limit(evaluatedstart);
      const end1 = this.limit(evaluatedend);
      var diffInDays = start1.diff(this.beginningOfPeriod, "day");
      var pos = fixedMarginLeft + diffInDays * this.timelineSlotWidth;
      var diffInDays = end1.diff(this.beginningOfPeriod, "day");
      var width = fixedMarginLeft + diffInDays * this.timelineSlotWidth - pos;
      if (pos == 1050 && evaluatedstart >= this.endOfPeriod) {
        pos = pos + 150;
        width = width + 150;
      } else if (
        evaluatedstart >= this.beginningOfPeriod &&
        evaluatedstart <= this.endOfPeriod
      ) {
        pos = pos + (evaluatedstart.hour() * this.timelineSlotWidth) / 24;
      }
      if (
        pos != 150 &&
        evaluatedend >= this.beginningOfPeriod &&
        evaluatedend <= this.endOfPeriod
      ) {
        width =
          width +
          ((evaluatedend.hour() - evaluatedstart.hour()) * this.timelineSlotWidth) / 24;
      }
      if (
        pos == 150 &&
        evaluatedend >= this.beginningOfPeriod &&
        evaluatedend <= this.endOfPeriod
      ) {
        width = width + (evaluatedend.hour() * this.timelineSlotWidth) / 24;
      }
      if (pos == 150 && width == 900) {
        //console.log("3")
        width = width + 150;
      }
      if (evaluatedend < this.beginningOfPeriod || evaluatedstart > this.endOfPeriod) {
        width = 0;
      }
      if (
        pos != 150 &&
        evaluatedend >= this.endOfPeriod &&
        evaluatedstart <= this.endOfPeriod
      ) {
        width = width + 100;
      }
      if (
        width < 150 &&
        width != 0 &&
        evaluatedstart >= this.beginningOfPeriod &&
        evaluatedstart <= this.endOfPeriod
      ) {
        pos = pos - (evaluatedstart.hour() * this.timelineSlotWidth) / 24;
        width = 150;
      }
      if (
        width < 150 &&
        width != 0 &&
        evaluatedstart < this.beginningOfPeriod &&
        evaluatedend <= this.endOfPeriod
      ) {
        width = 150;
      }
      /*if(pos == 1200){
          pos = pos-50;
        }*/
      return [pos, width];
    },
    computePosition(date, title) {
      const evaluatedDate = dayjs(date);
      const period = this.periodName;
      const fixedMarginLeft = (this.attributeName ? 1 : 0) * this.timelineSlotWidth;
      if (period == "quarter") {
        const workDate = this.limit(evaluatedDate);
        return (
          fixedMarginLeft +
          (workDate.week() - this.beginningOfPeriod.week()) * this.timelineSlotWidth +
          (workDate.weekday() * this.timelineSlotWidth) / 7
        );
      } else if (period == "week") {
        const workDate = this.limit(evaluatedDate);
        const diffInDays = workDate.diff(this.beginningOfPeriod, "day");
        //var pos =  diffInDays * this.timelineSlotWidth
        var pos =
          fixedMarginLeft +
          diffInDays * this.timelineSlotWidth +
          (evaluatedDate.hour() * this.timelineSlotWidth) / 60;

        return pos;
      } else if (period == "day") {
        const workDate = this.limit(evaluatedDate);
        return (
          fixedMarginLeft +
          workDate.hour() * this.timelineSlotWidth +
          (workDate.minute() * this.timelineSlotWidth) / 60
        );
      }
    },
    followingMonday(day) {
      return day.add(7 - day.day() + 1, "day");
    },
    followingSunday(day) {
      return day.add(0 - day.day() + 1, "day");
    },
    setToLocalStorage(key, item) {
      localStorage.setItem(key, JSON.stringify(item));
    },
    retrieveFromLocalStorage(key) {
      if (localStorage.getItem(key)) {
        try {
          return JSON.parse(localStorage.getItem(key));
        } catch (e) {
          localStorage.removeItem(key);
        }
      }
    },
    openUpadateModal(event) {
      this.$emit("openUpadateModal", event);
    },
  },
  computed: {
    dateDebutLower() {
      if (new Date(this.editedEvent.dateDebut) > new Date(this.editedEvent.dateFin)) {
        return "La date de début doit être inférieure ou égale à la date de fin";
      }
      return true;
    },
    dateFinHigher() {
      if (new Date(this.editedEvent.dateDebut) > new Date(this.editedEvent.dateFin)) {
        return "La date de début doit être inférieure ou égale à la date de fin";
      }
      return true;
    },
    timeStartLower() {
      if (this.editedEvent.dateDebut == this.editedEvent.dateFin) {
        if (this.editedEvent.timeStart >= this.editedEvent.timeEnd) {
          return "L'heure de début doit être inférieure";
        }
        return true;
      }
    },
    timeEndHigher() {
      if (this.editedEvent.dateDebut == this.editedEvent.dateFin) {
        if (this.editedEvent.timeEnd <= this.editedEvent.timeStart) {
          return "L'heure de fin doit être supérieure";
        }
        return true;
      }
    },
    periodName: function () {
      return this.period.toLowerCase();
    },
    localUnit: function () {
      return this.periodName === "quarter"
        ? "Week"
        : this.periodName === "week"
        ? "Day"
        : "Hour";
    },
    nowMarkerPosition() {
      return this.computePosition(dayjs());
    },
    beginningOfPeriod: function () {
      let now = this.selectedTimeslot;
      if (this.periodName === "quarter") {
        now = this.followingMonday(now.startOf("quarter"));
      } else if (this.periodName === "week") {
        now = now.startOf("week");
      } else {
        now = now.startOf("day");
      }
      return now;
    },
    endOfPeriod: function () {
      let now = this.selectedTimeslot;
      if (this.periodName === "quarter") {
        now = this.followingSunday(now.endOf("quarter"));
      } else if (this.periodName === "week") {
        now = now.endOf("week");
      } else {
        now = now.endOf("day");
      }
      return now;
    },
    eventGrid: function () {
      var test = this.computeEventGrid(this.events, this.attributeName);
      return test;
    },
    resourceLines: function () {
      let resources = [];
      for (const [key, resource] of Object.entries(this.eventGrid)) {
        resource.value = key;
        resource.height = resource.lines.length * 46 - 10;
        resources.push(resource);
      }
      return resources;
    },
    eventsLineTwo: function () {
      let lines = [];
      for (const [, resource] of Object.entries(this.eventGrid)) {
        lines.push(...resource.lines.map((line) => line.events));
      }
      return lines;
    },
    eventLines: function () {
      let lines = [];
      for (const [, resource] of Object.entries(this.eventGrid)) {
        lines.push(...resource.lines.map((line) => line.events));
      }
      return lines;
    },
    timelineWidth: function () {
      return this.totalSlots() * this.timelineSlotWidth;
    },
    timelineHeight: function () {
      return (3 + this.eventLines.length) * 41;
    },
    slots: function () {
      var i;
      var data = [];
      if (this.periodName === "quarter") {
        const firstQuarterWeek = this.beginningOfPeriod.week();
        for (i = 0; i < this.slotsCount(); i++) {
          data.push({
            id: i,
            title: `${this.localUnit} ${i + firstQuarterWeek}`,
            tooltip: `${this.beginningOfPeriod
              .add(i, "week")
              .format("MMM DD")} -> ${this.beginningOfPeriod
              .add(i, "week")
              .add(6, "day")
              .format("MMM DD")}`,
          });
        }
      } else if (this.periodName === "week") {
        // const firstDayOfWeek = dayjs().startOf("week");
        const firstDayOfWeek = this.beginningOfPeriod;
        for (i = 0; i < this.slotsCount(); i++) {
          data.push({
            id: i,
            title: `${firstDayOfWeek.add(i, "day").format("MMM DD")}`,
            fullDate: `${firstDayOfWeek.add(i, "day").format("YYYY-MM-DD")}`,
          });
        }
      } else if (this.periodName === "day") {
        for (i = 0; i < this.slotsCount(); i++) {
          data.push({
            id: i,
            title: `${this.localUnit} ${i + 1}`,
          });
        }
      }
      return data;
    },
  },
  mounted() {
    //this.slotsChange();
    this.selectedSlots = this.retrieveFromLocalStorage("selectedSlots") || [];
    this.timelineSlotWidth = 150;
  },
};
</script>

<style scoped>
.header-container {
  margin-top: 14px;
  margin-bottom: 1.5em;
}
.arrow-btns-wrapper {
  display: flex;
  align-items: center;
  flex-direction: row;
  color: #070707;
  font-weight: bold;
}
.vt-title {
  font-family: "Poppins";
  font-size: 32px;
  color: #020f47;
  flex-grow: 1;
  text-align: right;
  font-weight: 500 !important;
  line-height: 1.2 !important;
  margin: 0px !important;
}
.arrow-btns-wrapper .nav {
  background: #e8f2ff !important;
  border: none !important;
  border-radius: 4px !important;
  margin-right: 8px !important;
  padding: 4px 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  height: 45px;
}
.arrow-btns-wrapper .nav span {
  color: #26222f !important;
  font-size: 24px !important;
  font-weight: 600;
  text-transform: capitalize;
}
.scheduler-container {
  border: 1px solid #e2e2e2;
  position: relative;
  display: flex;
  overflow-x: auto;
  max-height: 550px;
}

.timeline {
  overflow: visible;
  height: 100%;
  display: flex;
}

.timeline .resource-slot {
  flex: 1;
  width: 150px;
  height: 100%;
  box-sizing: border-box;
  float: left;
  background-color: #f1f1f1;
  color: #1976d2;
  font-size: 12px;
  text-indent: 5px;
  padding-top: 5px;
  font-weight: bold;
  border-right: 1px solid #e2e2e2;
}

.timeline .timeline-slot {
  flex: 1;
  width: 150px;
  height: 100%;
  border-right: 1px solid #e2e2e2;
  box-sizing: border-box;
  float: left;
  color: #1976d2;
  font-size: 14px;
  text-indent: 5px;
  padding-top: 8px !important;
  font-weight: bold;
}

.timeline .selected {
  flex: 1;
  background-color: #fffff4;
}

.now {
  border-left: 1px solid red;
  opacity: 0.5;
  height: 100%;
  position: absolute;
  top: 0px;
  z-index: 0;
}

.resources {
  position: absolute;
  top: 20px;
  z-index: 1;
  border: 1px solid #f0f0f017 !important;
}

.resources .resource-row {
  top: 30px;
  margin-bottom: 10px;
  border: 1px solid #e2e2e2;
  border-radius: 4px;
  color: #14324f !important;
  text-transform: capitalize;
  background-color: #fff;
  position: relative;
  width: 138px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  box-shadow: none !important;
}

.events {
  position: absolute;
  top: 50px;
  z-index: 1;
}

.events .event-row {
  margin-bottom: 10px;
  height: 36px;
  position: relative;
}

.events .event-row .event {
  position: absolute;
  z-index: 1;
  border: 1px solid #dbdbdb !important;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0px 4px 24px 0px rgba(194, 194, 194, 1) !important;
  cursor: move;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 2px 8px;
  font-size: 12px;
  font-weight: 400 !important;
}

.events .event-row .event .cell {
  width: 100%;
  cursor: move;
  background-color: white;
  color: #fff;
  padding-top: 8px;
  padding-bottom: 8px;
}

.events .event-row .event .selected {
  background-color: rgb(200, 219, 240);
  z-index: 1;
}
.slotDay {
  font-size: 12px;
  font-weight: bold;
  margin-right: 5px;
  border-radius: 5px;
  padding: 2px 13px;
  color: #13a592 !important;
  cursor: pointer;
  border: 1px solid #e2e2e2 !important;
  padding-left: 40px !important;
  padding-right: 40px !important;
  background-color: #fff !important;
  padding-bottom: 7px !important ;
  padding-top: 5px !important;
  box-shadow: 0px 0px 16px -4px rgba(194, 194, 194, 1);
}
</style>
