<template>
    <v-dialog
         v-model="isDraged"
         persistent
         max-width="80%" style="z-index: 1028;"
         >
         <v-card>
             <v-toolbar  style="background-color: rgb(21, 191, 166);">
                <span class="text-h6 pl-0" style="color: whitesmoke;">Informations Event</span>
                    <v-spacer></v-spacer>
                  <v-btn  @click="" style="border: 1px solid rgb(211, 211, 211);">
                    
                    <v-icon class="mr-2">mdi-hammer-screwdriver</v-icon>
                    <span >Visite Technique</span>
                  </v-btn>
                </v-toolbar>
             <v-form ref="form" class="pa-4">
                 <v-row class="ma-0 p-2">
                     <v-col cols="12" sm="4" md="4" lg="4" xl='4' class="ma-0 p-2">
                         <v-text-field
                            dense
                            v-model="eventDraged.title"
                            placeholder="25 rue Baltimore 1234"
                            prepend-inner-icon="mdi-map-search-outline"
                            label="Nom"
                            outlined
                          />
                     </v-col>
                     <!-- this col for the old start and end date -->
                     <v-col cols="12" sm="4" md="4" lg="4" xl='4' class="ma-0 p-2">
                         <v-text-field
                            dense
                            type="date"
                            v-model="eventDraged.dateDebut"
                            prepend-inner-icon="mdi-map-search-outline"
                            label="Date Debut"
                            outlined
                            :rules="[dateDebutLower]"
                          />
                     </v-col>
                     <v-col cols="12" sm="4" md="4" lg="4" xl='4' class="ma-0 p-2">
                         <v-text-field
                            dense
                            type="date"
                            v-model="eventDraged.dateFin"
                            prepend-inner-icon="mdi-map-search-outline"
                            label="Date Fin"
                            outlined
                            :rules="[dateFinHigher]"
                          />
                     </v-col>
                        <!--end col new start and endDate-->
                     <v-col cols="12" sm="4" md="4" lg="4" xl='4' class="ma-0 p-2">
                         <v-text-field
                            dense
                            type="time"
                            v-model="eventDraged.timeStart"
                            placeholder="25 rue Baltimore 1234"
                            prepend-inner-icon="mdi-map-search-outline"
                            label="Time debut"
                            outlined
                            :rules="[timeStartLower]"
                          />
                     </v-col>
                     <v-col cols="12" sm="4" md="4" lg="4" xl='4' class="ma-0 p-2">
                         <v-text-field
                            dense
                            type="time"
                            v-model="eventDraged.timeEnd"
                            placeholder="25 rue Baltimore 1234"
                            prepend-inner-icon="mdi-map-search-outline"
                            label="Time Fin"
                            outlined
                            :rules="[timeEndHigher]"
                          />
                     </v-col>
                     <v-col cols="12" sm="12" md="12" lg="12" xl='12' class="ma-0 p-2">
                        <v-textarea
                          dense
                          v-model="eventDraged.description"
                          placeholder="description"
                          label="description"
                          outlined
                        />
                     </v-col>
                     <v-col cols="12" sm="12" md="12" lg="12" xl='12' class="ma-0 p-2">
                         <v-row justify="end">  
                            <div class="d-flex justify-end px-4 py-1">                      
                                <v-btn color="error" @click="close"  class="me-2"> Close </v-btn>           
                                <v-btn color="success" @click="updateDragedEvent"> Modifier </v-btn>     
                            </div>                 
                         </v-row>
                     </v-col>
                 </v-row>
             </v-form>
         </v-card>
     </v-dialog>
</template>

<script>
export default {
    name: "eventDragModal",
    props: {
        eventDraged: {
            type: Object,
            required: true,
        },
        isDraged: {
            type: Boolean,
            required: true,
        },
    },
    data() {
        return {
            editedEvent: this.eventDraged,
        };
    },
    async mounted() {
        this.editedEvent = this.eventDraged;
        console.log("eventDraged", this.editedEvent);
    },
    computed:{
       

    },
    methods: {
        close() {
            this.$emit("closeModalDragEvent", this.editedEvent);
        },
        updateDragedEvent(){
            if(this.$refs.form.validate()){
            this.editedEvent = this.eventDraged
            this.$emit("dragEditedEvent", this.eventDraged);
        }
        },
        dateDebutLower() {
        if (new Date(this.editedEvent.dateDebut) > new Date(this.editedEvent.dateFin)) {
            return "La date de début doit être inférieure ou égale à la date de fin";
        }  
        return true;
        },
        dateFinHigher() {
        if(this.editedEvent.dateFin !== null || this.editedEvent.dateFin !== undefined){      
        if (new Date(this.eventDraged.dateDebut) > new Date(this.eventDraged.dateFin)) {
            return "La date de début doit être inférieure ou égale à la date de fin";
        }
        return true;
        }
        },
        timeStartLower() {
        if(this.editedEvent.dateDebut == this.editedEvent.dateFin){

        if (this.eventDraged.timeStart >= this.eventDraged.timeEnd) {
            return "L'heure de début doit être inférieure";
        }
        return true;
        }
        },
        timeEndHigher() {
        if(this.editedEvent.dateDebut == this.editedEvent.dateFin){
        if ( this.eventDraged.timeEnd <= this.eventDraged.timeStart) {
            return "L'heure de fin doit être supérieure";
        }
        return true;
        }
        },
    },
    watch: {
        eventDraged: {
            handler: function (val) {
                this.editedEvent = val;               
            },
            deep: true,
        },
    },
}
</script>

<style scoped>
</style>