export default {
  setCookie(cname, cvalue, expiresTimeStamp) {
    let time = expiresTimeStamp * 1000;
    let now = new Date(time);
    let expiresDate = now.toLocaleDateString();
    let expires = "expires=" + now;
    document.cookie = cname + "=" + cvalue + ";" + expires + "SameSite=strict;path=/";
  },
  getCookieTimestamp(cookieName) {
    const cookies = document.cookie.split(";"); // Séparer les cookies en une liste
  
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim(); // Supprimer les espaces avant et après le cookie
  
      // Vérifier si le cookie correspond au nom recherché
      if (cookie.startsWith(`${cookieName}=`)) {
        const cookieParts = cookie.split(";"); // Séparer les parties du cookie
  
        for (let j = 0; j < cookieParts.length; j++) {
          const part = cookieParts[j].trim(); // Supprimer les espaces avant et après la partie du cookie
  
          // Vérifier si la partie correspond à "expires"
          if (part.startsWith("expires=")) {
            const expiration = part.substring("expires=".length); // Obtenir la valeur de l'expiration
            console.log(expiration)
            const expirationDate = new Date(expiration);
            return expirationDate.getTime(); // Retourner le timestamp de la date d'expiration
          }
        }
      }
    }
  
    return null; // Si le cookie n'est pas trouvé ou s'il n'a pas de date d'expiration
  },
  getCookie(name) {
    let cookieValue = null;
    if (document.cookie && document.cookie !== '') {
      const cookies = document.cookie.split(';');
      for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim();
        // Does this cookie string begin with the name we want?
        if (cookie.substring(0, name.length + 1) === (name + '=')) {
          cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
          break;
        }
      }
    }
    return cookieValue;
  },
  checkCookie() {
    let cookieToken = this.getCookie("token");
    if (cookieToken == null) {
      return false;
    } else {
      return true;
    }
  }
}