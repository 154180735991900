<template>
  <div class="pdf-wrapper-size">
    <!-- page de garde -->
    <section id="pageDeGarde" class="pdf-page">
      <PageDeGarde 
      :pageDeGardeTitle="pageDeGardeTitle"
      :vendeur="vendeur"
      :societe="societe"
      />
    </section>
  
    <!-- page 1 -->
    <section id="page-1" class="pdf-page">
      <NotreApproche />
    </section>
  
    <!-- page 2 -->
    <section id="page-2" class="pdf-page">
      <DetailProcessus />
    </section>
  
    <!-- page 3 -->
    <section id="page-3" class="pdf-page">
      <SyntheseTechniqueDetail 
      :puissanceKwc="puissanceKwc"
      :productionAttendueKwh="productionAttendueKwh"
      :nombrePanneaux="nombrePanneaux"
      />
    </section>
  
    <!-- page 4 -->
    <section id="page-4" class="pdf-page">
      <SyntheseTechniqueAddress 
      :address="address"
      :codePostale="codePostale"
      :imageSrc="imageSrc"
      />
    </section>
  
    <!-- page 5 -->
    <section id="page-5" class="pdf-page">
      <SyntheseConsoChart 
      :seriesConsoProd="seriesConsoProd"
      />
    </section>
  
    <!-- page 6 -->
    <section id="page-6" class="pdf-page">
      <SyntheseFinanciereChart 
      :seriesGains="seriesGains"
      :seriesAmortissement="seriesAmortissement"
      />
    </section>
  
    <!-- page 7 -->
    <section id="page-7" class="pdf-page">
      <CoutDeProjet 
      :coutTotalInstallation="coutTotalInstallation"
      :revenusExploitation="revenusExploitation"
      :chargesExploitation="chargesExploitation"
      :tempsDeRetour="tempsDeRetour"
      />
    </section>
  
    <!-- page 8 -->
    <section id="page-8" class="pdf-page">
      <TableSyntheseFinanciere 
      :budgetPhotovoltaique="budgetPhotovoltaique"
      :primeInvestissement="primeInvestissement"
      :gainAnnuelMoyen="gainAnnuelMoyen"
      :totalEconomie="totalEconomie"
      :totalSurplus="totalSurplus"
      :totalGain="totalGain"
      :tableauAmourtisement="tableauAmourtisement"
      />
    </section>
  
    <!-- page finale -->
    <section id="pageFinale" class="pdf-page">
      <PageFinale />
    </section>
  </div>
</template>

<script>
import PageDeGarde from "./components/PDFPages/PageDeGarde.vue";
import NotreApproche from "./components/PDFPages/NotreApproche.vue";
import DetailProcessus from "./components/PDFPages/DetailProcessus.vue";
import SyntheseTechniqueDetail from "./components/PDFPages/SyntheseTechniqueDetail.vue";
import SyntheseTechniqueAddress from "./components/PDFPages/SyntheseTechniqueAddress.vue";
import SyntheseConsoChart from "./components/PDFPages/SyntheseConsoChart.vue";
import SyntheseFinanciereChart from "./components/PDFPages/SyntheseFinanciereChart.vue";
import CoutDeProjet from "./components/PDFPages/CoutDeProjet.vue";
import TableSyntheseFinanciere from "./components/PDFPages/TableSyntheseFinanciere.vue";
import PageFinale from "./components/PDFPages/PageFinale.vue";

export default {
  name: "PresentationViewer",
  components: {
    PageDeGarde,
    NotreApproche,
    DetailProcessus,
    SyntheseTechniqueDetail,
    SyntheseTechniqueAddress,
    SyntheseConsoChart,
    SyntheseFinanciereChart,
    CoutDeProjet,
    TableSyntheseFinanciere,
    PageFinale
  },
  props: [
    "pageDeGardeTitle",
    "vendeur",
    "societe",
    "puissanceKwc",
    "productionAttendueKwh",
    "nombrePanneaux",
    "address",
    "codePostale",
    "coutTotalInstallation",
    "revenusExploitation",
    "chargesExploitation",
    "tempsDeRetour",
    "imageSrc",
    "budgetPhotovoltaique",
    "primeInvestissement",
    "gainAnnuelMoyen",
    "totalEconomie",
    "totalSurplus",
    "totalGain",
    "seriesConsoProd",
    "seriesGains",
    "seriesAmortissement",
    "tableauAmourtisement",
  ],
}
</script>

<style lang="scss" scoped>
.pdf-wrapper-size{
  width: 992px;
  height: 1403px;
}
.pdf-page {
  page-break-before: always;
  page-break-after: always;
  width: 100%;
  height: 100%;
  margin: 0;
  margin-bottom: 15px;
  background: #fff;
  position: relative;
  overflow: hidden;
}
</style>