<template>
  <v-card class="w-100 p-4">
    <!-- modal add borne -->
    <b-modal
      id="modal-add-bornes"
      title="Ajouter des bornes"
      cancel-title="Annuler"
      ok-title="Ajouter"
      ok-variant="info"
      header-bg-variant="info"
      cancel-variant="dark"
      @show="resetModal"
      @hidden="resetModal"
      @ok="handleOk"
    >
      <form ref="create-borne-by-type" class="p-2" @submit.stop.prevent="addBorneByType">
        <v-autocomplete
          v-model="borneToCreate"
          :items="listBornes"
          item-text="nom"
          return-object
          label="Type de Borne"
          dense
          outlined
        />
        <v-text-field
          type="number"
          v-model="nbrBorneToCreate"
          label="Nombre des bornes"
          outlined
          dense
          suffix="U"
          :rules="[rules.isNumber]"
        />
      </form>
    </b-modal>

    <!-- content -->
    <div class="imgs-wrapper">
      <div class="tgbt-img-wrapper">
        <span>TGBT</span>
        <img :src="require(`@/assets/images/tgbt.png`)" class="tgbt-img" />
      </div>

      <div class="line">
        <v-text-field
          v-if="!reverseSchemaBorne"
          v-model="distance_alimentation"
          label="Alimentation"
          class="input-tgbt-tdirve"
          outlined
          dense
          hide-details
          suffix="ML"
          :rules="[rules.required, rules.isNumberOrFloat]"
        />
      </div>
      <div class="d-flex flex-column justify-center">
        <v-btn
          :color="$colors[0]"
          class="mb-2 white--text"
          v-if="
            (listBorneSelected[0]?.TDIRVE == 'oui' ||
              listBorneSelected[0]?.nombrePointDeRecharge == 1) &&
            listBorneSelected.length == 1
          "
          @click="swapBorneTDIRVE()"
        >
          <v-icon>mdi-swap-vertical</v-icon>
        </v-btn>
        <div v-if="!reverseSchemaBorne" class="tdirve-img-wrapper">
          <img :src="require(`@/assets/images/tdirve.png`)" class="tdirve-img" />
        </div>
        <div
          v-if="
            reverseSchemaBorne &&
            (listBorneSelected[0].TDIRVE == 'oui' ||
              listBorneSelected[0]?.nombrePointDeRecharge == 1) &&
            listBorneSelected.length == 1
          "
          class="borne px-2"
          style="box-shadow: none; border: 4px solid #8aa7b4"
          v-for="(_, index) in listBorneSelected"
        >
          <div class="d-flex flex-row flex-nowrap align-items-start justify-center gap-2">
            <p class="Poppins-Bold">Borne n°{{ index + 1 }}</p>
            <!--<v-icon size="32" class="borne-btn-close" @click="() => removeBorne(index)"
              >mdi-close-circle</v-icon
            >-->
          </div>
          <v-autocomplete
            v-model="listBorneSelected[index]"
            :items="listBornes"
            item-text="nom"
            return-object
            label="Type de Borne"
            class="mb-4"
            dense
            hide-details
            outlined
            :rules="[rules.required]"
          />
          <v-text-field
            v-model="listBorneSelected[index]['distance']"
            label="Distance"
            class="mb-4"
            outlined
            dense
            hide-details="auto"
            suffix="ML"
            :rules="[rules.required, rules.isNumberOrFloat]"
          />
        </div>
      </div>
    </div>
    <div class="borne-wrapper">
      <div class="nbr-borne-wrapper">
        <div>
          <div class="d-flex justify-end">
            <b-button
              v-b-modal.modal-add-bornes
              class="borne-btn-add"
              title="Ajouter des bornes"
            >
              <v-icon class="borne-btn-add-icon">mdi-plus</v-icon>
            </b-button>
            <span class="borne-counter">{{ nbrBorne }}</span>
          </div>
        </div>
      </div>
      <div class="borne-list">
        <div
          style="position: relative"
          v-if="!reverseSchemaBorne"
          class="borne pa-2"
          v-for="(_, index) in listBorneSelected"
        >
          <v-icon
            style="position: absolute; top: 0px; right: 0px"
            size="32"
            class="borne-btn-close"
            @click="() => removeBorne(index)"
            >mdi-close-box</v-icon
          >
          <div
            class="d-flex flex-row flex-nowrap align-items-center justify-center gap-2 mb-4"
          >
            <p class="Poppins-Bold ma-0">Borne n°{{ index + 1 }}</p>
          </div>
          <div class="">
            <v-autocomplete
              v-model="listBorneSelected[index]"
              :items="listBornes"
              item-text="nom"
              return-object
              label="Type de Borne"
              class="mb-4"
              dense
              hide-details="auto"
              outlined
              :rules="[rules.required]"
            />
            <v-text-field
              v-model="listBorneSelected[index]['distance']"
              label="Distance"
              class="mb-4"
              outlined
              dense
              hide-details="auto"
              suffix="ML"
              :rules="[rules.required, rules.isNumberOrFloat]"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="questions-wrapper">
      <!-- question status compteur -->

      <div class="question-card">
        <v-checkbox
          label="repiquage terre client"
          v-model="lesQuestions.terreClient"
          value="oui"
        ></v-checkbox>
      </div>
      <div class="question-card">
        <!-- question title -->
        <h3 class="question-card-title">Statut compteur</h3>
        <v-radio-group v-model="nouveauPDL" column :disabled="true">
          <v-radio label="Non" value="non"></v-radio>
          <v-radio label="Changer l'abonnement" value="changerCompteur"></v-radio>
          <v-radio label="Changer le contrat" value="changerContract"></v-radio>
        </v-radio-group>
        <div v-if="messageWarning" class="alert alert-warning mt-2" role="alert">
          {{ messageWarning }}
        </div>
      </div>

      <div class="question-card">
        <!-- question title -->
        <h3 class="question-card-title">
          Protection du câble (hors tranchée) :
          <span style="color: red">*</span>
        </h3>
        <!-- question type "Option" -->
        <v-select
          v-model="lesQuestions.type_couverture_de_cable"
          :items="[
            'Tube IRL',
            'goulotte PVC blanche',
            'cablofil y compris accessoires finition EZ',
            'cablofil y compris accessoires finition GAC',
            'dalle marine y compris accessoires finition EZ',
            'dalle marine y compris accessoires finition GAC',
            'capots y compris accessoires de fixation finition EZ',
            'capots y compris accessoires de fixation finition GAC',
          ]"
          label="Select Item"
          style="max-width: 450px"
          dense
          outlined
          :rules="[rules.hasItemSelected]"
        >
        </v-select>
      </div>
      <div class="question-card">
        <!-- question title -->
        <h3 class="question-card-title">
          Est-ce que la solution de recharge doit être communicante ?
          <span style="color: red">*</span>
        </h3>
        <!-- question type "Radio" -->
        <v-radio-group v-model="lesQuestions.has_telecom" column>
          <v-radio label="oui" value="oui" />
          <v-radio label="non" value="non" />
        </v-radio-group>

        <div v-if="lesQuestions.has_telecom == 'oui'">
          <!-- question title -->
          <h3 class="question-card-title">
            Par ?
            <span style="color: red">*</span>
          </h3>
          <!-- question type "Radio" -->
          <v-radio-group v-model="lesQuestions.type_de_communication" column>
            <v-radio label="GSM" value="GSM" />
            <v-radio label="Filaire" value="filaire" />
          </v-radio-group>
        </div>
      </div>
      <div class="question-card">
        <!-- question title -->
        <h3 class="question-card-title">
          Est-ce que les bornes seront installées en pose murale ?
          <span style="color: red">*</span>
        </h3>
        <!-- question type "Radio" -->
        <v-radio-group
          v-model="lesQuestions.installation_de_borne_en_pose_murale"
          @change="
            () => {
              if (lesQuestions.installation_de_borne_en_pose_murale == 'non') {
                this.lesQuestions.il_y_a_tranchementBorne = 'oui';
              }
            }
          "
          column
        >
          <v-radio label="oui" value="oui" />
          <v-radio label="non" value="non" />
        </v-radio-group>
        <div>
          <!-- question title -->
          <h3 class="question-card-title">
            Y-a t'il un tranchement entre la borne et le TD-IRVE ?
            <span style="color: red">*</span>
          </h3>
          <!-- question type "Radio" -->
          <v-radio-group v-model="lesQuestions.il_y_a_tranchementBorne" column>
            <v-radio label="Oui" value="oui" />
            <v-radio label="Non" value="non" />
          </v-radio-group>
        </div>
      </div>
      <div class="question-card">
        <!-- question title -->
        <h3 class="question-card-title">
          Y-a t'il un tranchement entre le TGBT et le TD-IRVE?
          <span style="color: red">*</span>
        </h3>
        <!-- question type "Radio" -->
        <v-radio-group v-model="lesQuestions.il_y_a_tranchement" column>
          <v-radio label="Oui" value="oui" />
          <v-radio label="Non" value="non" />
        </v-radio-group>

        <div v-if="lesQuestions.il_y_a_tranchement == 'oui'">
          <!-- question title -->
          <h3 class="question-card-title">
            Quel type de support doit-on trancher pour desservir les bornes IRVE ?
            <span style="color: red">*</span>
          </h3>
          <!-- question type "Radio" -->
          <v-radio-group v-model="lesQuestions.type_de_tranchement" column>
            <v-radio label="Chaussée légère" value="chaussee_leger" />
            <v-radio label="Terre végétale" value="terre" />
            <v-radio label="chaussée lourde" value="chaussee_lourde" />
            <v-radio label="Trottoir" value="trottoir" />
          </v-radio-group>
          <!-- question title -->
          <h3 class="question-card-title">
            Quelle est la distance entre la sortie en façade du bâtiment ou se situe le
            TGBT et le TD-IRVE?
            <span style="color: red">*</span>
          </h3>
          <!-- question type "input" -->
          <v-text-field
            v-model="lesQuestions.distance_facade"
            outlined
            dense
            hide-details
            style="max-width: 150px"
            class="mt-4"
            suffix="ML"
            label="Distance"
            :rules="[rules.required, rules.isNumberOrFloat, accepteZero]"
          />
        </div>
      </div>

      <!--
        Question : Est-ce que l'installation IRVE doit être relié à la SSI du bâtiment ?
      -->
      <div class="question-card">
        <!-- question title -->
        <h3 class="question-card-title">
          Est-ce que l'installation IRVE doit être reliée à la SSI du bâtiment ?
          <span style="color: red">*</span>
        </h3>
        <!-- question type "Radio" -->
        <v-radio-group v-model="lesQuestions.ssi" column>
          <v-radio label="oui" value="oui" />
          <v-radio label="non" value="non" />
        </v-radio-group>

        <div v-if="lesQuestions.ssi == 'oui'">
          <!-- question title -->
          <h3 class="question-card-title">
            Est-ce que le cheminement de l'installation IRVE va passer dans une zone
            coupe-feu ?
            <span style="color: red">*</span>
          </h3>
          <!-- question type "Radio" -->
          <v-radio-group v-model="lesQuestions.zone_coupe_feu" column>
            <v-radio label="oui" value="oui" />
            <v-radio label="non" value="non" />
          </v-radio-group>
        </div>
      </div>
      <!--
        Question : Est-ce que le client souhaite un bouton d'arrêt d'urgence a un endroit différent du TD IRVE?
      -->
      <div class="question-card">
        <!-- question title -->
        <h3 class="question-card-title">
          Est-ce que le client souhaite un bouton d'arrêt d'urgence a un endroit différent
          du TD-IRVE?
          <span style="color: red">*</span>
        </h3>
        <!-- question type "Radio" -->
        <v-radio-group v-model="lesQuestions.button_arrete_urgent" column>
          <v-radio label="oui" value="oui" />
          <v-radio label="non" value="non" />
        </v-radio-group>

        <div v-if="lesQuestions.button_arrete_urgent == 'oui'">
          <!-- question title -->
          <h3 class="question-card-title">
            Entrer la distance :
            <span style="color: red">*</span>
          </h3>
          <!-- question type "input" -->
          <v-text-field
            v-model="lesQuestions.distance_button"
            outlined
            dense
            hide-details
            style="max-width: 150px"
            class="mt-4"
            suffix="ML"
            label="Distance"
            :rules="[rules.required, rules.isNumberOrFloat, rules.greaterThanZero]"
          />
        </div>
      </div>

      <!-- signialisation -->
      <div class="question-card">
        <!-- question title -->
        <h3 class="question-card-title">
          Séléctionner les signalisations <span style="color: red">*</span>
        </h3>
        <v-row cols="12">
          <v-col cols="12" md="4">
            <v-card class="w-100 h-100">
              <v-card-text>
                <p class="text-h6 text--primary">Verticale</p>
                <div>
                  <div
                    v-for="option in signialisationQuestion?.vertical?.options"
                    :key="option.value"
                  >
                    <v-checkbox
                      v-model="signialisationQuestion.vertical.selected"
                      hide-details
                      :label="option.name"
                      :value="option.value"
                    ></v-checkbox>
                  </div>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" md="4">
            <v-card class="w-100 h-100">
              <v-card-text>
                <p class="text-h6 text--primary">Horizontale</p>
                <div>
                  <div
                    v-for="option in signialisationQuestion?.horizontale?.options"
                    :key="option.value"
                  >
                    <v-checkbox
                      v-model="signialisationQuestion.horizontale.selected"
                      hide-details
                      :label="option.name"
                      :value="option.value"
                    ></v-checkbox>
                  </div>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" md="4">
            <v-card class="w-100 h-100">
              <v-card-text>
                <p class="text-h6 text--primary">Mécanique</p>
                <div>
                  <div
                    v-for="option in signialisationQuestion?.mecanique?.options"
                    :key="option.value"
                  >
                    <v-checkbox
                      v-model="signialisationQuestion.mecanique.selected"
                      hide-details
                      :label="option.name"
                      :value="option.value"
                    ></v-checkbox>
                  </div>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </div>
  </v-card>
</template>

<script>
import axios from "@/plugins/axios";

export default {
  name: "GraphDevis",
  props: {
    maxRechedValue: {
      type: Number,
      default: 0,
    },
    selectedCompteur: {},
  },
  data() {
    return {
      lesQuestions: {
        terreClient: [],
        ssi: "oui",
        zone_coupe_feu: "oui",
        type_couverture_de_cable: null,
        has_telecom: "non",
        distance_button: 0,
        type_de_communication: "filaire",
        type_de_tranchement: "chaussee_leger",
        distance_facade: 0,
        installation_de_borne_en_pose_murale: "non",
        button_arrete_urgent: "oui",
        installation_IRVE: "oui",
        il_y_a_tranchement: "oui",
        il_y_a_tranchementBorne: "oui",
      },
      reverseSchemaBorne: false,
      nouveauPDL: "non",
      messageWarning: "",

      nbrBorneToCreate: 0,
      borneToCreate: null,

      distance_alimentation: 0,
      listBornes: [],
      nbrBorne: 1,
      listBorneSelected: [{ id: null, distance: 0, TDIRVE: "" }],
      defaultQuestionsList: [],
      questionsList: [],
      signialisationQuestion: {},

      // rules
      rules: {
        isNumberOrFloat: (value) =>
          /^[0-9]+(\.\d+)?$/.test(value) ||
          "Ce champ n'accepte que les chiffres ou les nombres décimaux valides (ex. 50 ou 50.3)",
        isString: (value) =>
          !/\d/.test(value) || "Ce champ ne peut pas etre composé de chiffres",
        required: (value) => !!value || "Ce champ est obligatoire.",
        isNumber: (v) => /^[0-9]+$/.test(v) || "Ce champ n'accepte que les chiffres",
        isFloat: (v) =>
          /^-?\d*(\.\d+)?$/.test(v) ||
          "Ce champ doit être un nombre décimal valide(50.3)",
        hasItemSelected: (v) =>
          v.length >= 1 || "Vous devez selectionner aux moins une champ",
        greaterThanZero: (value) =>
          parseFloat(value) > 0 || "La valeur doit être supérieure à zéro.",
        accepteZero: (value) =>
          parseFloat(value) >= 0 || "La valeur doit être supérieure à zéro.",
      },
    };
  },
  watch: {
    reverseSchemaBorne: function (newValue) {
      console.log(newValue);
      this.$emit("TDIRVEBorne", newValue);
    },
    listBorneSelected: {
      deep: true,
      handler(newVal) {
        this.handleListBorneChange();
      },
    },
  },
  computed: {},
  async created() {
    // get list bornes
    await this.getBornes();
    await this.getQuestions();
  },
  methods: {
    swapBorneTDIRVE() {
      this.reverseSchemaBorne = !this.reverseSchemaBorne;
    },
    async getBornes() {
      await axios({
        url: process.env.VUE_APP_URL_API_CLIENT + "availableDevisItemsView/",
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
        data: JSON.stringify({
          token: this.$store.getters.getUserToken,
        }),
      }).then((res) => {
        this.listBornes = res.data.bornes;
      });
    },
    async getQuestions() {
      // await axios({
      //   url: process.env.VUE_APP_URL_API_CLIENT + "availableDevisItemsView/",
      //   method: "GET",
      //   headers: {
      //     "Content-Type": "application/json",
      //   },
      //   data: JSON.stringify({
      //     token: this.$store.getters.getUserToken,
      //   }),
      // }).then((res) => {
      //   this.listBornes = res.data.bornes;
      // }).catch((e)=>{
      //   console.log(e);
      // });

      let data = [
        {
          question:
            "Est-ce que l'installation IRVE doit être relié à la SSI du bâtiment ? ",
          type: "Radio",
          flag: "q-1",
          obligatoire: true,
          value: "oui",
          options: [
            {
              title: "Oui",
              value: "oui",
              childs: [
                {
                  question:
                    "Est-ce que le cheminement de l'installation IRVE va passer dans une zone coupe feu ?",
                  type: "Radio",
                  flag: "q-1-1",
                  obligatoire: true,
                  value: "oui",
                  options: [
                    {
                      title: "Oui",
                      value: "oui",
                      childs: [],
                    },
                    {
                      title: "Non",
                      value: "non",
                      childs: [],
                    },
                  ],
                },
              ],
            },
            {
              title: "Non",
              value: "non",
              childs: [],
            },
          ],
        },
        {
          question:
            "Est-ce que le client souhaite un bouton d'arrêt d'urgence a un endroit différent du TD IRVE?",
          type: "Radio",
          flag: "q-2",
          obligatoire: true,
          value: "oui",
          options: [
            {
              title: "Oui",
              value: "oui",
              childs: [
                {
                  question: "Entrer le distance",
                  type: "input",
                  flag: "q-2-1",
                  obligatoire: true,
                  value: "0",
                  options: [],
                },
              ],
            },
            {
              title: "Non",
              value: "non",
              childs: [],
            },
          ],
        },
        {
          question: "couverture de cable",
          type: "Option",
          flag: "q-3",
          obligatoire: true,
          value: [],
          options: [
            {
              title: "Tube IRL",
              value: "Tube IRL",
              childs: [],
            },
            {
              title: "goulotte PVC blanche",
              value: "goulotte PVC blanche",
              childs: [],
            },
            {
              title: "cablofil y compris accessoires finition EZ",
              value: "cablofil y compris accessoires finition EZ",
              childs: [],
            },
            {
              title: "cablofil y compris accessoires finition GAC",
              value: "cablofil y compris accessoires finition GAC",
              childs: [],
            },
            {
              title: "dalle marine y compris accessoires finition EZ",
              value: "dalle marine y compris accessoires finition EZ",
              childs: [],
            },
            {
              title: "dalle marine y compris accessoires finition GAC",
              value: "dalle marine y compris accessoires finition GAC",
              childs: [],
            },
            {
              title: "capots y compris accessoires de fixation finition EZ",
              value: "capots y compris accessoires de fixation finition EZ",
              childs: [],
            },
            {
              title: "capots y compris accessoires de fixation finition GAC",
              value: "capots y compris accessoires de fixation finition GAC",
              childs: [],
            },
          ],
        },
        {
          question: "Est ce que la solution de recharge doit être communicante ? ",
          type: "Radio",
          flag: "q-4",
          obligatoire: true,
          value: "oui",
          options: [
            {
              title: "Oui",
              value: "oui",
              childs: [
                {
                  question: "Par ?",
                  type: "Radio",
                  flag: "q-6-1",
                  obligatoire: true,
                  value: "",
                  options: [
                    {
                      title: "GSM",
                      value: "GSM",
                      childs: [],
                    },
                    {
                      title: "Filaire",
                      value: "filaire",
                      childs: [],
                    },
                  ],
                },
              ],
            },
            {
              title: "Non",
              value: "non",
              childs: [],
            },
          ],
        },
        {
          question: "y-a t'il un tranchement ?",
          type: "Radio",
          flag: "q-6",
          obligatoire: true,
          value: "oui",
          options: [
            {
              title: "Oui",
              value: "oui",
              childs: [
                {
                  question:
                    "Quelle type de support doit t on trancher pour desservir les bornes IRVE ?",
                  type: "Radio",
                  flag: "q-6-1",
                  obligatoire: true,
                  value: "chaussee_leger",
                  options: [
                    {
                      title: "Chaussée légère",
                      value: "chaussee_leger",
                      childs: [],
                    },
                    {
                      title: "Terre végétale",
                      value: "terre",
                      childs: [],
                    },
                    {
                      title: "chaussée lourde",
                      value: "chaussee_lourde",
                      childs: [],
                    },
                    {
                      title: "Trottoir",
                      value: "trottoir",
                      childs: [],
                    },
                  ],
                },
                {
                  question:
                    "Quelle est la distance entre la sortie en façade du bâtiment ou se situe le tgbt et le td irve?",
                  type: "input",
                  flag: "q-6-2",
                  obligatoire: true,
                  value: "0",
                  options: [],
                },
              ],
            },
            {
              title: "Non",
              value: "non",
              childs: [],
            },
          ],
        },
        {
          question: "Est ce que les bornes seront installés en pose murale ?",
          type: "Radio",
          flag: "q-7",
          obligatoire: true,
          value: "oui",
          options: [
            {
              title: "Oui",
              value: "oui",
              childs: [],
            },
            {
              title: "Non",
              value: "non",
              childs: [],
            },
          ],
        },
      ];
      let sign = {
        vertical: {
          selected: ["144"],
          options: [
            {
              name: "Signalétique spécifique VE (panneau voiture électrique mural)",
              value: "144",
            },
            {
              name:
                "Signalétique spécifique VE (panneau voiture électrique+ poteau + massif 250x250x250mm)",
              value: "145",
            },
          ],
        },
        horizontale: {
          selected: ["146"],
          options: [
            {
              name: "Marquage sol logo VE - bombe et pochoir",
              value: "146",
            },
            {
              name: "Marquage sol logo VE - thermocollé",
              value: "147",
            },
            {
              name:
                "Marquage sol place de parking entier avec peinture verte/bleu + logo VE",
              value: "148",
            },
          ],
        },
        mecanique: {
          selected: ["149"],
          options: [
            {
              name: "Arceaux de protection",
              value: "149",
            },
            {
              name: "Fourniture et pose d'un arrêt de roue au sol",
              value: "150",
            },
          ],
        },
      };
      this.signialisationQuestion = sign;
      this.defaultQuestionsList = JSON.parse(JSON.stringify(data)); // deep copy object the get rid of 2 way binding of vuejs
      this.questionsList = data;
    },

    handleListBorneChange() {
      if (
        this.listBorneSelected[0].nombrePointDeRecharge != 1 &&
        this.listBorneSelected[0].TDIRVE != "oui"
      ) {
        this.reverseSchemaBorne = false;
      }
      const quantiteBorne = this.listBorneSelected.filter((o) => o.id).length;
      let listSelectedBornesWithPuissance = [];
      this.listBorneSelected.forEach((borne) => {
        var x = this.listBornes.find((borne1) => borne1.id == borne.id);
        if (x) {
          listSelectedBornesWithPuissance.push(x);
        }
      });
      // Calculate the sum of 'puissance' values
      const sumPuissance = listSelectedBornesWithPuissance.reduce((total, item) => {
        // Remove commas and convert 'puissance' to a float number
        const puissanceValue = parseFloat(item.puissance.replace(",", "."));
        // Add the numeric 'puissance' value to the total
        return total + puissanceValue;
      }, 0);
      const total = sumPuissance + this.maxRechedValue;

      if (total > this.selectedCompteur.kva) {
        if (total > this.selectedCompteur.puissanceAtteinteMaximale) {
          // sweet alert with confirm and cancel button
          if (!(this.nouveauPDL == "changerCompteur")) {
            this.$swal({
              title:
                "La puissance de la borne dépasse la puissance maximale. Vous devez changer le compteur.",
              text: "Voulez vous changer le compteur ?",
              icon: "warning",
              showCancelButton: true,
              confirmButtonText: "Oui",
              cancelButtonText: "Non",
              dangerMode: true,
            }).then((willChange) => {
              if (willChange.isConfirmed) {
                this.nouveauPDL = "changerCompteur";
                this.messageWarning =
                  "La puissance de la borne dépasse la puissance maximale. Vous devez changer le compteur.";
              } else {
                this.nouveauPDL = "non";
                this.messageWarning = "";
              }
            });
          }
        } else {
          // warning toast
          this.nouveauPDL = "changerContract";
          this.messageWarning =
            "La puissance de la borne dépasse la limite maximale. Veuillez envisager une mise à jour de votre contrat.";
        }
      } else {
        this.nouveauPDL = "non";
        this.messageWarning = "";
      }
    },

    // reset default value when option unselected
    onParentOptionChange(questionIndex, selectedOptions) {
      this.questionsList[questionIndex].options.forEach((option, optionIndex) => {
        const isSelected = selectedOptions.includes(option.value);
        if (!isSelected && option.childs.length > 0) {
          // If the option is unselected and has child questions, reset them
          this.resetChildQuestions(questionIndex, optionIndex);
        }
      });
    },
    resetChildQuestions(parentQuestionIndex, parentOptionIndex) {
      const childQuestion = this.questionsList[parentQuestionIndex].options[
        parentOptionIndex
      ];
      childQuestion.childs.forEach((child, childIndex) => {
        const defaultValue = this.defaultQuestionsList[parentQuestionIndex].options[
          parentOptionIndex
        ].childs[childIndex].value;
        if (child.type === "input") {
          // Reset the child question value to default state
          this.$set(child, "value", defaultValue);
        } else if (child.type === "Radio") {
          // Reset the child question value to default state
          this.$set(child, "value", defaultValue);
        } else if (child.type === "Option") {
          // Reset the child question value to default state
          this.$set(child, "value", defaultValue);
        } else {
          // Reset other types of child questions as needed
          // For example, if child.type === 'input', you can reset the value like this:
          // this.$set(child, 'value', '');
        }
      });
    },

    // add list of borne by type
    addBorneByType() {
      // Validate if the required fields are filled
      if (!this.nbrBorneToCreate) {
        // Show an error message or handle validation as needed
        this.$toast.error("Veuillez remplir tous les champs.");
        return;
      }

      // Create an array to store new bornes with the same ID and distance set to 0
      this.borneToCreate.distance = 0;
      const newBornes = Array.from({ length: this.nbrBorneToCreate }, () => {
        return { ...this.borneToCreate };
      });

      // Add the new bornes to the listBorneSelected array
      this.listBorneSelected.push(...newBornes);

      // Increment the total number of bornes
      this.nbrBorne += newBornes.length;

      // Clear input fields after adding a new borne
      this.resetModal();

      // Hide the modal manually
      this.$nextTick(() => {
        this.$bvModal.hide("modal-add-bornes");
      });
      if (this.listBorneSelected.length > 1) {
        this.reverseSchemaBorne = false;
      }
    },
    resetModal() {
      this.borneToCreate = null;
      this.nbrBorneToCreate = 0;
    },
    handleOk(bvModalEvent) {
      // Prevent modal from closing
      bvModalEvent.preventDefault();
      // Trigger submit handler
      this.addBorneByType();
    },

    // remove borne from listBorneSelected
    removeBorne(index) {
      // Confirm if the user really wants to remove the item
      this.$swal({
        title: "Êtes-vous sûr de vouloir supprimer cette borne ?",
        text: "Cette action est irréversible.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Oui, supprimer",
        cancelButtonText: "Annuler",
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete.isConfirmed) {
          // Remove the item at the given index from listBorneSelected array
          this.listBorneSelected.splice(index, 1);
          this.nbrBorne = this.nbrBorne - 1;
        }
      });
    },
  },
};
</script>

<style scoped>
.imgs-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
}
.tgbt-img-wrapper {
  width: 100px;
  height: 100px;
}
.tgbt-img {
  width: 100%;
  height: 100%;
}
.tdirve-img-wrapper {
  width: 100px;
  height: 100px;
}
.tdirve-img {
  width: 100%;
  height: 100%;
}
.line {
  position: relative;
  width: 100%;
  height: 4px;
  background-color: #8aa7b4;
}
.input-tgbt-tdirve {
  position: absolute;
  top: -50px;
  left: 50%;
  transform: translateX(-50%);
  width: 50%;
  max-width: 200px;
  height: 56px;
}
/************ borne ************* */
.borne-wrapper {
  display: flex;
  flex-direction: column;
}
.nbr-borne-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.nbr-borne {
  max-width: 200px;
  height: 56px;
}
.borne-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
  min-height: 80px;
  padding: 15px;
  border-radius: 6px;
  background-color: rgb(179, 206, 206);
}
.borne {
  flex: 1;
  min-width: 200px;
  max-width: 350px;
  min-height: 100px;
  background-color: #fff;
  padding: 15px;
  border-radius: 6px;
  box-shadow: 0px 4px 16px 0px rgba(182, 182, 182, 0.75);
}
.borne-btn-close {
  color: rgb(221, 113, 113);
  cursor: pointer;
}

.borne-btn-add {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f1f1f1;
  padding: 8px 16px;
  border-radius: 4px;
  border: 1px solid #dae6e4;
  cursor: pointer;
  margin-bottom: 20px;
  margin-right: 15px;
}

.borne-btn-add-icon {
  font-size: 32px;
  color: #4f6772;
  padding: 0px;
}

.borne-counter {
  position: relative;
  font-size: 24px;
  font-weight: 700;
  font-family: sans-serif;
  color: #414242;
  padding: 8px 24px;
  border-radius: 4px;
  border: 1px solid #667f8b;
  background-color: rgb(255, 255, 255);
  margin-bottom: 20px;
}
.borne-counter::before {
  content: "";
  position: absolute;
  top: -20px;
  left: 50%;
  transform: translateX(-50%);
  height: 20px;
  width: 3px;
  background-color: #8aa7b4;
}
.borne-counter::after {
  content: "";
  position: absolute;
  bottom: -20px;
  left: 50%;
  transform: translateX(-50%);
  height: 20px;
  width: 3px;
  background-color: #8aa7b4;
}

/********** questions *************** */
.questions-wrapper {
  margin-top: 40px;
}
.question-card {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border: 1px solid #dadce0;
  box-shadow: inset 1px 1px 0 rgba(78, 78, 78, 0.1), inset 0 -1px 0 rgba(78, 78, 78, 0.07);
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
}

.question-card-title {
  font-size: 12pt;
  font-weight: 600;
  font-family: "docs-Roboto", Helvetica, Arial, sans-serif;
  letter-spacing: 0;
  margin-bottom: 20px;
}
</style>
