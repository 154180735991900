<template>
  <div>
    <v-icon class="ma-4" :color="$colors[0]" @click="back()" style="cursor: pointer"
      >mdi-arrow-left</v-icon
    >
    <div
      v-if="ListFolder.length == 0 && fileImageModel.length == 0"
      style="height: 100%; width: 100%"
      class="d-flex flex-row justify-center align-center"
    >
      <i
        :style="{ 'font-size': '40px', color: $colors[5] }"
        class="fas fa-folder-open"
      ></i>
      <span style="font-size: 30px" class="ml-2 align-self-end"> Dossier vide</span>
    </div>
    <div
      v-if="hideFolderProps == false || ListFolder.length > 0 || fileImageModel.length > 0"
    >
      <Photos v-if="fileImageModel.length > 0" :files="filesImages" />
      <v-row v-if="fileImageModel.length == 0" class="px-4 mb-8" justify="center">
        <v-col
          v-for="(value, index) in Object.entries(ListFolder)"
          :key="index"
          class="d-flex child-flex"
          cols="3"
        >
          <v-card
            @click="next(value)"
            elevation="0"
            height="100%"
            style="min-height: 125px; background-color: #ffffff"
            class="d-flex flex-column align-items-center"
          >
            <div
              style="height: 100%; width: 100%"
              class="rounded-lg d-flex flex-column align-items-end justify-center"
            >
              <div
                style="width: 100%"
                class="d-flex flex-column align-center justify-space-around"
              >
                <i
                  :style="{ 'font-size': '65px', color: $colors[5] }"
                  class="fas fa-folder"
                ></i>
                <div v-if="listCategorie.includes(value[0])">
                  <span
                    :style="{ color: $colors[1] }"
                    v-if="value[0] == 'visiteTechniqueFiles'"
                    >Visite Technique</span
                  >
                  <span
                    :style="{ color: $colors[1] }"
                    v-if="value[0] == 'visiteInstallationFiles'"
                    >Visite Installation</span
                  >
                  <span :style="{ color: $colors[1] }" v-if="value[0] == 'documentPhotos'"
                    >Document Photos</span
                  >
                </div>
                <span class="fontSizeMediaQuery" :style="{ color: $colors[1] }" v-else>{{
                  value[1].name
                }}</span>
              </div>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </div>
</template>
<script>
import Photos from "@/views/Photos.vue";
export default {
  name: "Dossier",
  props: ["ListFolder", "filesImages", "hideFolderProps"],
  components: { Photos },
  data() {
    return {
      fileImageModel: [],
      previousDossierOrder: "",
      dossierOrder: "",
      listCategorie: [
        "visiteTechniqueFiles",
        "visiteInstallationFiles",
        "documentPhotos",
      ],
    };
  },
  watch: {
    filesImages: function (newValue) {
      if (newValue.length > 0) {
        this.fileImageModel = [...newValue];
      }
    },
  },
  methods: {
    back() {
      let tempArray = this.dossierOrder.split("/");
      let newDossierOrder = "";
      if (tempArray.length == 1) {
        newDossierOrder = "";
      } else if (tempArray.length > 1) {
        newDossierOrder = `${tempArray[0]}`;
      }
      this.dossierOrder = newDossierOrder;
      this.fileImageModel = [];
      this.$emit("back", newDossierOrder);
    },
    next(object) {
      let objectTest = {};
      if (
        object[0] == "visiteTechniqueFiles" ||
        object[0] == "visiteInstallationFiles" ||
        object[0] == "documentPhotos"
      ) {
        this.dossierOrder = `${object[0]}`;
        objectTest = object[0];
      } else {
        if (!!object[1]?.name) {
          if (object[1]?.name == "visiteTechnique" || object[1]?.name == "cheminement") {
            objectTest = object[1];
          } else {
            objectTest = object[1]?.name;
          }
        }
        this.dossierOrder += `/${object[1]?.name}`;
      }
      this.$emit("next", objectTest);
    },
    
  },
};
</script>
<style scoped>
@media screen and (max-width: 1920px) {
  .fontSizeMediaQuery {
    font-size: 12px;
  }
}
</style>
