<template>
  <div id="vues">
    <v-card  color="white" class="rounded-lg" flat>
      <v-sheet
        height="700" class = "py-1 px-5">
    <insTimeLineView
      @selectedEvent="onEventSelected"
      @rechargeEvents="rechargeEvents"
      :events="events"
      :attributeName="'owner'"
      :period="'week'"
      :currentDay="currentDate"
      @toSelectedDay="moveToDayComponent"
      @miseAjourEffectuer="miseAjourEffectuer"
      @annulerLaModification="annulerLaModification"
    />

    </v-sheet>
  </v-card>
  </div>
</template>
<script>
    import dayjs from "dayjs";
    import insTimeLineView from "./vinsTimelineView.vue";
    import EventUpdateModal from "./modal/eventUpdateModal.vue";
    export default{
        name: "vinTimeLineComposent",
        components: {
            insTimeLineView,
            EventUpdateModal,
        },
        props: {
            events: {
                type: Array,
                required: true,
            },
            currentDate: null,
        },
         data(){
            return{
                isUpdate: false,
                selectedProject: null,
                updatedEvent:{
                    id: 1,
                    title: 'Nasri',
                    start: ' 2023-06-01 08:00:00 ',
                    end: ' 2023-06-01 09:00:00 ',
                },
                attributeName: "members",
                weekEvents: [],
             }
        },
        mounted() {
        },
        methods: {
            async rechargeEvents({dateDebut:dateDebut, dateFin:dateFin}){
                this.$emit('rechargeEvents', {dateDebut:dateDebut, dateFin:dateFin});
            },
            // Select event by adding the instance of the event to the selectedProject variable
            onEventSelected(event) {
                this.selectedProject = event;
                //fill the form with the selected event
                if (this.selectedProject) {
                    //this.isUpdate = true;
                    this.updatedEvent.id = this.selectedProject.id;
                    this.updatedEvent.title = this.selectedProject.title;
                    this.updatedEvent.start = this.selectedProject.start;
                    this.updatedEvent.end = this.selectedProject.end; 
                }
                // If the event is null, it means that the user clicked on the background
            },
            miseAjourEffectuer(event){
                this.$emit('miseAjourEffectuer', event);
            },
            annulerLaModification(){
                this.$emit('annulerLaModification');
            },
            moveToDayComponent(selectDay) {
                console.log("move to day")
                this.$emit("moveToDayCalendar", selectDay);

            },
            onModalClose() {
                this.isUpdate = false;
            },
            // update the event on the global array events
            onUpdateEventWeek(event) {
                this.events.forEach((element, index) => {
                    if (element.id == event.id) {
                        this.events[index].title = event.title;
                        this.events[index].start = event.start;
                        this.events[index].end = event.end
                    }
                });
                this.isUpdate = false;
            },
        },
    }

</script>

<style scoped>
    #vues {
        font-family: Avenir, Helvetica, Arial, sans-serif;
        text-align: center;
    }
</style>