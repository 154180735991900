
<template>
    <div>
        <v-btn 
        elevation="0" 
        class="generate-btn" 
        :style="'color:'+ buttonTextColor + ';background-color:'+ buttonBgColor +';'+ styleProps || ''" 
        @click="generatePresentation()" 
        :disabled="!devisData.resultatSelected?.length>0" 
        :loading="loadingGeneratingPres">
            {{buttonText}}
        </v-btn>
    </div>
</template>
<script>
import axios from "@/plugins/axios";
import PDFDevisBorneIRVEGenerator from './PDFDevisBorneIRVEGenerator.js';
export default {
    name: "devisPdfGenerator",
    props: ["buttonText", "buttonBgColor", "buttonTextColor","styleProps", "devisData"],
    components: {},
    data() {
        return {
            loadingGeneratingPres: false,
        }
    },
    methods: {
        // pdf generation
        async generatePresentation() {
            try {
                this.loadingGeneratingPres = true;
                // Initialize SharedMethods with component-specific data
                const PDFGenerator = new PDFDevisBorneIRVEGenerator(this.devisData);
                await PDFGenerator.generatePresentation();
            } catch (error) {
                console.log(error)
            } finally {
                this.loadingGeneratingPres = false;
            }
        },
    }
}

</script>
