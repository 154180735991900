<template>
  <v-card color="white" class="rounded-lg" flat>
 <v-dialog
         v-model="modalCreate"
         persistent
         max-width="80%" style="z-index: 1028;"
         >
         <v-card>
             <v-toolbar
                style="background-color: rgb(21, 191, 166);">
                <span class="text-h6 pl-0" style="color: whitesmoke;">Créer Visite</span>
             </v-toolbar>
             <v-form ref="formUpdateEvent" class="pa-4">
                 <v-row class="ma-0 p-2">
                     <v-col cols="12" sm="4" md="4" lg="4" xl='4' class="ma-0 p-2">
                         <v-text-field
                            dense
                            v-model="newEvent.title"
                            placeholder="25 rue Baltimore 1234"
                            prepend-inner-icon="mdi-map-search-outline"
                            label="Nom"
                            outlined
                          />
                     </v-col>
                     <v-col cols="12" sm="4" md="4" lg="4" xl='4' class="ma-0 p-2">
                         <v-text-field
                            dense
                            type="date"
                            v-model="newEvent.dateDebut"
                            placeholder="25 rue Baltimore 1234"
                            prepend-inner-icon="mdi-map-search-outline"
                            label="Date Debut"
                            outlined
                          />
                     </v-col>
                     <v-col cols="12" sm="4" md="4" lg="4" xl='4' class="ma-0 p-2">
                         <v-text-field
                            dense
                            type="date"
                            v-model="newEvent.dateFin"
                            placeholder="25 rue Baltimore 1234"
                            prepend-inner-icon="mdi-map-search-outline"
                            label="Date Fin"
                            outlined
                          />
                     </v-col>
                     <v-col cols="12" sm="4" md="4" lg="4" xl='4' class="ma-0 p-2">
                         <v-text-field
                            dense
                            type="time"
                            v-model="newEvent.timeStart"
                            placeholder="25 rue Baltimore 1234"
                            prepend-inner-icon="mdi-map-search-outline"
                            label="Time debut"
                            outlined
                          />
                     </v-col>
                     <v-col cols="12" sm="4" md="4" lg="4" xl='4' class="ma-0 p-2">
                         <v-text-field
                            dense
                            type="time"
                            v-model="newEvent.timeEnd"
                            placeholder="25 rue Baltimore 1234"
                            prepend-inner-icon="mdi-map-search-outline"
                            label="Time Fin"
                            outlined
                          />
                     </v-col>
                    <v-col cols="12" sm="4" md="4" lg="4" xl='4' class="ma-0 p-2">
                        <v-autocomplete
                          dense
                          label="technicien"
                          :items="techList"
                          v-model="newEvent.owner"
                          item-text="name"
                          outlined
                        >
                        </v-autocomplete>
                    </v-col>
                     <v-col cols="12" sm="12" md="12" lg="12" xl='12' class="ma-0 p-2">
                        <v-textarea
                          dense
                          v-model="newEvent.description"
                          placeholder="description"
                          label="description"
                          outlined
                        />
                     </v-col>
                     <v-col cols="12" sm="12" md="12" lg="12" xl='12' class="ma-0 p-2">
                         <v-row justify="end" > 
                            <div class="d-flex justify-end px-4 py-1">
                                <v-btn color="error" @click="modalCreate = false" class="me-2"> Close </v-btn>
                                <v-btn color='success' @click="CreateNewEvent"> Créer </v-btn>
                            </div>
                          </v-row>
                     </v-col>
                 </v-row>
             </v-form>
         </v-card>
  </v-dialog> 
<!-- 
################################################################################################
############################### | Update Visite | ##############################################
################################################################################################
      -->
     <v-dialog
         v-model="modalUpdateEventMonthly"
         persistent
         max-width="80%" style="z-index: 1028;"
         >
         <v-card>
             <v-toolbar
                  style="background-color: rgb(21, 191, 166);"
                  >
                 <span class="text-h6 pl-0" style="color: whitesmoke;">Informations Visite</span>
                    <v-spacer></v-spacer>
                  <v-btn  @click="navigateToProfilVisite(editedEvent.id)" style="border: 1px solid rgb(211, 211, 211);">
                    
                    <v-icon class="mr-2">mdi-hammer-screwdriver</v-icon>
                    <span >Visite Technique</span>
                  </v-btn>
                </v-toolbar>
             <v-form ref="form" class="pa-4" lazy-validation>
                 <v-row class="ma-0 p-2">
                     <v-col cols="12" sm="4" md="4" lg="4" xl='4' class="ma-0 p-2">
                         <v-text-field
                            dense
                            v-model="editedEvent.title"
                            placeholder="25 rue Baltimore 1234"
                            prepend-inner-icon="mdi-map-search-outline"
                            label="Nom"
                            outlined
                            :rules="[rules.isString,rules.required]"
                          />
                     </v-col>
                     <v-col cols="12" sm="4" md="4" lg="4" xl='4' class="ma-0 p-2">
                         <v-text-field
                            dense
                            type="date"
                            v-model="editedEvent.dateDebut"
                            placeholder="25 rue Baltimore 1234"
                            prepend-inner-icon="mdi-map-search-outline"
                            label="Date Debut"
                            outlined
                            :rules="[dateDebutLower]"
                          />
                     </v-col>
                     <v-col cols="12" sm="4" md="4" lg="4" xl='4' class="ma-0 p-2">
                         <v-text-field
                            dense
                            type="date"
                            v-model="editedEvent.dateFin"
                            placeholder="25 rue Baltimore 1234"
                            prepend-inner-icon="mdi-map-search-outline"
                            label="Date Fin"
                            outlined
                            :rules="[dateFinHigher]"
                          />
                     </v-col>
                     <v-col cols="12" sm="4" md="4" lg="4" xl='4' class="ma-0 p-2">
                         <v-text-field
                            dense
                            type="time"
                            v-model="editedEvent.timeStart"
                            placeholder="25 rue Baltimore 1234"
                            prepend-inner-icon="mdi-map-search-outline"
                            label="Time debut"
                            outlined
                            :rules="[timeStartLower]"
                          />
                     </v-col>
                     <v-col cols="12" sm="4" md="4" lg="4" xl='4' class="ma-0 p-2">
                         <v-text-field
                            dense
                            type="time"
                            v-model="editedEvent.timeEnd"
                            placeholder="25 rue Baltimore 1234"
                            prepend-inner-icon="mdi-map-search-outline"
                            label="Time Fin"
                            outlined
                            :rules="[timeEndHigher]"
                          />
                     </v-col>
                      <v-col cols="12" sm="4" md="4" lg="4" xl='4' class="ma-0 p-2">
                          <v-autocomplete
                              label="Choisir technicien"
                              :items="[{name:'Enlever l’affectation',id:null},...techList]"
                              :item-text="'name'"
                              v-model="affectedTo"
                              @change="changeTechIn"
                              outlined
                              dense
                              :rules="[rules.required]"
                          >
                          </v-autocomplete>
                          </v-col>
                     <v-col cols="12" sm="12" md="12" lg="12" xl='12' class="ma-0 p-2">
                        <v-textarea
                          dense
                          v-model="editedEvent.description"
                          placeholder="description"
                          label="description"
                          outlined
                          :rules="[rules.isString,rules.required]"
                        />
                     </v-col>
                     <v-col cols="12" sm="12" md="12" lg="12" xl='12' class="ma-0 p-2">
                         <v-row justify="end" > 
                            <div class="d-flex justify-end px-4 py-1">
                                <v-btn color="error" @click="modalUpdateEventMonthly = false" class="me-2"> Close </v-btn>
                                <v-btn color='success' @click="updateEventMonthly"> Modifier </v-btn>
                            </div>
                         </v-row>
                     </v-col>
                 </v-row>
             </v-form>
         </v-card>
     </v-dialog>
      <!-- end updateMonthlt Events -->
      <!-- drag event modal -->
      <eventDragModal :isDraged="isDraged" :eventDraged="dragedEvent" @dragEditedEvent="dragWellEditedEvent" @closeModalDragEvent="closeEtAnnuler" />

      <!-- the calendar -->
   
     <v-sheet height="700" class = "py-4 px-5">
    
      <FullCalendar
        ref="calendar"
        :options="calendarOptions" 
        :events="globalEvents"
      >
      <template v-slot:eventContent="arg">
        <i>
        <div class="fc-event-main-frame ">
          <div class="fc-event-title-container "
            :ref="'eventTitleContainer' + arg.event.id"
            @mouseover="showTooltip(`${arg.event.title} : ${arg.event.extendedProps['timeStart']} -> ${arg.event.extendedProps['timeEnd']}  `,arg.event.id)"
            @mouseleave="hideTooltip">
              <span style="font-size:11px !important; margin-left:3px"><i class="fa-regular fa-clock" style="margin-right: 0px ;"></i> {{ arg.event.extendedProps['timeStart'] }} -</span>
              <span style="font-size:11px !important;margin-right:7px !important;margin-left:3px !important"><i class="fa-regular fa-clock " style="margin-right: 3px ;"></i>{{ arg.event.extendedProps['timeEnd'] }}</span>
              <span  style="font-size:12px;font-weight:bold !important;margin-right:3px">{{ arg.event.title }}</span>
            
            </div>
        </div>
        </i>
        </template>
      </FullCalendar>
     </v-sheet>
 </v-card>
</template>
<script>
import { defineComponent } from 'vue'
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import { INITIAL_EVENTS, createEventId } from '@/utils/eventUtils.js'
import eventDragModal from './modal/eventDragModal.vue'
import dayjs from '@/utils/dayjsConfig.js'
import tippy from "tippy.js";
import "tippy.js/dist/tippy.css";
import 'tippy.js/themes/light-border.css';
import {hideAll} from 'tippy.js';

export default defineComponent({
  components: {
    FullCalendar,
    eventDragModal,

  },
  props:{
    globalEvents: {
      type: Array,
      default: () => []
    },
    techList: {},
    currentDate: null,
  },
  data() {
    return {
      affectedTo:{},
      timeError: false,
      type: "month",
      calendarApi: null,
      types: ["month", "week", "day", "4day"],
      mode: "stack",
      modes: ["stack", "column"],
      weekday: [0, 1, 2, 3, 4, 5, 6],
      weekdays: [
          { text: "Sun - Sat", value: [0, 1, 2, 3, 4, 5, 6] },
          { text: "Mon - Sun", value: [1, 2, 3, 4, 5, 6, 0] },
          { text: "Mon - Fri", value: [1, 2, 3, 4, 5] },
          { text: "Mon, Wed, Fri", value: [1, 3, 5] },
      ],
      value: "",
      colors: [
          "blue",
          "indigo",
          "deep-purple",
          "cyan",
          "green",
          "orange",
          "grey darken-1",
      ],
      names: [
          "Meeting",
          "Holiday",
          "PTO",
          "Travel",
          "Event",
          "Birthday",
          "Conference",
          "Party",
      ],
      dragEvent: null,
      dragStart: null,
      lastEvent: '',
      createEvent: null,
      createStart: null,
      extendOriginal: null,
      isMounted: false,
      modalCreate: false,
      modalUpdateEventMonthly: false,
      startUpdateMonthly: false,
      ExtendEvent: null,
      isDraged: false,
      editedEvent: {
          title:'', 
          dateDebut:'',
          dateFin:'',
          timeStart:'',
          timeEnd:'',
          index:'',
          start: "",
          end: "",
          description: "",
          owner: "",
          color: "",
      },
      dragedEvent: {
          id: "",
          title: "",
      },
      newEvent: {
          title:'', 
          dateDebut:'',
          dateFin:'',
          timeStart:'',
          timeEnd:'',
          start: "",
          end: "",
          description: "",
          owner: "",
          color:''

      },
      rules: {
          required: (value) => !!value || "Required.",
          isString: (value) => typeof value === "string" || "Required.",
      },
      event_list:[
            { title: 'event 1', date: '2023-05-01' },
            { title: 'event 2', date: '2023-05-02' }
        
      ],
      //calendar options
      calendarOptions: {
        plugins: [
          dayGridPlugin,
          timeGridPlugin,
          interactionPlugin,
          
        ],
        headerToolbar: {
          left: 'prev,today,next',
          center: '',
          right: 'title'
          
        },
        customButtons: {
          prev: {
            text: "Previous",
            click: this.handlePrevButtonClick,
          },
          next: {

            text: "Next",
            click: this.handleNextButtonClick,
          },
        },
        initialView: 'dayGridMonth',
        //initialEvents: this.globalEvents, // alternatively, use the `events` setting to fetch from a feed
        editable: true,
        selectable: true,
        selectMirror: true,
        dayMaxEvents: true,
        weekends: true,
        initialDate: dayjs(this.currentDate).format('YYYY-MM-DD'),
        //select: this.OpenCreateNewEvent,
        eventClick: this.clickedEventInfos,
        eventsSet: this.handleEvents,
        eventDrop: this.eventDraged,
        events: this.globalEvents,
        //select:this.OpenCreateNewEvent,
        height: 600,
        /* you can update a remote database when these fire:
        eventAdd:
        eventChange:
        eventRemove:
        */
      },
      currentEvents: [
        
      ],
    }
  }, 
  async created() {
  },
  computed:{
    dateDebutLower() {
      if (new Date(this.editedEvent.dateDebut) > new Date(this.editedEvent.dateFin)) {
        return "La date de début doit être inférieure ou égale à la date de fin";
      }
      return true;
    },
    dateFinHigher() {
      if (new Date(this.editedEvent.dateDebut) > new Date(this.editedEvent.dateFin)) {
        return "La date de début doit être inférieure ou égale à la date de fin";
      }
      return true;
    },
    timeStartLower() {
      if(this.editedEvent.dateDebut == this.editedEvent.dateFin){
      if ( this.editedEvent.timeStart >= this.editedEvent.timeEnd) {
        return "L'heure de début doit être inférieure";
      }
      return true;
    }
    },
    timeEndHigher() {
      if(this.editedEvent.dateDebut == this.editedEvent.dateFin){
      if ( this.editedEvent.timeEnd <= this.editedEvent.timeStart) {
        return "L'heure de fin doit être supérieure";
      }
      return true;
      }
    },

  },
  mounted() {
    //init calendar;
    this.calendarOptions['events'] = this.globalEvents;
    this.calendarApi = this.$refs.calendar.getApi()
    console.log("global",this.globalEvents)
    
  },
  watch: {
    globalEvents: {
      deep: true,
      handler(newList, oldList) {
      }
    }
  },
  methods: {
    formatTime(time) {
      return dayjs(time).format('HH:mm')
    },
    compareDates(startDate, endDate) {
    // Extract day, month, and year from the date strings
     
    },
    changeTechIn(){
      this.editedEvent.owner=this.affectedTo
    },
   
   
    // hover on event
    showTooltip(title,id) {
      const key=`eventTitleContainer${id}`
      console.log("tt",id)
      tippy(this.$refs[key], {
        content: title,
        theme: "light-border",
        placement: "top",
        offset: [0, 10],
        allowHTML: true,
       
        
      });
    },
    // hide tooltip
    hideTooltip() {
      hideAll()      
    },
    handlePrevButtonClick() {
      this.$refs.calendar.getApi().prev();
      this.$emit('rechargeEvents', {dateDebut:this.$refs.calendar.getApi().view.currentStart, dateFin:this.$refs.calendar.getApi().view.currentEnd})
    },
    handleNextButtonClick() {
      this.$refs.calendar.getApi().next();
      this.$emit('rechargeEvents', {dateDebut:this.$refs.calendar.getApi().view.currentStart, dateFin:this.$refs.calendar.getApi().view.currentEnd})
    },
    handleWeekendsToggle() {
      this.calendarOptions.weekends = !this.calendarOptions.weekends // update a property
    },
    handleDateSelect(selectInfo) {
      title = "prompt('Please enter a new title for your event')"
      let calendarApi = selectInfo.view.calendar
      calendarApi.unselect() // clear date selection
      if (title) {
        calendarApi.addEvent({
          id: createEventId(),
          title,
          start: selectInfo.startStr,
          end: selectInfo.endStr,
          allDay: selectInfo.allDay
        })
      }
    },
    createIdForEvent(){
      var maxId = this.globalEvents.reduce((max, event) => {
        return event.id > max ? event.id : max;
      }, 0);
      return maxId+1;
    },
    OpenCreateNewEvent(info){
      this.newEvent.title = ""
      this.newEvent.dateDebut = dayjs(info.start).format('YYYY-MM-DD')
      this.newEvent.dateFin = dayjs(info.end).format('YYYY-MM-DD')
      this.newEvent.start = dayjs(info.start).format('YYYY-MM-DD HH:mm')
      this.newEvent.end = dayjs(info.end).format('YYYY-MM-DD HH:mm')
      this.newEvent.timeStart = dayjs(info.start).format('HH:mm')
      this.newEvent.timeEnd = dayjs(info.end).format('HH:mm')
      this.modalCreate = true;
    },
    async CreateNewEvent(){
      var dateDebut = this.newEvent.dateDebut+" "+this.newEvent.timeStart
      var dateFin = this.newEvent.dateFin+" "+this.newEvent.timeEnd
      var event ={
          id: this.createIdForEvent(),
          title: this.newEvent.title,
          dateDebut: dayjs(new Date(dateDebut)).format('YYYY-MM-DD'),
          dateFin: dayjs(new Date(dateFin)).format('YYYY-MM-DD'),
          timeStart: this.newEvent.timeStart,
          timeEnd: this.newEvent.timeEnd,
          start: dayjs(new Date(dateDebut)).format('YYYY-MM-DD HH:mm'),
          end: dayjs(new Date(dateFin)).format('YYYY-MM-DD HH:mm'),
          description: this.newEvent.description,
          owner: this.newEvent.owner,
          color: this.getTechColor(this.newEvent.owner),

         
        };
        console.log(event)
      this.globalEvents.push(event);
      //refetchEvents after modifying the events global array
      //this.calendarApi.removeAllEvents();
      //this.calendarApi.addEventSource([event]);  
      
      await this.updateListCalendar() 
      this.modalCreate = false;
    },
    // get tech color
    getTechColor(tech){
      console.log(this.techList.filter(el=> { return el.name == tech}))
      return this.techList.filter(el=> { return el.name == tech})[0].color
    },
      // click event on v-dialog
    clickedEventInfos(event) {
      this.editedEvent.id = event.event.id
      this.editedEvent.title = event.event.title
      this.editedEvent.dateDebut= event.event.extendedProps.dateDebut
      this.editedEvent.dateFin= event.event.extendedProps.dateFin
      this.editedEvent.timeStart= event.event.extendedProps.timeStart
      this.editedEvent.timeEnd= event.event.extendedProps.timeEnd
      this.editedEvent.start= dayjs(new Date(this.editedEvent.dateDebut + " "+ this.editedEvent.timeStart)).format('YYYY-MM-DD HH:mm')
      this.editedEvent.end= dayjs(new Date(this.editedEvent.dateFin + " "+ this.editedEvent.timeEnd)).format('YYYY-MM-DD HH:mm')
      this.editedEvent.owner= event.event.extendedProps.owner
      this.editedEvent.description= event.event.extendedProps.description
      this.modalUpdateEventMonthly = true;
      this.affectedTo = this.techList.filter(el=> { return el.name == this.editedEvent.owner})[0];
      console.log(this.$refs.formUpdateEvent)

    },
    openUpdateEventMonthly(){
      this.modalUpdateEventMonthly = true;
    },
    updateEventMonthly(){
      if(this.$refs.form.validate()){
      this.editedEvent.id = this.editedEvent.id
      this.editedEvent.title = this.editedEvent.title
      this.editedEvent.dateDebut= this.editedEvent.dateDebut
      this.editedEvent.dateFin= this.editedEvent.dateFin
      this.editedEvent.timeStart= this.editedEvent.timeStart
      this.editedEvent.timeEnd= this.editedEvent.timeEnd
      this.editedEvent.start= dayjs(new Date(this.editedEvent.dateDebut + " "+ this.editedEvent.timeStart)).format('YYYY-MM-DD HH:mm')
      this.editedEvent.end= dayjs(new Date(this.editedEvent.dateFin + " "+ this.editedEvent.timeEnd)).format('YYYY-MM-DD HH:mm')
      this.editedEvent.owner= this.editedEvent.owner
      this.editedEvent.description= this.editedEvent.description
      var event ={
            id: this.editedEvent.id,
            title: this.editedEvent.title,
            dateDebut: this.editedEvent.dateDebut,
            dateFin: this.editedEvent.dateFin,
            timeStart: this.editedEvent.timeStart,
            timeEnd: this.editedEvent.timeEnd,
            start:  this.editedEvent.start,
            end:  this.editedEvent.end,
            owner:this.editedEvent.owner,
            description:this.editedEvent.description,
        };
      
      this.$emit('miseAjourEffectuer', event);
      this.modalUpdateEventMonthly = false;
      }
      //this.editEvent(event)
      //this.calendarOptions.events = this.globalEvents
      //this.calendarApi.refetchEvents()
      //this.calendarApi.addEventSource(this.globalEvents);   
    },
    eventDraged(dEvent) {
      if(dEvent.event.allDay == true){
        this.dragedEvent.start= dayjs(new Date(dEvent.event.start)).format('YYYY-MM-DD')
        this.dragedEvent.end= dayjs(new Date(dEvent.event.start)).format('YYYY-MM-DD')
      }else{
        this.dragedEvent.start= dayjs(new Date(dEvent.event.start)).format('YYYY-MM-DD')
        this.dragedEvent.end= dayjs(new Date(dEvent.event.end)).format('YYYY-MM-DD')
      }
      this.isDraged = true;
      this.dragedEvent.id = dEvent.event.id
      this.dragedEvent.title = dEvent.event.title
      this.dragedEvent.dateDebut= dEvent.event.extendedProps.dateDebut
      this.dragedEvent.dateFin= dEvent.event.extendedProps.dateFin
      this.dragedEvent.timeStart= dEvent.event.extendedProps.timeStart
      this.dragedEvent.timeEnd= dEvent.event.extendedProps.timeEnd
      this.dragedEvent.owner= dEvent.event.extendedProps.owner
      this.dragedEvent.description= dEvent.event.extendedProps.description

    },
    dragWellEditedEvent(editedEvent){
      var event ={
          id: editedEvent.id,
          title: editedEvent.title,
          dateDebut: dayjs(new Date(editedEvent.start)).format('YYYY-MM-DD'),
          dateFin: dayjs(new Date(editedEvent.end)).format('YYYY-MM-DD'),
          timeStart: editedEvent.timeStart,
          timeEnd: editedEvent.timeEnd,
          start:  editedEvent.start,
          end:  editedEvent.end,
          owner: editedEvent.owner,
          description: editedEvent.description,
      };
      this.$emit('miseAjourEffectuer', event);
      //this.editEvent(event)
      //this.calendarOptions.events = this.globalEvents
      this.isDraged = false;
    
    },
    async closeEtAnnuler(editedEvent){
      var event ={
          id: editedEvent.id,
          title: editedEvent.title,
          dateDebut: editedEvent.dateDebut,
          dateFin: editedEvent.dateFin,
          timeStart: editedEvent.timeStart,
          timeEnd: editedEvent.timeEnd,
          start:  dayjs(new Date(editedEvent.dateDebut+" "+editedEvent.timeStart)).format('YYYY-MM-DD HH:mm'),
          end:  dayjs(new Date(editedEvent.dateFin+" "+editedEvent.timeEnd)).format('YYYY-MM-DD HH:mm'),
          owner: editedEvent.owner,
          description: editedEvent.description,
      };
      this.$emit('annulerLaModification');
      /**await this.removeItem(event)
      await this.ajouterItemApresDelete(event)
      await this.updateListCalendar()*/
      this.isDraged = false;
    },
    async removeItem(newEvent){
      var index = this.globalEvents.findIndex(item => item.id == newEvent.id)
      if (index != -1){
        this.globalEvents.splice(index, 1)
      }
    },
    async ajouterItemApresDelete(newEvent){
      var v={
        id: newEvent.id,
        title:newEvent.title,
        dateDebut: newEvent.dateDebut,
        dateFin: newEvent.dateFin,
        timeStart: newEvent.timeStart,
        timeEnd: newEvent.timeEnd,
        start: dayjs(new Date(newEvent.dateDebut + " "+ newEvent.timeStart)).format('YYYY-MM-DD HH:mm'),
        end: dayjs(new Date(newEvent.dateFin + " "+ newEvent.timeEnd)).format('YYYY-MM-DD HH:mm'),
        owner: newEvent.owner,
        description: newEvent.description,
        }
      this.globalEvents.push(v)
    },
    async updateListCalendar(){
      this.calendarOptions.events = this.globalEvents
      //this.$refs.calendar.setOptions(this.calendarOptions)
      this.calendarApi.refetchEvents()
    },
    async editEvent(newEvent){
      var index = this.globalEvents.findIndex(item => item.id == newEvent.id)
      if (index != -1){
        this.globalEvents[index].title = newEvent.title
        this.globalEvents[index].dateDebut= newEvent.dateDebut
        this.globalEvents[index].dateFin= newEvent.dateFin
        this.globalEvents[index].timeStart= newEvent.timeStart
        this.globalEvents[index].timeEnd= newEvent.timeEnd
        this.globalEvents[index].start= dayjs(new Date(newEvent.dateDebut + " "+ newEvent.timeStart)).format('YYYY-MM-DD HH:mm')
        this.globalEvents[index].end= dayjs(new Date(newEvent.dateFin + " "+ newEvent.timeEnd)).format('YYYY-MM-DD HH:mm')
        this.globalEvents[index].owner= newEvent.owner
        this.globalEvents[index].description= newEvent.description
      }
    },
    navigateToProfilVisite(id) {
      this.$router.push({ path: `/visite-technique/profil/${id}` })
    },
    formatDateToTime(date){
      return dayjs(date).format("HH:mm");
    }
  }
})
</script>
<style lang='css'>


</style>
