<template>
  <div
    v-if="items.data.length > 0"
    style="background-color: white; padding: 60px; display: flex; flex-direction: column"
  >
    <div style="background-color: white">
      <div
        style="
          background-color: white;
          display: flex;
          flex-direction: column;
          justify-content: center;
        "
      >
        <div
          style="
            background-color: #14324f;
            color: white;
            font-weight: bold;
            margin-bottom: 4px;
            padding: 0.5em;
          "
        >
          {{ items.title }}
        </div>
        <table style="border-collapse: collapse; width: 100%; border: 2px solid black">
          <thead>
            <tr>
              <th
                v-for="h in headers"
                :class="h.class"
                style="
                  border: none;
                  text-align: left;
                  padding: 8px;
                  border-left: 2px solid black;
                  border-right: 2px solid black;
                "
              >
                <span
                  style="
                    color: #14324f;
                    font-weight: bold;
                    text-transform: uppercase;
                    font-size: 13px;
                  "
                  v-if="h.text == 'designation'"
                  >{{ h.text }}</span
                >
                <div
                  v-else
                  style="display: flex; flex-direction: row; justify-content: center"
                >
                  <span style="color: #14324f; font-size: 10px; font-weight: bold">{{
                    h.text.toUpperCase()
                  }}</span>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in items.data" :key="item.id">
              <td
                style="
                  border: none;
                  border-left: 2px solid black;
                  border-right: 2px solid black;
                  padding: 8px;
                "
              >
                <div style="display: flex; flex-direction: column">
                  <span v-if="item.borne" style="color: #14324f">{{
                    item.borne.nom
                  }}</span>
                  <span v-if="!item.borne && !item.materiel" style="color: #14324f">{{
                    item?.name
                  }}</span>
                  <span v-else style="color: #14324f">{{ item.materiel?.name }}</span>
                  <img
                    v-if="item.designation?.image"
                    class="mt-4"
                    style="max-height: 250px; max-width: 200px"
                    src="../../assets/images/tdirve.png"
                    alt="designation_image"
                  />
                </div>
              </td>
              <td
                style="
                  border: none;
                  border-left: 2px solid black;
                  border-right: 2px solid black;
                  padding: 8px;
                "
              >
                <div style="display: flex; flex-direction: row; justify-content: center">
                  <span style="color: #14324f" v-if="item.borne">u</span>
                  <span style="color: #14324f" v-if="!item.borne && !item.materiel"
                    >QTE</span
                  >
                  <span style="color: #14324f" v-else>{{ item.materiel?.unite }}</span>
                </div>
              </td>
              <!-- ... Repeat for the other columns -->
              <td
                style="
                  border: none;
                  border-left: 2px solid black;
                  border-right: 2px solid black;
                  padding: 8px;
                "
              >
                <div style="display: flex; flex-direction: row; justify-content: center">
                  <span v-if="!item.borne && !item.materiel" style="color: #14324f">
                    {{ Number(item.quantite).toLocaleString("fr-FR") }}
                  </span>
                  <span v-else style="color: #14324f">
                    {{ Number(item.quantity).toLocaleString("fr-FR") }}
                  </span>
                </div>
              </td>
              <td
                style="
                  border: none;
                  border-left: 2px solid black;
                  border-right: 2px solid black;
                  padding: 8px;
                "
              >
                <div style="display: flex; flex-direction: row; justify-content: center">
                  <span v-if="!item.materiel" style="color: #14324f">
                    {{ Number(item.prix).toLocaleString("fr-FR") }}
                  </span>
                  <span v-else style="color: #14324f">
                    {{ Number(item.prixUnitaire).toLocaleString("fr-FR") }}
                  </span>
                </div>
              </td>
              <td
                style="
                  border: none;
                  border-left: 2px solid black;
                  border-right: 2px solid black;
                  padding: 8px;
                "
              >
                <div style="display: flex; flex-direction: row; justify-content: center">
                  <span style="color: #14324f" v-if="item.borne">
                    {{ Number(item.quantity * item.prix).toLocaleString("fr-FR") }}€
                  </span>
                  <span
                    style="color: #14324f; padding-left: 15px; padding-right: 15px"
                    v-if="!item.borne && !item.prixUnitaire"
                  >
                    {{ Number(item.quantite * item.prix).toLocaleString("fr-FR") }}€
                  </span>
                  <span
                    v-if="item.materiel"
                    style="color: #14324f; padding-left: 5px; padding-right: 5px"
                  >
                    {{
                      Number(item.quantity * item.prixUnitaire).toLocaleString("fr-FR")
                    }}€
                  </span>
                </div>
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td
                colspan="4"
                style="
                  border: none;
                  border-left: 2px solid black;
                  border-right: 2px solid black;
                  padding: 8px;
                "
              >
                <div style="display: flex; flex-direction: row">
                  <div
                    style="
                      display: flex;
                      flex-direction: row;
                      justify-content: flex-end;
                      width: 100%;
                    "
                  >
                    <span style="color: #14324f; font-size: 15px; font-weight: bold">
                      <span>{{ items.title }}</span>
                    </span>
                  </div>
                </div>
              </td>
              <td style="height: 100%">
                <div
                  style="
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    border-left: 0;
                    width: 100%;
                    height: 100%;
                  "
                >
                  <span style="color: #14324f; font-weight: bold; height: 100%">
                    {{ Number(montantTotalFonction(items.data)).toLocaleString("fr-FR") }}
                    €
                  </span>
                </div>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["items"],
  data() {
    return {
      listLargueurDernierTH: [],
      listeLargueurTitre: [],
      isTrue: false,
      titre: "LOT 02 - Travaux VRD",
      headers: [
        {
          text: "designation",
          align: "start",
          sortable: false,
          value: "designation",
          class: "header-background",
          width: "60%",
          divider: true,
        },
        {
          text: "U",
          value: "unite",
          class: "header-background-1",
          width: "10%",
          divider: true,
        },
        {
          text: "Q",
          value: "quantity",
          class: "header-background-2",
          width: "10%",
          divider: true,
        },
        {
          text: "PU",
          value: "prixUnitaire",
          class: "header-background-3",
          width: "10%",
          divider: true,
        },
        {
          text: "MONTANT HT",
          value: "montant",
          class: "header-background-4",
          width: "10%",
        },
      ],
      desserts: [
        {
          designation: {
            title: "Maçonnerie",
            text:
              "Généralités L'entreprise titulaire de ce lot devra prendre toutes les dispositions utiles et nécessaires pour évacuer tous ses gravois et maintenir le chantier en parfait état de propreté",
          },
          unite: "ML",
          quantity: 6,
          prixUnitaire: 24,
          montant: 4.0,
        },
        {
          designation: {
            text: "Création d'une fouille et d'un massif béton coffré 500*500*500mm",
          },
          unite: "U",
          quantity: 9.0,
          prixUnitaire: 37,
          montant: 4.3,
        },
        {
          designation: {
            title: "BORNE",
            text:
              "Fourniture et pose de panneaux type B6a1+M6i commentaire 2 places M8f - CLASSE 1",
            image: "src/assets/images/tdirve.png",
          },
          unite: "ML",
          quantity: 16.0,
          prixUnitaire: 23,
          montant: 6.0,
        },
        {
          designation: {
            title: "ELEC",
            text:
              "Marquage au sol d'un logo véhicule électrique 1200mm x 600mm au centre de la place - Peinture routière blanche",
          },
          unite: "F",
          quantity: 3.7,
          prixUnitaire: 67,
          montant: 4.3,
        },
        {
          designation: {
            text: "Fourniture et pose d'un arceau de protection autour de la borne",
          },
          unite: "ML",
          quantity: 16.0,
          prixUnitaire: 49,
          montant: 3.9,
        },
      ],
    };
  },

  mounted() {
    if (document.getElementById("#tableauPDF")) {
      this.calculerLargeurDernierTH(document.querySelector(`#tableauPDF`));
      window.addEventListener(
        "resize",
        this.calculerLargeurDernierTH(document.querySelector(`#tableauPDF`))
      );
    }
  },
  methods: {
    montantTotalFonction(liste) {
      let total = 0;
      liste.forEach((element) => {
        if (element.borne) {
          total += Number(element.prix * element.quantity);
        } else if (!element.borne && !element.materiel) {
          total += Number(element.prix * element.quantite);
        } else {
          total += Number(element.prixUnitaire * element.quantity);
        }
      });
      return total;
    },
    calculerLargeurDernierTH(tableElement) {
      const dernierTH = tableElement.querySelector("th:last-child");
      const largeurDernierTH = dernierTH.offsetWidth;
      console.log("Largeur du dernier TH :", largeurDernierTH);

      const largeurTable = tableElement.offsetWidth;
      const largeurTitres = this.obtenirLargeursTHSaufDernier(tableElement);
      console.log(largeurTable);
      let largeurTitre = largeurTitres;
      console.log("Largeur du titre :", largeurTitre);
      this.listLargueurDernierTH.push(largeurDernierTH);
      console.log(this.listLargueurDernierTH);
      return largeurDernierTH;
    },

    obtenirLargeursTHSaufDernier(tableElement) {
      let largeurTitre = 0;
      const listeTH = tableElement.querySelectorAll("th:not(:last-child)");
      const largeursTH = Array.from(listeTH).map((th) => th.offsetWidth);
      largeursTH.forEach((element) => {
        largeurTitre += element;
      });
      this.listeLargueurTitre.push(largeurTitre);
      console.log(largeurTitre);
      return largeurTitre;
    },
  },
};
</script>
<style scoped>
.page {
  page-break-before: always;
  page-break-after: always;

  margin: 0;
}
::v-deep .v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
  font-size: 0.75 !important;
  height: 12px !important;
}
::v-deep tbody {
  border: 2px solid black !important;
}

.header-background-1,
.header-background-2,
.header-background-3,
.header-background-4 {
  width: 10% !important;
}

.header-background,
.header-background-1,
.header-background-2,
.header-background-3,
.header-background-4 {
  background-color: #c0c0c0;
  border: 2px black solid !important;
  border-bottom: 2px solid black;
  font-size: 10px;
  padding: 0px !important;
}
.header-width {
  min-width: 60%;
}
@font-face {
  font-family: "Poppins";
  src: local("Poppins"),
    url("../../assets/fonts/Poppins/Poppins-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins-Bold";
  src: local("Poppins-Bold"),
    url("../../assets/fonts/Poppins/Poppins-Bold.ttf") format("truetype");
}

.Poppins {
  font-family: "Poppins";
}

.Poppins-Bold {
  font-family: "Poppins-Bold";
}

::v-deep
  .theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > td:last-child,
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > th:last-child {
  border-bottom: thin solid rgb(255, 255, 255) !important;
}
::v-deep .theme--light.v-data-table .v-data-table__divider {
  border-bottom: thin solid rgb(255, 255, 255) !important;
  border-right: 2px solid black !important;
}
::v-deep .v-main__wrap {
  background-color: white;
}
</style>
