<template>
  <div class="p-4">
    <div class="shadow-sm">
      <breadcrumb :listNavs="listNavs" />
    </div>
    <v-card class="p-4">
      <DialogAffectation
        :dialogModel="ModalDeaffectationIng"
        typeAffectation="Ingénieur"
        labelListe="Liste Ingénieur"
        :listeItems="listeIngenieur"
        @change="changeIngenieur($event)"
        @closeDialog="ModalDeaffectationIng = false"
        @affecter="affectuerVisiteInstallationIng"
      />
      <DialogAffectation
        :dialogModel="ModalDeaffectation"
        typeAffectation="Technicien"
        labelListe="Liste Technicien"
        :listeItems="listeTech"
        @change="changeTechnician($event)"
        @closeDialog="ModalDeaffectation = false"
        @affecter="affectuerVisiteInstallation"
      />
      <!-- Start modal affectation visite d'installation -->

      <!-- END modal affectation visite d'installation -->

      <!-- START modal description -->
      <v-dialog v-model="dialogDescription" width="500">
        <v-card>
          <v-card-title class="pa-0">
            <v-toolbar :color="$colors[0]" dark>
              <v-icon size="30" class="mr-2">mdi-tooltip-text-outline</v-icon>
              <span class="Poppins pl-0">- Description</span>
            </v-toolbar>
          </v-card-title>

          <v-card-text class="mt-4 black--text">
            {{ dialogDescriptionText }}
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn outlined color="error" @click="closeDialogDescription"> Fermer </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- END modal description -->

      <!-- START modal Commentaire -->
      <v-dialog v-model="dialogCommentaire" width="500">
        <v-card>
          <v-card-title class="pa-0">
            <v-toolbar :color="$colors[0]" dark>
              <v-icon size="30" class="mr-2">mdi-clipboard-text</v-icon>
              <span class="Poppins pl-0">Commentaire</span>
            </v-toolbar>
          </v-card-title>

          <v-card-text class="mt-4 black--text">
            {{ dialogCommentaireText }}
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn outlined color="error" @click="closeDialogCommentaire"> Fermer </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- END modal Commentaire -->

      <loadingComponent
        v-if="loadingRequestVT"
        :dialogProps="dialogLoading"
        messageDialogeProps="erreur"
        :loadingProps="loadingRequestVT"
        :errorProps="false"
      />
      <!--<datatable
        id="table-validation-cotation"
        :columns="headersTable"
        :sortKey="sortKey"
        :sortOrders="sortOrders"
        @sort="sortBy"
        @search="rechercheData"
      >
        <template v-slot:header>
          <div style="width: 200px" class="float-end container-div mr-2">
            <select
              v-model="filterStatus"
              class="form-control"
              id="sel1"
              @change="filterByStatus"
            >
              <option value="tout">Toutes les demandes</option>
              <option value="1">acceptée</option>
              <option value="0">en attente</option>
              <option value="2">refusée</option>
            </select>
          </div>
        </template>-->

      <loadingComponent v-if="loadingRequestVT" :dialogProps="dialogLoading" messageDialogeProps="erreur"
        :loadingProps="loadingRequestVT" :errorProps="false" />
      <div style="width: 100%;overflow-x: auto;">
        <datatable id="table-validation-cotation" :columns="headersTable" :sortKey="sortKey" :sortOrders="sortOrders" @sort="sortBy" @search="rechercheData">
          <template v-slot:header >
          <div style="display: flex; justify-content: flex-end; align-items: flex-end;">
          <div style="display: flex; flex-direction: row;">
          <v-autocomplete
            label="Filtrer par technicien"
            :items="listeTech"
            :item-text="'name'"
            :item-value="'id'"
            v-model="tableData.techFilter"
            multiple 
            outlined
            dense
            hide-details
            @change="getData(pageNumber = 1)"
            class="mx-4"
        ></v-autocomplete>
        <v-autocomplete
            label="Filtrer par ingenieur"
            :items="listeIngenieur"
            :item-text="'name'"
            :item-value="'id'"
            outlined
            dense
            v-model="tableData.ingienieurFilter"
            multiple 
            hide-details
            @change="getData(pageNumber = 1)"
            class="mx-4"
        ></v-autocomplete>
          </div>
          </div>
        </template>
          <template v-slot:body>
            <tbody
              class="text-center"
              v-if="itemsTable.length > 0 && loadingRequestVT == false"
            >
              <tr v-for="(item, index) in itemsTable" :key="item.id">
                <td>
                  <router-link :to="`/visite-installation/profil/${item.id}`">
                    {{ item.title }}
                  </router-link>
                </td>
                <td>{{ item?.created_by }}</td>
                <td>{{ item.created_at }}</td>
                <td>{{ item.dateDebut }}</td>
                <td>{{ item.dateFin }}</td>
                <td>{{ item.timeStart }}</td>
                <td>{{ item.timeEnd }}</td>
  
                <td v-if="item.responsable">
                  <div class="d-flex flex-column align-items-center btn-group">
                    <div class="">
                      <span class="text-black">{{ item.responsable.nom }}</span>
                      <div
                        class=""
                        v-if="
                          $store.getters.hasPermission('VI', 'affecter_ing') ||
                          $store.getters.hasPermission('VI', 'envelve_ing')
                        "
                      >
                        <v-icon
                          class="dropdown-toggle"
                          data-toggle="collapse"
                          :data-target="'#collapseIng' + index"
                          aria-expanded="false"
                          :aria-controls="'collapseIng' + index"
                          size="20"
                          left
                        >
                          mdi-menu-down
                        </v-icon>
                      </div>
                    </div>
                    <div
                      class="collapse"
                      :id="'collapseIng' + index"
                      style="border: 0px"
                      v-if="
                        $store.getters.hasPermission('VI', 'affecter_ing') ||
                        $store.getters.hasPermission('VI', 'envelve_ing')
                      "
                    >
                      <div
                        class="d-flex flex-row align-items-center justify-content-center mt-4 mt-lg-2"
                      >
                        <!-- start les buttons annuler et affecter  -->
                        <v-btn
                          @click="openModalDeaffectationIng(item.id)"
                          class="dropdown-project"
                          v-if="$store.getters.hasPermission('VI', 'affecter_ing')"
                          icon
                          small
                          plain
                        >
                          <v-icon color="blue accent-5"> mdi-account-multiple-plus </v-icon>
                        </v-btn>
                        <!-- START ANNULER LA VISITE-->
                        <v-btn
                          @click="annulerVisiteInstallationIng(item.id)"
                          class="dropdown-project"
                          v-if="$store.getters.hasPermission('VI', 'envelve_ing')"
                          icon
                          small
                          plain
                        >
                          <v-icon color="red accent-2"> mdi-account-cancel-outline </v-icon>
                        </v-btn>
  
                        <!-- end les buttons annuler et affecter -->
                      </div>
                    </div>
                  </div>
                </td>
                <td v-else>
                  <div class="d-flex flex-column align-items-center btn-group">
                    <div class="">
                      <span style="font-size: smaller; color: red">non affecté(e)</span>
                      <div
                        class=""
                        v-if="
                          $store.getters.hasPermission('VI', 'affecter_ing') ||
                          $store.getters.hasPermission('VI', 'envelve_ing')
                        "
                      >
                        <v-icon
                          class="dropdown-toggle"
                          data-toggle="collapse"
                          :data-target="'#collapseIng' + index"
                          aria-expanded="false"
                          :aria-controls="'collapseIng' + index"
                          size="20"
                          left
                        >
                          mdi-menu-down
                        </v-icon>
                      </div>
                    </div>
                    <div
                      class="collapse"
                      :id="'collapseIng' + index"
                      style="border: 0px"
                      v-if="
                        $store.getters.hasPermission('VI', 'affecter_ing') ||
                        $store.getters.hasPermission('VI', 'envelve_ing')
                      "
                    >
                      <div class="d-flex flex-row mt-4 mt-lg-2">
                        <!-- start les buttons annuler et affecter  -->
                        <v-btn
                          @click="openModalDeaffectationIng(item.id)"
                          class="dropdown-project"
                          v-if="$store.getters.hasPermission('VI', 'affecter_ing')"
                          icon
                          small
                          plain
                        >
                          <v-icon color="blue accent-5"> mdi-account-multiple-plus </v-icon>
                        </v-btn>
                      </div>
                    </div>
                  </div>
                </td>
  
                <td v-if="item.technician">
                  <div class="d-flex flex-column align-items-center btn-group">
                    <div class="">
                      <span class="text-black">{{ item.technician.nom }}</span>
                      <div
                        v-if="
                          $store.getters.hasPermission('VI', 'affecter') ||
                          $store.getters.hasPermission('VI', 'envelve')
                        "
                      >
                        <v-icon
                          class="dropdown-toggle"
                          data-toggle="collapse"
                          :data-target="'#collapseTech' + index"
                          aria-expanded="false"
                          :aria-controls="'collapseTech' + index"
                          size="20"
                          left
                        >
                          mdi-menu-down
                        </v-icon>
                      </div>
                    </div>
                    <div
                      class="collapse"
                      :id="'collapseTech' + index"
                      style="border: 0px"
                      v-if="
                        $store.getters.hasPermission('VI', 'affecter') ||
                        $store.getters.hasPermission('VI', 'envelve')
                      "
                    >
                      <div
                        class="d-flex flex-row align-items-center justify-content-center mt-4 mt-lg-2"
                      >
                        <!-- start les buttons annuler et affecter  -->
                        <v-btn
                          @click="openModalDeaffectation(item.id)"
                          class="dropdown-project"
                          v-if="$store.getters.hasPermission('VI', 'affecter')"
                          icon
                          small
                          plain
                        >
                          <v-icon color="blue accent-5"> mdi-account-multiple-plus </v-icon>
                        </v-btn>
                        <!-- START ANNULER LA VISITE-->
                        <v-btn
                          @click="annulerVisiteInstallation(item.id)"
                          class="dropdown-project"
                          v-if="$store.getters.hasPermission('VI', 'envelve')"
                          icon
                          small
                          plain
                        >
                          <v-icon color="red accent-2"> mdi-account-cancel-outline </v-icon>
                        </v-btn>
  
                        <!-- end les buttons annuler et affecter -->
                      </div>
                    </div>
                  </div>
                </td>
                <td v-else>
                  <div class="d-flex flex-column align-items-center btn-group">
                    <div class="">
                      <span style="font-size: smaller; color: red">non affecté(e)</span>
                      <div
                        class=""
                        v-if="
                          $store.getters.hasPermission('VI', 'affecter') ||
                          $store.getters.hasPermission('VI', 'envelve')
                        "
                      >
                        <v-icon
                          class="dropdown-toggle text-center"
                          data-toggle="collapse"
                          :data-target="'#collapseTech' + index"
                          aria-expanded="false"
                          :aria-controls="'collapseTech' + index"
                          size="20"
                          left
                        >
                          mdi-menu-down
                        </v-icon>
                      </div>
                    </div>
                    <div
                      class="collapse"
                      :id="'collapseTech' + index"
                      style="border: 0px"
                      v-if="
                        $store.getters.hasPermission('VI', 'affecter') ||
                        $store.getters.hasPermission('VI', 'envelve')
                      "
                    >
                      <div class="d-flex flex-row mt-4 mt-lg-2">
                        <!-- start les buttons annuler et affecter  -->
                        <v-btn
                          @click="openModalDeaffectation(item.id)"
                          class="dropdown-project"
                          v-if="$store.getters.hasPermission('VI', 'affecter')"
                          icon
                          small
                          plain
                        >
                          <v-icon color="blue accent-5"> mdi-account-multiple-plus </v-icon>
                        </v-btn>
                      </div>
                    </div>
                  </div>
                </td>
                <td>
                  <v-btn @click="openDialogDescription(item.description)" icon>
                    <v-icon :color="$colors[0]">mdi-tooltip-text-outline</v-icon>
                  </v-btn>
                </td>
                <!-- <td>
                  <v-btn @click="openDialogCommentaire(item.commentaire)" icon>
                    <v-icon :color="$colors[0]">mdi-text-box-search</v-icon>
                  </v-btn>
                </td> -->
                <td>
                  <div class="d-flex flex-column align-items-center btn-group">
                    <div class="d-flex flex-row justify-content-center">
                      <small class="small-status" v-if="item.etat == 'refusee'"
                        ><v-icon dense style="color: #ff0000">mdi-close-circle</v-icon
                        ><b class="ml-2">Refusée</b></small
                      >
                      <small class="small-status" v-if="item.etat == 'annuler'"
                        ><v-icon dense style="color: #ff0000">mdi-cancel</v-icon
                        ><b class="ml-2">annulée</b></small
                      >
                      <small class="small-status" v-if="item.etat == 'en_attend'"
                        ><v-icon dense style="color: #2080ee">mdi-clock-outline</v-icon
                        ><b class="ml-2">en attente</b></small
                      >
                      <small class="small-status" v-if="item.etat == 'en_cours'"
                        ><v-icon dense style="color: orange">mdi-progress-clock</v-icon
                        ><b class="ml-2">en cours</b></small
                      >
                      <small class="small-status" v-if="item.etat == 'terminer'"
                        ><v-icon dense style="color: #00e676"
                          >mdi-checkbox-marked-circle-auto-outline</v-icon
                        ><b class="ml-2">terminée</b></small
                      >
                      <small class="small-status" v-if="item.etat == 'pre_valider'"
                        ><v-icon dense style="color: #9c27b0">mdi-progress-check</v-icon
                        ><b class="ml-2">pré validée</b></small
                      >
                      <small class="small-status" v-if="item.etat == 'valider'"
                        ><v-icon dense style="color: #00e676"
                          >mdi-check-circle-outline</v-icon
                        ><b class="ml-2" style="word-wrap: normal">validée</b></small
                      >
                      <div class="d-flex flex-row" v-if="showActionsBtns(item.etat)">
                        <v-icon
                          class="dropdown-toggle"
                          data-toggle="collapse"
                          :data-target="'#collapseStatut' + index"
                          aria-expanded="false"
                          :aria-controls="'collapseStatut' + index"
                          size="20"
                          left
                        >
                          mdi-menu-down
                        </v-icon>
                      </div>
                    </div>
                    <div
                      class="collapse"
                      :id="'collapseStatut' + index"
                      style="border: 0px"
                      v-if="showActionsBtns(item.etat)"
                    >
                      <div class="d-flex flex-row justify-center align-center mt-4 mt-lg-2">
                        <!-- Refuser -->
                        <v-btn
                          class="dropdown-project"
                          title="Refuser"
                          icon
                          small
                          plain
                          @click="
                            ActionsBtns(
                              item.id,
                              'refusee',
                              `Vous êtes sur le point de refuser ${item.title}!`
                            )
                          "
                          v-if="
                            $store.getters.hasPermissionByStatus('VI', item.etat, 'refusee')
                          "
                          color="#FF0000"
                        >
                          <v-icon color="#FF0000"> mdi-close-circle </v-icon>
                        </v-btn>
                        <!-- Annuler -->
                        <v-btn
                          class="dropdown-project"
                          title="Annuler"
                          icon
                          small
                          plain
                          @click="
                            ActionsBtns(
                              item.id,
                              'annuler',
                              `Vous êtes sur le point d'annuler ${item.title}!`
                            )
                          "
                          v-if="
                            $store.getters.hasPermissionByStatus('VI', item.etat, 'annuler')
                          "
                          color="#E91E63"
                        >
                          <v-icon color="#E91E63"> mdi-cancel </v-icon>
                        </v-btn>
                        <!-- Reinitialiser -->
                        <v-btn
                          class="dropdown-project"
                          title="Reinitialiser"
                          icon
                          small
                          plain
                          @click="
                            ActionsBtns(
                              item.id,
                              'en_attend',
                              `Vous êtes sur le point de reinitialiser ${item.title}!`
                            )
                          "
                          v-if="
                            $store.getters.hasPermissionByStatus(
                              'VI',
                              item.etat,
                              'reinitialiser'
                            )
                          "
                          color="#1976D2"
                        >
                          <v-icon color="#1976D2"> mdi-rotate-left </v-icon>
                        </v-btn>
                        <!-- pre valider -->
                        <v-btn
                          class="dropdown-project"
                          title="Pré Valider"
                          icon
                          small
                          plain
                          @click="
                            ActionsBtns(
                              item.id,
                              'pre_valider',
                              `Vous êtes sur le point de pré valider ${item.title}!`
                            )
                          "
                          v-if="
                            $store.getters.hasPermissionByStatus(
                              'VI',
                              item.etat,
                              'pre_valider'
                            )
                          "
                        >
                          <v-icon color="#9C27B0"> mdi-eye-check </v-icon>
                        </v-btn>
                        <!-- valider -->
                        <v-btn
                          class="dropdown-project"
                          title="Valider"
                          icon
                          small
                          plain
                          @click="
                            ActionsBtns(
                              item.id,
                              'valider',
                              `Vous êtes sur le point de valider ${item.title}!`
                            )
                          "
                          v-if="
                            $store.getters.hasPermissionByStatus('VI', item.etat, 'valider')
                          "
                        >
                          <v-icon color="#00E676"> mdi-check-circle </v-icon>
                        </v-btn>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
            <noDataTable
              class="ma-4"
              v-if="itemsTable.length == 0 && loadingRequestVT == false"
            />
            <progressCircular class="ma-8" v-if="loadingRequestVT == true" />
          </template>
        </datatable>
      </div>
      <div
        class="d-flex flex-row justify-content-center justify-sm-start align-items-center"
      >
        <pagination
          v-if="itemsTable.length > 0"
          :pagination="pagination"
          @selectNumberOfRecords="rechercheData"
          @first="getData(pagination.firstPage)"
          @last="getData(pagination.lastPage)"
          @prev="getData(pagination.prevPageUrl)"
          @current="getData(pagination.currentPage)"
          @next="getData(pagination.nextPageUrl)"
        >
        </pagination>
      </div>
    </v-card>
  </div>
</template>
<script>
import DialogAffectation from "@/components/DialogAffectation.vue";
import noDataTable from "@/components/dataTable/no-data-table.vue";
import progressCircular from "@/components/dataTable/progress-circular.vue";
import pagination from "@/components/dataTable/Pagination.vue";
import datatable from "@/components/dataTable/Datatable.vue";
import loadingComponent from "@/components/Loading.vue";
import axios from "@/plugins/axios";
import breadcrumb from "@/components/breadcrumb.vue";

export default {
  name: "listeVisiteInstallation",
  components: {
    DialogAffectation,
    loadingComponent,
    datatable,
    noDataTable,
    progressCircular,
    pagination,
    breadcrumb,
  },
  data() {
    let sortOrders = {};
    let columns = [
        { width: "12vw", label: "Visite Installation", name: "name", sortable: true },
        { width: "12vw", label:'createur de visite', name:'createur', sortable: true },
        { width: "12vw", label:"date de creation", name:"dateCreation", sortable: true},
        { width: "12vw", label: "Date de debut", name: "dateDebut", sortable: true },
        { width: "12vw", label: "Date de fin", name: "dateFin", sortable: true },
        { width: "12vw", label: "Heure de debut", name: "HeureDebut", sortable: false },
        { width: "12vw", label: "Heure de fin", name: "heureFin", sortable: false },
        { width: "12vw", label: "Ingenieur", name: "ingenieur", sortable: true },
        { width: "12vw", label: "Technicien", name: "technician", sortable: true},
        { width: "12vw", label: "Description", name: "description", sortable: false },
        // { width: "12vw", label: "Commentaire", name: "commentaire", sortable: false },
        { width: "15vw", label: "Statut", name: "etat", sortable: true },
      ];
    columns.forEach((column)=>{
        sortOrders[column.name] = -1;
    });
    return {
      loadingRequestVT: false,
      listNavs: [
        {
          name: "Visite d'installation",
          link: "#",
        },
        {
          name: "Liste visite d'installation",
          link: "#",
        },
      ],

      ModalDeaffectation: false,
      ModalDeaffectationIng: false,
      affectedTo: null,
      affectedToIng: null,
      listeTech: [],
      listeIngenieur: [],
      technician: null,
      ingenieur: null,
      id_vi: "",
      dialogLoading: false,
      dialogDescription: false,
      dialogCommentaire: false,
      dialogDescriptionText: "",
      dialogCommentaireText: "",
      columns: columns,
      headersTable: columns,
      itemsTable: [],
      search: "",
      filterStatus: "tout",
      sortKey: "date",
      sortOrders: sortOrders,
      perPage: ["15", "20", "30"],
      tableData: {
        statusFilter: "",
        draw: 0,
        length: 15,
        search: "",
        column: 0,
        dir: 'desc',
        techFilter: [],
        ingienieurFilter: [],

      },
      pagination: {
        perPage: ["10", "15", "30"],
        firstPage: 1,
        lastPage: "",
        currentPage: "",
        total: "",
        lastPageUrl: "",
        nextPageUrl: "",
        prevPageUrl: "",
        from: "",
        to: "",
      },
    };
  },
  async created() {
    await this.getData();
    await this.getAllTechnicien();
    await this.getAllIngenieur();
  },
  computed: {
    roleUser() {
      return this.$store.getters.getUserRole;
    },
    showActionsBtns() {
      return (status) => {
        return (
          this.$store.getters.hasPermissionByStatus("VI", status, "valider") ||
          this.$store.getters.hasPermissionByStatus("VI", status, "pre_valider") ||
          this.$store.getters.hasPermissionByStatus("VI", status, "annuler") ||
          this.$store.getters.hasPermissionByStatus("VI", status, "refusee") ||
          this.$store.getters.hasPermissionByStatus("VI", status, "reinitialiser")
        );
      };
    },
  },
  methods: {
    openDialogDescription(text) {
      this.dialogDescription = true;
      this.dialogDescriptionText = text;
    },
    closeDialogDescription() {
      this.dialogDescription = false;
      this.dialogDescriptionText = "";
    },
    openDialogCommentaire(text) {
      this.dialogCommentaire = true;
      this.dialogCommentaireText = text;
    },
    closeDialogCommentaire() {
      this.dialogCommentaire = false;
      this.dialogCommentaireText = "";
    },
    openModalDeaffectation(id) {
      this.ModalDeaffectation = true;
      this.id_vi = id;
    },
    openModalDeaffectationIng(id) {
      this.ModalDeaffectationIng = true;
      this.id_vi = id;
    },
    async rechercheData({ type, key }) {
      console.log(type);
      console.log(key);

      if (type == "search") {
        var pageNumber = 1;
        this.tableData.search = key;
      } else if (type == "nbrRecords") {
        this.tableData.length = key;
      }
      this.tableData.draw++;
      await fetch(process.env.VUE_APP_URL_API_CLIENT + "getVisiteInstallation/", {
        method: "POST",
        body: JSON.stringify({
          dataTable: this.tableData,
          pageNumber: pageNumber,
          token: this.$store.getters.getUserToken,
        }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      })
        .then((response) => response.json())
        .then((res) => {
          console.log(res);

          if (res.result == true) {
            let respObj = res.data;
            console.log(respObj);
            if (this.tableData.draw == respObj.draw) {
              this.itemsTable = respObj.data.data;
              this.configPagination(respObj.data);
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // get all technicien
    async getAllTechnicien() {
      await fetch(process.env.VUE_APP_URL_API_CLIENT + "getAllTechnicien/", {
        method: "POST",
        body: JSON.stringify({
          token: this.$store.getters.getUserToken,
        }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.error === false && data.result.length > 0) {
            for (let i = 0; i < data.result.length; i++) {
              this.listeTech.push({
                name: data.result[i].nom + " " + data.result[i].prenom,
                id: data.result[i].id,
              });
            }
          }
        });
    },
    // get all ingenieur
    async getAllIngenieur() {
      await fetch(process.env.VUE_APP_URL_API_CLIENT + "getAllIngenieur/", {
        method: "POST",
        body: JSON.stringify({
          token: this.$store.getters.getUserToken,
        }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.error === false && data.result.length > 0) {
            for (let i = 0; i < data.result.length; i++) {
              this.listeIngenieur.push({
                name: data.result[i].nom + " " + data.result[i].prenom,
                id: data.result[i].id,
              });
            }
          }
        });
    },
    // technicien
    async affectuerVisiteInstallation() {
      var formData = new FormData();
      formData.append("token", this.$cookies.get("token"));
      formData.append("id_vi", this.id_vi);
      formData.append("id_tech", this.technician);
      await axios({
        url:
          process.env.VUE_APP_URL_API_CLIENT +
          "effectuerUneVisiteInstallationAuTechForTable",
        method: "POST",
        data: formData,
      })
        .then((res) => {
          if (res.data.status == true) {
            this.ModalDeaffectation = false;
            this.getData();
          } else {
          }
        })
        .catch((err) => {});
      this.technician = null;
    },
    async annulerVisiteInstallation(vi_item) {
      console.log(vi_item);
      var formData = new FormData();
      formData.append("token", this.$cookies.get("token"));
      formData.append("id_vi", vi_item);
      await axios({
        url:
          process.env.VUE_APP_URL_API_CLIENT +
          "annulerUneVisiteInstallationAuTechForTable",
        method: "POST",
        data: formData,
      })
        .then((res) => {
          if (res.data.status == true) {
            this.getData();
          } else {
          }
        })
        .catch((err) => {});
    },
    // ingenieur
    async affectuerVisiteInstallationIng() {
      var formData = new FormData();
      formData.append("token", this.$cookies.get("token"));
      formData.append("id_vi", this.id_vi);
      formData.append("id_ing", this.ingenieur);
      await axios({
        url:
          process.env.VUE_APP_URL_API_CLIENT +
          "effectuerUneVisiteInstallationAuIngForTable",
        method: "POST",
        data: formData,
      })
        .then((res) => {
          if (res.data.status == true) {
            this.ModalDeaffectationIng = false;
            this.getData();
          } else {
          }
        })
        .catch((err) => {});
      this.ingenieur = null;
    },
    async annulerVisiteInstallationIng(vi_item) {
      var formData = new FormData();
      formData.append("token", this.$cookies.get("token"));
      formData.append("id_vi", vi_item);
      await axios({
        url:
          process.env.VUE_APP_URL_API_CLIENT +
          "annulerUneVisiteInstallationAuIngForTable",
        method: "POST",
        data: formData,
      })
        .then((res) => {
          if (res.data.status == true) {
            this.getData();
          } else {
          }
        })
        .catch((err) => {});
    },
    changeTechnician(event) {
      this.technician = event;
      console.log(this.affectedTo);
    },
    changeIngenieur(event) {
      this.ingenieur = event;
      console.log(this.affectedToIng);
    },
    async getData(pageNumber = 1) {
      this.tableData.draw++;
      this.loadingRequestVT = true;
      this.dialogLoading = true;
      console.log(this.tableData);
      await fetch(process.env.VUE_APP_URL_API_CLIENT + "getVisiteInstallation/", {
        method: "POST",
        body: JSON.stringify({
          dataTable: this.tableData,
          pageNumber: pageNumber,
          token: this.$store.getters.getUserToken,
        }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      })
        .then((response) => response.json())
        .then((res) => {
          console.log(res);

          if (res.result == true) {
            let respObj = res.data;
            console.log(respObj);
            if (this.tableData.draw == respObj.draw) {
              this.itemsTable = respObj.data.data;
              this.configPagination(respObj.data);
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
      this.loadingRequestVT = false;
      this.dialogLoading = false;
    },
    async ActionsBtns(id, status, actionMessage) {
      this.$swal({
        title: "Êtes-vous sûr?",
        text: actionMessage,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#007991",
        cancelButtonColor: "#d33",
        cancelButtonText: "Annuler",
        confirmButtonText: "Je Confirme",
      }).then((result) => {
        if (result.isConfirmed) {
          this.loadingRequestVT = true;
          this.dialogLoading = true;
          var formData = new FormData();
          formData.append("id_vi", id);
          formData.append("status", status);
          formData.append("token", this.$store.getters.getUserToken);
          axios({
            url: process.env.VUE_APP_URL_API_CLIENT + "changeVisiteInstallationStatus/",
            method: "POST",
            data: formData,
          })
            .then((res) => {
              if (res.status == 200) {
                this.getData(this.pagination.currentPage);
              }
            })
            .catch((err) => {
              console.log(err);
            });
          this.loadingRequestVT = false;
          this.dialogLoading = false;
        }
      });
    },
    spliteList(list) {
      return list.split(",");
    },
    spliteListSlice(list) {
      return list.split(",").slice(1);
    },
    configPagination(data) {
      this.pagination.lastPage = data.last_page;
      this.pagination.currentPage = data.current_page;
      this.pagination.total = data.total;
      this.pagination.lastPageUrl = data.last_page_url;
      this.pagination.nextPageUrl = data.next_page_url;
      this.pagination.prevPageUrl = data.prev_page_url;
      this.pagination.from = data.from;
      this.pagination.to = data.to;
    },
    sortBy(key) {
      this.sortKey = key;
      this.sortOrders[key] = this.sortOrders[key] * -1;
      this.tableData.column = this.getIndex(this.columns, "name", key);
      this.tableData.dir = this.sortOrders[key] === 1 ? "asc" : "desc";
      this.getData();
    },
    getIndex(array, key, value) {
      return array?.findIndex((i) => i[key] == value);
    },
  },
};
</script>
<style scoped>
::v-deep .v-expansion-panel-content__wrap {
  padding: 0px;
}

.v-expansion-panel {
  background-color: unset !important;
}

.v-expansion-panel::before {
  box-shadow: none;
}
.small-status {
  width: max-content;
}
</style>
