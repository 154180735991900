<template>
  <v-col
    :style="{ borderRight: `2px solid ${$colors[0]}` }"
    justify="center"
    align="center"
  >
    <h6
      :style="{ backgroundColor: $colors[0] }"
      class="mb-4 pa-2 Poppins-Bold white--text"
    >
      {{ title }}
    </h6>
    <v-row style="width: 100%">
      <div
        v-for="item in listExpansion"
        v-if="
          !!listupdatePrix[item] ||
          !!listupdatePrix[`prixAchat${item}`] ||
          title == 'Informations Générales'
        "
        :style="{
          width: '100%',
        }"
        class="ma-2 py-4 d-flex flex-column align-items-center"
      >
        <h6 style="color: #103a5e; font-size: larger" class="Poppins-Bold mb-0">
          {{ item }}
        </h6>

        <span v-if="item == 'Fournisseur'" class="black--text mb-0">{{
          listupdatePrix.typeFournisseur
        }}</span>
        <span v-else-if="item == 'TURPE INCLUS'" class="black--text mb-0">{{
          listupdatePrix.typeTurpe
        }}</span>
        <span v-else-if="item == 'Durée de contrat'" class="black--text mb-0"
          >{{ listupdatePrix.DUREE }} mois</span
        >
        <span
          v-else-if="
            title == 'Prix d\'achat' && listupdatePrix[`prixAchat${item}`] !== null
          "
          class="black--text mb-0"
        >
          {{ Number(listupdatePrix[`prixAchat${item}`]).toLocaleString("fr-FR") }}
          €/mois</span
        >
        <span
          v-else-if="title == 'CAPA' && listupdatePrix[`prixAchatCapa${item}`] !== null"
          class="black--text mb-0"
        >
          {{ Number(listupdatePrix[`prixAchatCapa${item}`]).toLocaleString("fr-FR") }}
          €/mois</span
        >
        <span v-else-if="title != 'Prix d\'achat'" class="black--text mb-0"
          >{{ Number(listupdatePrix[item]).toLocaleString("fr-FR") }} €/mois</span
        >
      </div>
    </v-row>
  </v-col>
</template>
<script>
export default {
  name: "expansionColumn",
  props: ["listExpansion", "listupdatePrix", "title"],
};
</script>
