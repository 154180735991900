<template>
  <v-card
    elevation='0'
    :style="{
      border:borderStyle
    }"
    class="ma-2 pa-4 d-flex flex-column align-center justify-space-between"
    width="100%"
    height="100%"
  >
    <div class="d-flex flex-row justify-center align-center mb-2">
      <span style="font-size: larger">{{title}}</span>
      <v-icon class="ml-2" size="20" color="black">{{icon}}</v-icon>
    </div>

    <span v-if="hasSlot != true" class="Poppins-Bold">{{content}}</span>
    <div v-if="hasSlot">
      <slot name="content"></slot>
    </div>
  </v-card>
</template>
<script>
export default {
  name: "CardProfil",
  props:['title','icon','content',"borderStyle","hasSlot"],
  
};
</script>
