<template>
  <div class="d-flex flex-column justify-center" style="height: 100%">
    <v-card class="d-flex flex-row justify-center align-center" elevation="0">
      <v-icon size="100">mdi-database-off</v-icon>
      <span class="text-lg-h6 text-center my-4"
        >Aucune donnée disponible pour le moment</span
      >
    </v-card>
  </div>
</template>
<script>
export default {
  name: "no-data-table", // ATTENTION DEUX NO DATA TABLE DISPONIBLE VERIFIER
};
</script>
