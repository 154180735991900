<template>
  <div class="d-flex flex-row justify-space-between pa-4" style="width: 100%">
    <div class="d-flex align-items-center">
      <p class="ma-0">éléments affichés</p>
      <select
        class="form-control selected"
        v-model="length"
        @change="$emit('selectNumberOfRecords', { type: 'nbrRecords', key: length })"
      >
        <option
          v-for="(records, index) in pagination.perPage"
          :key="index"
          :value="records"
        >
          {{ records }}
        </option>
      </select>
      <li class="page-stats">
        <span
          >{{ pagination.from }} - {{ pagination.to }} sur {{ pagination.total }}</span
        >
      </li>
    </div>
    <nav aria-label="Page navigation example">
      <ul class="pagination">
        <!-- got to first page button -->
        <li class="page-item">
          <a
            v-if="pagination.prevPageUrl && pagination.prevPageUrl >= 0"
            class="page-link"
            @click="$emit('first')"
          >
            &lt;&lt;</a
          >
          <a class="page-link" v-else :disabled="true"> &lt;&lt;</a>
        </li>

        <!-- got to prev page button -->
        <li class="page-item">
          <a
            v-if="pagination.prevPageUrl && pagination.prevPageUrl >= 0"
            class="page-link"
            @click="$emit('prev')"
          >
            &lt;</a
          >
          <a class="page-link" v-else :disabled="true"> &lt;</a>
        </li>

        <!-- got to prev page button with number -->
        <li
          class="page-item"
          v-if="pagination.prevPageUrl && pagination.prevPageUrl >= 0"
        >
          <a v-if="pagination.prevPageUrl >= 0" class="page-link" @click="$emit('prev')">
            {{ pagination.prevPageUrl }}</a
          >
          <a class="page-link" v-else :disabled="true"> {{ pagination.prevPageUrl }}</a>
        </li>

        <!-- current page button with number -->
        <li class="page-item active">
          <span class="page-link">
            {{ pagination.currentPage }}
          </span>
        </li>

        <!-- got to next page button with number -->
        <li
          class="page-item"
          v-if="pagination.nextPageUrl && pagination.nextPageUrl <= pagination.lastPage"
        >
          <a
            v-if="pagination.nextPageUrl <= pagination.lastPage"
            class="page-link"
            @click="$emit('next')"
          >
            {{ pagination.nextPageUrl }}
          </a>
          <a class="page-link" v-else :disabled="true">
            {{ pagination.nextPageUrl }}
          </a>
        </li>

        <!-- go to next page button -->
        <li class="page-item">
          <a
            v-if="pagination.nextPageUrl && pagination.nextPageUrl <= pagination.lastPage"
            class="page-link"
            @click="$emit('next')"
          >
            &gt;
          </a>
          <a class="page-link" v-else :disabled="true"> &gt; </a>
        </li>

        <!-- go to last page button -->
        <li class="page-item">
          <a
            v-if="pagination.nextPageUrl && pagination.nextPageUrl <= pagination.lastPage"
            class="page-link"
            @click="$emit('last')"
          >
            &gt;&gt;
          </a>
          <a class="page-link" v-else :disabled="true"> &gt;&gt; </a>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
export default {
    name: "PaginationFiles",
  //props: ['pagination'],
  props: {
    pagination: {
      type: Object,
      required: true,
      default: {
        perPage: ["10", "15", "30"],
        firstPage: 1,
        lastPage: 1,
        currentPage: 1,
        total: 0,
        lastPageUrl: 1,
        nextPageUrl: 1,
        prevPageUrl: 1,
        from: 1,
        to: 1,
      },
      validator: (propValue) => {
        let isTypeRight = false;
        for (const [key, value] of Object.entries(propValue)) {
          if (typeof value === "object" || typeof value === "number") {
            isTypeRight = true;
          }
        }
        return isTypeRight;
      },
    },
  },
  data() {
    return {
      perPage: this.pagination.perPage,
      length: this.pagination.length,
    };
  },
};
</script>
<style scoped>
.pagination {
  justify-center: center;
  margin: 0px;
}

.page-stats {
  list-style-type: none;
  margin-left: 24px;
}

.container {
  display: flex;
  align-items: center;
  margin-left: 16px;
  padding: 0;
}

.selected {
  width: 50px;
  margin-left: 5px;
  margin-right: 5px;
}

@media only screen and (max-width: 1280px) {
  .container {
    flex-direction: column;
    margin: 0px !important;
  }
}
</style>
<style scoped>
@media only screen and (max-width: 1280px) {
  .page-stats {
    margin-left: 0px;
  }
}
</style>
<style scoped>
@media only screen and (max-width: 1280px) {
  .pagination {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}
</style>
