<style scoped>
.td-valeur {
  align-items: center;
  flex-direction: column;
}

.td-statut {
  align-items: center;
  flex-direction: row;
}
.td-table,
.td-valeur,
.td-statut {
  display: flex;
  justify-content: center;
}
.d1 {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: flex-end;
}
</style>
<template>
  <div>
    <v-dialog v-model="ouvrireDialogeRefuser" max-width="700px">
      <v-card style="overflow: hidden">
        <v-card-title style="display: block" class="text-h5 text-center font-weight-light"
          >Êtes-vous sûr de bien vouloir refuser cette demande ?</v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-row>
            <v-col class="d-flex justify-content-center" cols="12" sm="12">
              <v-btn color="blue darken-1" text @click="fermerDialogeRefuserDemande"
                >Annuler</v-btn
              >
              <v-btn color="blue darken-1" text @click="refuserDemande">Confirmer</v-btn>
            </v-col>
          </v-row>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="ouvrireDialogeAccepter" max-width="700px">
      <v-card style="overflow: hidden">
        <v-card-title style="display: block" class="text-h5 text-center font-weight-light"
          >Êtes-vous sûr de bien vouloir accepter cette demande ?</v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-row>
            <v-col class="d-flex justify-content-center" cols="12" sm="12">
              <v-btn color="blue darken-1" text @click="fermerDialogeAccepterDemande"
                >Annuler</v-btn
              >
              <v-btn color="blue darken-1" text @click="accepterDemande">Confirmer</v-btn>
            </v-col>
          </v-row>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <datatable
      class="ma-8"
      :columns="columns"
      :sortKey="sortKey"
      :sortOrders="sortOrders"
      @sort="sortBy"
      @search="rechercheData"
    >
      <template v-slot:body>
        <tbody>
          <tr v-for="(demande, index) in lesDemande" :key="demande.id">
            <td>
              <div class="td-table">
                {{ demande.date }}
              </div>
            </td>
            <td>
              <div class="td-table">
                {{ demande.equipe.nomEquipe }}
              </div>
            </td>
            <td>
              <div class="td-table">
                {{ demande.ADV.userName }}
              </div>
            </td>
            <td>
              <div class="td-table">
                {{ demande.equipe.chefEquipe }}
              </div>
            </td>
            <td>
              <div class="td-table">
                <v-chip-group v-if="demande.equipe.Vendeurs.length > 0" column>
                  <v-chip
                    >{{ demande.equipe.Vendeurs[0].userName }}
                    <v-icon
                      v-if="demande.equipe.Vendeurs.length > 1"
                      large
                      data-toggle="collapse"
                      :data-target="'#multiCollapseVendeur' + index"
                      aria-expanded="false"
                      :aria-controls="'multiCollapseVendeur' + index"
                      right
                    >
                      mdi-menu-down
                    </v-icon>
                  </v-chip>
                  <div
                    class="collapse multi-collapse"
                    :id="'multiCollapseVendeur' + index"
                  >
                    <div>
                      <v-chip
                        v-for="(vendeur, indexV) in demande.equipe.Vendeurs.slice(
                          1,
                          demande.equipe.Vendeurs.length
                        )"
                        :key="indexV"
                        >{{ vendeur.userName }}</v-chip
                      >
                    </div>
                  </div>
                </v-chip-group>
                <v-chip-group v-else>
                  <span>Cette equipe ne contient aucun vendeur</span>
                </v-chip-group>
              </div>
            </td>
            <td>
              <div class="td-table">
                <v-chip-group v-if="demande.equipe.prospecteur.length > 0" column>
                  <v-chip
                    >{{ demande.equipe.prospecteur[0].userName }}
                    <v-icon
                      v-if="demande.equipe.prospecteur.length > 1"
                      large
                      data-toggle="collapse"
                      :data-target="'#multiCollapseprospecteur' + index"
                      aria-expanded="false"
                      :aria-controls="'multiCollapseprospecteur' + index"
                      right
                    >
                      mdi-menu-down
                    </v-icon>
                  </v-chip>
                  <div
                    class="collapse multi-collapse"
                    :id="'multiCollapseprospecteur' + index"
                  >
                    <div>
                      <v-chip
                        v-for="(prospecteur, indexV) in demande.equipe.prospecteur.slice(
                          1,
                          demande.equipe.prospecteur.length
                        )"
                        :key="indexp"
                        >{{ prospecteur.userName }}</v-chip
                      >
                    </div>
                  </div>
                </v-chip-group>
                <v-chip-group v-else>
                  <span>Cette equipe ne contient aucun prospecteur</span>
                </v-chip-group>
              </div>
            </td>
            <td>
              <div class="btn-group td-statut">
                <small v-if="demande.etat == 1" style="color: green"
                  ><b>acceptée</b></small
                >
                <small v-if="demande.etat == 2" style="color: red"><b>refuser</b></small>
                <small v-if="demande.etat == 0" style="color: gray"
                  ><b>en attente</b></small
                >
                <v-icon
                  v-if="demande.etat == 0"
                  size="25"
                  class="dropdown-toggle"
                  data-toggle="collapse"
                  :data-target="'#collapseStatut' + index"
                  aria-expanded="false"
                  :aria-controls="'collapseStatut' + index"
                  left
                  style="cursor: pointer"
                >
                  mdi-menu-down
                </v-icon>
                <div
                  class="text-center collapse"
                  :id="'collapseStatut' + index"
                  style="border: 0px solid white"
                >
                  <div class="d-flex">
                    <v-btn
                      @click="ouvrireDialogeAccepterDemande(demande)"
                      v-if="demande.etat == 0"
                      class="mx-2"
                      color="grey lighten-1"
                      small
                      dark
                    >
                      <v-icon color="grey darken-4"> mdi-check-outline </v-icon>
                    </v-btn>
                    <v-btn
                      @click="ouvrireDialogeRefuserDemande(demande)"
                      v-if="demande.etat == 0"
                      class="mx-2"
                      color="grey lighten-1"
                      small
                      dark
                    >
                      <v-icon color="grey darken-4"> mdi-cancel </v-icon>
                    </v-btn>
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </template>
    </datatable>
    <v-row>
      <v-col>
        <pagination
          :pagination="pagination"
          @selectNumberOfRecords="rechercheData"
          @first="getData(pagination.firstPage)"
          @last="getData(pagination.lastPage)"
          @prev="getData(pagination.prevPageUrl)"
          @current="getData(pagination.currentPage)"
          @next="getData(pagination.nextPageUrl)"
        >
        </pagination>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import "jquery/dist/jquery.min.js";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.bundle";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";

import $ from "jquery";
import jQuery from "jquery";

import Datatable from "../../components/dataTable/Datatable.vue";
import Pagination from "../../components/dataTable/Pagination.vue";
import axios from "@/plugins/axios";
export default {
  name: "demandeCreationEquipeAdmin",
  components: { datatable: Datatable, pagination: Pagination },
  data() {
    let sortOrders = {};
    let columns = [
      { width: "8vw", label: "Date", name: "date", sortable: true },
      { width: "10vw", label: "Equipe", name: "Equipe", sortable: true },
      { width: "10vw", label: "ADV", name: "ADV", sortable: true },
      { width: "10vw", label: "chefEquipe", name: "Chef équipe", sortable: false },
      { width: "22vw", label: "Vendeurs", name: "Vendeurs", sortable: false },
      { width: "22vw", label: "Prospecteurs", name: "Prospecteurs", sortable: false },
      { width: "10vw", label: "Statut", name: "status", sortable: true },
    ];
    columns.forEach((column) => {
      sortOrders[column.name] = -1;
    });
    return {
      dialog: false,
      loading: false,
      error: false,
      commentRefuser: "",
      messageDialoge: "",
      ouvrireDialogeRefuser: false,
      ouvrireDialogeAccepter: false,
      lesDemande: [],
      columns: columns,
      sortKey: "date",
      sortOrders: sortOrders,
      perPage: ["15", "15", "30"],
      tableData: {
        draw: 0,
        length: 15,
        search: "",
        column: 0,
        dir: "desc",
      },
      demandeTraiter: null,
      pagination: {
        perPage: ["10", "15", "30"],
        firstPage: 0,
        lastPage: "",
        currentPage: "",
        total: "",
        lastPageUrl: "",
        nextPageUrl: "",
        prevPageUrl: "",
        from: "",
        to: "",
      },

      rules: {
        required(finPhrase) {
          return (value) => !!value || `Veuillez saisir ${finPhrase}`;
        },
      },
    };
  },
  async created() {
    if (this.$store.getters.getUserToken == "noToken") {
      localStorage.removeItem("vendeurName");
      localStorage.removeItem("typeUser");
      localStorage.removeItem("token");
      this.$router.push("/login");
    } else {
      localStorage.setItem("typeUser", this.$store.getters.getUserToken);
    }
    this.getData();
  },
  methods: {
    rechercheData({ type, key }) {
      if (type == "search") {
        var pageNumber = 0;
        this.tableData.search = key;
      } else if (type == "nbrRecords") {
        this.tableData.length = key;
      }
      var pageNumber = 0;
      this.tableData.search = key;
      this.tableData.draw++;
      axios({
        url: process.env.VUE_APP_URL_API_CLIENT + "getAllDemandeDeCreationDeEquipe/",
        method: "POST",
        data: {
          dataTable: this.tableData,
          pageNumber: pageNumber,
          token: this.$store.getters.getUserToken,
        },
      })
        .then((response) => {
          if (response.data.result == true) {
            let data = response.data.data;
            if (this.tableData.draw == data.draw) {
              this.lesDemande = data.data.data;
              this.configPagination(data.data);
            }
          } else {
            localStorage.removeItem("vendeurName");
            localStorage.removeItem("typeUser");
            localStorage.removeItem("token");
            this.$router.push("/login");
          }
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    accepterDemande() {
      //open modal loading
      this.dialog = true;
      this.loading = true;
      this.error = false;
      this.messageDialoge = "";
      let self = this;
      var formData = new FormData();
      formData.append("idDemande", this.demandeTraiter.id);
      formData.append("token", this.$store.getters.getUserToken);
      axios({
        url: process.env.VUE_APP_URL_API_CLIENT + "accepterCreationEquipe/",
        method: "POST",
        data: formData,
      })
        .then((res) => {
          this.fermerDialogeAccepterDemande();
          this.getData();
        })
        .catch((err) => {
          this.fermerDialogeAccepterDemande();
          this.getData();
        });
    },
    refuserDemande() {
      //fermer modal comment
      this.ouvrireDialogeRefuser = false;
      //open modal loading
      this.dialog = true;
      this.loading = true;
      this.error = false;
      this.messageDialoge = "";

      let self = this;
      var formData = new FormData();
      formData.append("token", this.$store.getters.getUserToken);
      formData.append("idDemande", this.demandeTraiter.id);
      axios({
        url: process.env.VUE_APP_URL_API_CLIENT + "refuserCreationEquipe/",
        method: "POST",
        data: formData,
      })
        .then((res) => {
          this.fermerDialogeRefuserDemande();
          this.getData();
        })
        .catch((err) => {
          this.fermerDialogeRefuserDemande();
          this.getData();
        });
    },
    ouvrireDialogeRefuserDemande(item) {
      this.demandeTraiter = item;
      this.ouvrireDialogeRefuser = true;
    },
    fermerDialogeRefuserDemande() {
      this.demandeTraiter = "";
      this.ouvrireDialogeRefuser = false;
    },
    ouvrireDialogeAccepterDemande(item) {
      this.demandeTraiter = item;
      this.ouvrireDialogeAccepter = true;
    },
    fermerDialogeAccepterDemande() {
      this.demandeTraiter = "";
      this.ouvrireDialogeAccepter = false;
    },
    getData(pageNumber = 0) {
      this.tableData.draw++;
      axios({
        url: process.env.VUE_APP_URL_API_CLIENT + "getAllDemandeDeCreationDeEquipe/",
        method: "POST",
        data: {
          dataTable: this.tableData,
          pageNumber: pageNumber,
          token: this.$store.getters.getUserToken,
        },
      })
        .then((response) => {
          if (response.data.result == true) {
            let data = response.data.data;
            if (this.tableData.draw == data.draw) {
              this.lesDemande = data.data.data;
              this.configPagination(data.data);
            }
          } else {
            localStorage.removeItem("vendeurName");
            localStorage.removeItem("typeUser");
            localStorage.removeItem("token");
            this.$router.push("/login");
          }
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    configPagination(data) {
      this.pagination.lastPage = data.last_page;
      this.pagination.currentPage = data.current_page;
      this.pagination.total = data.total;
      this.pagination.lastPageUrl = data.last_page_url;
      this.pagination.nextPageUrl = data.next_page_url;
      this.pagination.prevPageUrl = data.prev_page_url;
      this.pagination.from = data.from;
      this.pagination.to = data.to;
    },
    sortBy(key) {
      this.sortKey = key;
      this.sortOrders[key] = this.sortOrders[key] * -1;
      this.tableData.column = this.getIndex(this.columns, "name", key);
      this.tableData.dir = this.sortOrders[key] === 1 ? "asc" : "desc";
      this.getData();
    },
    getIndex(array, key, value) {
      return array.findIndex((i) => i[key] == value);
    },
  },
};
</script>
