<template>
    <nav style="--bs-breadcrumb-divider: '>';" aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li v-for="(nav, index) in listNavs" :key="index" class="breadcrumb-item">
                <a :href="nav.link">{{nav.name}}</a>
                </li>
            </ol>
        </nav>
</template>
<script>
export default {
    name: "breadcrumb",
     props: {
         listNavs: {
             type: Array,
             required: true,
         },
    },
    data() {
        return {
            
        };
    },
}
</script>
<style scoped>
    .breadcrumb{
        background-color: #ffffff !important;
  
    }
    .containe_comp{
        background-color: #f2f2f2 !important;
        border-radius: 10px;
        padding: 50px 70px;
        font-size: 14px;
        font-family: -apple-system,BlinkMacSystemFont,Segoe UI,PingFang SC,Hiragino Sans GB,Microsoft YaHei,Helvetica Neue,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;
        font-variant: tabular-nums;
        line-height: 1.5;
        font-feature-settings: "tnum";
    }
    .card{
        position: relative;
        display: flex;
        flex-direction: column;
        min-width: 0;
        word-wrap: break-word;
        background-color: #fff;
        background-clip: border-box;
        border: 0 solid rgba(0,0,0,.125);
    }
    .body-header{
       
        color: #192946;
    }
    .icon {
    width: 58px;
    height: 58px;
    top: 0;
    bottom: 0;
    right: 16px;
    margin: auto;
    background: linear-gradient(45deg, rgb(0, 121, 145) 0%, rgb(24, 174, 162) 68%) rgb(0, 121, 145);
    border-radius: 7px;
    box-shadow: 2px 4px 6px rgba(0,0,0,.12);
    justify-content: center;
    align-items: center;
    padding: 1px 5px;
    }
    .text-button{
        color: #192946;
        font-size: 14px;
        font-weight: 500;
        line-height: 1.2;
        letter-spacing: -.02em;
        text-align: center;
        margin-bottom: 0;
        margin-top: 0;
        margin-left: 10px;
    }
    .text-button:hover{
        color: #1e3766;
        font-size: 14px;
        font-weight: 500;
        line-height: 1.2;
        letter-spacing: -.02em;
        text-align: center;
        margin-bottom: 0;
        margin-top: 0;
        margin-left: 10px;
        text-decoration: underline;
    }
    .bgc-primary{
        background-color: #396c99;
    }
    .bgc-primary:hover{
        background-color: #1e4c66;
    }
    .bgc-success{
        background-color: #2fb8ac;
    }
</style>