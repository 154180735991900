<template>
  <div style="height: 100%">
    <router-view></router-view>
  </div>
</template>
<script>
export default {
  name: "CalendarLayout",
};
</script>
