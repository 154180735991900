<template>
    <div style="height:100%" class="d-flex flex-row justify-center align-center">
        <div class="d-flex flex-column align-center">    
            <span class="Poppins-Bold" style="color:#007991;font-size:150px;">404</span>
            <span class="Poppins-Bold" style="color:#007991;font-size:20px;">Oops ! Page introuvable</span>
        </div>
    </div>
</template>
<script>
import cookie from "@/services/cookie"
export default {
    name:'not-found',
}
</script>