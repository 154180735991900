<template>
  <div style="background-color: white; padding: 60px; font-family: 'Poppins', sans-serif">
    <div
      style="
        display: flex;
        flex-direction: row;
        justify-content: center;
        text-decoration: underline;
        font-size: 20px;
        font-weight: bold;
        margin-bottom: 20px;
      "
    >
      INFRASTRUCTURE DE RECHARGE DE VEHICULE ELECTRIQUE
    </div>
    <div
      style="
        display: flex;
        flex-direction: row;
        justify-content: center;
        font-weight: bold;
        margin-bottom: 20px;
      "
    >
      PROJET : IRVE
    </div>
    <div
      style="
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin-bottom: 64px;
        font-weight: bold;
      "
    >
      {{ societe?.raison.toUpperCase() }}
    </div>
    <div style="display: flex; flex-direction: row; justify-content: center">
      <img
        src="../../assets/logo/ENEFFIC-logo.png"
        style="max-height: 100px; max-width: 200px; margin-bottom: 96px"
        alt="Logo"
      />
    </div>
    <table style="border: 4px solid black; width: 100%; border-collapse: collapse">
      <tr>
        <th
          style="
            background-color: #14324f;
            color: white;
            padding: 10px;
            font-weight: bold;
          "
        >
          RECAPITULATIF DES LOTS
        </th>
        <th style="background-color: #14324f; padding: 10px"></th>
      </tr>
      <tr>
        <th style="height: 100px"></th>
      </tr>
      <tr style="background-color: #c0c0c0; height: 25px; border: 2px solid black">
        <td style="width: 85%; border: 2px black solid"></td>
        <td style="max-width: 10%; border: 2px black solid; text-align: center">
          <span style="color: white">Totaux</span>
        </td>
      </tr>
      <tr style="background-color: #c0c0c0; height: 25px; border: 2px solid black">
        <td style="width: 85%; border: 2px black solid"></td>
        <td style="max-width: 10%; border: 2px black solid"></td>
      </tr>
      <tr style="background-color: #c0c0c0; height: 25px; border: 2px solid black">
        <td style="width: 85%; border: 2px black solid"></td>
        <td style="max-width: 10%; border: 2px black solid"></td>
      </tr>
      <!-- Boucle pour les lignes d'itemsProps -->
      <tr v-for="item in itemsProps" v-if="item.prixTotal > 0">
        <td
          style="
            border-left: 2px black solid;
            border-right: 2px solid black;
            padding: 10px;
          "
        >
          <span style="color: #14324f; font-weight: bold">{{ item.title }}</span>
        </td>
        <td
          style="
            border-left: 2px black solid;
            border-right: 2px solid black;
            padding: 0px;
            text-align: center;
          "
        >
          <span style="color: #14324f; font-weight: 600"
            >{{ Number(item.prixTotal).toLocaleString("fr-FR") }} €</span
          >
        </td>
      </tr>
      <!-- Répétez cette structure pour chaque élément de itemsProps -->
      <!-- Boucle pour les lignes de categories -->
      <tr v-for="categorie in categories">
        <td
          :class="
            categorie.title.toUpperCase() == 'MONTANT TVA 20%'
              ? 'padding-16'
              : 'padding-4'
          "
        >
          <div style="display: flex">
            <span
              style="
                color: white;
                font-weight: bold;
                background-color: #14324f;
                width: 100%;
              "
              >{{ categorie.title }}</span
            >
          </div>
        </td>
        <td
          :class="
            categorie.title.toUpperCase() == 'MONTANT TVA 20%'
              ? 'padding-16'
              : 'padding-4'
          "
          style="text-align: center"
        >
          <div style="display: flex">
            <span
              style="
                border-left: 2px solid black;
                color: white;
                font-weight: 600;
                background-color: #14324f;
                width: 100%;
              "
              >{{ Number(categorie.total).toLocaleString("fr-FR") }}€</span
            >
          </div>
        </td>
      </tr>
      <!-- Répétez cette structure pour chaque élément de categories -->
      <tr>
        <td style="border: 2px black solid; border-top: none; padding: 10px" colspan="2">
          <div>
            <span style="font-weight: bold">Observation :</span>
            <span style="margin-top: 10px; display: block">
              Installation au départ du TGBT existant Sous réserve de visite technique,
              notes de calcul Sous réserve d'étude courants (pouvoir de coupure depuis le
              transformateur ENEDIS + Zone AQ Parafoudre) Hors CFA selon besoins
              utilisateurs et infrastructure IP existantes
            </span>
          </div>
        </td>
      </tr>
    </table>
  </div>
</template>
<script>
export default {
  name: "recapTemplate",
  props: ["itemsProps", "societe"],
  data() {
    return {
      categories: [
        { title: "TOTAL HT", total: 0 },
        { title: "MOE EXECUTION", total: 0 },
        { title: "MONTANT TOTAL HT", total: 0 },
        { title: "MONTANT TVA 20%", total: 0 },
        { title: "MONTANT TOTAL TTC", total: 0 },
      ],
      items: [],
    };
  },

  watch: {
    itemsProps: function (newValue) {
      if (!!newValue) {
        newValue.forEach((element) => {
          this.categories[0].total += Number(element.prixTotal);
          this.categories[2].total =
            Number(this.categories[1].total) + Number(this.categories[0].total);
          this.categories[3].total = Number(this.categories[2].total) * 0.2;
          this.categories[4].total =
            Number(this.categories[3].total) + Number(this.categories[2].total);
        });
      }
    },
  },
};
</script>
<style scoped>
.padding-16 {
  padding-top: 16px !important;
}
.padding-4 {
  padding-top: 4px !important;
}
.page {
  page-break-before: always;
  page-break-after: always;

  margin: 0;
}
</style>
