<template>
    <div style="height:90vh;" class="d-flex flex-row justify-center align-center">  
        <v-dialog
          v-model="dialogError"
          persistent
          max-width="500"
          >
          <v-card style="background-color:#ff5252 !important">
              <v-card-title class="white--text text-h6">
              Erreur Connexion
              </v-card-title>
              <v-card-text>
                  <v-alert type="error">
                      {{messageErrorLogin}}
                  </v-alert>
              </v-card-text>
              <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                  class="white--text"
                  text
                  @click="dialogError = false"
              >
                  FERMER
              </v-btn>                           
              </v-card-actions>
          </v-card>
          </v-dialog>
        <loadingComponent v-if="loadingLogin" :dialogProps="dialogLoading" messageDialogeProps="erreur" :loadingProps="loadingLogin" :errorProps="false"/>  
        <v-card v-if="!loadingLogin" class="ma-4 rounded-lg" style="width:25%">
            <v-toolbar
                class="rounded-t-lg"
                :style="{background:$styles.background}"
                dark>
                <span class="pl-0">Formulaire de Connexion</span>
            </v-toolbar>
            <v-form class="d-flex pa-4 flex-column mt-4 formLogin" id="formLoginRef" ref='formLoginRef' :lazy-validation="false"  v-if="!$store.getters.getUserToken">
                    <v-text-field autocomplete="username" id="email" class="email" v-model="email" placeholder="email" :rules="[$rules.required('une adresse mail'),$rules.formatEmail,$rules.isSanitize,$rules.injection_sql]" dense outlined></v-text-field>
                    <v-text-field autocomplete="current-password" type="password" id="password" class="password" v-model="password" :rules="[$rules.required('un mot de passe'),$rules.isSanitize,$rules.injection_sql]" placeholder="mot de passe" dense outlined></v-text-field>
                    <v-btn id="btnLogin" :color="$colors[0]" class="mt-3" @click="login"><span class="white--text">Login</span><v-icon class="white--text ml-2">mdi-login-variant</v-icon></v-btn>
            </v-form>
        </v-card>
    </div>
</template>
<script>
import loadingComponent from "../components/Loading.vue"
export default {
    name:"LoginComponent",
    components:{loadingComponent},
    data(){
        return   {
            dialogError:false,
            messageErrorLogin:"",
            loadingLogin:false,
            dialogLoading:false,
            email:"",
            password:""
        }
    },
    computed:{
      // get user
      getUserRole(){
        return this.$store.getters.getUserRole;
      },
    },
    methods:{
      async login(){
        if(this.$refs.formLoginRef.validate()){
          this.dialogLoading = true;
          this.loadingLogin = true;
          await fetch(process.env.VUE_APP_URL_API_CLIENT+"login/", {
            method: "POST",
            body:JSON.stringify({
              email:this.$DOMPurify.sanitize(this.email),
              password:this.$DOMPurify.sanitize(this.password)
          }),
          headers: {
              "Content-type": "application/json; charset=UTF-8"
            }
          })
          .then((response) => response.json())
          .then((data) => {
            if(data.login === true){
              console.log(data.exp)
              this.$cookieService.setCookie("token", data.token, data.exp);
              this.$cookieService.setCookie("id", data.id);
              this.$cookieService.setCookie("compte", JSON.stringify(data.compte));
              this.$store.dispatch('setActionUserToken');
              this.$store.dispatch('setActionUserId');
              this.$store.dispatch('setActionCompte');
              this.$store.dispatch('setActionRedirect');
              // if user is admin
            }else if(data.login == "incorrect"){
              this.messageErrorLogin = "Identifiants incorrects !";
              this.dialogError = true;
            }
          })
        }
        this.dialogLoading = false;
        this.loadingLogin = false;
      },
    },
}
</script>