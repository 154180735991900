<template>
  <div class="container d-flex flex-row justify-center align-center">
    <v-row v-if="loading || errorModal">
      <loadingComponent
        @closeDialog="close($event)"
        :loadingProps="loading"
        :messageDialogeProps="messageDialoge"
        :dialogProps="dialog"
        :errorProps="errorModal"
      />
    </v-row>
    <v-card>
      <v-toolbar class="white--text" :style="{ background: $styles.background }">
        <span class="pl-0">Rentrer une opportunité</span>
      </v-toolbar>
      <div class="d-flex flex-column">
        <v-stepper
          style="border-top-left-radius: 0px; border-top-right-radius: 0px"
          elevation="0"
          v-model="e1"
        >
          <v-stepper-header style="box-shadow: 0 0 0 transparent">
            <v-stepper-step
              :color="$colors[0]"
              :complete="e1 > 1"
              step="1"
              @click="e1 > 1 ? (e1 = 1) : (e1 = e1)"
            >
              Recherche Entreprise
            </v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step
              :color="$colors[0]"
              :complete="e1 > 2"
              step="2"
              @click="e1 > 2 ? (e1 = 2) : (e1 = e1)"
            >
              Informations Entreprise
            </v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step
              :color="$colors[0]"
              :complete="e1 > 3"
              step="3"
              @click="e1 > 3 ? (e1 = 3) : (e1 = e1)"
            >
              Ajout Point(s)
            </v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step :color="$colors[0]" step="4">
              Ajout Fichier(s)
            </v-stepper-step>
          </v-stepper-header>
          <v-stepper-items style="height: 10%">
            <v-stepper-content class="pa-4" step="1">
              <v-card elevation="0" class="d-flex flex-row">
                <v-row class="ma-2">
                  <v-col class="pa-0" cols="12" style="height: 35%">
                    <v-form ref="formSiret">
                      <v-row>
                        <v-col cols="12" md="4">
                          <v-text-field
                            v-model="siret"
                            dense
                            :rules="[
                              $rules.required(`un siret valide`),
                              $rules.isSanitize,
                              $rules.isNumber,
                              $rules.compteurElecLength,
                            ]"
                            label="SIRET"
                            placeholder="SIRET"
                            outlined
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-col>
                </v-row>
              </v-card>
              <v-btn
                @click="getSiretInformations()"
                :color="$colors[0]"
                class="white--text mx-2"
                >Rechercher</v-btn
              >
            </v-stepper-content>
            <v-stepper-content step="2">
              <v-form class="mt-4" ref="formInfo">
                <v-card elevation="0" class="mb-6 d-flex flex-row">
                  <v-row>
                    <v-col cols="12" sm="4" md="3">
                      <v-text-field
                        class="ma-0"
                        dense
                        outlined
                        label="Raison"
                        :rules="[$rules.required(`un Raison valide`), $rules.isSanitize]"
                        disabled
                        v-model="societe.raison"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="4" md="3">
                      <v-text-field
                        class="ma-0"
                        dense
                        outlined
                        label="Adresse Postal"
                        :rules="[
                          $rules.required(`un Adresse Postal valide`),
                          $rules.isSanitize,
                        ]"
                        disabled
                        v-model="societe.adressePostal"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="4" md="3">
                      <v-select
                        label="Civilite"
                        dense
                        outlined
                        :items="selectionGenre"
                        v-model="selectionGenre[0]"
                      ></v-select>
                    </v-col>
                    <v-col cols="12" sm="4" md="3">
                      <v-text-field
                        class="ma-0"
                        dense
                        outlined
                        label="Nom"
                        :rules="[$rules.required(`un Nom valide`), $rules.isSanitize]"
                        v-model="societe.nom"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="4" md="3">
                      <v-text-field
                        class="ma-0"
                        dense
                        outlined
                        label="Prenom"
                        :rules="[$rules.required(`un Prenom valide`), $rules.isSanitize]"
                        v-model="societe.prenom"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="4" md="3">
                      <v-text-field
                        class="ma-0"
                        dense
                        outlined
                        label="Email"
                        :rules="[
                          $rules.required(`un Email valide`),
                          $rules.isSanitiz,
                          valideEmail,
                        ]"
                        v-model="societe.adresseEmail"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="4" md="3">
                      <v-text-field
                        class="ma-0"
                        dense
                        outlined
                        label="N° Tele"
                        :rules="[
                          $rules.required(`un N° tele valide`),
                          $rules.isSanitize,
                          validePhoneNumber,
                        ]"
                        v-model="societe.numTele"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="4" md="3">
                      <v-text-field
                        class="ma-0"
                        dense
                        outlined
                        label="Fonction"
                        :rules="[
                          $rules.required(`une Fonction valide`),
                          $rules.isSanitize,
                        ]"
                        v-model="societe.fonction"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="4" md="3">
                      <v-text-field
                        class="ma-0"
                        dense
                        outlined
                        :min="new Date().toISOString().split('T')[0]"
                        type="date"
                        label="Date Devis"
                        :rules="[
                          $rules.required(`un Date devis valide`),
                          $rules.isSanitize,
                        ]"
                        v-model="societe.dateDevis"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-card>
              </v-form>
              <v-btn class="mr-2 white--text" :color="$colors[0]" @click="validateInfo()">
                Valider
              </v-btn>
              <v-btn text @click="e1 = 1"> Annuler </v-btn>
            </v-stepper-content>
            <v-stepper-content step="3">
              <v-card class="mb-12" height="300px;">
                <v-form ref="formValidePdls1">
                  <v-row>
                    <v-col cols="12">
                      <v-divider class="mt-2"></v-divider>
                      <p>Ajouter les PDL(s)</p>
                      <v-row>
                        <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                          <v-textarea
                            id="counterInput"
                            label="Label"
                            :rules="[
                              $rules.required(`une Fonction valide`),
                              $rules.isCompteurSolar,
                            ]"
                            v-model="num_comp"
                            @input="validateInput"
                          ></v-textarea>
                        </v-col>

                        <!--<v-col cols="12" sm="12" md="12" lg="12" xl="12">
                          <v-btn
                            depressed
                            elevation="1"
                            class="text-white mb-4"
                            @click="ajouterCompteur"
                          >
                            <span class="Poppins-Bold">Ajouter un compteur</span>
                          </v-btn>
                        </v-col>-->
                      </v-row>
                    </v-col>
                  </v-row>
                </v-form>
              </v-card>
              <v-btn
                class="mr-2 white--text"
                :color="$colors[0]"
                @click="verifierListeCompteurExiste()"
              >
                Valider
              </v-btn>
              <v-btn text @click="e1 = 1"> Annuler </v-btn>
            </v-stepper-content>
            <v-stepper-content step="4">
              <v-card elevation="0">
                <v-combobox
                  v-model="fichierChoisi"
                  :items="choixFichiers"
                  outlined
                  dense
                ></v-combobox>
                <v-form ref="formFiles">
                  <div
                    style="display: flex; justify-content: center; align-items: center"
                  >
                    <uploadFile
                      :fileProps="files[fichierChoisi]"
                      :name="fichierChoisi"
                      :maxNumberFileProps="maxNumberFile"
                      :fileRequired="fileRequired"
                      @maxNumberFile="4"
                      @FileData="retrieveFile($event, fichierChoisi)"
                    />
                    <!--<listFile
                      :isVisible="true"
                      :file="files[fichierChoisi]"
                      @remove="refreshListFiles($event, fichierChoisi)"
                    />-->
                  </div>
                </v-form>
                <div
                  style="border: orange solid 1px; text-align: center"
                  v-if="error == true"
                >
                  <p style="color: orange">{{ errorAlertText }}</p>
                </div>
              </v-card>
              <v-btn class="white--text mr-2" :color="$colors[0]" @click="submit()">
                Finaliser
              </v-btn>
              <v-btn text @click="e1 = 2"> Annuler </v-btn>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
        <!---->
      </div>
    </v-card>
  </div>
</template>
<script>
import loadingComponent from "./components/Loading";
import listFile from "./components/listFile.vue";
import uploadFile from "./components/uploadFile.vue";
import axios from "@/plugins/axios";
export default {
  name: "NouvelleOpportuniteSolarCollectif",
  components: {
    uploadFile: uploadFile,
    listFile: listFile,
    loadingComponent: loadingComponent,
  },
  data() {
    return {
      //
      responseStatus: 0,
      dialog: false,
      loading: false,
      messageDialoge: "",
      errorModal: false,
      //
      num_comp: [],
      isLoading: false,
      files: {
        ACD: [],
        Contrat: [],
        Facture: [],
        Plans: [],
      },
      maxNumberFile: 4,
      fileRequired: false,
      choixFichiers: ["ACD", "Facture", "Contrat", "Plans"],
      fichierChoisi: "ACD",
      e1: 1,
      selectionGenre: ["Monsieur", "Madame"],
      siret: "",
      societe: {
        Siret: this.siret,
        nom: "",
        prenom: "",
        adresseEmail: "",
        numTele: "",
        fonction: "",
        codeNaf: "",
        codeInsee: "",
        raison: "",
        adressePostal: "",
        codePostal: "",
        commune: "",
        voie: "",
        statusSociete: "",
        RCS: "",
        TVA: "",
        civile: "",
        formJu: "",
        dateDevis: "",
      },
      errorAlertText: "",
      error: false,
    };
  },
  methods: {
    ajouterCompteur() {
      this.num_comp.push("");
    },
    supprimerCompteur(index) {
      this.num_comp.splice(index, 1);
    },
    close(event) {
      this.dialog = event;
      this.errorModal = event;
      if (this.responseStatus === 401) {
        this.$store.dispatch("logout");
      }
    },
    clickMenu(clikedElement) {
      this.e1 = clikedElement;
    },
    changeCompteurSelected() {},
    async submit() {
      if (
        this.files["ACD"].length === 0 &&
        this.files["Contrat"].length === 0 &&
        this.files["Facture"].length === 0
      ) {
        this.dialog = true;
        this.loading = false;
        this.messageDialoge = "Sélectionnez au moins une fichier!";
        this.errorModal = true;
      } else {
        this.dialog = true;
        this.loading = true;
        this.messageDialoge = "";
        this.errorModal = false;
        var formData = new FormData();
        formData.append("PDLs", this.num_comp);
        formData.append("societe", JSON.stringify(this.societe));
        formData.append("token", this.$cookies.get("token"));
        for (var i = 0; i < this.files.Contrat.length; i++) {
          //lesDonner["Contrat"+i] = this.files.Contract[i]
          formData.append("Contrat" + i, this.files.Contrat[i]);
        }
        for (var i = 0; i < this.files.ACD.length; i++) {
          //lesDonner["ACD"+i] = this.files.ACD[i]
          formData.append("ACD" + i, this.files.ACD[i]);
        }
        for (var i = 0; i < this.files.Facture.length; i++) {
          //lesDonner["Facture"+i] = this.files.Facture[i]
          formData.append("Facture" + i, this.files.Facture[i]);
        }
        for (var i = 0; i < this.files.Plans.length; i++) {
          formData.append("Plans" + i, this.files.Plans[i]);
        }
        await axios({
          url:
            process.env.VUE_APP_URL_API_CLIENT +
            "ajouterDemandeDeCotationSolarCollectif/",
          method: "POST",
          data: formData,
        })
          .then((res) => {
            if (res.data.result == true) {
              this.dialog = true;
              this.loading = false;
              this.messageDialoge = "Demande de cotation ajouter";
              this.errorModal = false;
            } else {
              this.dialog = true;
              this.loading = false;
              this.messageDialoge = res.data.message;
              this.errorModal = true;
            }
          })
          .catch((err) => {
            this.dialog = true;
            this.loading = false;
            this.messageDialoge = "une erreur technique est survenue";
            this.errorModal = true;
          });
      }
    },
    refreshListFiles(file, typeFichier) {
      this.files[typeFichier] = file;
      this.maxNumberFile = 4;
    },
    CheckNumberFile(e) {
      this.maxNumberFile = e;
    },
    retrieveFile(event, typeFichier) {
      this.files[typeFichier] = event;
    },
    async getSiretInformations() {
      if (this.$refs.formSiret.validate()) {
        this.dialog = true;
        this.loading = true;
        this.messageDialoge = "";
        this.errorModal = false;
        await axios({
          url: process.env.VUE_APP_URL_API_CLIENT + "getSiretInformations/",
          method: "POST",
          data: {
            token: this.$cookies.get("token"),
            siret: this.siret,
          },
        })
          .then(async (response) => {
            let responseData = response.data;
            this.responseStatus = response.status;
            this.loading = false;
            this.dialog = true;
            if (this.responseStatus === 200) {
              switch (responseData.result) {
                case true:
                  this.dialog = false;
                  this.messageDialoge = "";
                  this.societe = responseData.data;
                  this.e1 = 2;
                  this.errorModal = false;
                  break;
                case false:
                  this.messageDialoge = "Verifer que votre siret est correct";
                  this.errorModal = true;
                  break;
                default:
                  break;
              }
            } else if (this.responseStatus === 401) {
              this.messageDialoge = responseData.result;
              this.errorModal = true;
            } else {
              this.messageDialoge = data.result;
              this.errorModal = true;
            }
          })
          .catch((err) => {
            this.dialog = true;
            this.loading = false;
            this.messageDialoge = "une erreur technique est survenue";
            this.errorModal = true;
          });
      }
    },
    validateInfo() {
      if (this.$refs.formInfo.validate()) {
        this.e1 = 3;
      }
    },
    /*async recherchePointes() {
      this.dialog = true;
      this.loading = true;
      this.messageDialoge = "";
      this.errorModal = false;
      if (this.$refs.formRecherchePdls.validate()) {
        await fetch(process.env.VUE_APP_URL_API_CLIENT + "getListeDesPoints/", {
          method: "POST",
          body: JSON.stringify({
            adresseUser: this.societe.adressePostal,
            codeInsee: this.societe.codeInsee,
            raison: this.societe.raison,
          }),
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        })
          .then((response) => response.json())
          .then((data) => {
            if (!("error" in data) && !("errorPostal" in data)) {
              this.listeCompteurs = data.listCompteur;
              this.dialog = false;
              this.loading = false;
              this.messageDialoge = "";
              this.errorModal = false;
            } else {
              this.listeCompteurs = data.listCompteur;
              this.dialog = true;
              this.loading = false;
              this.messageDialoge = "Il n'existe aucune PDL reliée à cette adresse.";
              this.errorModal = true;
            }
          })
          .catch((err) => {
            this.dialog = true;
            this.loading = false;
            this.messageDialoge = "une erreur technique est survenue";
            this.errorModal = true;
          });
      }
    },*/
    async verifierListeCompteurExiste() {
      if (this.num_comp) {
        this.dialog = true;
        this.loading = true;
        this.messageDialoge = "";
        this.errorModal = false;
        if (this.$refs.formValidePdls1.validate()) {
          var formDonne = new FormData();
          formDonne.append("PDLS", this.num_comp);
          await axios({
            url: process.env.VUE_APP_URL_API_CLIENT + "verifierListeCompteurExisteSolar/",
            method: "POST",
            data: formDonne,
          })
            .then((response) => {
              var data = response.data;
              if (data["result"] == "valide") {
                this.dialog = false;
                this.loading = false;
                this.messageDialoge = "";
                this.errorModal = false;
                this.e1 = 4;
              } else {
                this.dialog = true;
                this.loading = false;
                this.messageDialoge =
                  "le compteur n°" + data.compteurError + " n'existe pas";
                this.errorModal = true;
              }
            })
            .catch((err) => {
              this.dialog = true;
              this.loading = false;
              this.messageDialoge = "une erreur technique est survenue";
              this.errorModal = true;
            });
        }
      } else {
        this.dialog = true;
        this.loading = false;
        this.messageDialoge = "Sélectionnez au moins un compteur!";
        this.errorModal = true;
      }
    },
  },
  computed: {
    valideEmail() {
      const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

      if (emailPattern.test(this.societe.adresseEmail)) {
        return true;
      } else {
        return "Email invalide";
      }
    },
    validePhoneNumber() {
      // phone numver pattern is a string that contains 10 numeric digits starting with 0
      const phonePattern = /^\d{10}$/;
      if (phonePattern.test(this.societe.numTele)) {
        return true;
      } else {
        return "Numéro de téléphone invalide";
      }
    },
  },
};
</script>
<style scoped>
.container {
  height: 100%;
}
</style>
