<template>
    <div>
        <p>visite</p>
    </div>
</template>
<script>
    export default{
        name: "VisiteComponent",
         data(){
            return{

            }
        },
        methods: {

        }
    } 

</script>
<style>

</style>

