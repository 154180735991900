var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"background-color":"white","padding":"60px","font-family":"'Poppins', sans-serif"}},[_c('div',{staticStyle:{"display":"flex","flex-direction":"row","justify-content":"center","text-decoration":"underline","font-size":"20px","font-weight":"bold","margin-bottom":"20px"}},[_vm._v(" INFRASTRUCTURE DE RECHARGE DE VEHICULE ELECTRIQUE ")]),_c('div',{staticStyle:{"display":"flex","flex-direction":"row","justify-content":"center","font-weight":"bold","margin-bottom":"20px"}},[_vm._v(" PROJET : IRVE ")]),_c('div',{staticStyle:{"display":"flex","flex-direction":"row","justify-content":"center","margin-bottom":"64px","font-weight":"bold"}},[_vm._v(" "+_vm._s(_vm.societe?.raison.toUpperCase())+" ")]),_vm._m(0),_c('table',{staticStyle:{"border":"4px solid black","width":"100%","border-collapse":"collapse"}},[_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._l((_vm.itemsProps),function(item){return (item.prixTotal > 0)?_c('tr',[_c('td',{staticStyle:{"border-left":"2px black solid","border-right":"2px solid black","padding":"10px"}},[_c('span',{staticStyle:{"color":"#14324f","font-weight":"bold"}},[_vm._v(_vm._s(item.title))])]),_c('td',{staticStyle:{"border-left":"2px black solid","border-right":"2px solid black","padding":"0px","text-align":"center"}},[_c('span',{staticStyle:{"color":"#14324f","font-weight":"600"}},[_vm._v(_vm._s(Number(item.prixTotal).toLocaleString("fr-FR"))+" €")])])]):_vm._e()}),_vm._l((_vm.categories),function(categorie){return _c('tr',[_c('td',{class:categorie.title.toUpperCase() == 'MONTANT TVA 20%'
            ? 'padding-16'
            : 'padding-4'},[_c('div',{staticStyle:{"display":"flex"}},[_c('span',{staticStyle:{"color":"white","font-weight":"bold","background-color":"#14324f","width":"100%"}},[_vm._v(_vm._s(categorie.title))])])]),_c('td',{class:categorie.title.toUpperCase() == 'MONTANT TVA 20%'
            ? 'padding-16'
            : 'padding-4',staticStyle:{"text-align":"center"}},[_c('div',{staticStyle:{"display":"flex"}},[_c('span',{staticStyle:{"border-left":"2px solid black","color":"white","font-weight":"600","background-color":"#14324f","width":"100%"}},[_vm._v(_vm._s(Number(categorie.total).toLocaleString("fr-FR"))+"€")])])])])}),_vm._m(6)],2)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"display":"flex","flex-direction":"row","justify-content":"center"}},[_c('img',{staticStyle:{"max-height":"100px","max-width":"200px","margin-bottom":"96px"},attrs:{"src":require("../../assets/logo/ENEFFIC-logo.png"),"alt":"Logo"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('th',{staticStyle:{"background-color":"#14324f","color":"white","padding":"10px","font-weight":"bold"}},[_vm._v(" RECAPITULATIF DES LOTS ")]),_c('th',{staticStyle:{"background-color":"#14324f","padding":"10px"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('th',{staticStyle:{"height":"100px"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',{staticStyle:{"background-color":"#c0c0c0","height":"25px","border":"2px solid black"}},[_c('td',{staticStyle:{"width":"85%","border":"2px black solid"}}),_c('td',{staticStyle:{"max-width":"10%","border":"2px black solid","text-align":"center"}},[_c('span',{staticStyle:{"color":"white"}},[_vm._v("Totaux")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',{staticStyle:{"background-color":"#c0c0c0","height":"25px","border":"2px solid black"}},[_c('td',{staticStyle:{"width":"85%","border":"2px black solid"}}),_c('td',{staticStyle:{"max-width":"10%","border":"2px black solid"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',{staticStyle:{"background-color":"#c0c0c0","height":"25px","border":"2px solid black"}},[_c('td',{staticStyle:{"width":"85%","border":"2px black solid"}}),_c('td',{staticStyle:{"max-width":"10%","border":"2px black solid"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',{staticStyle:{"border":"2px black solid","border-top":"none","padding":"10px"},attrs:{"colspan":"2"}},[_c('div',[_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v("Observation :")]),_c('span',{staticStyle:{"margin-top":"10px","display":"block"}},[_vm._v(" Installation au départ du TGBT existant Sous réserve de visite technique, notes de calcul Sous réserve d'étude courants (pouvoir de coupure depuis le transformateur ENEDIS + Zone AQ Parafoudre) Hors CFA selon besoins utilisateurs et infrastructure IP existantes ")])])])])
}]

export { render, staticRenderFns }