<template>
  <div>
    <v-col
      :style="{ backgroundColor: color }"
      class="pa-2 mb-4"
      cols="12"
      sm="12"
      md="12"
      lg="12"
      xl="12"
    >
      <span class="white--text"
        >{{ title }} <v-icon class="white--text">{{ icon }}</v-icon></span
      >
    </v-col>
  </div>
</template>
<script>
export default {
  name: "titleComponent",
  props: ["color", "title", "icon"],
};
</script>
