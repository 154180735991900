import axios from "axios"
import store from "@/store";
export async function getVisiteTechniqueFiles(request){
    let response;
    await axios({
        url:
        process.env.VUE_APP_URL_API_CLIENT + "getListVisteTechniqueProfilClient/", // a remplacer apres test
        method: "POST",
        data: {
          id_societe: request.id,
          pageNumber: request.pageNumber,
          length: request.length,
          token:store.getters.getUserToken, // A REMPLACER
        },
      }).then((res) => {
        if (res.data.status) {
          response = res.data.data
        }
      })
      .catch(error=>{
        if(error.response.status == 404){
            response = error.response
            alert('donne introuvable')
        }
      })
      return response;
}

export async function getVisiteInstallationFiles(request){
    let response;
    await axios({
        url:
        process.env.VUE_APP_URL_API_CLIENT + "getListVisiteInstallationBySocieteForProfilClient/", // a remplacer apres test
        method: "POST",
        data: {
            id_societe: request.id,
            pageNumber: request.pageNumber,
            length: request.length,
            token:store.getters.getUserToken,
        },
      }).then((res) => {
        if (res.data.status) {
          response = res.data.data;
        }
      });
      return response;
}

export async function getImagesSociete(request){
    let response;
    await axios({
        url:
        process.env.VUE_APP_URL_API_CLIENT + "getListSocietePhotoProfilClient/", // a remplacer apres test
        method: "POST",
        data: {
            id_societe: request.id,
            pageNumber: request.pageNumber,
            length: request.length,
            token:store.getters.getUserToken, // A REMPLACER
        },
      }).then((res) => {
        if (res.data.status) {
            response = res.data.data
        }
      });
      return response;
}

export async function getVisiteTechnique(request){
  let response;
  await axios({
      url:
      process.env.VUE_APP_URL_API_CLIENT + "getListImageVisiteTechnique/", // a remplacer apres test
      method: "POST",
      data: {
          id_societe: request.id,
          pageNumber: request.pageNumber,
          length: request.length,
          id_visite_technique: request.id_vt,
          type_question: request.name,
          token:store.getters.getUserToken, // A REMPLACER
      },
    }).then((res) => {
      if (res.data.status) {
          response = res.data.data
      }
    });
    return response
}
export async function getImagesVisiteInstallation(request){
    let response;
    await axios({
        url:
        process.env.VUE_APP_URL_API_CLIENT + "getListImageVisiteInstallation/", // a remplacer apres test
        method: "POST",
        data: {
            id_societe: request.id,
            pageNumber: request.pageNumber,
            length: request.length,
            id_visite_installation: request.id_visite_installation,
            type_question: request.name,
            token:store.getters.getUserToken, // A REMPLACER
        },
      }).then((res) => {
        if (res.data.status) {
            response = res.data.data
        }
      });
      return response
}
