<template>
  <div class="mb-4">
    <div class="d-flex flex-row">
      <div class="card card-body">
        <div>
          <div class="card-title mb-0 pa-0">
            <span style="font-weight: 400">Informations Société</span>
          </div>
        </div>
        <!-- DATA TABLE FOR VISITE -->
        <hr />
        <div class="d-flex flex-row justify-space-between">
          <div class="py-0">
            <p class="mb-0">Raison Sociale</p>
          </div>
          <div class="pa-0 d-flex flex-row justify-center align-items-center">
            <p style="font-size: 0.9rem" class="text-muted mb-0">
              {{ dataProps.raison }}
            </p>
          </div>
        </div>
        <hr />

        <div class="d-flex flex-row justify-space-between">
          <div class="py-0">
            <p class="mb-0">Adresse</p>
          </div>
          <div class="pa-0 d-flex flex-row justify-center align-items-center">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <p
                  v-bind="attrs"
                  v-on="on"
                  style="font-size: 0.9rem"
                  class="text-muted mb-0"
                >
                  {{ dataProps.adressePostal?.substring(0, 25) }}...
                </p>
              </template>
              <span>{{ dataProps.adressePostal }}</span>
            </v-tooltip>
          </div>
        </div>
        <hr />
        <div class="d-flex flex-row justify-space-between">
          <div class="py-0">
            <p class="mb-0">Code postal</p>
          </div>
          <div class="pa-0 d-flex flex-row justify-center align-items-center">
            <p style="font-size: 0.9rem" class="text-muted mb-0">
              {{ dataProps.codePostal }}
            </p>
          </div>
        </div>
        <hr />
        <div class="d-flex flex-row justify-space-between">
          <div class="py-0">
            <p class="mb-0">N° Téléphone</p>
          </div>
          <div class="pa-0 d-flex flex-row justify-center align-items-center">
            <p style="font-size: 0.9rem" class="text-muted mb-0">
              {{ dataProps.numTele }}
            </p>
          </div>
        </div>
        <hr />
        <div class="d-flex flex-row justify-space-between">
          <div class="py-0">
            <p class="mb-0">Site Web</p>
          </div>
          <div class="pa-0 d-flex flex-row justify-center align-items-center">
            <p style="font-size: 0.9rem" class="text-muted mb-0">?</p>
          </div>
        </div>
        <hr />
        <div class="d-flex flex-row justify-space-between">
          <div class="py-0">
            <p class="mb-0">Ville</p>
          </div>
          <div class="pa-0 d-flex flex-row justify-center align-items-center">
            <p style="font-size: 0.9rem" class="text-muted mb-0">
              {{ dataProps.commune }}
            </p>
          </div>
        </div>
        <hr />
        <div class="d-flex flex-row justify-space-between">
          <div class="py-0">
            <p class="mb-0">Mail générique</p>
          </div>

          <div class="pa-0 d-flex flex-row justify-center align-items-center">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <p
                  v-bind="attrs"
                  v-on="on"
                  style="font-size: 0.9rem"
                  class="text-muted mb-0"
                >
                  {{ dataProps.adresseEmail?.substring(0, 20) }}...
                </p>
              </template>
              <span>{{ dataProps.adresseEmail }}</span>
            </v-tooltip>
          </div>
        </div>
        <hr />
      </div>
      <div style="width: 45%" class="card card-body ml-4">
        <div>
          <div class="card-title mb-0 pa-0">
            <span style="font-weight: 400">Immatriculation</span>
          </div>
        </div>
        <!-- DATA TABLE FOR VISITE -->
        <hr />
        <div class="d-flex flex-row justify-space-between">
          <div class="py-0">
            <p class="mb-0">N° Siret</p>
          </div>
          <div class="pa-0 d-flex flex-row justify-center align-items-center">
            <p style="font-size: 0.9rem" class="text-muted mb-0">
              {{ dataProps.siret }}
            </p>
          </div>
        </div>
        <hr />
        <div class="d-flex flex-row justify-space-between">
          <div class="py-0">
            <p class="mb-0">Code APE/NAF</p>
          </div>

          <div class="pa-0 d-flex flex-row justify-center align-items-center">
            <p style="font-size: 0.9rem" class="text-muted mb-0">
              {{ dataProps.codeNaf }}
            </p>
          </div>
        </div>
        <hr />
        <div class="d-flex flex-row justify-space-between">
          <div class="py-0 d-flex flex-row align-items-center">
            <p class="mb-0">N° TVA</p>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon class="ml-2" color="primary" v-bind="attrs" v-on="on" small
                  >mdi-help-circle-outline</v-icon
                >
              </template>
              <span>N° TVA Intracommunautaire</span>
            </v-tooltip>
          </div>

          <div class="pa-0 d-flex flex-row justify-center align-items-center">
            <p style="font-size: 0.9rem" class="text-muted mb-0">
              {{ dataProps.TVA }}
            </p>
          </div>
        </div>
        <hr />
        <div class="d-flex flex-row justify-space-between">
          <div class="py-0 d-flex flex-row align-items-center">
            <p class="mb-0">VAT Number</p>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon class="ml-2" color="primary" v-bind="attrs" v-on="on" small
                  >mdi-help-circle-outline</v-icon
                >
              </template>
              <span>(foreign customer only)</span>
            </v-tooltip>
          </div>

          <div class="pa-0 d-flex flex-row justify-center align-items-center">
            <p style="font-size: 0.9rem" class="text-muted mb-0">?</p>
          </div>
        </div>
        <hr />
      </div>
    </div>
    <div class="d-flex flex-row mt-4">
      <div class="card card-body pa-0">
        <div
          class="d-flex flex-row justify-space-between align-items-center pa-2"
          style="
            background: linear-gradient(45deg, rgb(0, 121, 145) 0%, rgb(24, 174, 162) 68%)
              rgb(0, 121, 145);
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
          "
        >
          <div class="d-flex flex-row align-items-center" style="max-width: 100%">
            <v-select
              class="VSelect"
              style="max-width: 60%"
              dense
              outlined
              hide-details
              v-model="modelSelect"
              :items="listSelectProps"
              @change="boolEditRenseignement = false"
            >
              <template v-slot:selection="{ item }">
                <div
                  class="card-title mb-0 pa-0"
                  style="background-color: rgba(0, 0, 0, 0); min-width: 255px"
                >
                  <span v-if="item == 'ChefProjet'" style="font-weight: 400; color: white"
                    >Contact Commercial</span
                  >
                  <span
                    v-if="item == 'ResponsableInformatique'"
                    style="font-weight: 400; color: white"
                    >Responsable Informatique</span
                  >
                  <span
                    v-if="item == 'ResponsableTechnique'"
                    style="font-weight: 400; color: white"
                    >Responsable Technique</span
                  >
                  <span
                    v-if="item == 'ContactComptabilite'"
                    style="font-weight: 400; color: white"
                    >Contact Comptabilite</span
                  >
                </div>
              </template>
              <template v-slot:item="{ item }">
                <span v-if="item == 'ChefProjet'" style="font-weight: 400"
                  >Contact Commercial</span
                >
                <span v-if="item == 'ResponsableInformatique'" style="font-weight: 400"
                  >Responsable Informatique</span
                >
                <span v-if="item == 'ResponsableTechnique'" style="font-weight: 400"
                  >Responsable Technique</span
                >
                <span v-if="item == 'ContactComptabilite'" style="font-weight: 400"
                  >Contact Comptabilite</span
                >
              </template>
            </v-select>
            <v-checkbox
              v-model="contactUnique"
              class="mt-0 ml-4"
              hide-details
              color="white"
              label="Contact unique"
            ></v-checkbox>
          </div>
          <div style="height: 100%" class="d-flex flex-row">
            <v-btn
              icon
              class="white--text"
              @click="editRenseignementClient(modelSelect)"
              color="white"
              ><v-icon v-if="!boolEditRenseignement">mdi-pencil</v-icon>
              <v-icon v-else>mdi-pencil-off</v-icon>
            </v-btn>
            <v-btn
              :disabled="sameObject"
              outlined
              height="100%"
              class="ml-4"
              @click="updateInfos(modelSelect)"
              color="white"
              >Enregistrer</v-btn
            >
          </div>
          <!--<div class="card-title mb-0 pa-0">
            <h5>Chef de projet client</h5>
          </div>-->
        </div>
        <!-- DATA TABLE FOR VISITE -->
        <v-form class="my-4" ref="formEditInfos" v-if="!!listModel[modelSelect]">
          <v-row class="px-4">
            <v-col class="" cols="4">
              <div style="width: 100%" class="d-flex flex-row align-items-center pa-0">
                <v-tooltip :color="$colors[0]" bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      color="white"
                      style="max-height: 40px; max-width: 40px"
                      class="icon pa-1 ma-0 ml-4"
                      >mdi-card-account-details-outline</v-icon
                    >
                  </template>
                  <span class="Poppins-Bold">Nom</span>
                </v-tooltip>

                <p v-if="boolEditRenseignement == false" class="mb-0">
                  {{
                    listModel[modelSelect].nom?.length > 0
                      ? listModel[modelSelect].nom
                      : "?"
                  }}
                </p>
                <v-text-field
                  class="formTextField"
                  hide-details="auto"
                  v-else
                  outlined
                  :rules="[$rules.required('un nom')]"
                  dense
                  v-model="listModelEdit[modelSelect].nom"
                  placeholder="?"
                ></v-text-field>
              </div>
            </v-col>
            <v-col class="" cols="4">
              <div style="width: 100%" class="d-flex flex-row align-items-center pa-0">
                <v-tooltip :color="$colors[0]" bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      color="white"
                      style="max-height: 40px; max-width: 40px"
                      class="icon pa-1 ma-0 ml-4"
                      >mdi-card-account-details-outline</v-icon
                    >
                  </template>
                  <span class="Poppins-Bold">Prénom</span>
                </v-tooltip>

                <p v-if="boolEditRenseignement == false" class="mb-0">
                  {{
                    listModel[modelSelect].prenom?.length > 0
                      ? listModel[modelSelect].prenom
                      : "?"
                  }}
                </p>
                <v-text-field
                  class="formTextField"
                  hide-details="auto"
                  v-else
                  outlined
                  dense
                  :rules="[$rules.required('un prenom')]"
                  v-model="listModelEdit[modelSelect].prenom"
                  placeholder="?"
                ></v-text-field>
              </div>
            </v-col>
            <v-col class="" cols="4">
              <div style="width: 100%" class="d-flex flex-row align-items-center pa-0">
                <v-tooltip :color="$colors[0]" bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      color="white"
                      style="max-height: 40px; max-width: 40px"
                      class="icon pa-1 ma-0 ml-4"
                      >mdi-domain</v-icon
                    >
                  </template>
                  <span class="Poppins-Bold">Fonction</span>
                </v-tooltip>

                <p v-if="boolEditRenseignement == false" class="mb-0">
                  {{
                    listModel[modelSelect].fonction?.length > 0
                      ? listModel[modelSelect].fonction
                      : "?"
                  }}
                </p>
                <v-text-field
                  class="formTextField"
                  hide-details="auto"
                  v-else
                  outlined
                  dense
                  :rules="[$rules.required('une fonction')]"
                  v-model="listModelEdit[modelSelect].fonction"
                  placeholder="?"
                ></v-text-field>
              </div>
            </v-col>
          </v-row>
          <hr />
          <v-row class="px-4 py-2">
            <v-col class="" cols="4">
              <div style="width: 100%" class="d-flex flex-row align-items-center pa-0">
                <v-tooltip :color="$colors[0]" bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      color="white"
                      style="max-height: 40px; max-width: 40px"
                      class="icon pa-1 ma-0 ml-4"
                      >mdi-phone-outline</v-icon
                    >
                  </template>
                  <span class="Poppins-Bold">Téléphone</span>
                </v-tooltip>

                <p v-if="boolEditRenseignement == false" class="mb-0">
                  {{
                    listModel[modelSelect].telephone?.length > 0
                      ? listModel[modelSelect].telephone
                      : "?"
                  }}
                </p>
                <v-text-field
                  hide-details="auto"
                  v-else
                  class="formTextField"
                  outlined
                  dense
                  :rules="[$rules.required('une téléphone')]"
                  v-model="listModelEdit[modelSelect].telephone"
                  placeholder="?"
                ></v-text-field>
              </div>
            </v-col>
            <v-col class="" cols="4">
              <div style="width: 100%" class="d-flex flex-row align-items-center pa-0">
                <v-tooltip :color="$colors[0]" bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      color="white"
                      style="max-height: 40px; max-width: 40px"
                      class="icon pa-1 ma-0 ml-4"
                      >mdi-at</v-icon
                    >
                  </template>
                  <span class="Poppins-Bold">Mail</span>
                </v-tooltip>

                <p v-if="boolEditRenseignement == false" class="mb-0">
                  {{
                    listModel[modelSelect].email?.length > 0
                      ? listModel[modelSelect].email
                      : "?"
                  }}
                </p>
                <v-text-field
                  hide-details="auto"
                  v-else
                  class="formTextField"
                  outlined
                  dense
                  :rules="[$rules.required('un email')]"
                  v-model="listModelEdit[modelSelect].email"
                  placeholder="?"
                ></v-text-field>
              </div>
            </v-col>

            <v-col cols="4"></v-col>
          </v-row>
        </v-form>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "@/plugins/axios";

export default {
  name: "InfoSociete",
  props: ["dataProps", "listSelectProps", "listModelProps"],
  data() {
    return {
      formEditInfos: null,
      societeId: null,
      sameObject: true,
      contactUnique: false,
      modelSelect: "Chef ",
      loaded: false,
      boolEditRenseignement: false,
      modelObject: { nom: "", prenom: "", fonction: "", email: "" },
      listModelEdit: { ...this.listModelProps },
      listModel: { ...this.listModelProps },
    };
  },
  created() {
    this.modelSelect = this.listSelectProps[0];
    if (Object.keys(this.listModel).length == 0) {
      this.listModel = { ...this.listModelProps };
    }
  },
  methods: {
    isEqualObjects(objet1, objet2) {
      const clesObjet1 = Object.keys(objet1);
      const clesObjet2 = Object.keys(objet2);

      if (clesObjet1.length !== clesObjet2.length) {
        return false;
      }

      for (let i = 0; i < clesObjet1.length; i++) {
        const cle = clesObjet1[i];

        if (typeof objet1[cle] === "object" && typeof objet2[cle] === "object") {
          if (!this.isEqualObjects(objet1[cle], objet2[cle])) {
            return false;
          }
        } else {
          if (objet1[cle] !== objet2[cle]) {
            return false;
          }
        }
      }

      return true;
    },
    async updateInfos(item) {
      if (
        this.isEqualObjects(this.listModelEdit[item], this.listModel[item]) == false &&
        this.$refs.formEditInfos.validate()
      ) {
        if (this.contactUnique) {
          this.listSelectProps.forEach(async (items) => {
            await axios({
              url: process.env.VUE_APP_URL_API_CLIENT + `updateInfos${items}/`,
              method: "POST",
              data: {
                societeId: this.dataProps.id,
                data: this.listModelEdit[item],
                token: this.$cookies.get("token"),
              },
            }).then((res) => {
              this.$set(this.listModel, items, { ...this.listModelEdit[item] });
              this.$set(this.listModelEdit, items, { ...this.listModelEdit[item] });
            });
          });
        } else {
          await axios({
            url: process.env.VUE_APP_URL_API_CLIENT + `updateInfos${item}/`,
            method: "POST",
            data: {
              societeId: this.dataProps.id,
              data: this.listModelEdit[item],
              token: this.$cookies.get("token"),
            },
          }).then((res) => {
            this.$set(this.listModel, item, { ...this.listModelEdit[item] });
            this.$emit("newListModel", this.listModel);
          });
        }
        this.boolEditRenseignement = false;
        this.contactUnique = false;
        this.sameObject = true;
      }
    },
    editRenseignementClient(keySelect) {
      this.boolEditRenseignement = !this.boolEditRenseignement;
      this.listModelEdit[keySelect] = { ...this.listModel[keySelect] };
      console.log(this.boolEditRenseignement);
      if (this.boolEditRenseignement == false) {
        this.sameObject = true;
      }
    },
  },
  watch: {
    listModelProps: {
      handler(newValue, oldValue) {
        this.listModel = { ...newValue };
      },
      deep: true,
    },
    listModelEdit: {
      handler(newValue) {
        this.sameObject = this.isEqualObjects(newValue, this.listModel);
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
.breadcrumb {
  background-color: #ffffff !important;
}

.containe_comp {
  background-color: #f2f2f2 !important;
  border-radius: 10px;
  padding: 50px 70px;
  font-size: 14px;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, PingFang SC, Hiragino Sans GB,
    Microsoft YaHei, Helvetica Neue, Helvetica, Arial, sans-serif, Apple Color Emoji,
    Segoe UI Emoji, Segoe UI Symbol;
  font-variant: tabular-nums;
  line-height: 1.5;
  font-feature-settings: "tnum";
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 0 solid rgba(0, 0, 0, 0.125);
}

.body-header {
  color: #192946;
}

.icon {
  width: 58px;
  height: 58px;
  top: 0;
  bottom: 0;
  right: 16px;
  background: linear-gradient(45deg, rgb(0, 121, 145) 0%, rgb(24, 174, 162) 68%)
    rgb(0, 121, 145);
  border-radius: 7px;
  box-shadow: 2px 4px 6px rgba(0, 0, 0, 0.12);
  justify-content: center;
  align-items: center;
  padding: 1px 5px;
}

.text-button {
  color: #192946;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.2;
  letter-spacing: -0.02em;
  text-align: center;
  margin-bottom: 0;
  margin-top: 0;
  margin-left: 10px;
}

.text-button:hover {
  color: #1e3766;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.2;
  letter-spacing: -0.02em;
  text-align: center;
  margin-bottom: 0;
  margin-top: 0;
  margin-left: 10px;
  text-decoration: underline;
}

.bgc-primary {
  background-color: #396c99;
}

.bgc-primary:hover {
  background-color: #1e4c66;
}

.bgc-success {
  background-color: #2fb8ac;
}

.bgc-warning {
  background-color: #868685;
}

.card-title {
  padding: 0.45rem 0.825rem;
  margin-bottom: 10;
  background-color: #ffffff;
  font-weight: 700;
  line-height: 1;
  letter-spacing: -0.02em;
  font-size: 1.25rem;
  color: #1d3258;
}

.card-header {
  background-color: #ffffff !important;
  border-bottom: 1px solid #e6e6f2 !important;
}

#table-validation-cotation th {
  background-color: #103a5e;
  color: white;
  font-weight: bold;
}

.v-application ul {
  padding-left: 10px;
}

.info-title {
  font-size: 15px;
  font-weight: 700;
  line-height: 1.2;
  letter-spacing: 0.02em;
  margin-bottom: 0;
}

@media only screen and (max-width: 400px) {
  .container-div {
    width: 125px !important;
    padding: 0px;
    margin: 0;
  }
}

.small-status {
  width: max-content;
}
::v-deep .v-input--selection-controls .v-input__slot > .v-label,
.v-input--selection-controls .v-radio > .v-label {
  margin-bottom: 0px;
}
::v-deep [data-v-07234524] .v-input--selection-controls .v-input__slot > .v-label,
.v-input--selection-controls .v-radio > .v-label[data-v-07234524] {
  color: white;
}
::v-deep .v-input__icon.v-input__icon--append > i {
  color: white;
}
::v-deep .v-input--selection-controls__input .v-icon {
  color: white;
}
::v-deep .VSelect.v-text-field--outlined fieldset {
  border: 0px solid white;
}
::v-deep .formTextField.v-text-field--outlined fieldset {
  border-color: #007991;
}
::v-deep .v-input__icon.v-input__icon--append > i::before {
  color: white !important;
}
</style>
