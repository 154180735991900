import axios from "@/plugins/axios";
export  async function whoIAm (token) {
    var formData = new FormData()
    formData.append('token', token)
    var typeUser = "";
    await axios({
        url:
        process.env.VUE_APP_URL_API_CLIENT +
        'whoIam/',
        method: 'POST',
        data: formData,
    })
    .then((res) => {
      typeUser =  res.data.result
    }).catch((err)=>{
      console.log(err)
      typeUser = "noToken"
    })
    return typeUser
  }

