<template>
            <v-dialog
                v-model="dialogData"
                persistent
                max-width="500"
                >
                <v-card>
                    <v-card-title class="title-loader text-h5">
                    {{titreData}}
                    </v-card-title>
                    <v-card-text>
                        <v-alert v-if="error==true && loading==false" type="error">
                            {{messageDialogeError}}
                        </v-alert>
                        <v-alert v-if="error==false && loading==false" type="success">
                            {{messageDialogeSucces}}
                        </v-alert>
                        <v-flex  class="text-center">
                            <v-progress-circular v-if="loading==true"
                                :size="150"
                                :width="7"
                                color="primary"
                                indeterminate
                                >
                                Patientez  ...
                            </v-progress-circular>
                        </v-flex>
                    </v-card-text>
                    <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn v-if="loading==false"
                        color="green darken-1"
                        text
                        @click="$emit('closeDialog')"
                    >
                        FERMER
                    </v-btn>
                    <v-btn v-if="error && typeDemande=='ELEC'"
                        color="blue darken-1" text
                        @click="$emit('modifierAnyway')"
                    >
                        FERMER
                    </v-btn>
                    <v-btn v-if="error && typeDemande=='GAZ'"
                        color="blue darken-1" text
                        @click="$emit('modifierAnyway')"
                    >
                        FERMER
                    </v-btn>                                        
                    </v-card-actions>
                </v-card>
            </v-dialog>
</template>

<script>
    export default {
    name: 'dialogeModal',
    props: ["titre","messageDialogeError","messageDialogeSucces","loading","error","dialog","typeDemande"],
    data(){
        return {
                dialogData: this.dialog,
                titreData:this.titre,
        }
    },
    }

</script>
<style scoped>
 @media only screen and (max-width:500px){
   .title-loader {
     font-size:1em !important;

  }
 }
</style>
