<template>
  <div class="wrapper">
    <v-card color="white" class="rounded-lg" flat>
      <v-sheet height="700" class="py-1 px-5">
        <div class="header-container" style="display: flex; flex-direction: row; align-items: center;">
          <div class="arrow-btns-wrapper" style="text-align: left;">
            <button class="nav" @click="goToPrevDay"><span class="mdi mdi-chevron-left"></span></button>
            <button class="nav" @click="goToNextDay"><span class="mdi mdi-chevron-right"></span></button>
          </div>
          <h2 class="vt-title">{{ formatDate(currentDate) }}</h2>
        </div>

        <div id="visualization"></div>
        <div id="calendar">
        </div>
      </v-sheet>
    </v-card>
    <!-- the drag edit modal  -->
    <eventDragModal :isDraged="isDragEvent" :eventDraged="dragedEvent" @dragEditedEvent="dragWellEditedEvent" @closeModalDragEvent="closeEtAnnuler"/>

    <!--end  the drag edit modal  -->
    </div>
  </template>
  
  <script>
  import { Timeline } from 'vis-timeline/standalone';
  import dayjs from "@/utils/dayjsConfig";
  import eventDragModal from './modal/eventDragModal.vue'
  import axios from "@/plugins/axios";
  
  export default {
    name: 'insTimeLineDay',
    components: {
      eventDragModal,
    },
    props:{
        events: {
            type: Array,
            required: true,
        },
        groups: {
            type: Array,
            required: true,
        },
        selectDay: {
            type: String,
            required: true,
        },
    },
    data() {
      return {
        isDragEvent: false,
        isCluster: false,
        isGroup: true,
        modifiedEvents: [],
        modifiedGroups: [],
        currentDate: dayjs(this.selectDay).format('YYYY-MM-DD HH:mm'),
        initialeDate : this.selectDay,
        selectedDate: "2023-06-01",
        dragedEvent: {
            id: "",
            title: "",
            oldStart: "",
            oldEnd: "",
            newStart: "",
            newEnd: "",
        },
        dateoption :[
            '2023-06-01',
            '2023-06-02',
            '2023-06-03',
        ],
        listGroups: [
          
        ],
        
        // timeline options
        options: {
          orientation: 'top',
          itemsAlwaysDraggable: true,
          groupOrder: "content", // groupOrder can be a property name or a sorting function,
          groupEditable: true,
          template: function (item, element, data) {
            if(this.isCluster){
              return `
              <div class="vis-item-content" style="background-color: ${item.color};">
                <div class="vis-item-title">${data.items.length} plus</div>
               `
            }else{

              return `
              <div class="vis-item-content" style="background-color: ${item.color};">
                <div class="vis-item-title">${item.title}</div>
                <div class="vis-item-content"></div>
              </div>`
            }
          },
          timeAxis: { 
            scale: 'hour', 
            step: 1 ,
            
             },
             margin: {
              item: 10,
              axis: 5,
              },
          stack: true,
          zoomMax: 3600000 * 26, //3600000 zoom hour
          // initiale:
          zoomMin : 3600000 * 20,
          minHeight: '600px',
          start: this.selectDay,
          end: dayjs(this.selectDay).add(15,'hour').format('YYYY-MM-DD HH:mm'),
          showCurrentTime: true,
          editable: {
            add: false, // add new items by double tapping
            updateTime: true, // drag items horizontally
            updateGroup: false, // drag items from one group to another
            remove: false, // delete an item by tapping the delete button top right
            overrideItems: false,
            //dragAxis: 'x',
          },
          // tooltip:
          tooltip: {
            followMouse: true,
            overflowMethod: 'cap',
            delay: 100,
            template: function (item, element, data) {
               function formatHour (date) {
                  return dayjs(date).format('HH:mm');
                }
              return `
              <div class="vis-item-content" style="background-color: ${item.color};width:200px !important">
                <div class="vis-item-content"> ${item.title} : ${formatHour(item.start)} - ${formatHour(item.end)}</div>
                <div class="vis-item-content">${item.description.substring(0,10)+'...'}</div>
              </div>`
            },
          },
          onUpdate: function (item, callback) {
          },
          onMove: function (item, callback) {
            // the eventDragModal will be open
            this.isDragEvent = true;
            this.dragedEvent.id = item.id
            this.dragedEvent.group = item.group
            this.dragedEvent.title = item.title
            this.dragedEvent.dateDebut= item.dateDebut
            this.dragedEvent.dateFin= item.dateFin
            this.dragedEvent.timeStart= dayjs(item.start).format('HH:mm')
            this.dragedEvent.timeEnd= dayjs(item.end).format('HH:mm')
            this.dragedEvent.start= dayjs(item.start).format('YYYY-MM-DD')
            this.dragedEvent.end= dayjs(item.end).format('YYYY-MM-DD')
            this.dragedEvent.owner= item.owner
            this.dragedEvent.description= item.description
            this.dragedEvent.color= item.color
            this.dragedEvent.allDay= item.allDay
          }.bind(this),

        },
        dataLoaded: null,
        timeline: null,
      };
    },
    methods: {
        async closeEtAnnuler(){
          this.$emit('annulerLaModification');
          this.timeline.setItems(this.modifyEvents());
          this.isDragEvent = false;
        },
        async dragWellEditedEvent(editedEvent){
          var event ={
              id: editedEvent.id,
              title: editedEvent.title,
              dateDebut: dayjs(new Date(editedEvent.start)).format('YYYY-MM-DD'),
              dateFin: dayjs(new Date(editedEvent.end)).format('YYYY-MM-DD'),
              timeStart: editedEvent.timeStart,
              timeEnd: editedEvent.timeEnd,
              start:  editedEvent.start,
              end:  editedEvent.end,
              owner: editedEvent.owner,
              description: editedEvent.description,
              group: editedEvent.group,
              color: editedEvent.color,
              allDay: editedEvent.allDay,
          };
          this.$emit('miseAjourEffectuer', event);
          //await this.editEvent(event)
          this.isDragEvent = false;

        },
        async editEvent(newEvent){
          var index = this.events.findIndex(item => item.id == newEvent.id)
          if (index != -1){
            this.events[index].title = newEvent.title
            this.events[index].dateDebut= newEvent.dateDebut
            this.events[index].dateFin= newEvent.dateFin
            this.events[index].timeStart= newEvent.timeStart
            this.events[index].timeEnd= newEvent.timeEnd
            this.events[index].start= dayjs(new Date(newEvent.dateDebut + " "+ newEvent.timeStart)).format('YYYY-MM-DD HH:mm')
            this.events[index].end= dayjs(new Date(newEvent.dateFin + " "+ newEvent.timeEnd)).format('YYYY-MM-DD HH:mm')
            this.events[index].owner= newEvent.owner
            this.events[index].description= newEvent.description
            this.events[index].group= newEvent.group
            this.events[index].color= newEvent.color
            this.events[index].allDay= newEvent.allDay
          }
          this.timeline.setItems(this.modifyEvents());
        },
        goToNextDay() {
            const nextDay = new Date(this.currentDate);
            this.currentDate = dayjs(nextDay.setDate(nextDay.getDate() + 1)).format('YYYY-MM-DD HH:mm');
            this.options.start= this.currentDate
            this.options.end= dayjs(this.currentDate).add(15,'hour').format('YYYY-MM-DD HH:mm')
            this.timeline.setOptions(this.options);
            
            var dateDebut = dayjs(this.currentDate).format('YYYY-MM-01')
            var dateFin = dayjs(dateDebut).add(1, 'month').format('YYYY-MM-DD')
            this.$emit('rechargeEvents', {dateDebut:dateDebut, dateFin:dateFin});
            
        },
        goToPrevDay() {
            const nextDay = new Date(this.currentDate);
            this.currentDate = dayjs(nextDay.setDate(nextDay.getDate() - 1)).format('YYYY-MM-DD HH:mm');
            this.options.start= this.currentDate
            this.options.end= dayjs(this.currentDate).add(15,'hour').format('YYYY-MM-DD HH:mm')
            this.timeline.setOptions(this.options);
            var dateDebut = dayjs(this.currentDate).format('YYYY-MM-01')
            var dateFin = dayjs(dateDebut).add(1, 'month').format('YYYY-MM-DD')
            this.$emit('rechargeEvents', {dateDebut:dateDebut, dateFin:dateFin});
        },
        selectInitailDate(){
            const selectedDate = new Date(this.selectedDate);
            const startDate = new Date(selectedDate);
            startDate.setHours(0, 0, 0, 0); // Set time to the beginning of the day
            const endDate = new Date(selectedDate);
            endDate.setHours(23, 59, 59, 999); // Set time to the end of the day
            this.options.start = startDate;
            this.options.end = endDate
            // Update the timeline
            this.updateTimeline();
        },
        updateTimeline() {
          //destroy timeline
          this.timeline.destroy();
          // Create a Timeline
          this.timeline = new Timeline(document.getElementById('visualization'));
          this.timeline.setOptions(this.options);
          this.timeline.setGroups(this.groups);
         // this.timeline.setItems(this.items);
        },
        async handleMouseOut(event){
          console.log("uuu")
          await this.closeEtAnnuler()
        },
        modifyEvents() {
          const modifiedEvents = this.events.map((event, index) => {
              const idgroup = this.getGroupId(event.owner);
              return {
                id: event.id,
                group: idgroup,
                owner: event.owner,
                content: event.title,
                title: event.title,
                dateDebut: event.dateDebut,
                dateFin: event.dateFin,
                timeStart: event.timeStart,
                timeEnd: event.timeEnd,
                start: event.start,
                end: event.end,
                description: event.description,
                color: event.color,
                allDay: event.allDay,
                style: "background-color: "+event.color+";",
              };
          });
          return modifiedEvents;
        },
    
        modifyGroups() {
           return this.modifiedGroups = this.groups.map((group, index) => {
            return {
                id: group.id,
                content: group.name,
                className: 'vis-h0',
               
                };
            });
        },
        getGroupId(groupName) {
            const group = this.groups.find(group => group.name === groupName);
            return group ? group.id : null;
        },
        handleDragEvent(item) {
            this.isDragEvent = true;
            
        },
        formatDate(date) {
            
            // in langage french
            dayjs.locale('fr');
            return dayjs(date).format('MMMM D, YYYY');
       
        },
        showCluster(){
         if (this.isCluster == false){
          this.isCluster = true
          this.options.cluster = true
          this.timeline.setOptions(this.options);
          }else{
            this.isCluster = false
            this.options.cluster = false
            this.timeline.setOptions(this.options);
          }
        },
        showGroup(){
          if (this.isGroup == false){
            this.isGroup = true
            this.options.groupOrder = 'content'
            this.timeline.setGroups(this.modifyGroups());
            this.timeline.setOptions(this.options);
            }else{
              this.isGroup = false
              this.options.groupOrder = 'content'
              this.timeline.setGroups(null);
              this.timeline.setOptions(this.options);
            }
        },
    },
     mounted() {
      console.log(this.events)
      console.log(this.modifyEvents())
         this.dataLoaded;
        // Create a Timeline
        this.modifiedGroups = this.modifyGroups();
        this.timeline = new Timeline(document.getElementById('visualization'));
        this.timeline.setOptions(this.options);
        this.timeline.setItems(this.modifyEvents());
        this.timeline.setGroups(this.modifiedGroups);
     
    },
    watch: {
        //update the modified events went events change
        events: function (newEvents) {
            this.timeline.setGroups(this.modifyGroups());
            this.timeline.setItems(this.modifyEvents());
        },
        //update the modified groups went groups change
        groups: function (newGroups) {
            this.timeline.setGroups(this.modifyGroups());
        },
    },
  };
  </script>
  
  <style>
  .header-container{
      margin-top: 14px;
      margin-bottom: 1.5em;
    }
      .arrow-btns-wrapper {
        display: flex;
        align-items: center;
        flex-direction: row;
        color: #070707;
        font-weight: bold;

      }
      .vt-title{
        font-family: "Poppins";
        font-size: 32px;
        color: #020f47;
        flex-grow: 1; 
        text-align: right;
        font-weight: 500 !important;
        line-height: 1.2 !important;
        margin: 0px !important;
      }
      .arrow-btns-wrapper .nav {
        background: #e8f2ff !important;
        border: none !important;
        border-radius: 4px !important;
        margin-right: 8px !important;
        padding: 4px 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        height: 45px;
      }
      .arrow-btns-wrapper .nav span{
        color: #26222f !important;
        font-size: 24px !important;
        font-weight: 600;
        text-transform: capitalize;
      }
         .vis-time-axis .vis-grid.vis-odd {
        background: #f3f6f9 !important;
        border-collapse: separate;
        border-spacing: 0;
        border-right: 1px solid #e2e2e2 !important;
        border-top: 0px !important ;
        border-bottom: 0px;
      }
      .vis-time-axis .vis-grid.vis-saturday,
      .vis-time-axis .vis-grid.vis-sunday {
        background: rgb(29, 201, 135) !important;
      }
      .vis-time-axis .vis-text.vis-saturday,
      .vis-time-axis .vis-text.vis-sunday {
        color: rgb(206, 211, 214) !important;
      
      }
      .vis-time-axis .vis-text{
        font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
        font-size: .995rem !important;
        font-weight: 500 !important;
        padding: 0.70rem;
        color: #13a592 !important;
        border-top: #e2e2e2 1px solid;
        border-bottom: #e2e2e2 1px solid; 
      }
      .vis-item {
        background-color: #f2f2f2;
        border-color: #e2e2e2;
        color: #fff !important;
        font-weight: 500;
        opacity: 1.8;
        font-size: 13px;
        height: 35px;
        border-radius: 8px !important;
        border: 3px solid #ffffff;
      }
  
      .vis-cluster {
        display: flex;
        align-items: center;
        background-color: #3f8d9b;
        color: #ffffff;
        font-weight: 500;
        font-size: 13px;
        border-radius: 8px !important;
        height: 25px;

        justify-content: center;
  }
      .vis-h0 .vis-h01 .vis-h15 .vis-h16 {
        color: #2c3e50 !important;
        height: 100px;
        text-align: center;
        font-weight: bold;
      }
      .vis-text {
        color: #7f8a8a !important;
        text-align: center;

      }
      .vis-label {
        color: #020f47 !important;
        text-align: center;

      }
      .vis-text .vis-major .vis-h15  .vis-odd{
        color: #7f8a8a !important;
        text-align: center;     
      }
      .vis-timeline {
        margin-top: 3px;
        background-color: #ffffffce;
        border-collapse: separate;
        border-spacing: 0;
        border: 1px solid #e2e2e2 !important;
        border-radius: 0.2rem;      
      }
      .vis-time-axis .vis-foreground{
        background:linear-gradient(45deg, rgb(0, 121, 145) 0%, rgb(24, 174, 162) 68%) rgb(0, 121, 145) !important;
      }
      .vis-major{
      
        border-bottom: 0px !important;
        border-top: 0px !important;

      }
      .vis-inner{
        font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif !important;
        font-size: .975rem !important;
        font-weight: 500 !important;
        color: #020f47;
        text-transform: capitalize;
      }
    .vis-panel.vis-center, .vis-panel.vis-left, .vis-panel.vis-right, .vis-panel.vis-top, .vis-panel.vis-bottom {
      border: 1px #e2e2e2 solid !important;
    }
    .option-label {
    margin-right: 10px; /* Adjust the spacing between the labels and checkboxes */
    color: rgb(3, 87, 104);
  }
  .custom-control-input:checked~.custom-control-label::before {
    border-color: #e2e2e2 !important;
    background-color: rgb(3, 87, 104) !important;

  }
  .custom-control-label::before {
    border-color: rgb(3, 87, 104) !important;
    background-color: rgb(255, 255, 255) !important;
  }
  .custom-control-input {
      position: absolute;
      z-index: -1;
      opacity: 0;
  }

  .vis-labelset .vis-label {
        border-bottom: 1px solid #e2e2e2;
    }
  

  </style>
  