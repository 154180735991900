<template>
  <div class="ma-4">
    <v-card class="p-4" elevation="10">
      <!-- START add vendeurs -->
      <v-dialog persistent v-model="dialog" max-width="600px" style="z-index: 1050;">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            :style="{ background: $styles.background }"
            dark
            class="mb-2 text-white"
            v-bind="attrs"
            v-on="on"
          >
            <span class="Poppins-Bold"
              >Ajouter un Compte<v-icon class="ml-2">mdi-account-plus</v-icon></span
            >
          </v-btn>
        </template>

        <v-card>
          <v-toolbar :style="{ background: $styles.background }" dark>
            <span class="Poppins-Bold pl-0">{{ formTitle }}</span>
          </v-toolbar>

          <v-card-text>
            <p style="font-size: 14px; color: red" v-if="compteExist">
              Ce compte existe déjà
            </p>
            <v-container class="ml-0">
              <v-form ref="formVendeur" lazy-validation>
                <v-row class="mr-0">
                  <!-- nom -->
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      v-model="editedItem.nom"
                      :rules="[rules.required, rules.isString]"
                      label="Nom"
                    ></v-text-field>
                  </v-col>
                  <!-- prenom -->
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      v-model="editedItem.prenom"
                      :rules="[rules.required, rules.isString]"
                      label="Prénom"
                    ></v-text-field>
                  </v-col>
                  <!-- email -->
                  <v-col cols="12">
                    <v-text-field
                      :disabled="editedItemId > -1"
                      v-model="editedItem.email"
                      :rules="[rules.emailRules]"
                      label="Email"
                    ></v-text-field>
                  </v-col>
                  <!-- Numéro de téléphone -->
                  <v-col cols="12">
                    <v-text-field
                      type="number"
                      v-model="editedItem.telephone"
                      :rules="[rules.required, rules.phoneCorrect]"
                      label="Numéro de téléphone"
                    ></v-text-field>
                  </v-col>
                  <!--<v-col cols="12" sm="6" md="6">
                    <v-select
                      :items="societes"
                      :rules="[rules.required]"
                      v-model="editedItem.societeGroupe"
                      label="Societe Groupe"
                    ></v-select>
                  </v-col>-->

                  <!-- Type Compte -->
                  <v-col cols="12" sm="6" md="6">
                    <v-select
                      v-if="typeUser == 'admin' || typeUser == 'superadmin'"
                      :items="typeVendeurAdmin"
                      :rules="[rules.required]"
                      v-model="editedItem.type"
                      label="Type Compte"
                    ></v-select>
                    <v-select
                      v-if="typeUser == 'ADV'"
                      :items="typeVendeur"
                      :rules="[rules.required]"
                      v-model="editedItem.type"
                      label="Type Compte"
                    ></v-select>
                    <v-select
                      v-if="typeUser == 'Chef équipe'"
                      :items="typeVendeurChef"
                      :rules="[rules.required]"
                      v-model="editedItem.type"
                      label="Type Compte"
                    ></v-select>
                  </v-col>
                  <!-- Mot de passe -->
                  <v-col cols="12" sm="6" md="6" v-if="this.editedItemId < 0">
                    <v-text-field
                      v-model="editedItem.password"
                      :append-icon="showpassword1 ? 'mdi-eye' : 'mdi-eye-off'"
                      :type="showpassword1 ? 'text' : 'password'"
                      :rules="[rules.required, rules.minPasswordLength]"
                      @click:append="showpassword1 = !showpassword1"
                      label="Mot de passe"
                    ></v-text-field>
                  </v-col>
                  <!-- équipe -->
                  <v-col cols="12" md="6" lg="6" xl="6">
                    <v-autocomplete
                      :rules="[required('une équipe')]"
                      :items="['superadmin','admin'].includes(typeUser) ? listeEquipeWithSolo : listeEquipe"
                      item-text="nomEquipe"
                      item-value="id"
                      v-model="editedItem.EquipeSelected"
                      label="équipe"
                    ></v-autocomplete>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" text @click="close('addVendeur')"> Annuler </v-btn>
            <v-btn
              :style="{ background: $styles.background }"
              class="white--text"
              @click="save"
            >
              <span class="Poppins-Bold">Enregistrer</span>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- END add vendeurs -->

      <!-- START modify vendeurs -->
      <v-dialog persistent v-model="dialogEdit" max-width="600px" style="z-index: 1050;">
        <v-card>
          <v-toolbar :style="{ background: $styles.background }" class="text-white" dark>
            <v-card-title
              class="d-flex justify-content-center align-items-center Poppins-Bold"
              >Modification du Compte</v-card-title
            >
          </v-toolbar>
          <v-card-text>
            <v-container class="ml-0">
              <v-form ref="formEdit" lazy-validation>
                <v-row class="mr-0">
                  <!-- nom -->
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      v-model="editedItem.nom"
                      :rules="[rules.required, rules.isString]"
                      label="Nom"
                    ></v-text-field>
                  </v-col>
                  <!-- prenom -->
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      v-model="editedItem.prenom"
                      :rules="[rules.required, rules.isString]"
                      label="Prénom"
                    ></v-text-field>
                  </v-col>
                  <!-- email -->
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      disabled
                      v-model="editedItem.email"
                      :rules="[rules.emailRules]"
                      label="Email"
                    ></v-text-field>
                  </v-col>
                  <!-- telephone -->
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      type="number"
                      v-model="editedItem.telephone"
                      :rules="[rules.required, rules.phoneCorrect]"
                      label="Numéro de téléphone"
                    ></v-text-field>
                  </v-col>
                  <!--<v-col cols="12"  lg='6' sm="6" md="6">
                    <v-select
                      :items="societes"
                      :rules="[rules.required]"
                      v-model="editedItem.societeGroupe"
                      label="Societe Groupe"
                    ></v-select>
                  </v-col>-->

                  <!-- Select Equipe -->
                  <v-col cols="12" sm="6" md="6" v-if="editedItem.type != 'ADV'">
                    <v-autocomplete
                      :rules="[required('une équipe')]"
                      :items="listeEquipeWithSolo"
                      item-text="nomEquipe"
                      item-value="id"
                      v-model="editedItem.EquipeSelected"
                      label="équipe"
                    ></v-autocomplete>
                  </v-col>
                  <!-- Type Compte -->
                  <v-col cols="12" sm="6" md="6">
                    <v-select
                      v-if="beforEditedItem.type == 'ADV'"
                      :items="typeRolesADVEdit"
                      :rules="[rules.required]"
                      v-model="editedItem.type"
                      label="Type Compte"
                    ></v-select>
                    <v-select
                      v-if="beforEditedItem.type == 'Chef équipe'"
                      :items="typeRolesChefEdit"
                      :rules="[rules.required]"
                      v-model="editedItem.type"
                      label="Type Compte"
                    ></v-select>
                    <v-select
                      v-if="beforEditedItem.type == 'chefIngenieur'"
                      :items="typeRolesChefIngenieurEdit"
                      :rules="[rules.required]"
                      v-model="editedItem.type"
                      label="Type Compte"
                    ></v-select>
                    <v-select
                      v-if="beforEditedItem.type == 'technicien'"
                      :items="typeRolesVendeurEdit"
                      :rules="[rules.required]"
                      v-model="editedItem.type"
                      label="Type Compte"
                    ></v-select>
                    <v-select
                      v-if="beforEditedItem.type == 'ingenieur'"
                      :items="typeRolesVendeurEdit"
                      :rules="[rules.required]"
                      v-model="editedItem.type"
                      label="Type Compte"
                    ></v-select>
                    <v-select
                      v-if="beforEditedItem.type == 'Prospecteur'"
                      :items="typeRolesProspecteurEdit"
                      :rules="[rules.required]"
                      v-model="editedItem.type"
                      label="Type Compte"
                    ></v-select>
                  </v-col>
                  <!-- Remplacent  -->
                  <v-col
                    cols="12"
                    sm="12"
                    md="12"
                  >
                    <v-autocomplete
                      v-if="
                        (editedItem.type == 'ADV' &&
                          beforEditedItem.type == 'Chef équipe') ||
                        (beforEditedItem.EquipeSelected !== editedItem.EquipeSelected &&
                          beforEditedItem.type == 'Chef équipe')
                      "
                      :rules="[required('un chef d\'équipe')]"
                      :items="listeChef"
                      item-text="userName"
                      item-value="id"
                      v-model="newManager"
                      label="Remplaçant de chef d'équipe"
                    >
                      <template v-slot:item="data">
                        <template>
                          <v-list-item-content>
                            <v-list-item-title>{{
                              data.item.userName
                            }}</v-list-item-title>
                            <v-list-item-subtitle>{{
                              data.item.type
                            }}</v-list-item-subtitle>
                          </v-list-item-content>
                        </template>
                      </template>
                    </v-autocomplete>
                    <!-- v-autocomplete to specify the new chef ingenieur that will replace the old one -->
                    <v-autocomplete
                      v-if="
                        (editedItem.type == 'ingenieur' &&
                          beforEditedItem.type == 'chefIngenieur') ||
                        (beforEditedItem.EquipeSelected !== editedItem.EquipeSelected &&
                          beforEditedItem.type == 'chefIngenieur')
                      "
                      :rules="[required('un chef ingénieur')]"
                      :items="listeChefIngenieur"
                      item-text="userName"
                      item-value="id"
                      v-model="newManager"
                      label="Remplaçant de chef ingénieur"
                    >
                      <template v-slot:item="data">
                        <template>
                          <v-list-item-content>
                            <v-list-item-title>{{
                              data.item.userName
                            }}</v-list-item-title>
                            <v-list-item-subtitle>{{
                              data.item.type
                            }}</v-list-item-subtitle>
                          </v-list-item-content>
                        </template>
                      </template>
                    </v-autocomplete>
                  </v-col>
                  <!-- select multiple équipes -->
                  <v-col
                    cols="12"
                    md="12"
                    lg="12"
                    xl="12"
                    v-if="editedItem.type == 'ADV'"
                  >
                    <v-autocomplete
                      v-model="editedItem.ADVEquipeSelected"
                      :items="listeEquipe"
                      :loading="isLoading"
                      hide-no-data
                      hide-selected
                      item-text="nomEquipe"
                      item-value="id"
                      label="Les équipes"
                      placeholder="Trouver des équipe"
                      prepend-icon="mdi-account-search-outline"
                      chips
                      deletable-chips
                      multiple
                      :rules="[rules.requiredSelect]"
                    >
                      <template v-slot:item="data">
                        <template>
                          <v-list-item-content>
                            <v-list-item-title>{{
                              data.item.nomEquipe
                            }}</v-list-item-title>
                            <v-list-item-subtitle v-if="data.item.chefEquipe">{{
                              data.item.chefEquipe.userName
                            }}</v-list-item-subtitle>
                          </v-list-item-content>
                        </template>
                      </template>
                    </v-autocomplete>
                  </v-col>

                </v-row>
              </v-form>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" text @click="close('modifyVendeur')"> Annuler </v-btn>
            <v-btn
              :style="{ background: $styles.background }"
              class="text-white"
              @click="edit"
            >
              Enregistrer
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- END modify vendeurs -->

      <!-- START Confirm delete -->
      <v-dialog persistent v-model="dialogDelete" max-width="640" style="z-index: 1050;">
        <v-card>
          <v-card-title class="text-h5"
            >Êtes-vous sûr de bien vouloir supprimer cette Compte ?</v-card-title
          >
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="deleteItemConfirm">Confirmer</v-btn>
            <v-btn color="blue darken-1" text @click="closeDelete">Annuler</v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- END Confirm delete -->

      <!-- START Modifier le mot de passe -->
      <v-dialog persistent v-model="dialogUpdatePassword" max-width="500px" style="z-index: 1050;">
        <v-card>
          <v-card-title>
            <span class="text-h5">Modifier le mot de passe</span>
          </v-card-title>
          <v-card-text>
            <p style="font-size: 14px; color: red" v-if="compteExist">
              Modifier le mot de passe
            </p>
          </v-card-text>
          <v-container>
            <v-form ref="form1" lazy-validation>
              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    v-model="newPassword"
                    :rules="[rules.required]"
                    label="nouveau mot de passe"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-form>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" @click="closeDialogUpdatePassword">
                Annuler
              </v-btn>
              <v-btn
                :style="{ background: $styles.background }"
                @click="updatePassword(idComptePasswordUpdate)"
              >
                Enregistrer
              </v-btn>
            </v-card-actions>
          </v-container>
        </v-card>
      </v-dialog>
      <!-- END Modifier le mot de passe -->

      <template>
        <div style="width: 100%;overflow-x: auto;">
          <datatable
            id="table-validation-cotation"
            :columns="columns" 
            :sortKey="sortKey" 
            :sortOrders="sortOrders" 
            @sort="sortBy" 
            @search="rechercheData"
          >
            <template v-slot:header>
              <div style="width: 200px" class="float-end container-div mr-2">
                <select
                  v-model="filterStatus"
                  class="form-control"
                  id="sel1"
                  @change="filterByStatus"
                >
                  <option value="tout">Toutes les demandes</option>
                  <option value="1">acceptée</option>
                  <option value="0">en attente</option>
                  <option value="2">refusée</option>
                </select>
              </div>
            </template>
            <template v-slot:body>
              <tbody
                class="text-center"
                v-if="dataRows.length > 0 && loadingTable == false"
              >
                <tr v-for="(item, index) in dataRows" :key="item.id">
                  <td>{{ item.nom }}</td>
                  <td>{{ item.prenom }}</td>
                  <td>{{ item.email }}</td>
                  <td>{{ item.type }}</td>
                  <td>{{ item.telephone }}</td>
                  <td>{{ item.hasEquipe }}</td>
                  <td>
                    <div class="d-flex justify-content-center align-items-center">
                      <v-btn icon :style="{ background: $styles.background }"
                        ><v-icon class="white--text" medium @click="editItem(item)"
                          >mdi-account-edit</v-icon
                        ></v-btn>
                    </div>
                  </td>
                </tr>
              </tbody>
              <noDataTable
                class="ma-4"
                v-if="dataRows.length == 0 && loadingTable == false"
              />
              <progressCircular class="ma-8" v-if="loadingTable == true" />
            </template>
          </datatable>
        </div>

        <pagination v-if="dataRows.length > 0" :pagination="pagination"
          @selectNumberOfRecords="rechercheData"
          @first="getData(pagination.firstPage)"
          @last="getData(pagination.lastPage)"
          @prev="getData(pagination.prevPageUrl)"
          @current="getData(pagination.currentPage)"
          @next="getData(pagination.nextPageUrl)"
        >
        </pagination>
      </template>
    </v-card>
  </div>
</template>

<script>
import Datatable from "../../components/dataTable/Datatable.vue";
import Pagination from "../../components/dataTable/Pagination.vue";
import noDataTable from "../../components/dataTable/no-data-table.vue"
import progressCircular from "../../components/dataTable/progress-circular.vue"
import axios from "@/plugins/axios";
import breadcrumb from "@/components/breadcrumb.vue";

export default {
  name: "Vendeurs",

  components: {
    datatable: Datatable, 
    pagination: Pagination,
    noDataTable,
    progressCircular,
    breadcrumb 
  },
  data() {
    let sortOrders={};
    let columns = [
      { width: "14vw", name: "nom", label: "Nom", sortable: true },
      { width: "14vw", name: "prenom", label: "Prénom", sortable: true },
      { width: "14vw", name: "email", label: "Adresse mail", sortable: true },
      { width: "14vw", name: "type", label: "Poste", sortable: true },
      { width: "14vw", name: "telephone", label: "Téléphone", sortable: true },
      { width: "14vw", name: "hasEquipe", label: "Statut", sortable: true },
      { width: "14vw", name: "actions", label: "Actions" },
    ];
    columns.forEach((column)=>{
      sortOrders[column.name] = -1;
    });
    return {
    isLoading: false,
    loadingTable: false,
    filter: null,
    sortDesc: true,
    filterStatus: 'tout',
    dataRows: [],
    columns: columns,
    sortKey: 'date',
    sortOrders: sortOrders,
    perPage: ['15', '20', '30'],
    tableData: {
      statusFilter: "",
      draw: 0,
      length: 15,
      search: '',
      column: 0,
      dir: 'desc',
    },
    pagination: {
      perPage: ['10', '15', '30'],
      firstPage: 1,
      lastPage: '',
      currentPage: '',
      total: '',
      lastPageUrl: '',
      nextPageUrl: '',
      prevPageUrl: '',
      from: '',
      to: ''
    },
    dialogUpdatePassword: false,
    newPassword: null,
    idComptePasswordUpdate: null,
    societes: [
      "Gaz De Bordeaux",
      "Gaz Elec Moinscher",
      "Global marketing Services",
      "Power Conseils",
      "SkyBlue",
      "Ekopro energy",
      "Groupe he",
      "Lab energies",
      "I finance energies",
      "ZOUGGARI",
    ],
    compteExist: false,
    editedItemId: -1,
    editedItem: {
      nom: null,
      prenom: null,
      email: null,
      societeGroupe: null,
      telephone: null,
      type: null,
      password: null,
      ADVEquipeSelected: [],
      EquipeSelected: null,
    },
    beforEditedItem: {
      nom: null,
      prenom: null,
      email: null,
      societeGroupe: null,
      telephone: null,
      type: null,
      password: null,
      ADVEquipeSelected: [],
      EquipeSelected: null,
    },
    dialog: false,
    dialogEdit: false,
    dialogDelete: false,
    showpassword1: false,
    required(fieldName) {
      return (value) =>
        (!!value && value >= 0) ||
        (!!value && value != "") ||
        `Veuillez saisir ${fieldName}`;
    },
    rules: {
      isString: (value) =>
        !/\d/.test(value) || "Ce champ ne peut pas etre composé de chiffres",
      required: (value) => !!value || "Ce champ est obligatoire.",
      requiredSelect: (value) => value.length > 0 || "Sélectionner au moins un utilisateur.",    
      minPasswordLength: (v) =>
        v?.length >= 8 || "Un mot de passe doit contenir au minimum 8 caractères",
      isNumber: (v) => v.match(/^[0-9]+$/) != null || "Ce champ doit être un nombre",
      phoneLength: (v) =>
        v.length == 10 || "Le numéro de téléphone est une suite de 10 chiffres",
      phoneCorrect: (v) =>
        /^(?:(?:\+|00)33|0)\s*[1-9](?:[\s.-]*\d{2}){4}$/.test(v) ||
        "Le numéro de téléphone est invalide",
      emailRules: (v) =>
        (!!v && /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(v)) || "E-mail invalide",
    },

    keys: ["nom", "prenom", "type", "email", "telephone", "Action"],
    defaultItem: {
      nom: null,
      prenom: null,
      email: null,
      telephone: null,
      type: null,
      password: null,
      ADVEquipeSelected: null,
      EquipeSelected: null,
    },
    // add vendeurs array
    typeVendeurAdmin: ["ADV", "Chef équipe", "chefIngenieur", "Prospecteur", "technicien", "ingenieur"],
    typeVendeur: ["Chef équipe", "chefIngenieur", "Prospecteur", "technicien", "ingenieur"],
    typeVendeurChef: ["chefIngenieur", "Prospecteur", "technicien", "ingenieur"],
    // edit vendeurs array
    typeRolesProspecteurEdit: ["ADV", "Chef équipe", "chefIngenieur", "Prospecteur", "technicien", "ingenieur"],
    typeRolesVendeurEdit: ["ADV", "Chef équipe", "chefIngenieur","Prospecteur", "technicien", "ingenieur"],
    typeRolesChefEdit: ["ADV", "Chef équipe"],
    typeRolesChefIngenieurEdit: ["chefIngenieur", "ingenieur"],
    typeRolesADVEdit: ["ADV"],
    // list
    listeEquipe: [],
    listeEquipeWithSolo: [],
    indivEquipe: {
      id: -1,
      nomEquipe: "Solo",
    },
    listeADV: [],
    listeChef: [],
    listeChefIngenieur: [],
    newManager: null, 
    }
  },
  computed: {
    typeUser() {
      return this.$store.getters.getUserRole;
    },
    formTitle() {
      return this.editedItemId >= 0 ? "Profil Vendeur" : "Nouveau Vendeur";
    },
    numberOfPages() {
      return Math.ceil(this.dataRows.length / this.itemsPerPage);
    },
    filteredKeys() {
      return this.keys;
    },
    filteredKeysSorted() {
      return this.keys.filter((key) => key !== "Action");
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },
  async created() {
    this.loadingTable = true;
    await this.getData();
    await this.getAllEquipe();
    await this.getAllChefIngenieur()
    if (["admin", "superadmin"].includes(this.$store.getters.getUserRole)) {
      await this.getAllAdv();
    }
    if (["admin", "superadmin","ADV"].includes(this.$store.getters.getUserRole)) {
    await this.getAllChef();
    }
    this.loadingTable = false;
  },
  methods: {
    async getAllEquipe() {
      var formData = new FormData();
      formData.append("token", this.$store.getters.getUserToken);
      await axios({
        url: process.env.VUE_APP_URL_API_CLIENT + "getAllEquipe/",
        method: "POST",
        data: formData,
      })
        .then((res) => {
          if (res.data.error == false) {
            this.listeEquipe = res.data.result;
            this.listeEquipeWithSolo = [this.indivEquipe, ...this.listeEquipe];
          } else if (res.data.error == "permission") {
            localStorage.removeItem("vendeurName");
            localStorage.removeItem("typeUser");
            localStorage.removeItem("token");
            this.$router.push("/login");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async getAllChef() {
      var formData = new FormData();
      formData.append("token", this.$store.getters.getUserToken);
      await axios({
        url: process.env.VUE_APP_URL_API_CLIENT + "getAllChefEquipe/",
        method: "POST",
        data: formData,
      })
        .then((res) => {
          if (res.data.error == false) {
            this.listeChef = res.data.result;
          } else if (res.data.error == "permission") {
            localStorage.removeItem("vendeurName");
            localStorage.removeItem("typeUser");
            localStorage.removeItem("token");
            this.$router.push("/login");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async getAllChefIngenieur() {
      var formData = new FormData();
      formData.append("token", this.$store.getters.getUserToken);
      await axios({
        url: process.env.VUE_APP_URL_API_CLIENT + "getAllChefIngenieur/",
        method: "POST",
        data: formData,
      })
        .then((res) => {
          if (res.data.error == false) {
            this.listeChefIngenieur = res.data.result;
          } else if (res.data.error == "permission") {
            localStorage.removeItem("vendeurName");
            localStorage.removeItem("typeUser");
            localStorage.removeItem("token");
            this.$router.push("/login");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async getAllAdv() {
      var formData = new FormData();
      formData.append("token", this.$store.getters.getUserToken);
      await axios({
        url: process.env.VUE_APP_URL_API_CLIENT + "getAllADV/",
        method: "POST",
        data: formData,
      })
        .then((res) => {
          if (res.data.error == false) {
            this.listeADV = res.data.result;
          } else if (res.data.error == "permission") {
            localStorage.removeItem("vendeurName");
            localStorage.removeItem("typeUser");
            localStorage.removeItem("token");
            this.$router.push("/login");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    filterByStatus(){
      var pageNumber = 1
      if(this.filterStatus != 'tout'){
        this.tableData.statusFilter = this.filterStatus
        this.getData(1)
      }else{
        this.tableData.statusFilter = ""
        this.getData(1)
      }
    },
    rechercheData({type, key}) {
      if(type=='search'){
          var pageNumber = 1
          this.tableData.search = key
      }else if(type=="nbrRecords")
      {
          this.tableData.length = key
      }
      this.tableData.draw++;
      axios({url:process.env.VUE_APP_URL_API_CLIENT + 'getAllCompte/',
          method: 'POST',
          data: {'dataTable': this.tableData,
              "pageNumber": pageNumber,
              'token': this.$cookies.get("token")},
      })
      .then(response => {
        if(response.data.result == true){
          let data = response.data.data;
          if (this.tableData.draw == data.draw) {
            this.dataRows = data.data.data;
            this.configPagination(data.data);
          }
        }
      })
      .catch(errors => {
        console.log(errors);
      });
    },
    getData(pageNumber = 1) {
      this.tableData.draw++;
      axios({
        url: process.env.VUE_APP_URL_API_CLIENT + "getAllCompte/",
        method: "POST",
        data: {'dataTable': this.tableData,
              "pageNumber": pageNumber,
              'token': this.$cookies.get("token")},
        }).then((response) => {
          if(response.data.result == true){
            let data = response.data.data;
            if (this.tableData.draw == data.draw) {
              this.dataRows = data.data.data;
              this.configPagination(data.data);
            }
          }
      }).catch(errors => {
        console.log(errors);
      });
    },
    closeDialogUpdatePassword() {
      this.dialogUpdatePassword = false;
      this.newPassword = null;
      this.idComptePasswordUpdate = null;
    },
    openDialogUpdatePassword(id) {
      this.dialogUpdatePassword = true;
      this.idComptePasswordUpdate = id;
    },
    updatePassword(idComptePasswordUpdate) {
      let self = this;
      var formData = new FormData();
      formData.append("id", idComptePasswordUpdate);
      formData.append("password", this.newPassword);
      formData.append("token", this.$store.getters.getUserToken);
      axios({
        url: process.env.VUE_APP_URL_API_CLIENT + "modifierPassword/",
        method: "POST",
        data: formData,
      })
        .then((res) => {
          if (res.data.result == true) {
            this.getData();
            this.closeDialogUpdatePassword();
          }
          if (res.data.result == false) {
            this.closeDialogUpdatePassword();
          } else if (res.data.result == "permission") {
            localStorage.removeItem("vendeurName");
            localStorage.removeItem("typeUser");
            localStorage.removeItem("token");
            this.$router.push("/login");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    nextPage() {
      if (this.page + 1 <= this.numberOfPages) this.page += 1;
    },
    formerPage() {
      if (this.page - 1 >= 1) this.page -= 1;
    },
    updateItemsPerPage(number) {
      this.itemsPerPage = number;
    },
    editItem(item) {
      this.editedItemId = item.id;
      this.editedItem = Object.assign({}, item); //copie le contenu d'item dans editedItem
      this.beforEditedItem = Object.assign({}, item);
      this.dialogEdit = true;
    },

    deleteItem(item) {
      this.editedItemId = item.id;
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.supprimerCompte(this.editedItemId);
      this.closeDelete();
    },

    close(stringTypeForm) {
      if (stringTypeForm == "addVendeur") {
        this.$refs.formVendeur.resetValidation();
      } else if (stringTypeForm == "modifyVendeur") {
        this.$refs.formEdit.resetValidation();
      }
      this.compteExist = false;
      this.dialog = false;
      this.dialogEdit = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedItemId = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedItemId = -1;
      });
    },

    save() {
      if (this.$refs.formVendeur.validate()) {
        this.ajouterCompte(this.editedItem);
      }
    },
    edit() {
      if (this.$refs.formEdit.validate()) {
        this.modifierCompte(this.editedItem);
      }
    },
    supprimerCompte(compteVendeurId) {
      let self = this;
      var formData = new FormData();
      formData.append("id", compteVendeurId);
      formData.append("token", this.$store.getters.getUserToken);
      axios({
        url: process.env.VUE_APP_URL_API_CLIENT + "deleteCompte/",
        method: "POST",
        data: formData,
      })
        .then((res) => {
          if (res.data.result == true) {
            this.getData();
            this.close();
          }
          if (res.data.result == false) {
          } else if (res.data.result == "permission") {
            localStorage.removeItem("vendeurName");
            localStorage.removeItem("typeUser");
            localStorage.removeItem("token");
            this.$router.push("/login");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    modifierCompte(compteVendeur) {
      var formData = new FormData();
      formData.append("idCompte", compteVendeur.id);
      formData.append("email", compteVendeur.email);
      formData.append("nom", compteVendeur.nom);
      formData.append("prenom", compteVendeur.prenom);
      formData.append("type", compteVendeur.type);
      formData.append("societeGroupe", compteVendeur.societeGroupe);
      formData.append("telephone", compteVendeur.telephone);
      formData.append("equipeId", compteVendeur.EquipeSelected);
      formData.append("advEquipeId", compteVendeur.ADVEquipeSelected);
      formData.append("newManager", this.newManager);
      formData.append("token", this.$store.getters.getUserToken);
      axios({
        url: process.env.VUE_APP_URL_API_CLIENT + "updateCompte/",
        method: "POST",
        data: formData,
      })
        .then((res) => {
          if (res.data.result == true) {
            this.getData();
            this.close();
          }
          if (res.data.result == false) {
          }
          if (res.data.result == "permission") {
            localStorage.removeItem("vendeurName");
            localStorage.removeItem("typeUser");
            localStorage.removeItem("token");
            this.$router.push("/login");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    ajouterCompte(compteVendeur) {
      //let self = this
      var formData = new FormData();
      formData.append("email", compteVendeur.email);
      formData.append("nom", compteVendeur.nom);
      formData.append("prenom", compteVendeur.prenom);
      formData.append("type", compteVendeur.type);
      formData.append("telephone", compteVendeur.telephone);
      formData.append("societeGroupe", compteVendeur.societeGroupe);
      formData.append("password", compteVendeur.password);
      formData.append("equipeId", compteVendeur.EquipeSelected);
      formData.append("token", this.$store.getters.getUserToken);
      axios({
        url: process.env.VUE_APP_URL_API_CLIENT + "createCompte/",
        method: "POST",
        data: formData,
      })
        .then((res) => {
          console.log(res);
          if (res.data.exist == true) {
            this.compteExist = true;
            this.getData();
          } else if (res.data.exist == false) {
            this.getData();
            this.close();
          } else {
            localStorage.removeItem("vendeurName");
            localStorage.removeItem("typeUser");
            localStorage.removeItem("token");
            this.$router.push("/login");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    configPagination(data) {
      this.pagination.lastPage = data.last_page;
      this.pagination.currentPage = data.current_page;
      this.pagination.total = data.total;
      this.pagination.lastPageUrl = data.last_page_url;
      this.pagination.nextPageUrl = data.next_page_url;
      this.pagination.prevPageUrl = data.prev_page_url;
      this.pagination.from = data.from;
      this.pagination.to = data.to;
    },
    sortBy(key) {
      this.sortKey = key;
      this.sortOrders[key] = this.sortOrders[key] * -1;
      this.tableData.column = this.getIndex(this.columns, 'name', key);
      this.tableData.dir = this.sortOrders[key] === 1 ? 'asc' : 'desc';
      this.getData();
    },
    getIndex(array, key, value) {
      return array.findIndex(i => i[key] == value)
    },
  },
};
</script>
<style scoped>
.v-application .blue.darken-3 {
  margin-bottom: 25px !important;
}
::v-deep thead > tr > th {
  border: 2px white solid;
  color: white;
}
</style>
