<template>
  <v-expansion-panels v-model="panel" multiple>
    <v-expansion-panel v-for="(question, index) in listQuestions" :key="index">
      <v-expansion-panel-header class="px-0">
        <div class="d-flex flex-row justify-start align-center">
          <v-icon class="mr-1" :color="$colors[0]">mdi-tooltip-question</v-icon>
          <span  class="fs-14 f-w-700" :style="{ color: $colors[1] }">
            - {{ question.question }}
          </span>
        </div>
      </v-expansion-panel-header>
      <v-expansion-panel-content class="pa-0">
        <div
          style="
            background-color: #f7f7f7;
            border-left: 3px solid #a89d04;
            width: 100%;
            min-height: 50px;
          "
          class="pa-2"
        >
          <div style="height: 100%" v-if="question.questionType == 'choice'">
            <span class="fs-14">{{ question.reponseText }}</span>
          </div>
          <ul
            style="height: 100%"
            v-if="question.questionType == 'multi_choice'"
          >
            <li v-for="(response, index) in question.reponseText" :key="index">
              <span class="fs-14">{{ response }}</span>
            </li>
          </ul>
          <ul
            style="height: 100%"
            v-if="question.questionType == 'multi_choice_metre'"
          >
            <li
              v-for="(respObj, index) in question?.reponseText?.answersList"
              :key="index"
            >
              <span class="fs-14">{{ respObj?.name }} - {{ respObj?.value }}</span>
            </li>
            <div class="d-flex flex-row fs-14" v-if="question?.reponseText?.autre">
              {{ question?.reponseText?.autre }}
            </div>
          </ul>
          <div class="d-flex flex-row fs-14" v-if="question.type == 'checkbox'">
            {{ question.reponse }}
          </div>
          <div
            class="d-flex flex-row flex-wrap"
            v-if="question.questionType == 'image'"
          >
            <img 
              class="image-card" 
              v-for="(file, i) in question.files" 
              :src="baseURL+file.path" 
              :key="i" 
              @click="openSlideShow(i, question.files)" 
            />
          </div>
          <div v-if="question.questionType == 'file'">
            <listFile
              v-if="question.files.length > 0"
              :isRemovable="false"
              :file="question.files"
            />
            <span v-else class="fs-14">pas de fichier</span>
          </div>
          <div v-if="question.questionType == 'file_image'">
            <img 
              class="image-card" 
              v-for="(file, i) in filteredImageFiles(question.files)" 
              :src="baseURL+file.path" 
              :key="i" 
              @click="()=> {let fs=filteredImageFiles(question.files); openSlideShow(i, fs)}" 
            />
            <listFile
              v-if="question.files.length > 0"
              :isRemovable="false"
              :file="filteredPdfFiles(question.files)"
            />
          </div>
          <div v-if="['number', 'text'].includes(question.questionType)">
            <span class="fs-14">{{ question.reponseText }}</span>
          </div>
          <div class="mt-2" v-if="question.childs?.length > 0">
            <div class="py-4" style="border-bottom: 1px solid rgb(216, 216, 216);" v-for="(questionChild, index) in question.childs" :key="index">
              <div class="d-flex flex-column">
                <span class="fs-14 f-w-700">{{ questionChild.question }}</span>
              </div>

              <div class="d-flex flex-row flex-wrap align-items-center mt-2">
                <!-- question type choice -->
                <div
                  class="fs-14"
                  style="height: 100%"
                  v-if="questionChild.questionType == 'choice'"
                >
                  {{ questionChild.reponseText }}
                </div>

                <!-- question type multi_choice -->
                <div
                  style="height: 100%"
                  v-if="questionChild.questionType == 'multi_choice'"
                >
                  <div
                    class="d-flex flex-row fs-14"
                    v-for="(response, index) in questionChild.reponseText"
                    :key="index"
                  >
                    {{ response }}
                  </div>
                </div>

                <!-- question type multi_choice_metre -->
                <div
                  style="height: 100%"
                  v-if="question.questionType == 'multi_choice_metre'"
                >
                  <div
                    class="d-flex flex-row fs-14"
                    v-for="(respObj, index) in question?.reponseText
                      ?.answersList"
                    :key="index"
                  >
                    {{ respObj?.name }} - {{ respObj?.value }}
                  </div>
                  <div
                    class="d-flex flex-row fs-14"
                    v-if="question?.reponseText?.autre"
                  >
                    {{ question?.reponseText?.autre }}
                  </div>
                </div>
                <div
                  class="d-flex flex-row fs-14"
                  v-if="questionChild.type == 'checkbox'"
                >
                  {{ questionChild.reponse }}
                </div>

                <!-- question type image -->
                <div
                  class="d-flex flex-row flex-wrap"
                  v-if="questionChild.questionType == 'image'"
                >
                  <img 
                    class="image-card" 
                    v-for="(file, i) in questionChild.files" 
                    :src="baseURL+file.path" 
                    :key="i" 
                    @click="openSlideShow(i, questionChild.files)" 
                  />
                </div>

                <!-- question type file -->
                <div
                  class="d-flex flex-row flex-wrap"
                  v-if="questionChild.questionType == 'file'"
                >
                  <listFile :isRemovable="false" :file="questionChild.files" />
                </div>
                <!-- question type file_image -->
                <div v-if="questionChild.questionType == 'file_image'">
                  <img 
                    class="image-card" 
                    v-for="(file, i) in filteredImageFiles(questionChild.files)" 
                    :src="baseURL+file.path" 
                    :key="i" 
                    @click="()=> {let fs=filteredImageFiles(questionChild.files); openSlideShow(i, fs)}" 
                  />
                  <listFile
                    v-if="questionChild.files.length > 0"
                    :isRemovable="false"
                    :file="filteredPdfFiles(questionChild.files)"
                  />
                </div>
                <div
                  v-if="['number', 'text'].includes(questionChild.questionType)"
                >
                  <span class="fs-14">{{ questionChild.reponseText }}</span>
                </div>
                
                <div class="row m-3 ml-5 border" style="height: 100%" v-if="questionChild.questionType == 'sign'">
                  <div class="col-12">
                    <div
                      v-for="(obj, index1) in questionChild.reponseText.filter((c) => c.selected == true)"
                      :key="index1">
                      <h6>{{ obj.name }}</h6>
                      <ul>
                        <li
                          v-for="(option, index3) in obj.options.filter((c) => c.selected == true)">

                          {{ option.label }} {{ option.haveInput ? ": " + option.value : "" }}

                        </li>
                      </ul>
                    </div>
                  </div>
                  <div>
                    <p>Image pour la Signalisation : </p>
                    <img 
                      class="image-card" 
                      v-for="(file, i) in questionChild.files" 
                      :src="baseURL+file.path" 
                      :key="i" 
                      @click="openSlideShow(i, questionChild.files)" 
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import listFile from "@/components/listFile.vue";
export default {
  name: "QuestionsAnswersList",
  props: ['listQuestions'],
  components: { listFile },
  data() {
    return {
      baseURL: "",
      panel: [],
    }
  },
  watch: {
    listQuestions: {
      deep: true,
      handler(newVal) {
        this.expandAllpanels();
      }
    }
  },
  mounted() {
    this.baseURL = process.env.VUE_APP_URL_API_CLIENT_BASE_URL;
  },
  created() {
    this.expandAllpanels();
  },
  methods: {
    expandAllpanels () {
      this.panel = Array.from(Array(this.listQuestions.length).keys());
    },
    openSlideShow(i, listImages) {
      const newImages = listImages.map(img => this.baseURL + img.path) || [];
      this.$emit('openSlideShow', { index: i, images: newImages });
    },
    filteredPdfFiles(files) {
      return files.filter(f => f.extention == 'pdf');
    },
    filteredImageFiles(files) {
      return files.filter(f => ['jpg','png'].includes(f.extention));
    },
  },
}
</script>

<style scoped>
.fs-14{
  font-size: 14px;
}
.image-card {
  width: 100px;
  height: 100px;
  background-size: cover;
  cursor: pointer;
  margin: 5px;
  border-radius: 3px;
  border: 1px solid lightgray;
  object-fit: contain;
}
::v-deep
  .theme--light.v-expansion-panels
  .v-expansion-panel-header
  .v-expansion-panel-header__icon
  .v-icon {
  font-size: initial !important;
}
::v-deep .v-expansion-panel::before {
  box-shadow: none !important;
}
</style>