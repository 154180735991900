<template>
  <v-app :style="{ background: $vuetify.theme.themes.light.background }">
    <div>
      <v-row class="ma-4">
        <v-col cols="12" md="3" sm="3" style="padding-top: 0px !important">
          <v-row>
            <v-col cols="12" sm="12">
              <!-- 
                            <VisiteComponent />
                            -->
              <TechLeftMenu
                :techList="listeTech"
                @Selected_tech="unSelectAndSelectTech"
                @effectuerUneDemandeAuTech="effectuerUneDemandeAuTech"
                :UnaffectedEvents="eventsNonOwner"
                :globalEvents="events"
                @refreshEvents="refreshEvents"
              />
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" sm="9" style="background-color: #fff" class="card-shadow">
          <v-row>
            <v-col cols="4" sm="4">
              <div class="title-calendar">
                <h2>Calendrier Visite Technique</h2>
              </div>
            </v-col>
            <v-col cols="8" sm="8">
              <div class="button-switch-wrapper">
                <div class="button-switch-container">
                  <button
                    class="button-sc"
                    :class="{ active: isCalendarView }"
                    @click="changeView('month')"
                  >
                    Mois
                  </button>
                  <div :class="{ 'button-sc-separator': isTimeLineDay }"></div>
                  <button
                    class="button-sc"
                    :class="{ active: isTimelineWeekView }"
                    @click="changeView('week')"
                  >
                    Semaine
                  </button>
                  <div :class="{ 'button-sc-separator': isCalendarView }"></div>
                  <button
                    class="button-sc"
                    :class="{ active: isTimeLineDay }"
                    @click="changeView('day')"
                  >
                    Jour
                  </button>
                </div>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="12">
              <timeLineComposent
                :events="eventsToAffiche"
                :currentDate="currentDate"
                @moveToDayCalendar="goToDay"
                @rechargeEvents="rechargeEvents"
                @miseAjourEffectuer="miseAjourEffectuer"
                @annulerLaModification="annulerLaModification"
                v-if="isTimelineWeekView"
              />
              <timeLineDay
                :events="eventsToAffiche"
                :groups="SelectedTech"
                :selectDay="currentDate"
                @annulerLaModification="annulerLaModification"
                @rechargeEvents="rechargeEvents"
                @miseAjourEffectuer="miseAjourEffectuer"
                v-if="isTimeLineDay"
              />
              <!--<timeLine />-->
              <calendar
                :globalEvents="eventsToAffiche"
                :currentDate="currentDate"
                :techList="listeTech"
                ref="monthCalendar"
                v-if="isCalendarView"
                @annulerLaModification="annulerLaModification"
                @miseAjourEffectuer="miseAjourEffectuer"
                @rechargeEvents="rechargeEvents"
              />
              <!--   <AgendaComponent />-->
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </v-app>
</template>
<script>
import AgendaComponent from "./Component/Agenda.vue";
import VisiteComponent from "./Component/Visite.vue";
import calendar from "./Component/calendar.vue";
import timeLine from "./Component/timelineVersion.vue";
import timeLineComposent from "./Component/timeLineComposent.vue";
import TechLeftMenu from "../../components/left-menu.vue";
import timeLineDay from "./Component/timeLineDay.vue";
import AddEventMenu from "./Component/modal/addEventMenu.vue";
import dayjs from "@/utils/dayjsConfig";
import axios from "@/plugins/axios";
export default {
  name: "VTcalendar",
  components: {
    AgendaComponent,
    VisiteComponent,
    calendar,
    timeLine,
    timeLineComposent,
    TechLeftMenu,
    timeLineDay,
    AddEventMenu,
  },
  data() {
    return {
      currentDate: dayjs().format("YYYY-MM-DD"),
      defaultStartDateEvents: dayjs().format("YYYY-MM-01"),
      defaultEndDateEvents: dayjs().add(1, "month").format("YYYY-MM-01"),
      calendarApi: null,
      isMenuOpen: false,
      isTimelineWeekView: false,
      isCalendarView: true,
      isTimeLineDay: false,
      events: [],
      listeTech: [],
      SelectedTech: [],
      selectedTechNotNull: [],
      isCheck: true,
      eventsToAffiche: [],
      eventsNonOwner: [],
      weekEvents: [],
      selectedDay: dayjs().format("YYYY-MM-DD HH:mm"),
    };
  },
  async mounted() {
    await this.getAllTechnicienForCalendar();
    await this.getAllVisiteTechnique();
    // fix the start ans end date format:
    this.eventsFormat();
    // get the getApi() from full calendar
    this.calendarApi = this.$refs.monthCalendar.calendarApi;
    await this.removeEventWithOwner();
    await this.filerEventsAfterSelectTech();
    this.SelectedTech = this.SelectedTech.filter((tech) => {
      return tech != null;
    });
    // initialize week events
  },

  methods: {
    async rechargeEvents({ dateDebut: dateDebut, dateFin: dateFin }) {
      this.currentDate = dayjs(dateDebut).format("YYYY-MM-DD");
      dateDebut = dayjs(dateDebut).format("YYYY-MM-DD");
      dateFin = dayjs(dateFin).format("YYYY-MM-DD");
      if (
        this.defaultStartDateEvents != dateDebut ||
        this.defaultEndDateEvents != dateFin
      ) {
        this.defaultStartDateEvents = dateDebut;
        this.defaultEndDateEvents = dateFin;
      }
      this.getAnouveauMesVisites();
    },
    async getAllTechnicienForCalendar() {
      var formData = new FormData();
      formData.append("token", this.$cookies.get("token"));
      await axios({
        url: process.env.VUE_APP_URL_API_CLIENT + "getAllTechnicienForCalendar/",
        method: "POST",
        data: formData,
      })
        .then((res) => {
          if (res.data.status == true) {
            this.listeTech = res.data.listeTech;
          } else {
            this.listeTech = [];
          }
        })
        .catch((err) => {});
    },
    async effectuerUneDemandeAuTech({ event, idTech }) {
      var formData = new FormData();
      formData.append("token", this.$cookies.get("token"));
      formData.append("eventToUpdate", JSON.stringify(event));
      await axios({
        url: process.env.VUE_APP_URL_API_CLIENT + "effectuerUneVisiteTechniqueAuTech/",
        method: "POST",
        data: formData,
      })
        .then((res) => {
          if (res.data.status == true) {
          } else {
          }
        })
        .catch((err) => {});
      await this.getAnouveauMesVisites();
    },
    async miseAjourEffectuer(eventToUpdate) {
      var formData = new FormData();
      eventToUpdate.owner = [
        { name: "Enlever l’affectation", id: null },
        ...this.listeTech,
      ].filter((el) => {
        return el.name == eventToUpdate.owner;
      })[0].id;
      formData.append("token", this.$cookies.get("token"));
      formData.append("eventToUpdate", JSON.stringify(eventToUpdate));
      await axios({
        url: process.env.VUE_APP_URL_API_CLIENT + "updateVisiteTechniqueCalendar/",
        method: "POST",
        data: formData,
      })
        .then((res) => {
          if (res.data.status == true) {
          } else {
          }
        })
        .catch((err) => {});
      await this.getAnouveauMesVisites();
    },
    async getAnouveauMesVisites() {
      await this.getAllVisiteTechnique();
      await this.eventsFormat();
      await this.removeEventWithOwner();
      await this.filteredVisits();
      try {
        this.calendarApi = this.$refs.monthCalendar.calendarApi;
      } catch (err) {}
      this.SelectedTech = this.listeTech.map((tech) => {
        // remove the null value from the array
        if (tech.selected == true) {
          return tech;
        }
      });
      this.SelectedTech = this.SelectedTech.filter((tech) => {
        return tech != null;
      });
    },
    async annulerLaModification() {
      await this.getAnouveauMesVisites();
    },
    async getAllVisiteTechnique() {
      var formData = new FormData();
      formData.append("token", this.$cookies.get("token"));
      formData.append("startDate", this.defaultStartDateEvents);
      formData.append("endDate", this.defaultEndDateEvents);
      formData.append("listeTech", JSON.stringify(this.listeTech));
      await axios({
        url: process.env.VUE_APP_URL_API_CLIENT + "getVisiteTechniqueForCalendar/",
        method: "POST",
        data: formData,
      })
        .then((res) => {
          if (res.data.status == true) {
            this.events = res.data.data;
          } else {
          }
        })
        .catch((err) => {});
    },
    //change the format of start and end date of events
    async eventsFormat() {
      this.events.forEach((event) => {
        event.start = dayjs(event.dateDebut + " " + event.timeStart).format(
          "YYYY-MM-DD HH:mm"
        );
        event.end = dayjs(event.dateFin + " " + event.timeEnd).format("YYYY-MM-DD HH:mm");
      });
    },
    async filteredVisits() {
      this.eventsToAffiche = this.events.filter((visit) => {
        return this.listeTech.some((tech) => {
          return tech.name === visit.owner && tech.selected;
        });
      });
      try {
        // inialize the calendar after filtring
        this.calendarApi.removeAllEvents();
        this.calendarApi.addEventSource(this.eventsToAffiche);
      } catch (err) {}
      console.log("events to affiche", this.eventsToAffiche);
      return this.eventsToAffiche;
    },
    // select and unselect tech and filtring events after selecting tech
    async unSelectAndSelectTech() {
      this.getAnouveauMesVisites();
      await this.filteredVisits();
    },

    // filtring events after selecting tech
    async filerEventsAfterSelectTech() {
      await this.filteredVisits();
    },
    // remove events that has owner
    async removeEventWithOwner() {
      this.eventsNonOwner = [];
      this.eventsToAffiche = this.events.filter((event) => {
        if (event.owner == "") {
          this.eventsNonOwner.push(event);
        }
        return event.owner !== "";
      });
      try {
        this.calendarApi.removeAllEvents();
        this.calendarApi.addEventSource(this.eventsToAffiche);
      } catch (error) {}
      // inialize the calendar after filtring
    },
    // refresh events after update event owner
    async refreshEvents({ events }) {
      this.events = events;
      this.eventsToAffiche = this.events.filter((event) => {
        return event.owner !== "";
      });
      await this.removeEventWithOwner();
    },
    // change calendar between week and month and day
    changeView(view) {
      this.isTimelineWeekView = view === "week";
      this.isCalendarView = view === "month";
      this.isTimeLineDay = view === "day";
    },
    // go to selected Day from the timelineComposent emit
    goToDay(day) {
      this.isCalendarView = false;
      this.isTimelineWeekView = false;
      this.isTimeLineDay = true;
      this.currentDate = day;
    },
    displayMenu() {
      if (this.isMenuOpen) {
        this.isMenuOpen = false;
      } else {
        this.isMenuOpen = true;
      }
    },
  },
};
</script>
<style scoped>
.button-row {
  justify-content: flex-end;
  background-color: #ffffff;
  margin-bottom: 0px;
  padding: 5px;
}
.container-calendar {
  margin-left: 40px !important;
  margin-right: 40px !important;
  margin-top: 50px;
}

.button-switch-wrapper {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.button-switch-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  background-color: #e8f2ff;
  border-radius: 4px;
  padding: 4px;
  margin: 8px;
  width: fit-content;
}
.button-sc {
  font-size: 14px;
  font-weight: 600;
  color: #020f47;
  background-color: transparent;
  padding: 8px 24px;
  border-radius: 4px;
}
.button-sc.active {
  background-color: #fff;
  color: #13a592;
  box-shadow: 0px 0px 16px -4px rgba(194, 194, 194, 1);
}
.button-sc-separator {
  width: 1px;
  margin-top: 8px;
  margin-bottom: 8px;
  background-color: #d3d3df;
}
.button {
  color: #ffffff !important;
  background-color: #5094d3;
  margin-left: 8px;
}
.fixed-bottom-left {
  position: fixed;
  bottom: 40px;
  right: 50px;
  background-color: #14324f;
  border-radius: 40px;
}
.add-btn {
  color: #ffffff !important;
}
.vtlist-button {
  display: flex;
  justify-content: flex-start !important;
  color: rgba(209, 61, 15, 0.18);
}
.vt-btn-secondary {
  color: rgba(209, 61, 15, 0.18);
}

.card-shadow {
  border-radius: 6px;
  box-shadow: 0px 4px 16px -4px rgba(194, 194, 194, 1);
}

.title-calendar {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  padding-left: 24px;
}

h2 {
  font-size: 18px;
  font-weight: 600;
  color: #4a6680;
  margin-top: 10px;
}
</style>
