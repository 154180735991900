<template>
  <div class="page-de-garde">
    <img class="img-bg" src="../images/PageDeGarde.png" />
    <div class="content">
      <p class="titleOfContent">{{pageDeGardeTitle ? pageDeGardeTitle : 'OFFRE SOCIETE'}}</p>
      <div class="cards-wrapper">
        <div class="card">
          <p class="titleOfCard">Devis PHOTOVOLTAIQUE</p>
          <p>Préparé par:</p>
          <p class="mb-0">{{vendeur?.nom + " " + vendeur?.prenom}}</p>
          <p class="mb-0">{{vendeur?.fonction}}</p>
          <p class="mb-0">{{vendeur?.email}}</p>
          <p class="mb-0">{{vendeur?.telephone}}</p>
        </div>
        <div class="card">
          <p class="titleOfCard">RAISON SOCIAL</p>
          <p>A l'attention de:</p>
          <p class="mb-0">{{societe?.nom + " " + societe?.prenom}}</p>
          <p class="mb-0">{{societe?.raison}}</p>
          <p class="mb-0">{{societe?.siret}}</p>
        </div>
      </div>
    </div>
    <div class="footer">
      <ul>
        <li>ENEFFIC - 2 Aliée du Levant 34970 Lattes</li>
        <li>Téléphone: +33 (0)1 41 221 410 - E-mail:</li>
        <li>contact@eneffic.fr SIREN: 951 979 798</li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "PageDeGarde",
  props: [
    "pageDeGardeTitle",
    "societe",
    "vendeur",
  ],
};
</script>

<style lang="scss" scoped>
.page-de-garde{
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: left;
  overflow: hidden;

  .img-bg{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  .content{
    position: relative;
    width: 100%;
    border: none;
    margin-top: 580px;
    padding: 32px;
    z-index: 2;
    
    .titleOfContent{
      font-size: 28pt;
      font-weight: 500;
      text-align: center;
      color:#e4eaec;
      margin-bottom: 60px;
      z-index: 2;
    }
    .cards-wrapper{
      display: flex;
      flex-direction: row;
      justify-content: center;
      gap: 80px;
      .card{
        flex: 1;
        max-width: 300px;
        min-height: 250px;
        border-radius: 28px;
        padding: 24px 20px;
        color: #14324F;
        background-color: #fff;
        z-index: 2;
        .titleOfCard{
          font-size: 14pt;
          font-weight: 700;
          text-align: left;
          color:#03dfab;
          margin-bottom: 10px;
        }
      }
    }

  }
  .footer{
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0px;
    z-index: 2;
    font-size: 10pt;
    color:#e4eaec;
    font-weight: 400;
    text-align: center;
    ul{
      list-style-type: none;
    }
  }
}
</style>