<template>
  <div class="page-blanc">
    <img class="left-center-shape" src="../images/shapes/shape-4.png" />
    <img class="right-top-shape" src="../images/shapes/shape-4.png" />
    <img class="bottom-left-shape" src="../images/shapes/shape-2.png" />

    <img 
    class="logo-enffic-pdf"
    src="../images/logo-eneffic.png"
    />
    <p class="page-title">
      <span>Notre approche</span>
    </p>
    <div class="content">
      <div class="cards-wrapper">
        <div class="card">
          <div class="icon-wrapper">
            <img src="../images/icons/lampe.png" />
          </div>
          <p class="titleOfCard">Efficaité énergétique</p>
          <p class="subtitle">Optimisez votre consommation et vos ressources.</p>
        </div>
        <div class="card">
          <div class="icon-wrapper">
            <img src="../images/icons/target.png" />
          </div>
          <p class="titleOfCard">Audit & Stratégie</p>
          <p class="subtitle">Une stratégie énergétique solide pour votre réussite.</p>
        </div>
        <div class="card">
          <div class="icon-wrapper">
            <img src="../images/icons/conso.png" />
          </div>
          <p class="titleOfCard">Gestion & Optimisation</p>
          <p class="subtitle">La gestion des consommations clé du succès de votre projet</p>
        </div>
      </div>
      <p class="page-title">
        <span>Nos qualifications</span>
      </p>
      <div class="cards-logo-wrapper">
        <div class="card">
          <img src="../images/detail/la-french-tech.png" />
        </div>
        <div class="card">
          <img src="../images/detail/QE.png" />
        </div>
        <div class="card">
          <img src="../images/detail/qualibat.png" />
        </div>
        <div class="card">
          <img src="../images/detail/ecovadis.png" />
        </div>
        <div class="card">
          <img src="../images/detail/advenir.png" />
        </div>
      </div>
      <div class="cards-logo-wrapper">
        <div class="card">
          <img src="../images/detail/qualipv.png" />
        </div>
        <div class="card">
          <img src="../images/detail/qualifelec.png" />
        </div>
        <div class="card">
          <img src="../images/detail/operat.png" />
        </div>
        <div class="card">
          <img src="../images/detail/certif-eco.png" />
        </div>
      </div>
      <div class="cards-logo-wrapper">
        <div class="card">
          <img src="../images/detail/enedis.png" />
        </div>
        <div class="card">
          <img src="../images/detail/grdf.png" />
        </div>
        <div class="card">
          <img src="../images/detail/republique-francaise.png" />
        </div>
      </div>
    </div>
    <div class="footer">
      <ul>
        <li>ENEFFIC - 2 Aliée du Levant 34970 Lattes</li>
        <li>Téléphone: +33 (0)1 41 221 410 - E-mail: contact@eneffic.fr</li>
        <li>SIREN: 951 979 798</li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "NotreApproche",
};
</script>

<style lang="scss" scoped>
.page-blanc{
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  padding: 32px;

  .right-top-shape{
    position: absolute;
    width: 75px;
    height: 75px;
    top: 10%;
    right: 0;
  }

  .left-center-shape{
    position: absolute;
    width: 75px;
    height: 75px;
    top: 50%;
    left: 0;
  }

  .bottom-left-shape{
    position: absolute;
    width: 150px;
    height: 150px;
    bottom: 0;
    left: 0;
  }
  

  .logo-enffic-pdf{
    height: 24px;
    margin-top: 40px;
    margin-left: 40px;
  }
  
  .page-title{
    width: fit-content;
    position: relative;
    font-size: 18pt;
    font-weight: 600;
    text-align: center;
    margin: 100px auto 60px;
    span{
      position: relative;
    }
    &::before{
      content: "";
      position: absolute;
      display: block;
      width: 114%;
      height: 16px;
      top: 60%;
      left: -7%;
      background: #00f7bd;
    }
  }

  .content{
    position: relative;
    width: 100%;
    z-index: 1;
    .cards-wrapper{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
      margin: 0px 20px;

      .card{
        flex: 1;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 0px;
        border: none;
        outline: none;
        padding: 5px;
        background-color: transparent;
        color: #14324F;

        .icon-wrapper{
          display: flex;
          justify-content: center;
          align-items: center;
          width: 80px;
          height: 80px;
          background: #00f7bd;
          border-radius: 2px;
          margin-bottom: 20px;

          img{
            width: 100%;
            height: 100%;
          }
        }
        .titleOfCard{
          font-size: 14pt;
          font-weight: 600;
          text-align: center;
          color:#14324F;
          margin-bottom: 10px;
        }
        .subtitle{
          font-size: 12pt;
          font-weight: 500;
          text-align: center;
          color:#14324F;
          margin-bottom: 10px;
          box-shadow: none;
        }
      }
    }

    .cards-logo-wrapper{
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: flex-start;
      gap: 28px;
      margin: 24px 60px;
      z-index: 1;

      .card{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 130px;
        height: 110px;
        border-radius: 20px;
        // border: none;
        border: 4px solid rgba(240, 245, 244, 0.15);
        outline: none;
        background-color: transparent;
        z-index: 1;
        box-shadow: 1px 1px 13px 0px rgba(177, 177, 177, 0.75);

        img{
          padding: 10px;
          max-width: 100%;
          max-height: 100%;
        }
      }
    }

  }
  .footer{
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0px;
    z-index: 2;
    font-size: 10pt;
    color: #14324F;
    font-weight: 400;
    text-align: center;
    ul{
      list-style-type: none;
    }
  }
}
</style>