<style scoped>
.td-valeur {
  align-items: center;
  flex-direction: column;
}

.td-statut {
  align-items: center;
  flex-direction: row;
}
.td-table,
.td-valeur,
.td-statut {
  display: flex;
  justify-content: center;
}
.d1 {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: flex-end;
}
</style>
<template>
  <div class="ma-4">
    <v-card class="mt-6 pa-4" elevation="10">
      <v-dialog persistent v-model="ouvrireDialogeRefuser" max-width="620px">
        <v-card style="overflow: hidden">
          <v-toolbar :color="$colors[1]" dark>
            <v-card-title class="d-flex justify-content-center align-items-center"
              >Suppression de l'équipe {{ demandeTraiter?.nomEquipe }}</v-card-title
            >
          </v-toolbar>
          <v-card-text
            style="display: block"
            class="text-h6 text-center font-weight-light my-8"
            >Êtes-vous sûr de bien vouloir supprimer cette équipe ?</v-card-text
          >
          <div class="d-flex justify-content-end mb-2">
            <v-btn text class="mx-2" color="error" @click="fermerDialogesupprimerEquipe"
              >Annuler</v-btn
            >
            <v-btn class="text-white mr-2" :color="$colors[1]" @click="supprimerEquipe"
              >Supprimer</v-btn
            >
          </div>
        </v-card>
      </v-dialog>
      <ajouterEquipe
        v-if="ajouterEquipe == true"
        @ajouterEquipeMethod="getEtatajouterEquipe"
      />
      <v-dialog persistent v-model="modifierEquipe" style="z-index:1050;" max-width="900px" max-height="800px">
        <modifierEquipe
          :equipe="equipeModifier"
          @modifierEquipe="getEtatModifierEquipe"
          v-if="modifierEquipe == true"
        />
      </v-dialog>
      <v-btn
        :style="{background:$styles.background}"
        class="ma-4 text-white"
        @click="ajouterEquipe = true"
        v-if="ajouterEquipe == false"
        ><span class="Poppins-Bold"
          >Ajouter une equipe<v-icon class="ml-2">mdi-account-plus</v-icon></span
        >
      </v-btn>
      <div style="width: 100%;overflow-x: auto;">
        <datatable
          id="table-liste-equipe"
          class="mx-4"
          :columns="columns"
          :sortKey="sortKey"
          :sortOrders="sortOrders"
          @sort="sortBy"
          @search="rechercheData"
        >
          <template v-slot:body>
            <tbody v-if="lesEquipes?.length > 0 && loadingTable == false">
              <tr v-for="(equipe, index) in lesEquipes" :key="equipe.id">
                <td>
                  <div >
                    <span>{{ equipe?.date }}</span>
                  </div>
                </td>
                <td>
                  <div >
                    <span>
                      {{ equipe?.nomEquipe }}
                    </span>
                  </div>
                </td>
                <td>
                  <div >
                    <span>
                      {{ equipe?.ADV?.userName }}
                    </span>
                  </div>
                </td>
                <td>
                  <div >
                    <span>
                      {{ equipe?.chefEquipe?.userName }}
                    </span>
                  </div>
                </td>
                <td>
                  <div >
                    <span>
                      {{ equipe?.chefIngenieur?.userName }}
                    </span>
                  </div>
                </td>
                <td>
                  <div >
                    <span v-if="equipe?.prospecteur?.length > 0" column>
                      <span>{{ equipe?.prospecteur[0]?.userName }}
                        <v-icon
                          v-if="equipe?.prospecteur?.length > 1"
                          data-toggle="collapse"
                          :data-target="'#multiCollapseprospecteur' + index"
                          aria-expanded="false"
                          :aria-controls="'multiCollapseprospecteur' + index"
                          right
                        >
                          mdi-menu-down
                        </v-icon>
                      </span>
                      <div
                        class="collapse multi-collapse"
                        :id="'multiCollapseprospecteur' + index"
                      >
                        <div v-for="(prospecteur, indexV) in equipe?.prospecteur?.slice(
                              1,
                              equipe?.prospecteur?.length
                            )"
                            :key="indexV">
                          <span>{{ prospecteur?.userName }}</span>
                        </div>
                      </div>
                    </span>
                    <div v-else>
                      <span>aucun prospecteur</span>
                    </div>
                  </div>
                </td>
                <td>
                  <div >
                    <div v-if="equipe?.Techniciens?.length > 0" column>
                      <span>{{ equipe?.Techniciens[0]?.userName }}
                        <v-icon
                          v-if="equipe?.Techniciens?.length > 1"
                          data-toggle="collapse"
                          :data-target="'#multiCollapseprospecteur' + index"
                          aria-expanded="false"
                          :aria-controls="'multiCollapseprospecteur' + index"
                          right
                        >
                          mdi-menu-down
                        </v-icon>
                      </span>
                      <div
                        class="collapse multi-collapse"
                        :id="'multiCollapseprospecteur' + index"
                      >
                        <div v-for="(Technicien, indexV) in equipe?.Techniciens?.slice(
                              1,
                              equipe?.Techniciens?.length
                            )"
                            :key="indexV">
                          <span>{{ Technicien?.userName }}</span>
                        </div>
                      </div>
                    </div>
                    <div v-else>
                      <span>aucun Technicien</span>
                    </div>
                  </div>
                </td>
                <td>
                  <div >
                    <div v-if="equipe?.Ingenieurs?.length > 0" column>
                      <span>{{ equipe?.Ingenieurs[0]?.userName }}
                        <v-icon
                          v-if="equipe?.Ingenieurs?.length > 1"
                          data-toggle="collapse"
                          :data-target="'#multiCollapseprospecteur' + index"
                          aria-expanded="false"
                          :aria-controls="'multiCollapseprospecteur' + index"
                          right
                        >
                          mdi-menu-down
                        </v-icon>
                      </span>
                      <div
                        class="collapse multi-collapse"
                        :id="'multiCollapseprospecteur' + index"
                      >
                        <div v-for="(Ingenieur, indexV) in equipe?.Ingenieurs?.slice(1,equipe?.Ingenieurs?.length)"
                            :key="indexV">
                          <span
                            
                            >{{ Ingenieur?.userName }}</span>
                        </div>
                      </div>
                    </div>
                    <div v-else>
                      <span>aucun Ingenieur</span>
                    </div>
                  </div>
                </td>
                <td>
                  <div class="btn-group td-statut">
                    <div class="d-flex flex-row justify-content-start align-items-center">
                      <v-btn
                        @click="ouvrireDialogeModifier(equipe)"
                        :color='$colors[0]'
                        icon
                        dark
                      >
                        <v-icon>mdi-account-edit</v-icon>
                      </v-btn>
                      <v-btn
                        @click="ouvrireDialogesupprimerEquipe(equipe)"
                        color="error"
                        icon
                        dark
                      >
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
            <noDataTable
              class="ma-4"
              v-if="lesEquipes.length == 0 && loadingTable == false"
            />
            <progressCircular class="ma-8" v-if="loadingTable == true" />
          </template>
        </datatable>
      </div>

      <pagination
        v-if="lesEquipes?.length > 0"
        :pagination="pagination"
        @selectNumberOfRecords="rechercheData"
        @first="getData(pagination.firstPage)"
        @last="getData(pagination.lastPage)"
        @prev="getData(pagination.prevPageUrl)"
        @current="getData(pagination.currentPage)"
        @next="getData(pagination.nextPageUrl)"
      >
      </pagination>
    </v-card>
  </div>
</template>

<script>
import "jquery/dist/jquery.min.js";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.bundle";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import Datatable from "../../components/dataTable/Datatable.vue";
import Pagination from "../../components/dataTable/Pagination.vue";
import ajouterEquipe from "./ajouterEquipe.vue";
import modifierEquipe from "./modifierEquipe.vue";
import noDataTable from "../../components/no-data-table.vue";
import progressCircular from "../../components/progress-circular.vue";
import axios from "@/plugins/axios";
export default {
  name: "listeEquipe",
  components: {
    datatable: Datatable,
    pagination: Pagination,
    ajouterEquipe: ajouterEquipe,
    modifierEquipe: modifierEquipe,
    noDataTable,
    progressCircular,
  },
  data() {
    let sortOrders = {};
    let columns = [
      { width: "13vw", label: "Date", name: "date", sortable: true },
      { width: "13vw", label: "Equipe", name: "Equipe", sortable: true },
      { width: "13vw", label: "ADV", name: "ADV", sortable: true },
      { width: "13vw", label: "Chef d'equipe", name: "Chef équipe", sortable: true },
      { width: "13vw", label: "Chef ingenieur", name: "Chef ingenieur", sortable: true },
      { width: "13vw", label: "Prospecteurs", name: "Prospecteurs", sortable: false },
      { width: "13vw", label: "Techniciens", name: "Techniciens", sortable: false },
      { width: "13vw", label: "Ingenieurs", name: "Ingenieurs", sortable: false },
      { width: "10vw", label: "Actions", name: "status", sortable: false },
    ];
    columns.forEach((column) => {
      sortOrders[column.name] = -1;
    });
    return {
      loadingTable: false,
      totalElements: 0,
      equipeModifier: null,
      ajouterEquipe: false,
      modifierEquipe: false,
      dialog: false,
      loading: false,
      error: false,
      commentRefuser: "",
      messageDialoge: "",
      ouvrireDialogeRefuser: false,
      lesEquipes: [],
      columns: columns,
      sortKey: "date",
      sortOrders: sortOrders,
      perPage: ["15", "15", "30"],
      tableData: {
        draw: 0,
        length: 15,
        search: "",
        column: 0,
        dir: "desc",
      },
      demandeTraiter: null,
      pagination: {
        perPage: ["10", "15", "30"],
        firstPage: 1,
        lastPage: "",
        currentPage: "",
        total: "",
        lastPageUrl: "",
        nextPageUrl: "",
        prevPageUrl: "",
        from: "",
        to: "",
      },

      rules: {
        required(finPhrase) {
          return (value) => !!value || `Veuillez saisir ${finPhrase}`;
        },
      },
    };
  },
  async created() {
    this.loadingTable = true;
    if (this.$store.getters.getUserToken == "noToken") {
      localStorage.removeItem("vendeurName");
      localStorage.removeItem("typeUser");
      localStorage.removeItem("token");
      this.$router.push("/login");
    } else {
      localStorage.setItem("typeUser", this.$store.getters.getUserRole);
    }
    await this.getData();
    this.loadingTable = false;
  },
  methods: {
    filterByStatus(){
      var pageNumber = 1
      if(this.filterStatus != 'tout'){
        this.tableData.statusFilter = this.filterStatus
        this.getData(1)
      }else{
        this.tableData.statusFilter = ""
        this.getData(1)
      }
    },
    rechercheData({ type, key }) {
      if (type == "search") {
        var pageNumber = 1;
        this.tableData.search = key;
      } else if (type == "nbrRecords") {
        this.tableData.length = key;
      }
      this.tableData.draw++;
      axios({
        url: process.env.VUE_APP_URL_API_CLIENT + "getAllEquipeServerSide/",
        method: "POST",
        data: {
          dataTable: this.tableData,
          pageNumber: pageNumber,
          token: this.$store.getters.getUserToken,
        },
      })
        .then((response) => {
          if (response.data.result == true) {
            let data = response.data.data;
            if (this.tableData.draw == data.draw) {
              this.lesEquipes = data.data.data;
              this.configPagination(data.data);
            }
          }
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    supprimerEquipe() {
      //fermer modal comment
      this.ouvrireDialogeRefuser = false;
      //open modal loading
      this.dialog = true;
      this.loading = true;
      this.error = false;
      this.messageDialoge = "";

      let self = this;
      var formData = new FormData();
      formData.append("token", this.$store.getters.getUserToken);
      formData.append("idEquipe", this.demandeTraiter.id);
      axios({
        url: process.env.VUE_APP_URL_API_CLIENT + "supprimerEquipe/",
        method: "POST",
        data: formData,
      })
        .then((res) => {
          this.fermerDialogesupprimerEquipe();
          this.getData();
        })
        .catch((err) => {
          this.fermerDialogesupprimerEquipe();
          this.getData();
        });
    },
    ouvrireDialogesupprimerEquipe(item) {
      this.demandeTraiter = item;
      this.ouvrireDialogeRefuser = true;
    },
    fermerDialogesupprimerEquipe() {
      this.demandeTraiter = "";
      this.ouvrireDialogeRefuser = false;
    },
    getEtatModifierEquipe(varr) {
      this.modifierEquipe = varr;
      this.getData();
    },
    getEtatajouterEquipe(varr) {
      this.ajouterEquipe = varr;
      this.getData();
    },
    ouvrireDialogeModifier(item) {
      this.equipeModifier = item;
      this.modifierEquipe = true;
    },
    async getData(pageNumber = 1) {
      this.tableData.draw++;
      await axios({
        url: process.env.VUE_APP_URL_API_CLIENT + "getAllEquipeServerSide/",
        method: "POST",
        data: {
          dataTable: this.tableData,
          pageNumber: pageNumber,
          token: this.$store.getters.getUserToken,
        },
      })
        .then((response) => {
          if (response.data.result == true) {
            let data = response.data.data;
            if (this.tableData.draw == data.draw) {
              this.lesEquipes = data.data.data;
              this.configPagination(data.data);
            }
          }
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    configPagination(data) {
      this.pagination.lastPage = data.last_page;
      this.pagination.currentPage = data.current_page;
      this.pagination.total = data.total;
      this.pagination.lastPageUrl = data.last_page_url;
      this.pagination.nextPageUrl = data.next_page_url;
      this.pagination.prevPageUrl = data.prev_page_url;
      this.pagination.from = data.from;
      this.pagination.to = data.to;
    },
    sortBy(key) {
      this.sortKey = key;
      this.sortOrders[key] = this.sortOrders[key] * -1;
      this.tableData.column = this.getIndex(this.columns, "name", key);
      this.tableData.dir = this.sortOrders[key] === 1 ? "asc" : "desc";
      this.getData();
    },
    getIndex(array, key, value) {
      return array.findIndex((i) => i[key] == value);
    },
  },
};
</script>
<style scoped>
::v-deep #table-liste-equipe thead th {
  color: white;
  font-weight: bold;
}
@media only screen and (max-width: 760px),
  (min-device-width: 768px) and (max-device-width: 1024px) {
  @import "../../components/dataTable/style.css";
  #table-liste-equipe td:nth-of-type(1):before {
    content: "Date";
  }
  #table-liste-equipe td:nth-of-type(2):before {
    content: "Equipe";
  }
  #table-liste-equipe td:nth-of-type(3):before {
    content: "ADV";
  }
  #table-liste-equipe td:nth-of-type(4):before {
    content: "chef d'equipe";
  }
  #table-liste-equipe td:nth-of-type(5):before {
    content: "Vendeurs";
  }
  #table-liste-equipe td:nth-of-type(6):before {
    content: "Prospecteurs";
  }
  #table-liste-equipe td:nth-of-type(7):before {
    content: "Statut";
  }
}
</style>
