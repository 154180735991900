<template>
  <div>
    <v-icon class="ma-4" :color="$colors[0]" @click="back()" style="cursor: pointer"
      >mdi-arrow-left</v-icon
    >
    <div
      v-if="hideFolderProps == false"
    >
      <v-row class="px-4 mb-8" justify="center">
        <v-col
          v-for="(value, index) in Object.entries(ListFolder)"
          :key="index"
          class="d-flex child-flex"
          cols="3"
        >
          <v-card
            @click="next(value)"
            elevation="0"
            height="100%"
            style="min-height: 125px; background-color: #ffffff"
            class="d-flex flex-column align-items-center"
          >
            <div
              style="height: 100%; width: 100%"
              class="rounded-lg d-flex flex-column align-items-end justify-center"
            >
              <div
                style="width: 100%"
                class="d-flex flex-column align-center justify-space-around"
              >
                <i
                  :style="{ 'font-size': '65px', color: $colors[5] }"
                  class="fas fa-folder"
                ></i>
                <div v-if="listCategorie.includes(value[0])">
                  <span
                    :style="{ color: $colors[1] }"
                    v-if="value[1]?.name == 'visiteTechniqueFiles'"
                    >Visite Technique</span
                  >
                  <span
                    :style="{ color: $colors[1] }"
                    v-if="value[1]?.name  == 'visiteInstallationFiles'"
                    >Visite Installation</span
                  >
                  <span :style="{ color: $colors[1] }" v-if="value[1]?.name  == 'documentPhotos'"
                    >Document Photos</span
                  >
                </div>
                <span class="fontSizeMediaQuery" :style="{ color: $colors[1] }" v-else>{{
                  value[1]?.name 
                }}</span>
              </div>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </div>
</template>
<script>
import Photos from "@/views/Photos.vue";
export default {
  name: "Dossiers",
  props: ["ListFolder", "hideFolderProps", "previousDossierOrder"],
  components: { Photos },
  data() {
    return {
      fileImageModel: [],
      dossierOrder: "",
      listCategorie: [
        "visiteTechniqueFiles",
        "visiteInstallationFiles",
        "documentPhotos",
      ],
    };
  },
  watch: {
  },
  methods: {
    back() {
        this.$emit("back");
    },
    next(object) {
        var tmpObject = {}
        try{
            if(object[1]?.name != undefined){
                tmpObject['name'] = object[1]?.name 
                tmpObject['id'] = object[1]?.id 
            }else{
                tmpObject['name'] = object[1]?.name 
                tmpObject['id'] = null
            } 
        }catch(error){
            tmpObject['name'] = object[1]?.name 
            tmpObject['id'] = null
        }
        try{
            if(object[1]?.typeDossier != undefined){
                tmpObject['typeDossier'] = object[1]?.typeDossier 
            }else{
                tmpObject['typeDossier'] = null
            } 
        }catch(error){
            tmpObject['typeDossier'] = null
        }
        
        this.$emit("next", tmpObject);
    },
  },
};
</script>
<style scoped>
@media screen and (max-width: 1920px) {
  .fontSizeMediaQuery {
    font-size: 12px;
  }
}
</style>
