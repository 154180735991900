<template>
  <v-card style="margin-right: 32px">
    <v-toolbar class="rounded-t-lg" :style="{ background: $colors[0] }" dark>
      <v-row>
        <v-col cols="12" sm="12" md="6" xl="6" lg="6">
          <span class="pl-0">Questions Faisabilité</span>
        </v-col>
        <v-col cols="12" sm="12" md="6" xl="6" lg="6">
          <v-btn @click="getDataFromVisiteTechnique" color="indigo"
            >Appliquer Visite technique data</v-btn
          >
        </v-col>
      </v-row>
    </v-toolbar>
    <v-card-text class="p-4">
      <div class="d-flex flex-column w-100">
        <!-- modal add borne -->
        <b-modal
          id="modal-add-bornes"
          title="Ajouter des bornes"
          cancel-title="Annuler"
          ok-title="Ajouter"
          ok-variant="info"
          header-bg-variant="info"
          cancel-variant="dark"
          @show="resetModal"
          @hidden="resetModal"
          @ok="handleOk"
        >
          <form
            ref="create-borne-by-type"
            class="p-2"
            @submit.stop.prevent="addBorneByType"
          >
            <v-autocomplete
              v-model="borneToCreate"
              :items="listBornes"
              item-text="nom"
              item-value="id"
              label="Type de Borne"
              dense
              outlined
            />
            <v-text-field
              type="number"
              v-model="nbrBorneToCreate"
              label="Nombre des bornes"
              outlined
              dense
              suffix="U"
              :rules="[rules.isNumber]"
            />
          </form>
        </b-modal>

        <!-- questions form create devis questions -->
        <v-form ref="formCreateDevisQuestions">
          <div class="w-100 p-4">
            <!-- content -->
            <v-row>
              <v-col
                cols="12"
                sm="4"
                md="3"
                lg="3"
                xl="3"
                style="border: 2px solid #8aa7b4"
                class="text-center"
              >
                <div>
                  <img :src="require(`@/assets/images/tgbt.png`)" class="tgbt-img" />
                </div>
                <div class="pt-2">
                  <v-autocomplete
                    v-if="generale.nbrPointDeRecharge >= 2"
                    v-model="generale.alimentation.disjoncteur"
                    :items="
                      disjoncteur['generale' + generale.alimentation.typeAlimentation]
                    "
                    item-text="value"
                    return-object
                    label="disjoncteur général"
                    class="mb-4"
                    dense
                    hide-details
                    outlined
                    :rules="[rules.required]"
                  />
                </div>
                <div class="pt-2">
                  <v-autocomplete
                    v-model="generale.parafoudre"
                    :items="parafoudre"
                    item-text="value"
                    return-object
                    label="Parafoudre 3 Poles+Neutre"
                    class="mb-4"
                    dense
                    multiple
                    hide-details
                    outlined
                    :rules="[rules.required]"
                  />
                </div>
              </v-col>
              <v-col cols="12" sm="4" md="6" lg="6" xl="6" class="text-center">
                <v-row>
                  <v-col cols="12" sm="6" md="4" lg="4" xl="4">
                    <v-text-field
                      v-model="generale.alimentation.distance_alimentation"
                      label="Distance Alimentation"
                      outlined
                      dense
                      hide-details
                      suffix="ML"
                      :rules="[rules.required, rules.isNumberOrFloat]"
                    />
                  </v-col>
                  <v-col cols="12" sm="6" md="8" lg="8" xl="8">
                    <v-autocomplete
                      v-model="generale.alimentation.cableAlimentation"
                      :items="depart[generale.alimentation.typeAlimentation]"
                      item-text="value"
                      return-object
                      label="Alimentation"
                      class="mb-4"
                      dense
                      hide-details
                      outlined
                      :rules="[rules.required]"
                    />
                  </v-col>
                  <v-col cols="12" sm="6" md="6" lg="6" xl="6">
                    <v-radio-group
                      v-model="generale.communicante"
                      label="Solution communicante:"
                      row
                    >
                      <v-radio label="oui" value="oui"></v-radio>
                      <v-radio label="non" value="non"></v-radio>
                    </v-radio-group>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="6"
                    lg="6"
                    xl="6"
                    v-if="generale.communicante == 'oui'"
                  >
                    <v-radio-group
                      v-model="generale.typeCommunication"
                      @change="setSolutionCommunicate"
                      label="Par: "
                      row
                    >
                      <v-radio label="Filaire" value="Filaire"></v-radio>
                      <v-radio label="GSM" value="GSM"></v-radio>
                    </v-radio-group>
                  </v-col>
                </v-row>
              </v-col>
              <v-col
                cols="12"
                sm="4"
                md="3"
                lg="3"
                xl="3"
                style="border: 2px solid #8aa7b4"
                class="text-center"
              >
                <v-autocomplete
                  v-model="generale.tdIrve.emplacement"
                  :items="emplacementDeTdIrve"
                  item-text="value"
                  return-object
                  label="Emplacement de TD-IRVE"
                  class="mb-4"
                  dense
                  hide-details
                  outlined
                  :rules="[rules.required]"
                />
                <img :src="require(`@/assets/images/tdirve.png`)" class="tdirve-img" />
                <div class="pt-2">
                  <v-autocomplete
                    v-if="generale.nbrPointDeRecharge >= 2"
                    v-model="generale.tdIrve.Repartiteur"
                    :items="Repartiteur"
                    item-text="value"
                    return-object
                    label="Répartiteur"
                    class="mb-4"
                    dense
                    hide-details
                    outlined
                    :rules="[rules.required]"
                  />
                </div>
                <hr />
                <!-- partie de botton d'arret d'urgance -->
                <v-expansion-panels>
                  <v-expansion-panel>
                    <v-expansion-panel-header>
                      <small>Boutons d'arrêt d'urgence</small>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-btn icon @click="createUneBottonDeArretDeUrgance(10)"
                        ><v-icon>mdi-plus</v-icon></v-btn
                      >
                      <v-btn
                        icon
                        @click="deleteUneBottonDeArretDeUrgance"
                        v-if="generale.tdIrve.bottonDarretDurgence.length > 1"
                        ><v-icon>mdi-minus</v-icon></v-btn
                      >
                      <div
                        v-for="(botton, index) in generale.tdIrve.bottonDarretDurgence"
                        :key="'botton' + index"
                      >
                        <v-autocomplete
                          v-model="botton.botton"
                          :items="bottonsDarretUrgance"
                          item-text="value"
                          return-object
                          label="Botton"
                          class="mb-4"
                          dense
                          hide-details
                          outlined
                          :rules="[rules.required]"
                        />
                        <v-autocomplete
                          v-model="botton.depart"
                          :items="depart['bobineMnx']"
                          item-text="value"
                          return-object
                          label="Départ Botton"
                          class="mb-4"
                          dense
                          hide-details
                          outlined
                          :rules="[rules.required]"
                        />
                        <v-autocomplete
                          v-model="botton.bobineMnx"
                          :items="bobineMnx"
                          item-text="value"
                          return-object
                          label="Bobine Mnx"
                          class="mb-4"
                          dense
                          hide-details
                          outlined
                          :rules="[rules.required]"
                        />
                        <v-autocomplete
                          v-model="botton.Disjonteur"
                          :items="disjoncteur['bobineMnx']"
                          item-text="value"
                          return-object
                          label="Disjoncteur"
                          class="mb-4"
                          dense
                          hide-details
                          outlined
                          :rules="[rules.required]"
                        />
                        <v-text-field
                          v-model="botton.distance"
                          label="Distance"
                          class="mb-4"
                          dense
                          suffix="ML"
                          outlined
                          :rules="[rules.required]"
                        />
                      </div>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>

                <!-- partie de botton d'arret d'urgance -->
                <v-expansion-panels class="pt-2">
                  <v-expansion-panel>
                    <v-expansion-panel-header>
                      <small>Piquets de terre</small>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-btn icon @click="createUnPiquetsDeTerre(10)"
                        ><v-icon>mdi-plus</v-icon></v-btn
                      >
                      <v-btn
                        icon
                        @click="deleteUnPiquetsDeTerre"
                        v-if="generale.tdIrve.ProtectionTerre.length > 1"
                        ><v-icon>mdi-minus</v-icon></v-btn
                      >
                      <div
                        v-for="(piquet, index) in generale.tdIrve.ProtectionTerre"
                        :key="'piquet' + index"
                      >
                        <v-autocomplete
                          v-model="piquet.cable"
                          :items="ProtectionTerre.cable"
                          item-text="value"
                          return-object
                          label="Départ piquet"
                          class="mb-4"
                          dense
                          hide-details
                          outlined
                          :rules="[rules.required]"
                        />
                        <v-autocomplete
                          v-model="piquet.piquets"
                          :items="ProtectionTerre.piquets"
                          item-text="value"
                          return-object
                          label="piquet"
                          class="mb-4"
                          dense
                          hide-details
                          outlined
                          :rules="[rules.required]"
                        />
                        <v-autocomplete
                          v-model="piquet.cosses"
                          :items="ProtectionTerre.cosses"
                          item-text="value"
                          return-object
                          label="cosses"
                          class="mb-4"
                          dense
                          hide-details
                          outlined
                          :rules="[rules.required]"
                        />
                        <v-text-field
                          v-model="piquet.cable.distance"
                          label="Distance"
                          class="mb-4"
                          dense
                          suffix="ML"
                          outlined
                          :rules="[rules.required]"
                        />
                      </div>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-col>
            </v-row>
            <div class="borne-wrapper">
              <div class="nbr-borne-wrapper">
                <div class="item" style="width: 100%">
                  <v-row> </v-row>
                </div>
                <div class="item">
                  <div class="d-flex justify-end">
                    <b-button
                      v-b-modal.modal-add-bornes
                      class="borne-btn-add"
                      title="Ajouter des bornes"
                    >
                      <v-icon class="borne-btn-add-icon">mdi-plus</v-icon>
                    </b-button>
                    <span class="borne-counter">{{ listBorneSelected.length }}</span>
                  </div>
                </div>
              </div>
              <div class="borne-list">
                <div class="borne" v-for="(item, index) in listBorneSelected">
                  <div
                    class="d-flex flex-row flex-nowrap align-items-start justify-content-between gap-2"
                  >
                    <p class="title">Borne N: {{ index + 1 }}</p>
                    <v-icon
                      size="32"
                      class="borne-btn-close"
                      @click="() => removeBorne(index)"
                      >mdi-close-circle</v-icon
                    >
                  </div>
                  <div
                    class="d-flex flex-row flex-nowrap align-items-start justify-content-between gap-2"
                  >
                    <v-radio-group
                      @change="changeEtatSignalisation(item)"
                      v-model="item.signalisation.etat"
                      label="Signalisation"
                      row
                    >
                      <v-radio label="oui" value="oui"></v-radio>
                      <v-radio label="non" value="non"></v-radio>
                    </v-radio-group>
                  </div>
                  <div
                    class="d-flex flex-row flex-nowrap align-items-start justify-content-between gap-2"
                  >
                    <v-radio-group
                      @change="changePoseMurale(item)"
                      v-model="item.poseMurale.etat"
                      label="Pose murale"
                      row
                      :key="'poseMural' + index"
                    >
                      <v-radio label="oui" value="oui"></v-radio>
                      <v-radio label="non" value="non"></v-radio>
                    </v-radio-group>
                  </div>
                  <div>
                    <v-autocomplete
                      v-model="item.id"
                      :items="listBornes"
                      item-text="nom"
                      item-value="id"
                      label="Type de Borne"
                      class="mb-4"
                      dense
                      disabled
                      hide-details
                      outlined
                      :rules="[rules.required]"
                    />
                    <v-text-field
                      v-model="item.distance"
                      label="Distance"
                      class="mb-4"
                      outlined
                      dense
                      hide-details
                      suffix="ML"
                      @input="changeDistance(item)"
                      :rules="[rules.required, rules.isNumberOrFloat]"
                    />
                    <v-expansion-panels>
                      <v-expansion-panel>
                        <v-expansion-panel-header>
                          <small>Départ</small>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                          <v-autocomplete
                            v-model="item.depart"
                            :items="depart[item.puissance]"
                            item-text="value"
                            return-object
                            label="Départ"
                            class="mb-4"
                            dense
                            hide-details
                            outlined
                            :rules="[rules.required]"
                          />
                          <v-autocomplete
                            v-model="item.disjoncteur"
                            :items="disjoncteur[item.puissance]"
                            item-text="value"
                            return-object
                            label="Disjoncteur"
                            class="mb-4"
                            dense
                            hide-details
                            outlined
                            :rules="[rules.required]"
                          />
                          <v-autocomplete
                            v-model="item.diff"
                            :items="differentiel[item.puissance]"
                            item-text="value"
                            return-object
                            label="différentiel"
                            class="mb-4"
                            dense
                            hide-details
                            outlined
                            :rules="[rules.required]"
                          />
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                  </div>
                  <div v-if="item.priseDeService == 'oui'">
                    <hr />
                    <v-expansion-panels>
                      <v-expansion-panel>
                        <v-expansion-panel-header>
                          <small>Prise de services</small>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                          <v-autocomplete
                            v-model="item.cablePrise"
                            :items="depart['prise']"
                            item-text="value"
                            return-object
                            label="Départ PS"
                            class="mb-4"
                            dense
                            hide-details
                            outlined
                            :rules="[rules.required]"
                          />
                          <v-autocomplete
                            v-model="item.disjoncteurPrise"
                            :items="disjoncteur['prise']"
                            item-text="value"
                            return-object
                            label="Disjoncteur PS"
                            class="mb-4"
                            dense
                            hide-details
                            outlined
                            :rules="[rules.required]"
                          />
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                  </div>
                  <div
                    v-if="
                      generale.communicante == 'oui' &&
                      generale.typeCommunication == 'GSM'
                    "
                  >
                    <hr />
                    <v-expansion-panels>
                      <v-expansion-panel>
                        <v-expansion-panel-header>
                          <small>Courant faible - Telecom</small>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                          <v-autocomplete
                            v-model="item.telecom.baie"
                            :items="telecomObject.baie"
                            item-text="value"
                            return-object
                            label="Baie Télecom"
                            class="mb-4"
                            dense
                            hide-details
                            outlined
                            :rules="[rules.required]"
                          />
                          <v-autocomplete
                            v-model="item.telecom.bandeau"
                            :items="telecomObject.bandeau"
                            item-text="value"
                            return-object
                            label="Bandeau de brassage"
                            class="mb-4"
                            dense
                            hide-details
                            outlined
                            :rules="[rules.required]"
                          />
                          <v-autocomplete
                            v-model="item.telecom.Cable"
                            :items="telecomObject.Cable"
                            item-text="value"
                            return-object
                            label="Cable"
                            class="mb-4"
                            dense
                            hide-details
                            outlined
                            :rules="[rules.required]"
                          />
                          <v-autocomplete
                            v-model="item.telecom.Noyaux"
                            :items="telecomObject.Noyaux"
                            item-text="value"
                            return-object
                            label="Noyaux"
                            class="mb-4"
                            dense
                            hide-details
                            outlined
                            :rules="[rules.required]"
                          />
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                  </div>
                  <div v-if="item.signalisation.etat == 'oui'">
                    <hr />
                    <v-expansion-panels>
                      <v-expansion-panel>
                        <v-expansion-panel-header>
                          <small>les signalisations</small>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                          <v-autocomplete
                            v-model="item.signalisation.Verticale"
                            :items="signalisation.Verticale"
                            item-text="value"
                            return-object
                            multiple
                            label="Signalisation Vertical"
                            class="mb-4"
                            dense
                            hide-details
                            outlined
                            :rules="[rules.required]"
                          />
                          <v-autocomplete
                            v-model="item.signalisation.Horizontale"
                            :items="signalisation.Horizontale"
                            item-text="value"
                            return-object
                            multiple
                            label="Signalisation Horizontale"
                            class="mb-4"
                            dense
                            hide-details
                            outlined
                            :rules="[rules.required]"
                          />
                          <v-autocomplete
                            v-model="item.signalisation.Mecanique"
                            :items="signalisation.Mecanique"
                            item-text="value"
                            return-object
                            multiple
                            label="Signalisation Mecanique"
                            class="mb-4"
                            dense
                            hide-details
                            outlined
                            :rules="[rules.required]"
                          />
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                  </div>
                  <div
                    v-if="item.poseMurale.etat == 'oui' || item.poseMurale.etat == 'non'"
                  >
                    <hr />
                    <v-expansion-panels>
                      <v-expansion-panel>
                        <v-expansion-panel-header>
                          <small>raccordement d'une IRVE type A</small>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                          <v-autocomplete
                            v-model="item.poseMurale.PoseIRVESign"
                            :items="PoseIRVESign[item.poseMurale.etat]"
                            item-text="value"
                            return-object
                            label="Pose et raccordement d'une IRVE type A"
                            class="mb-4"
                            dense
                            hide-details
                            outlined
                            :rules="[rules.required]"
                          />
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <v-card>
                <v-tabs
                  v-model="tab"
                  background-color="#5D8AA8"
                  centered
                  dark
                  icons-and-text
                >
                  <v-tabs-slider></v-tabs-slider>
                  <v-tab href="#tab-one">
                    <small
                      >Génie Civil
                      <v-icon color="red" v-if="verifierRules('genieCivil')"
                        >mdi-alert-outline</v-icon
                      ></small
                    >
                    <v-icon>mdi-account-hard-hat-outline</v-icon>
                  </v-tab>

                  <v-tab href="#tab-two">
                    <small
                      >Maçonnerie
                      <v-icon color="red" v-if="verifierRules('Maconnerie')"
                        >mdi-alert-outline</v-icon
                      ></small
                    >
                    <v-icon>mdi-dump-truck</v-icon>
                  </v-tab>

                  <v-tab href="#tab-three">
                    <small
                      >Etude de fin de chantier & Suivi
                      <v-icon color="red" v-if="verifierRules('etudeSuivi')"
                        >mdi-alert-outline</v-icon
                      ></small
                    >
                    <v-icon>mdi-archive-plus-outline</v-icon>
                  </v-tab>
                </v-tabs>
                <v-tabs-items v-model="tab">
                  <v-tab-item value="tab-one">
                    <v-card flat>
                      <v-row>
                        <v-col cols="12" sm="6" md="6" lg="6" xl="6">
                          <small>CHAMBRE DE TIRAGE</small>
                          <hr />

                          <v-radio-group
                            class="pt-0"
                            v-model="generale.ChambreDeTirage.etat"
                            @change="selectedChambreDeTirage = []"
                            label="Est-ce que solution de recharge besoin de CHAMBRE DE TIRAGE:"
                            row
                          >
                            <v-radio label="oui" value="oui"></v-radio>
                            <v-radio label="non" value="non"></v-radio>
                          </v-radio-group>
                        </v-col>
                        <v-col cols="12" sm="6" md="6" lg="6" xl="6">
                          <small>Fouille et Massif béton coffré</small>
                          <hr />
                          <v-radio-group
                            class="pt-0"
                            v-model="generale.FouilleetMassif.etat"
                            @change="selectedFouilleetMassif = []"
                            label="Est-ce que solution de recharge besoin d'une fouille et d'un massif béton coffré:"
                            row
                          >
                            <v-radio label="oui" value="oui"></v-radio>
                            <v-radio label="non" value="non"></v-radio>
                          </v-radio-group>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" sm="6" md="6" lg="6" xl="6">
                          <!--  CHAMBRE DE TIRAGE -->
                          <v-expansion-panels
                            v-if="generale.ChambreDeTirage.etat == 'oui'"
                            class="pt-2 pb-2"
                          >
                            <v-expansion-panel>
                              <v-expansion-panel-header>
                                <small>CHAMBRE DE TIRAGE</small
                                ><v-icon
                                  color="red"
                                  v-if="
                                    verifierRules(
                                      'obligatoire',
                                      selectedChambreDeTirage,
                                      generale.ChambreDeTirage.etat
                                    )
                                  "
                                  >mdi-alert-outline</v-icon
                                >
                              </v-expansion-panel-header>
                              <v-expansion-panel-content>
                                <v-row>
                                  <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                                    <v-autocomplete
                                      v-model="selectedChambreDeTirage"
                                      :items="ChambresDesTirages"
                                      item-text="value"
                                      return-object
                                      label="Type Chambre De Tirage"
                                      class="mb-4"
                                      dense
                                      hide-details
                                      outlined
                                      multiple
                                      :rules="[rules.required]"
                                    />
                                  </v-col>
                                  <v-col
                                    cols="12"
                                    sm="12"
                                    md="12"
                                    lg="12"
                                    xl="12"
                                    v-for="(chambre, index) in selectedChambreDeTirage"
                                    :key="'ChambreDeTirage' + index"
                                  >
                                    <v-text-field
                                      v-model="chambre.distance"
                                      :label="chambre.value"
                                      outlined
                                      dense
                                      hide-details
                                      suffix="U"
                                      :rules="[rules.required, rules.isNumberOrFloat]"
                                    />
                                  </v-col>
                                </v-row>
                              </v-expansion-panel-content>
                            </v-expansion-panel>
                          </v-expansion-panels>
                        </v-col>
                        <v-col cols="12" sm="6" md="6" lg="6" xl="6">
                          <!--  massif béton coffré -->
                          <v-expansion-panels
                            v-if="generale.FouilleetMassif.etat == 'oui'"
                            class="pt-2 pb-2"
                          >
                            <v-expansion-panel>
                              <v-expansion-panel-header>
                                <small>Fouille et Massif béton coffré</small
                                ><v-icon
                                  color="red"
                                  v-if="
                                    verifierRules(
                                      'obligatoire',
                                      selectedFouilleetMassif,
                                      generale.FouilleetMassif.etat
                                    )
                                  "
                                  >mdi-alert-outline</v-icon
                                >
                              </v-expansion-panel-header>
                              <v-expansion-panel-content>
                                <v-row>
                                  <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                                    <v-autocomplete
                                      v-model="selectedFouilleetMassif"
                                      :items="FouillesetMassifs"
                                      item-text="value"
                                      return-object
                                      label="Type de Fouille et Massif Béton"
                                      class="mb-4"
                                      dense
                                      hide-details
                                      outlined
                                      multiple
                                      :rules="[rules.required]"
                                    />
                                  </v-col>
                                  <v-col
                                    cols="12"
                                    sm="12"
                                    md="12"
                                    lg="12"
                                    xl="12"
                                    v-for="(chambre, index) in selectedFouilleetMassif"
                                    :key="'FouilleetMassif' + index"
                                  >
                                    <v-text-field
                                      v-model="chambre.distance"
                                      :label="chambre.value"
                                      outlined
                                      dense
                                      hide-details
                                      suffix="U"
                                      :rules="[rules.required, rules.isNumberOrFloat]"
                                    />
                                  </v-col>
                                </v-row>
                              </v-expansion-panel-content>
                            </v-expansion-panel>
                          </v-expansion-panels>
                        </v-col>

                        <v-col cols="12" sm="6" md="6" lg="6" xl="6">
                          <!--  CHAMBRE DE TIRAGE -->
                          <v-expansion-panels class="pt-2 pb-2">
                            <v-expansion-panel>
                              <v-expansion-panel-header>
                                <small>Protection et schémas de cables</small
                                ><v-icon
                                  color="red"
                                  v-if="
                                    verifierRules(
                                      'obligatoire',
                                      generale.protectionEtSchemasDeCables,
                                      generale.ChambreDeTirage.etat
                                    )
                                  "
                                  >mdi-alert-outline</v-icon
                                >
                              </v-expansion-panel-header>
                              <v-expansion-panel-content>
                                <v-row>
                                  <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                                    <v-autocomplete
                                      v-model="generale.protectionEtSchemasDeCables"
                                      :items="protectionEtSchemasDeCables"
                                      item-text="value"
                                      return-object
                                      label="Schémas de cables"
                                      class="mb-4"
                                      dense
                                      hide-details
                                      outlined
                                      multiple
                                      :rules="[rules.required]"
                                    />
                                  </v-col>
                                  <v-col
                                    cols="12"
                                    sm="12"
                                    md="12"
                                    lg="12"
                                    xl="12"
                                    v-for="(
                                      protection, index
                                    ) in generale.protectionEtSchemasDeCables"
                                    :key="'protection' + index"
                                  >
                                    <v-text-field
                                      v-model="protection.distance"
                                      :label="'Distance ' + protection.value"
                                      outlined
                                      dense
                                      hide-details
                                      suffix="ML"
                                      :rules="[rules.required, rules.isNumberOrFloat]"
                                    />
                                  </v-col>
                                </v-row>
                              </v-expansion-panel-content>
                            </v-expansion-panel>
                          </v-expansion-panels>
                        </v-col>
                      </v-row>
                      <!-- plaqueDeRoulement -->
                      <v-row>
                        <v-col cols="12" sm="6" md="6" lg="6" xl="6">
                          <v-autocomplete
                            v-model="generale.OptionsCivile.plaqueDeRoulement"
                            :items="OptionsCivile.plaqueDeRoulement"
                            item-text="value"
                            return-object
                            label="Plaque de roulement "
                            class="mb-4"
                            dense
                            hide-details="auto"
                            outlined
                            multiple
                            :rules="[rules.required]"
                          />
                        </v-col>
                        <v-col
                          cols="12"
                          sm="6"
                          md="6"
                          lg="6"
                          xl="6"
                          v-if="generale.OptionsCivile.plaqueDeRoulement.length > 0"
                          v-for="(item, index) in generale.OptionsCivile
                            .plaqueDeRoulement"
                          :key="'plaqueDeRoulement' + index"
                        >
                          <div class="d-flex flex-row align-start">
                            <v-icon
                              class="pr-4 pt-2"
                              color="red"
                              v-if="
                                verifierRules(
                                  'optionnelle',
                                  generale.OptionsCivile.plaqueDeRoulement
                                )
                              "
                              >mdi-alert-outline</v-icon
                            >
                            <v-text-field
                              v-model="item.distance"
                              :label="'Distance' + item.value"
                              outlined
                              dense
                              hide-details="auto"
                              suffix="ML"
                              :rules="[rules.isNumberOrFloat, rules.greaterThanZero]"
                            />
                          </div>
                        </v-col>
                      </v-row>
                      <!-- geotextile -->
                      <v-row>
                        <v-col cols="12" sm="6" md="6" lg="6" xl="6">
                          <v-autocomplete
                            v-model="generale.OptionsCivile.geotextile"
                            :items="OptionsCivile.geotextile"
                            item-text="value"
                            return-object
                            label="géotextile"
                            class="mb-4"
                            dense
                            hide-details
                            outlined
                            multiple
                            :rules="[rules.required]"
                          />
                        </v-col>
                        <v-col
                          cols="12"
                          sm="6"
                          md="6"
                          lg="6"
                          xl="6"
                          v-if="generale.OptionsCivile.geotextile.length > 0"
                          v-for="(item, index) in generale.OptionsCivile.geotextile"
                          :key="'geotextile' + index"
                        >
                          <div class="d-flex flex-row align-start">
                            <v-icon
                              class="pr-4 pt-2"
                              color="red"
                              v-if="
                                verifierRules(
                                  'optionnelle',
                                  generale.OptionsCivile.geotextile
                                )
                              "
                              >mdi-alert-outline</v-icon
                            >
                            <v-text-field
                              v-model="item.distance"
                              :label="'Distance' + item.value"
                              outlined
                              dense
                              hide-details
                              suffix="ML"
                              :rules="[rules.required, rules.isNumberOrFloat]"
                            />
                          </div>
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-tab-item>
                  <v-tab-item value="tab-two">
                    <v-card flat>
                      <v-row>
                        <v-col cols="12" sm="6" md="6" lg="6" xl="6">
                          <small>Tranchement</small>
                          <hr />
                          <v-radio-group
                            class="pt-0"
                            v-model="generale.tranchement.etat"
                            @change="selectedTranchement = []"
                            label="Y'a t'il de tranchement:"
                            row
                          >
                            <v-radio label="oui" value="oui"></v-radio>
                            <v-radio label="non" value="non"></v-radio>
                          </v-radio-group>
                        </v-col>
                        <v-col cols="12" sm="6" md="6" lg="6" xl="6">
                          <small>Percements</small>
                          <hr />
                          <v-radio-group
                            class="pt-0"
                            v-model="generale.percements.etat"
                            @change="selectedPercements = []"
                            label="Y'a t'il de percements de murs complexes:"
                            row
                          >
                            <v-radio label="oui" value="oui"></v-radio>
                            <v-radio label="non" value="non"></v-radio>
                          </v-radio-group>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" sm="6" md="6" lg="6" xl="6">
                          <!-- tranchement -->
                          <v-expansion-panels
                            v-if="generale.tranchement.etat == 'oui'"
                            class="pt-2 pb-2"
                          >
                            <v-expansion-panel>
                              <v-expansion-panel-header>
                                <small>Tranchement</small
                                ><v-icon
                                  color="red"
                                  v-if="
                                    verifierRules(
                                      'obligatoire',
                                      selectedTranchement,
                                      generale.tranchement.etat
                                    )
                                  "
                                  >mdi-alert-outline</v-icon
                                >
                              </v-expansion-panel-header>
                              <v-expansion-panel-content>
                                <v-row>
                                  <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                                    <v-autocomplete
                                      v-model="selectedTranchement"
                                      :items="tranchement"
                                      item-text="value"
                                      return-object
                                      label="Type Tranchement"
                                      class="mb-4"
                                      dense
                                      hide-details
                                      outlined
                                      multiple
                                      :rules="[rules.required]"
                                    />
                                  </v-col>
                                  <v-col
                                    cols="12"
                                    sm="12"
                                    md="12"
                                    lg="12"
                                    xl="12"
                                    v-for="(tranch, index) in selectedTranchement"
                                    :key="'tranch' + index"
                                  >
                                    <v-text-field
                                      v-model="tranch.distance"
                                      :value="generale.alimentation.distance_alimentation"
                                      :label="tranch.value"
                                      outlined
                                      dense
                                      hide-details
                                      suffix="ML"
                                      :rules="[rules.required, rules.isNumberOrFloat]"
                                    />
                                  </v-col>
                                </v-row>
                              </v-expansion-panel-content>
                            </v-expansion-panel>
                          </v-expansion-panels>
                        </v-col>
                        <v-col cols="12" sm="6" md="6" lg="6" xl="6">
                          <!--  percements de murs complexes -->
                          <v-expansion-panels
                            v-if="generale.percements.etat == 'oui'"
                            class="pt-2 pb-2"
                          >
                            <v-expansion-panel>
                              <v-expansion-panel-header>
                                <small>Percements</small
                                ><v-icon
                                  color="red"
                                  v-if="
                                    verifierRules(
                                      'obligatoire',
                                      selectedPercements,
                                      generale.percements.etat
                                    )
                                  "
                                  >mdi-alert-outline</v-icon
                                >
                              </v-expansion-panel-header>
                              <v-expansion-panel-content>
                                <v-row>
                                  <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                                    <v-autocomplete
                                      v-model="selectedPercements"
                                      :items="Percements"
                                      item-text="value"
                                      return-object
                                      label="Type Percements"
                                      class="mb-4"
                                      dense
                                      hide-details
                                      outlined
                                      multiple
                                      :rules="[rules.required]"
                                    />
                                  </v-col>
                                  <v-col
                                    cols="12"
                                    sm="12"
                                    md="12"
                                    lg="12"
                                    xl="12"
                                    v-for="(perce, index) in selectedPercements"
                                    :key="'perce' + index"
                                  >
                                    <v-text-field
                                      v-model="perce.distance"
                                      :label="perce.value"
                                      outlined
                                      dense
                                      hide-details
                                      suffix="U"
                                      :rules="[rules.required, rules.isNumberOrFloat]"
                                    />
                                  </v-col>
                                </v-row>
                              </v-expansion-panel-content>
                            </v-expansion-panel>
                          </v-expansion-panels>
                        </v-col>
                      </v-row>

                      <!-- ProtectionTravail -->
                      <v-row>
                        <v-col cols="12" sm="6" md="6" lg="6" xl="6">
                          <v-autocomplete
                            v-model="generale.ProtectionTravail"
                            :items="ProtectionTravail"
                            item-text="value"
                            return-object
                            label="Signalisation travaux"
                            class="mb-4"
                            dense
                            hide-details
                            outlined
                            multiple
                            :rules="[rules.required]"
                          />
                        </v-col>
                        <v-col
                          cols="12"
                          sm="6"
                          md="6"
                          lg="6"
                          xl="6"
                          v-if="generale.ProtectionTravail.length > 0"
                          v-for="(pretectionTraveau, index) in generale.ProtectionTravail"
                          :key="'pretectionTraveau' + index"
                        >
                          <div class="d-flex flex-row align-start">
                            <v-icon
                              class="pr-4 pt-2"
                              color="red"
                              v-if="
                                verifierRules('optionnelle', generale.ProtectionTravail)
                              "
                              >mdi-alert-outline</v-icon
                            >
                            <v-text-field
                              v-model="pretectionTraveau.distance"
                              :label="'Distance' + pretectionTraveau.value"
                              outlined
                              dense
                              hide-details
                              suffix="ML"
                              :rules="[rules.required, rules.isNumberOrFloat]"
                            />
                          </div>
                        </v-col>
                      </v-row>
                      <!-- Decoupe -->
                      <v-row>
                        <v-col cols="12" sm="6" md="6" lg="6" xl="6">
                          <v-autocomplete
                            v-model="generale.OptionsCivile.Decoupe"
                            :items="OptionsCivile.Decoupe"
                            item-text="value"
                            return-object
                            label="Découpe d'enrobés"
                            class="mb-4"
                            dense
                            hide-details
                            outlined
                            multiple
                            :rules="[rules.required]"
                          />
                        </v-col>
                        <v-col
                          cols="12"
                          sm="6"
                          md="6"
                          lg="6"
                          xl="6"
                          v-if="generale.OptionsCivile.Decoupe.length > 0"
                          v-for="(item, index) in generale.OptionsCivile.Decoupe"
                          :key="'Decoupe' + index"
                        >
                          <div class="d-flex flex-row align-start">
                            <v-icon
                              class="pr-4 pt-2"
                              color="red"
                              v-if="
                                verifierRules(
                                  'optionnelle',
                                  generale.OptionsCivile.Decoupe
                                )
                              "
                              >mdi-alert-outline</v-icon
                            >
                            <v-text-field
                              v-model="item.distance"
                              :label="'Distance' + item.value"
                              outlined
                              dense
                              hide-details
                              suffix="ML"
                              :rules="[rules.required, rules.isNumberOrFloat]"
                            />
                          </div>
                        </v-col>
                      </v-row>
                      <!-- gravat -->
                      <v-row>
                        <v-col cols="12" sm="6" md="6" lg="6" xl="6">
                          <v-autocomplete
                            v-model="generale.OptionsCivile.gravats"
                            :items="OptionsCivile.gravats"
                            item-text="value"
                            return-object
                            label="Evacuation des gravats"
                            class="mb-4"
                            dense
                            hide-details
                            outlined
                            multiple
                            :rules="[rules.required]"
                          />
                        </v-col>
                        <v-col
                          cols="12"
                          sm="6"
                          md="6"
                          lg="6"
                          xl="6"
                          v-if="generale.OptionsCivile.gravats.length > 0"
                          v-for="(item, index) in generale.OptionsCivile.gravats"
                          :key="'gravats' + index"
                        >
                          <div class="d-flex flex-row align-start">
                            <v-icon
                              class="pr-4 pt-2"
                              color="red"
                              v-if="
                                verifierRules(
                                  'optionnelle',
                                  generale.OptionsCivile.gravats
                                )
                              "
                              >mdi-alert-outline</v-icon
                            >
                            <v-text-field
                              v-model="item.distance"
                              label="Distance"
                              outlined
                              dense
                              hide-details
                              suffix="ML"
                              :rules="[rules.required, rules.isNumberOrFloat]"
                            />
                          </div>
                        </v-col>
                      </v-row>
                      <!-- paveDeRue -->
                      <v-row>
                        <v-col cols="12" sm="6" md="6" lg="6" xl="6">
                          <v-autocomplete
                            v-model="generale.OptionsCivile.paveDeRue"
                            :items="OptionsCivile.paveDeRue"
                            item-text="value"
                            return-object
                            label="Pavé de rue sur lit de sable"
                            class="mb-4"
                            dense
                            hide-details
                            outlined
                            multiple
                            :rules="[rules.required]"
                          />
                        </v-col>
                        <v-col
                          cols="12"
                          sm="6"
                          md="6"
                          lg="6"
                          xl="6"
                          v-if="generale.OptionsCivile.paveDeRue.length > 0"
                          v-for="(item, index) in generale.OptionsCivile.paveDeRue"
                          :key="'paveDeRue' + index"
                        >
                          <div class="d-flex flex-row align-start">
                            <v-icon
                              class="pr-4 pt-2"
                              color="red"
                              v-if="
                                verifierRules(
                                  'optionnelle',
                                  generale.OptionsCivile.paveDeRue
                                )
                              "
                              >mdi-alert-outline</v-icon
                            >
                            <v-text-field
                              v-model="item.distance"
                              :label="'Distance' + item.value"
                              outlined
                              dense
                              hide-details
                              suffix="ML"
                              :rules="[rules.required, rules.isNumberOrFloat]"
                            />
                          </div>
                        </v-col>
                      </v-row>
                      <!-- tracementManuelle -->
                      <v-row>
                        <v-col cols="12" sm="6" md="6" lg="6" xl="6">
                          <v-autocomplete
                            v-model="generale.OptionsCivile.tracementManuelle"
                            :items="OptionsCivile.tracementManuelle"
                            item-text="value"
                            return-object
                            label=" terrassement manuel"
                            class="mb-4"
                            dense
                            hide-details
                            outlined
                            multiple
                            :rules="[rules.required]"
                          />
                        </v-col>
                        <v-col
                          cols="12"
                          sm="6"
                          md="6"
                          lg="6"
                          xl="6"
                          v-if="generale.OptionsCivile.tracementManuelle.length > 0"
                          v-for="(item, index) in generale.OptionsCivile
                            .tracementManuelle"
                          :key="'tracementManuelle' + index"
                        >
                          <div class="d-flex flex-row align-start">
                            <v-icon
                              class="pr-4 pt-2"
                              color="red"
                              v-if="
                                verifierRules(
                                  'optionnelle',
                                  generale.OptionsCivile.tracementManuelle
                                )
                              "
                              >mdi-alert-outline</v-icon
                            >
                            <v-text-field
                              v-model="item.distance"
                              :label="'Distance' + item.value"
                              outlined
                              dense
                              hide-details
                              suffix="ML"
                              :rules="[rules.required, rules.isNumberOrFloat]"
                            />
                          </div>
                        </v-col>
                      </v-row>

                      <!-- enrobe -->
                      <v-row>
                        <v-col cols="12" sm="6" md="6" lg="6" xl="6">
                          <v-autocomplete
                            v-model="generale.OptionsCivile.enrobe"
                            :items="OptionsCivile.enrobe"
                            item-text="value"
                            return-object
                            label="Réfection enrobé"
                            class="mb-4"
                            dense
                            hide-details
                            outlined
                            multiple
                            :rules="[rules.required]"
                          />
                        </v-col>
                        <v-col
                          cols="12"
                          sm="6"
                          md="6"
                          lg="6"
                          xl="6"
                          v-if="generale.OptionsCivile.enrobe.length > 0"
                          v-for="(item, index) in generale.OptionsCivile.enrobe"
                          :key="'enrobe' + index"
                        >
                          <div class="d-flex flex-row align-start">
                            <v-icon
                              class="pr-4 pt-2"
                              color="red"
                              v-if="
                                verifierRules(
                                  'optionnelle',
                                  generale.OptionsCivile.enrobe
                                )
                              "
                              >mdi-alert-outline</v-icon
                            >
                            <v-text-field
                              v-model="item.distance"
                              :label="'Distance' + item.value"
                              outlined
                              dense
                              hide-details
                              suffix="ML"
                              :rules="[rules.required, rules.isNumberOrFloat]"
                            />
                          </div>
                        </v-col>
                      </v-row>
                      <!-- fourreaux -->
                      <v-row>
                        <v-col cols="12" sm="6" md="6" lg="6" xl="6">
                          <v-autocomplete
                            v-model="generale.OptionsCivile.fourreaux"
                            :items="OptionsCivile.fourreaux"
                            item-text="value"
                            return-object
                            label="fourreaux annelés et  aiguillage"
                            class="mb-4"
                            dense
                            hide-details
                            outlined
                            multiple
                            :rules="[rules.required]"
                          />
                        </v-col>
                        <v-col
                          cols="12"
                          sm="6"
                          md="6"
                          lg="6"
                          xl="6"
                          v-if="generale.OptionsCivile.fourreaux.length > 0"
                          v-for="(item, index) in generale.OptionsCivile.fourreaux"
                          :key="'fourreaux' + index"
                        >
                          <div class="d-flex flex-row align-start">
                            <v-icon
                              class="pr-4 pt-2"
                              color="red"
                              v-if="
                                verifierRules(
                                  'optionnelle',
                                  generale.OptionsCivile.fourreaux
                                )
                              "
                              >mdi-alert-outline</v-icon
                            >
                            <v-text-field
                              v-model="item.distance"
                              :label="'Distance' + item.value"
                              outlined
                              dense
                              hide-details
                              suffix="ML"
                              :rules="[rules.required, rules.isNumberOrFloat]"
                            />
                          </div>
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-tab-item>
                  <v-tab-item value="tab-three">
                    <v-card flat class="pt-2">
                      <v-row>
                        <!-- livraisonBorn -->
                        <v-col cols="12" sm="6" md="6" lg="6" xl="6">
                          <v-autocomplete
                            v-model="generale.OptionsCivile.livraisonBorn"
                            :items="OptionsCivile.livraisonBorn"
                            item-text="value"
                            return-object
                            label="Forfait livraison des bornes"
                            class="mb-4"
                            dense
                            hide-details
                            outlined
                            multiple
                            :rules="[rules.required]"
                          />
                        </v-col>
                        <!-- livraisonArmoire -->
                        <v-col cols="12" sm="6" md="6" lg="6" xl="6">
                          <v-autocomplete
                            v-model="generale.OptionsCivile.livraisonArmoire"
                            :items="OptionsCivile.livraisonArmoire"
                            item-text="value"
                            return-object
                            label="Livraison d'une armoire électrique"
                            class="mb-4"
                            dense
                            hide-details
                            outlined
                            multiple
                            :rules="[rules.required]"
                          />
                        </v-col>
                        <!-- DossierDeConception -->
                        <v-col cols="12" sm="6" md="6" lg="6" xl="6">
                          <v-autocomplete
                            v-model="generale.OptionEtude.DossierDeConception"
                            :items="OptionEtude.DossierDeConception"
                            item-text="value"
                            return-object
                            label="Dossier de conception"
                            class="mb-4"
                            dense
                            hide-details
                            outlined
                            multiple
                            :rules="[rules.required]"
                          />
                        </v-col>
                        <!-- DOE -->
                        <v-col cols="12" sm="6" md="6" lg="6" xl="6">
                          <v-autocomplete
                            v-model="generale.OptionEtude.DOE"
                            :items="OptionEtude.DOE"
                            item-text="value"
                            return-object
                            label="DOE"
                            class="mb-4"
                            dense
                            hide-details
                            outlined
                            multiple
                            :rules="[rules.required]"
                          />
                        </v-col>
                        <!-- AccompagnementBureauControle -->
                        <v-col cols="12" sm="6" md="6" lg="6" xl="6">
                          <v-autocomplete
                            v-model="generale.OptionEtude.AccompagnementBureauControle"
                            :items="OptionEtude.AccompagnementBureauControle"
                            item-text="value"
                            return-object
                            label="Accompagnement Bureau de contrôle"
                            class="mb-4"
                            dense
                            hide-details
                            outlined
                            multiple
                            :rules="[rules.required]"
                          />
                        </v-col>
                      </v-row>
                      <!-- noteDeCalcule -->
                      <v-row>
                        <v-col cols="12" sm="6" md="6" lg="6" xl="6">
                          <v-autocomplete
                            v-model="generale.OptionEtude.noteDeCalcule"
                            :items="OptionEtude.noteDeCalcule"
                            item-text="value"
                            return-object
                            label="Note de calcul supp"
                            class="mb-4"
                            dense
                            hide-details
                            outlined
                            multiple
                            :rules="[rules.required]"
                          />
                        </v-col>
                        <v-col
                          cols="12"
                          sm="6"
                          md="6"
                          lg="6"
                          xl="6"
                          v-if="generale.OptionEtude.noteDeCalcule.length > 0"
                          v-for="(item, index) in generale.OptionEtude.noteDeCalcule"
                          :key="'noteDeCalcule' + index"
                        >
                          <div class="d-flex flex-row align-start">
                            <v-icon
                              class="pr-4 pt-2"
                              color="red"
                              v-if="
                                verifierRules(
                                  'optionnelle',
                                  generale.OptionEtude.noteDeCalcule
                                )
                              "
                              >mdi-alert-outline</v-icon
                            >
                            <v-text-field
                              v-model="item.distance"
                              :label="'quantité' + item.value"
                              outlined
                              dense
                              hide-details
                              suffix="U"
                              :rules="[rules.required, rules.isNumberOrFloat]"
                            />
                          </div>
                        </v-col>
                      </v-row>
                      <!-- etudeDeStructure -->
                      <v-row>
                        <v-col cols="12" sm="6" md="6" lg="6" xl="6">
                          <v-autocomplete
                            v-model="generale.OptionEtude.etudeDeStructure"
                            :items="OptionEtude.etudeDeStructure"
                            item-text="value"
                            return-object
                            label="Plus value APD avec étude de structure"
                            class="mb-4"
                            dense
                            hide-details
                            outlined
                            multiple
                            :rules="[rules.required]"
                          />
                        </v-col>
                        <v-col
                          cols="12"
                          sm="6"
                          md="6"
                          lg="6"
                          xl="6"
                          v-if="generale.OptionEtude.etudeDeStructure.length > 0"
                          v-for="(item, index) in generale.OptionEtude.etudeDeStructure"
                          :key="'etudeDeStructure' + index"
                        >
                          <div class="d-flex flex-row align-start">
                            <v-icon
                              class="pr-4 pt-2"
                              color="red"
                              v-if="
                                verifierRules(
                                  'optionnelle',
                                  generale.OptionEtude.etudeDeStructure
                                )
                              "
                              >mdi-alert-outline</v-icon
                            >
                            <v-text-field
                              v-model="item.distance"
                              :label="'quantité' + item.value"
                              outlined
                              dense
                              hide-details
                              suffix="U"
                              :rules="[rules.required, rules.isNumberOrFloat]"
                            />
                          </div>
                        </v-col>
                      </v-row>
                      <!-- DAAT -->
                      <v-row>
                        <v-col cols="12" sm="6" md="6" lg="6" xl="6">
                          <v-autocomplete
                            v-model="generale.OptionEtude.DAAT"
                            :items="OptionEtude.DAAT"
                            item-text="value"
                            return-object
                            label="DAAT"
                            class="mb-4"
                            dense
                            hide-details
                            outlined
                            multiple
                            :rules="[rules.required]"
                          />
                        </v-col>
                        <v-col
                          cols="12"
                          sm="6"
                          md="6"
                          lg="6"
                          xl="6"
                          v-if="generale.OptionEtude.DAAT.length > 0"
                          v-for="(item, index) in generale.OptionEtude.DAAT"
                          :key="'DAAT' + index"
                        >
                          <div class="d-flex flex-row align-start">
                            <v-icon
                              class="pr-4 pt-2"
                              color="red"
                              v-if="
                                verifierRules('optionnelle', generale.OptionEtude.DAAT)
                              "
                              >mdi-alert-outline</v-icon
                            >
                            <v-text-field
                              v-model="item.distance"
                              :label="'quantité' + item.value"
                              outlined
                              dense
                              hide-details
                              suffix="U"
                              :rules="[rules.required, rules.isNumberOrFloat]"
                            />
                          </div>
                        </v-col>
                      </v-row>
                      <!-- DICT -->
                      <v-row>
                        <v-col cols="12" sm="6" md="6" lg="6" xl="6">
                          <v-autocomplete
                            v-model="generale.OptionEtude.DICT"
                            :items="OptionEtude.DICT"
                            item-text="value"
                            return-object
                            label="DICT"
                            class="mb-4"
                            dense
                            hide-details
                            outlined
                            multiple
                            :rules="[rules.required]"
                          />
                        </v-col>
                        <v-col
                          cols="12"
                          sm="6"
                          md="6"
                          lg="6"
                          xl="6"
                          v-if="generale.OptionEtude.DICT.length > 0"
                          v-for="(item, index) in generale.OptionEtude.DICT"
                          :key="'DICT' + index"
                        >
                          <div class="d-flex flex-row align-start">
                            <v-icon
                              class="pr-4 pt-2"
                              color="red"
                              v-if="
                                verifierRules('optionnelle', generale.OptionEtude.DICT)
                              "
                              >mdi-alert-outline</v-icon
                            >
                            <v-text-field
                              v-model="item.distance"
                              :label="'quantité' + item.value"
                              outlined
                              dense
                              hide-details
                              suffix="U"
                              :rules="[rules.required, rules.isNumberOrFloat]"
                            />
                          </div>
                        </v-col>
                      </v-row>
                      <!-- visite supplimentaire -->
                      <v-row>
                        <v-col cols="12" sm="6" md="6" lg="6" xl="6">
                          <v-autocomplete
                            v-model="generale.visiteSupplementaire"
                            :items="visiteSupplementaire"
                            item-text="value"
                            return-object
                            label="Visite supplémentaire"
                            class="mb-4"
                            dense
                            hide-details
                            outlined
                            multiple
                            :rules="[rules.required]"
                          />
                        </v-col>
                        <v-col
                          cols="12"
                          sm="6"
                          md="6"
                          lg="6"
                          xl="6"
                          v-if="generale.visiteSupplementaire.length > 0"
                          v-for="(item, index) in generale.visiteSupplementaire"
                          :key="'visiteSupplementaire' + index"
                        >
                          <div class="d-flex flex-row align-start">
                            <v-icon
                              class="pr-4 pt-2"
                              color="red"
                              v-if="
                                verifierRules(
                                  'optionnelle',
                                  generale.visiteSupplementaire
                                )
                              "
                              >mdi-alert-outline</v-icon
                            >
                            <v-text-field
                              v-model="item.distance"
                              :label="'quantité' + item.value"
                              outlined
                              dense
                              hide-details
                              suffix="U"
                              :rules="[rules.required, rules.isNumberOrFloat]"
                            />
                          </div>
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-tab-item>
                </v-tabs-items>
              </v-card>
            </div>
          </div>
        </v-form>
        <!-- Add new product -->
        <v-card class="p-4 mt-4">
          <h3 class="title text-primary">Ajouter autre Produit</h3>
          <v-form ref="addProduct" class="my-6">
            <v-row cols="12">
              <v-col cols="12">
                <v-text-field
                  v-model="newproduct.name"
                  outlined
                  dense
                  hide-details
                  label="Nom de Produit"
                  :rules="[rules.required]"
                />
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="newproduct.prix"
                  outlined
                  dense
                  label="Prix"
                  :rules="[rules.required, rules.isNumberOrFloat, rules.greaterThanZero]"
                />
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="newproduct.distance"
                  outlined
                  dense
                  label="Quantité"
                  :rules="[rules.required, rules.isNumber, rules.greaterThanZero]"
                />
              </v-col>
            </v-row>
            <div class="d-flex justify-content-end">
              <v-btn @click="addProduct" class="text-white mx-2 primary">
                <span class="Poppins-Bold">Ajouter</span>
              </v-btn>
            </div>
          </v-form>

          <div class="py-4" v-if="productsToBeAdded.length > 0">
            <table class="table table-striped">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Nom de produit</th>
                  <th scope="col">Prix</th>
                  <th scope="col">Quantité</th>
                  <th scope="col">Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(product, index) in productsToBeAdded">
                  <td>{{ index }}</td>
                  <td>{{ product.name }}</td>
                  <td>{{ product.prix }}</td>
                  <td>{{ product.distance }}</td>
                  <td>
                    <v-btn
                      class="mr-2"
                      icon
                      color="error"
                      @click="deleteProduct(index)"
                      title="supprimer produit"
                    >
                      <v-icon dark> mdi-delete </v-icon>
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </v-card>
        <!-- Enregistrer button -->
        <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="pa-0 mt-4">
          <div class="d-flex justify-content-end">
            <!--<v-btn
              @click="() => save()"
              style="background-color: #5d8aa8"
              class="text-white mx-2"
            >
              <span class="Poppins-Bold">Enregistrer</span>
            </v-btn>-->
            <v-btn
              @click="validerDevisComplete"
              :style="{ background: $styles.background }"
              class="text-white mx-2"
            >
              <span class="Poppins-Bold">Valider</span>
            </v-btn>
          </div>
        </v-col>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import axios from "@/plugins/axios";
export default {
  name: "QuestionFaisabiliteNew",
  props: ["vtId"],
  data() {
    return {
      comeFrom: "Faisabilite",
      listCheckValue: {
        OptionsCivile: ["livraisonBorn", "livraisonArmoire"],
        OptionEtude: ["DossierDeConception", "DOE", "AccompagnementBureauControle"],
      },
      tab: null,
      buttonpanel: [],
      loadingPage: false,
      dialogPage: false,
      message: "",
      defaultData: {},
      newproduct: {
        name: "",
        prix: "",
        distance: "",
      },
      generale: {
        nbrPointDeRecharge: 0,
        communicante: "non",
        typeCommunication: "Filaire",
        alimentation: {
          typeAlimentation: "Monophasé",
          disjoncteur: "",
          distance_alimentation: 0,
          cableAlimentation: 0,
        },
        tranchement: {
          etat: "oui",
        },
        percements: {
          etat: "oui",
        },
        ChambreDeTirage: {
          etat: "oui",
        },
        FouilleetMassif: {
          etat: "oui",
        },
        OptionsCivile: {
          gravats: [],
          enrobe: [],
          Decoupe: [],
          tracementManuelle: [],
          plaqueDeRoulement: [],
          paveDeRue: [],
          fourreaux: [],
          geotextile: [],
          livraisonBorn: [],
          livraisonArmoire: [],
        },
        OptionEtude: {
          DossierDeConception: [],
          noteDeCalcule: [],
          etudeDeStructure: [],
          DAAT: [],
          DICT: [],
          DOE: [],
          AccompagnementBureauControle: [],
        },
        tdIrve: {
          emplacement: {},
          porte: {},
          Repartiteur: {},
          bottonDarretDurgence: [],
          ProtectionTerre: [],
        },
        visiteSupplementaire: [],
        protectionEtSchemasDeCables: [],
        ProtectionTravail: [],
        parafoudre: [],
      },
      depart: null,
      ProtectionTerre: null,
      protectionEtSchemasDeCables: null,
      disjoncteur: null,
      bobineMnx: null,
      bottonsDarretUrgance: null,
      differentiel: null,
      parafoudre: null,
      emplacementDeTdIrve: null,
      porteTdIrve: null,
      Repartiteur: null,
      Percements: null,
      ProtectionTravail: null,
      OptionsCivile: null,
      ChambresDesTirages: null,
      FouillesetMassifs: null,
      PoseIRVESign: null,
      signalisation: null,
      OptionEtude: null,
      visiteSupplementaire: null,
      tranchement: null,
      telecomObject: null,
      selectedFouilleetMassif: [],
      selectedPercements: [],
      selectedTranchement: [],
      selectedChambreDeTirage: [],
      productsToBeAdded: [],
      tabIndex: 0,
      tabs: [],
      panel: 0,

      nbrBorneToCreate: 0,
      borneToCreate: null,

      distance_alimentation: 0,
      listBornes: [],
      listBorneSelected: [],

      compteur: {
        tarif: "bleu",
        kva: 0,
        maxRechedValue: 0,
        puissanceAtteinteMaximale: 0,
      },
      nouveauPDL: "non",
      messageWarning: "",

      // form questions to create devis questions states
      defaultFormQuestionsList: [],
      formQuestionsList: [],
      signialisationQuestion: {},

      // devis questions states
      defaultQuestionsList: {},
      questionsList: {},
      // rules
      rules: {
        isNumberOrFloat: (value) =>
          /^[0-9]+(\.\d+)?$/.test(value) ||
          "Ce champ n'accepte que les chiffres ou les nombres décimaux valides (ex. 50 ou 50.3)",
        isString: (value) =>
          !/\d/.test(value) || "Ce champ ne peut pas etre composé de chiffres",
        required: (value) => !!value || value.length > 0 || "Ce champ est obligatoire.",
        isNumber: (v) => /^[0-9]+$/.test(v) || "Ce champ n'accepte que les chiffres",
        isFloat: (v) =>
          /^-?\d*(\.\d+)?$/.test(v) ||
          "Ce champ doit être un nombre décimal valide(50.3)",
        hasItemSelected: (v) =>
          v.length >= 1 || "Vous devez selectionner aux moins une champ",
        greaterThanZero: (value) =>
          parseFloat(value) > 0 || "La valeur doit être supérieure à zéro.",
      },
    };
  },
  watch: {
    // watch for props
    /*data: {
      deep: true,
      handler(newVal) {
        console.log('kkk')
        this.initialize();
      }
    },*/
    /*questionsList: {
      deep: true,
      handler(newVal) {
        if (Object.keys(this.questionsList).length > 0 && this.tabs.length>0) this.panel = null;
      }
    },*/
    listBorneSelected: {
      deep: true,
      handler(newVal) {
        this.handleListBorneChange();
      },
    },
  },
  async created() {
    await this.getMatrielData();
    await this.getDefaultData();
    // get compteur info
    await this.getCompteurInfoForApd();
    // get list bornes
    await this.getBornes();
    // initialize data
    await this.initializeListeBorne();
    await this.initialize();
  },
  methods: {
    async getDataFromVisiteTechnique() {
      this.loadingPage = true;
      this.dialogPage = true;
      await axios({
        url: process.env.VUE_APP_URL_API_CLIENT + `getVisitechniqueAdpQuestions/`,
        method: "POST",
        data: {
          token: this.$store.getters.getUserToken,
          visiteTechnique_id: this.vtId,
        },
      })
        .then((response) => {
          if (response.status == 200) {
            this.defaultData = response.data.data;
            this.comeFrom = response.data.typeDemande;
            if (response.data.typeDemande == "VisiteTechnique") {
              this.generale["communicante"] = this.defaultData["communicante"];
              this.generale["typeCommunication"] = this.defaultData["typeCommunication"];
              this.generale["tranchement"] = this.defaultData["tranchement"];
              this.generale["percements"] = this.defaultData["percements"];
              this.generale["ChambreDeTirage"] = this.defaultData["ChambreDeTirage"];
              this.generale["FouilleetMassif"] = this.defaultData["FouilleetMassif"];
              this.generale["visiteSupplementaire"] = this.defaultData[
                "visiteSupplementaire"
              ];
              this.generale["protectionEtSchemasDeCables"] = this.defaultData[
                "protectionEtSchemasDeCables"
              ];
              //this.generale['ProtectionTravail'] = this.defaultData['ProtectionTravail']
              this.selectedFouilleetMassif = this.defaultData["selectedFouilleetMassif"];
              this.selectedChambreDeTirage = this.defaultData["selectedChambreDeTirage"];
              this.selectedPercements = this.defaultData["selectedPercements"];
              this.selectedTranchement = this.defaultData["selectedTranchement"];
              // Iterate through keys in defaultData['OptionsCivile']
              for (const key in this.defaultData["OptionsCivile"]) {
                // Check if the key exists in generale['OptionsCivile']
                if (this.generale["OptionsCivile"].hasOwnProperty(key)) {
                  // Update the value in generale['OptionsCivile']
                  this.generale["OptionsCivile"][key] = this.defaultData["OptionsCivile"][
                    key
                  ];
                }
              }
              // Iterate through keys in defaultData['OptionEtude']
              for (const key in this.defaultData["OptionEtude"]) {
                // Check if the key exists in generale['OptionEtude']
                if (this.generale["OptionEtude"].hasOwnProperty(key)) {
                  // Update the value in generale['OptionEtude']
                  this.generale["OptionEtude"][key] = this.defaultData["OptionEtude"][
                    key
                  ];
                }
              }
              var nbrBottonArretUrgence = this.defaultData["nbrBottonArretUrgence"];
              var nbrPiquetsTerre = this.defaultData["nbrPiquetsTerre"];
              var distancePiquetsTerre = this.defaultData["distancePiquetsTerre"];
              var distanceArretUrgence = this.defaultData["distanceArretUrgence"];
              this.generale.tdIrve.bottonDarretDurgence = [];
              this.generale.tdIrve.ProtectionTerre = [];
              if (nbrBottonArretUrgence > 0) {
                for (var i = 0; i < nbrBottonArretUrgence; i++) {
                  this.createUneBottonDeArretDeUrgance(
                    distanceArretUrgence / nbrBottonArretUrgence
                  );
                }
              }
              if (nbrPiquetsTerre > 0) {
                for (var i = 0; i < nbrPiquetsTerre; i++) {
                  this.createUnPiquetsDeTerre(distancePiquetsTerre / nbrPiquetsTerre);
                }
              }
            }
            this.initializeListeBorne();
            this.initialize();
          }
        })
        .catch((errors) => {
          console.log(errors);
        });
      this.loadingPage = false;
      this.dialogPage = false;
    },
    async getMatrielData() {
      await axios({
        url: process.env.VUE_APP_URL_API_CLIENT + `getMaterielApd/`,
        method: "POST",
        data: {
          token: this.$store.getters.getUserToken,
        },
      })
        .then((res) => {
          if (res.status == 200) {
            this.depart = res.data.data.depart;
            this.ProtectionTerre = res.data.data.ProtectionTerre;
            this.protectionEtSchemasDeCables = res.data.data.protectionEtSchemasDeCables;
            this.disjoncteur = res.data.data.disjoncteur;
            this.bobineMnx = res.data.data.bobineMnx;
            this.bottonsDarretUrgance = res.data.data.bottonsDarretUrgance;
            this.differentiel = res.data.data.differentiel;
            this.parafoudre = res.data.data.parafoudre;
            this.emplacementDeTdIrve = res.data.data.emplacementDeTdIrve;
            this.porteTdIrve = res.data.data.porteTdIrve;
            this.Repartiteur = res.data.data.Repartiteur;
            this.Percements = res.data.data.Percements;
            this.ProtectionTravail = res.data.data.ProtectionTravail;
            this.OptionsCivile = res.data.data.OptionsCivile;
            this.ChambresDesTirages = res.data.data.ChambresDesTirages;
            this.FouillesetMassifs = res.data.data.FouillesetMassifs;
            this.PoseIRVESign = res.data.data.PoseIRVESign;
            this.signalisation = res.data.data.signalisation;
            this.OptionEtude = res.data.data.OptionEtude;
            this.visiteSupplementaire = res.data.data.visiteSupplementaire;
            this.tranchement = res.data.data.tranchement;
            this.telecomObject = res.data.data.telecomObject;
          }
        })
        .catch((errors) => {});
    },
    async getDefaultData() {
      this.loadingPage = true;
      this.dialogPage = true;
      await axios({
        url: process.env.VUE_APP_URL_API_CLIENT + `get_devis_for_validation/`,
        method: "POST",
        data: {
          token: this.$store.getters.getUserToken,
          vtId: this.vtId,
        },
      })
        .then((response) => {
          if (response.status == 200) {
            this.defaultData = response.data.devis;
            this.comeFrom = response.data.typeDemande;
            if (response.data.typeDemande == "APD") {
              this.generale["communicante"] = this.defaultData["communicante"];
              this.generale["typeCommunication"] = this.defaultData["typeCommunication"];
              this.generale["tranchement"] = this.defaultData["tranchement"];
              this.generale["percements"] = this.defaultData["percements"];
              this.generale["ChambreDeTirage"] = this.defaultData["ChambreDeTirage"];
              this.generale["FouilleetMassif"] = this.defaultData["FouilleetMassif"];
              //this.generale['OptionsCivile'] = this.defaultData['OptionsCivile']
              /*this.generale['OptionEtude'] = this.defaultData['OptionEtude']*/
              this.generale["visiteSupplementaire"] = this.defaultData[
                "visiteSupplementaire"
              ];
              this.generale["protectionEtSchemasDeCables"] = this.defaultData[
                "protectionEtSchemasDeCables"
              ];
              this.generale["ProtectionTravail"] = this.defaultData["ProtectionTravail"];
              this.generale["parafoudre"] = this.defaultData["parafoudre"];
              this.selectedFouilleetMassif = this.defaultData["selectedFouilleetMassif"];
              this.selectedChambreDeTirage = this.defaultData["selectedChambreDeTirage"];
              this.selectedPercements = this.defaultData["selectedPercements"];
              this.selectedTranchement = this.defaultData["selectedTranchement"];
              // Iterate through keys in defaultData['OptionsCivile']
              for (const key in this.defaultData["OptionsCivile"]) {
                // Check if the key exists in generale['OptionsCivile']
                if (this.generale["OptionsCivile"].hasOwnProperty(key)) {
                  // Update the value in generale['OptionsCivile']
                  this.generale["OptionsCivile"][key] = this.defaultData["OptionsCivile"][
                    key
                  ];
                }
              }
              // Iterate through keys in defaultData['OptionEtude']
              for (const key in this.defaultData["OptionEtude"]) {
                // Check if the key exists in generale['OptionEtude']
                if (this.generale["OptionEtude"].hasOwnProperty(key)) {
                  // Update the value in generale['OptionEtude']
                  this.generale["OptionEtude"][key] = this.defaultData["OptionEtude"][
                    key
                  ];
                }
              }

              var nbrBottonArretUrgence = this.defaultData["nbrBottonArretUrgence"];
              var nbrPiquetsTerre = this.defaultData["nbrPiquetsTerre"];
              var distancePiquetsTerre = this.defaultData["distancePiquetsTerre"];
              var distanceArretUrgence = this.defaultData["distanceArretUrgence"];
              this.generale.tdIrve.bottonDarretDurgence = [];
              this.generale.tdIrve.ProtectionTerre = [];
              if (nbrBottonArretUrgence > 0) {
                for (var i = 0; i < nbrBottonArretUrgence; i++) {
                  this.createUneBottonDeArretDeUrgance(
                    distanceArretUrgence / nbrBottonArretUrgence
                  );
                }
              }
              if (nbrPiquetsTerre > 0) {
                for (var i = 0; i < nbrPiquetsTerre; i++) {
                  this.createUnPiquetsDeTerre(distancePiquetsTerre / nbrPiquetsTerre);
                }
              }
            }
            this.initializeListeBorne();
            this.initialize();
          }
        })
        .catch((errors) => {
          console.log(errors);
        });
      this.loadingPage = false;
      this.dialogPage = false;
    },
    changeEtatSignalisation(born) {
      if (born["signalisation"]["etat"] == "non") {
        born["signalisation"]["Verticale"] = [];
        born["signalisation"]["Horizontale"] = [];
        born["signalisation"]["Mecanique"] = [];
        born["signalisation"]["autre"] = [];
      }
    },
    changePoseMurale(born) {
      born["poseMurale"]["PoseIRVESign"] = this.PoseIRVESign[
        born["poseMurale"]["etat"]
      ][0];
    },
    // get data from props
    async initialize() {
      // from props
      this.generale.alimentation.typeAlimentation = "Monophasé";
      this.listBorneSelected.forEach((bornSelected) => {
        if (bornSelected.typeDePhase == "Triphasé") {
          this.generale.alimentation.typeAlimentation = "Triphasé";
        }
        const matchingBorne = this.findMatchingBorn(bornSelected.id);
        bornSelected = this.setBorneInfoByType(matchingBorne, bornSelected);
        this.generale.nbrPointDeRecharge += bornSelected.nbrPointDeRecharge;
      });
      await this.verfierTypeAllimentation();
      if (this.comeFrom == "Faisabilite") {
        await this.createUneBottonDeArretDeUrgance(1);
        await this.createUnPiquetsDeTerre(10);
      }
      await this.initializeTdIrve();
      this.generale.OptionEtude.DOE[0] = this.OptionEtude.DOE[0];
      this.generale.OptionEtude.AccompagnementBureauControle[0] = this.OptionEtude.AccompagnementBureauControle[0];
      this.generale.OptionsCivile.livraisonArmoire[0] = this.OptionsCivile.livraisonArmoire[0];
      await this.setDossierDetude();
      await this.setForfaitDeLivraisonBorne();
    },
    async initializeListeBorne() {
      if (this.comeFrom != "VisiteTechnique") {
        this.productsToBeAdded = this.defaultData.materielAuxiliaire;
      }
      this.generale.alimentation.distance_alimentation = this.defaultData.distance_TD;
      this.listBorneSelected = this.defaultData.bornes.map((b) => ({
        id: b.borne.id,
        distance: b.distance,
        prix: b.prix,
        quantity: b.quantity,
        signalisation: b.signalisation,
        poseMurale: b.poseMurale,
      }));
    },
    async setForfaitDeLivraisonBorne() {
      for (var i = 0; i < this.OptionsCivile.livraisonBorn.length; i++) {
        if (this.OptionsCivile.livraisonBorn[i]["pied"].length > 1) {
          if (
            this.listBorneSelected.length >=
              this.OptionsCivile.livraisonBorn[i]["pied"][0] &&
            this.listBorneSelected.length <=
              this.OptionsCivile.livraisonBorn[i]["pied"][1]
          ) {
            this.generale.OptionsCivile.livraisonBorn = [];
            this.generale.OptionsCivile.livraisonBorn[0] = this.OptionsCivile.livraisonBorn[
              i
            ];
            break;
          }
        } else if (i == this.OptionsCivile.livraisonBorn.length - 1) {
          this.generale.OptionsCivile.livraisonBorn = [];
          this.generale.OptionsCivile.livraisonBorn[0] = this.OptionsCivile.livraisonBorn[
            i
          ];
          break;
        }
      }
    },
    async setDossierDetude() {
      for (var i = 0; i < this.OptionEtude.DossierDeConception.length; i++) {
        if (this.OptionEtude.DossierDeConception[i]["pdr"].length > 1) {
          if (
            this.generale.nbrPointDeRecharge >=
              this.OptionEtude.DossierDeConception[i]["pdr"][0] &&
            this.generale.nbrPointDeRecharge <=
              this.OptionEtude.DossierDeConception[i]["pdr"][1]
          ) {
            this.generale.OptionEtude.DossierDeConception = [];
            this.generale.OptionEtude.DossierDeConception[0] = this.OptionEtude.DossierDeConception[
              i
            ];
            break;
          }
        } else if (i == this.OptionEtude.DossierDeConception.length - 1) {
          this.generale.OptionEtude.DossierDeConception = [];
          this.generale.OptionEtude.DossierDeConception[0] = this.OptionEtude.DossierDeConception[
            i
          ];
          break;
        }
      }
    },
    setSolutionCommunicate() {
      for (var i = 0; i < this.listBorneSelected.length; i++) {
        if (
          this.generale.communicante == "oui" &&
          this.generale.typeCommunication == "GSM"
        ) {
          this.listBorneSelected[i].telecom = {};
          this.listBorneSelected[i].telecom.baie = this.telecomObject.baie[0];
          this.listBorneSelected[i].telecom.bandeau = this.telecomObject.bandeau[0];
          this.listBorneSelected[i].telecom.Cable = this.telecomObject.Cable[0];
          this.listBorneSelected[i].telecom.Noyaux = this.telecomObject.Noyaux[0];
        } else {
          this.listBorneSelected[i].telecom = {};
        }
      }
    },
    async initializeTdIrve() {
      if (this.defaultData.type_TD == "exterieur") {
        this.generale.tdIrve.emplacement = this.emplacementDeTdIrve[1];
      } else {
        this.generale.tdIrve.emplacement = this.emplacementDeTdIrve[0];
      }
      this.generale.tdIrve.porte = this.porteTdIrve[0];
      this.generale.tdIrve.Repartiteur = this.Repartiteur[0];
    },
    async createUneBottonDeArretDeUrgance(distance) {
      var item = {
        botton: this.bottonsDarretUrgance[0],
        depart: this.depart["bobineMnx"][0],
        bobineMnx: this.bobineMnx[0],
        Disjonteur: this.findItemByTarif(
          this.disjoncteur["bobineMnx"],
          this.compteur.tarif
        ),
        distance: distance,
      };
      this.generale.tdIrve.bottonDarretDurgence.push(item);
    },
    async createUnPiquetsDeTerre(distance) {
      var item = {
        cable: { ...this.ProtectionTerre["cable"][0] },
        piquets: { ...this.ProtectionTerre["piquets"][0] },
        cosses: { ...this.ProtectionTerre["cosses"][0] },
      };
      item["cable"]["distance"] = distance;
      this.generale.tdIrve.ProtectionTerre.push(item);
    },
    async deleteUneBottonDeArretDeUrgance() {
      this.generale.tdIrve.bottonDarretDurgence.splice(
        this.generale.tdIrve.bottonDarretDurgence.length - 1,
        1
      );
    },
    async deleteUnPiquetsDeTerre() {
      this.generale.tdIrve.ProtectionTerre.splice(
        this.generale.tdIrve.ProtectionTerre.length - 1,
        1
      );
    },
    verfierTypeAllimentation() {
      this.generale.alimentation.typeAlimentation = "Monophasé";
      this.listBorneSelected.forEach((bornSelected) => {
        if (bornSelected.typeDePhase == "Triphasé") {
          this.generale.alimentation.typeAlimentation = "Triphasé";
        }
      });
      this.generale.alimentation.cableAlimentation = this.depart[
        this.generale.alimentation.typeAlimentation
      ][this.depart[this.generale.alimentation.typeAlimentation].length - 1];
      if (this.generale.nbrPointDeRecharge < 2) {
        this.generale.alimentation.disjoncteur = {};
        this.generale.tdIrve.Repartiteur = {};
      } else {
        var disjoncteurGenerale = this.findItemByTarif(
          this.disjoncteur["generale" + this.generale.alimentation.typeAlimentation],
          this.compteur.tarif
        );
        this.generale.alimentation.disjoncteur = disjoncteurGenerale;
        this.generale.tdIrve.Repartiteur = this.Repartiteur[0];
      }
    },
    findMatchingBorn(id) {
      const bornesDict = this.listBornes.reduce((acc, borne) => {
        acc[borne.id] = borne;
        return acc;
      }, {});
      var matchingBorne = bornesDict[id];
      return matchingBorne;
    },
    setBorneInfoByType(bornPricipale, currentBorn) {
      if (currentBorn) {
        currentBorn.typeDePhase = bornPricipale.typeDePhase;
        currentBorn.puissance = bornPricipale.puissance;
        currentBorn.priseDeService = bornPricipale.priseDeService;
        currentBorn.nbrPointDeRecharge = bornPricipale.nombrePointDeRecharge;
        //set disjoncteur par PDL
        currentBorn.disjoncteur = this.findItemByTarif(
          this.disjoncteur[bornPricipale.puissance],
          this.compteur.tarif
        );
        //set differentiel
        currentBorn.diff = this.differentiel[bornPricipale.puissance][
          this.differentiel[bornPricipale.puissance].length - 1
        ];
        // set depart
        currentBorn.depart = this.findItemByDistance(
          this.depart[bornPricipale.puissance],
          currentBorn.distance
        );
        //set donner pris de services
        if (bornPricipale.priseDeService == "oui") {
          currentBorn.cablePrise = this.depart["prise"][0];
          currentBorn.disjoncteurPrise = this.findItemByTarif(
            this.disjoncteur["prise"],
            this.compteur.tarif
          );
        }
        if (
          this.generale.communicante == "oui" &&
          this.generale.typeCommunication == "GSM"
        ) {
          currentBorn.telecom = {};
          currentBorn.telecom.baie = this.telecomObject.baie[0];
          currentBorn.telecom.bandeau = this.telecomObject.bandeau[0];
          currentBorn.telecom.Cable = this.telecomObject.Cable[0];
          currentBorn.telecom.Noyaux = this.telecomObject.Noyaux[0];
        }
        if (currentBorn.signalisation.etat == "oui") {
          if (!("Verticale" in currentBorn.signalisation)) {
            currentBorn.signalisation.Verticale = [];
          }
          if (!("Mecanique" in currentBorn.signalisation)) {
            currentBorn.signalisation.Mecanique = [];
          }
          if (!("Horizontale" in currentBorn.signalisation)) {
            currentBorn.signalisation.Horizontale = [];
          }
        }
        if (
          currentBorn.poseMurale["etat"] != "oui" &&
          currentBorn.poseMurale["etat"] != "non"
        ) {
          currentBorn.poseMurale = {};
          currentBorn.poseMurale["etat"] = "oui";
          currentBorn.poseMurale["PoseIRVESign"] = this.PoseIRVESign["oui"][0];
        } else {
          currentBorn.poseMurale["PoseIRVESign"] = this.PoseIRVESign[
            currentBorn.poseMurale["etat"]
          ][0];
        }
      }
      return currentBorn;
    },
    findItemByTarif(items, tarif) {
      var item = items[0];
      for (var i = 0; i < items.length; i++) {
        if (items[i]["tarif"] == tarif) {
          item = items[i];
          break;
        } else if (i == items.length - 1) {
          item = items[i];
          break;
        }
      }
      return item;
    },
    findItemByDistance(items, distance) {
      var item = items[0];
      if (items[0]["distance"] == "all") {
        item = items[0];
      } else {
        for (var i = 0; i < items.length; i++) {
          if (items[i]["distance"].length > 1) {
            if (
              distance >= items[i]["distance"][0] &&
              distance <= items[i]["distance"][1]
            ) {
              item = items[i];
              break;
            }
          } else if (i == items.length - 1) {
            item = items[i];
            break;
          }
        }
      }
      return item;
    },
    findItemByProfil(items, profil) {
      var item = items[0];
      for (var i = 0; i < items.length; i++) {
        if (items[i]["profil"] == profil) {
          item = items[i];
          break;
        } else if (i == items.length - 1) {
          item = items[i];
          break;
        }
      }
      return item;
    },
    changeDistance(bornSelected) {
      if (bornSelected) {
        var depart = this.depart[bornSelected.puissance];
        if (depart[0]["distance"] == "all") {
          bornSelected.depart = depart[0];
        } else {
          for (var i = 0; i < depart.length; i++) {
            if (depart[i]["distance"].length > 1) {
              if (
                bornSelected.distance >= depart[i]["distance"][0] &&
                bornSelected.distance <= depart[i]["distance"][1]
              ) {
                bornSelected.depart = depart[i];
                break;
              }
            } else if (i == depart.length - 1) {
              bornSelected.depart = depart[i];
              break;
            }
          }
        }
      }
    },
    async getCompteurInfoForApd() {
      await axios({
        url: process.env.VUE_APP_URL_API_CLIENT + "get_compteur_info_for_apd/",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        data: JSON.stringify({
          token: this.$store.getters.getUserToken,
          vtId: this.vtId,
        }),
      })
        .then((res) => {
          if (res.status == 200) {
            this.compteur.kva = res.data.kva;
            this.compteur.maxRechedValue = res.data.puissanceMaximale;
            this.compteur.puissanceAtteinteMaximale = res.data.puissanceAtteinteMaximale;
            this.compteur.tarif = res.data.tarif;
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    async getBornes() {
      await axios({
        url: process.env.VUE_APP_URL_API_CLIENT + "availableDevisItemsView/",
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
        data: JSON.stringify({
          token: this.$store.getters.getUserToken,
        }),
      }).then((res) => {
        this.listBornes = res.data.bornes;
      });
    },
    // add product
    async addProduct() {
      // Validate if the required fields are filled
      const isFormValid = this.$refs.addProduct.validate();

      if (isFormValid) {
        const updatedProducts = [
          ...this.productsToBeAdded,
          {
            name: this.newproduct.name,
            prix: parseFloat(this.newproduct.prix),
            distance: parseInt(this.newproduct.distance),
          },
        ];
        // Add the new product to the productsToBeAdded array
        this.productsToBeAdded = updatedProducts;

        // Clear input fields after adding a new product
        this.newproduct = {
          name: "",
          prix: "",
          distance: "",
        };

        //this.$refs.addProduct.reset();
      }
    },
    // delete product
    async deleteProduct(index) {
      // Confirm if the user really wants to remove the product
      this.$swal({
        title: "Êtes-vous sûr de vouloir supprimer ce produit ?",
        text: "Cette action est irréversible.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Oui, supprimer",
        cancelButtonText: "Annuler",
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete.isConfirmed) {
          // Remove the product at the given index from productsToBeAdded array
          this.productsToBeAdded.splice(index, 1);
        }
      });
    },

    // handle displaying warning for changing compteur or contract
    handleListBorneChange() {
      const distanceBorne = this.listBorneSelected.filter((o) => o.id).length;
      let listSelectedBornesWithPuissance = [];
      this.listBorneSelected.forEach((borne) => {
        var x = this.listBornes.find((borne1) => borne1.id == borne.id);
        if (x) {
          listSelectedBornesWithPuissance.push(x);
        }
      });
      // Calculate the sum of 'puissance' values
      const sumPuissance = listSelectedBornesWithPuissance.reduce((total, item) => {
        // Remove commas and convert 'puissance' to a float number
        const puissanceValue = parseFloat(item.puissance.replace(",", "."));
        // Add the numeric 'puissance' value to the total
        return total + puissanceValue;
      }, 0);
      const total = sumPuissance + this.compteur.maxRechedValue;
      if (total > this.compteur.kva) {
        if (total > this.compteur.puissanceAtteinteMaximale) {
          // sweet alert with confirm and cancel button
          if (!(this.nouveauPDL == "changerContract")) {
            this.$swal({
              title:
                "La puissance de la borne dépasse la limite maximale. Veuillez envisager une mise à jour de votre contrat.",
              text: "Voulez vous changer le contrat ?",
              icon: "warning",
              showCancelButton: true,
              confirmButtonText: "Oui",
              cancelButtonText: "Non",
              dangerMode: true,
            }).then((willChange) => {
              if (willChange.isConfirmed) {
                this.nouveauPDL = "changerContract";
                this.messageWarning =
                  "La puissance de la borne dépasse la limite maximale. Veuillez envisager une mise à jour de votre contrat.";
              } else {
                this.nouveauPDL = "non";
                this.messageWarning = "";
              }
            });
          }
        } else {
          // warning toast
          this.nouveauPDL = "changerCompteur";
          this.messageWarning =
            "La puissance de la borne dépasse la puissance maximale. Vous devez changer le compteur.";
        }
      } else {
        this.nouveauPDL = "non";
        this.messageWarning = "";
      }
    },
    resetModal() {
      this.borneToCreate = null;
      this.nbrBorneToCreate = 0;
    },
    handleOk(bvModalEvent) {
      // Prevent modal from closing
      bvModalEvent.preventDefault();
      // Trigger submit handler
      this.addBorneByType();
    },
    // add list of borne by type
    async addBorneByType() {
      if (this.nbrBorneToCreate + this.listBorneSelected.length <= 50) {
        let idBorneType = this.borneToCreate;
        // Validate if the required fields are filled
        if (!this.nbrBorneToCreate) {
          // Show an error message or handle validation as needed
          this.$toast.error("Veuillez remplir tous les champs.");
          return;
        }

        // Create an array to store new bornes with the same ID and distance set to 0

        const newBornes = Array.from({ length: this.nbrBorneToCreate }, () => {
          const matchingBorne = this.findMatchingBorn(idBorneType);
          var bornSelected = {
            id: idBorneType,
            distance: 0,
            puissance: 0,
            priseDeService: "non",
            depart: "",
            disjoncteur: "",
            diff: "",
            nbrPointDeRecharge: 0,
            cablePrise: "",
            disjoncteurPrise: "",
            signalisation: {
              etat: "non",
              Verticale: [],
              Horizontale: [],
              Mecanique: [],
              autre: [],
            },
            poseMurale: {
              etat: "oui",
            },
          };
          bornSelected = this.setBorneInfoByType(matchingBorne, bornSelected);
          this.generale.nbrPointDeRecharge += bornSelected.nbrPointDeRecharge;
          if (bornSelected.typeDePhase == "Triphasé") {
            this.generale.alimentation.typeAlimentation = "Triphasé";
          }
          this.generale.alimentation.cableAlimentation = this.depart[
            this.generale.alimentation.typeAlimentation
          ][this.depart[this.generale.alimentation.typeAlimentation].length - 1];
          return bornSelected;
        });

        // Add the new bornes to the listBorneSelected array
        this.listBorneSelected.push(...newBornes);

        // Clear input fields after adding a new borne
        this.resetModal();

        await this.verfierTypeAllimentation();
        await this.setDossierDetude();
        await this.setForfaitDeLivraisonBorne();
        // Hide the modal manually
        this.$nextTick(() => {
          this.$bvModal.hide("modal-add-bornes");
        });
      } else {
        this.$swal({
          title: "Vous avez dépassé la valeur maximale autorisée des bornes sur le site.",
          text: "Valeur Totale de Bornes Autorisées : 50",
          icon: "warning",
          showCancelButton: false,
          confirmButtonText: "Annuler",
          dangerMode: true,
        }).then((willChange) => {});
      }
    },
    // remove borne from listBorneSelected
    removeBorne(index) {
      // Confirm if the user really wants to remove the item
      this.$swal({
        title: "Êtes-vous sûr de vouloir supprimer cette borne ?",
        text: "Cette action est irréversible.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Oui, supprimer",
        cancelButtonText: "Annuler",
        dangerMode: true,
      }).then(async (willDelete) => {
        if (willDelete.isConfirmed) {
          // Remove the item at the given index from listBorneSelected array
          this.generale.nbrPointDeRecharge -= this.listBorneSelected[
            index
          ].nbrPointDeRecharge;
          this.listBorneSelected.splice(index, 1);
          await this.verfierTypeAllimentation();
          await this.setDossierDetude();
          await this.setForfaitDeLivraisonBorne();
        }
      });
    },
    // rules like tabs question check
    verifierRules(key, valueTest, etat) {
      if (key == "obligatoire") {
        // test sur les input de (Percements)
        //si il select un autre option il faut remplire le champ distance ou quantity
        if (etat == "oui") {
          if (valueTest.length > 0) {
            let condition = valueTest.every(
              (element) => element.distance > 0 || element.distance > 0
            );
            if (condition) {
              return false;
            } else {
              return true;
            }
          } else {
            return true;
          }
        } else {
          return false;
        }
      } else if (key == "optionnelle") {
        if (valueTest.length > 0) {
          let condition = valueTest.every(
            (element) => element.distance > 0 || element.distance > 0
          );
          if (condition) {
            return false;
          } else {
            return true;
          }
        } else {
          return false;
        }
      } else if (key == "etudeSuivi") {
        let tempCheck = [];
        let condition = false;
        // test sur les input de (fourfait livraison), (livraison TGBT), (dossier conception), (DOE), (Accompagnement)
        //si il select un autre option il faut remplire le champ distance ou quantity
        Object.entries(this.listCheckValue).forEach((element, index) => {
          for (let i = 0; i < element[1].length; i++) {
            if (this.generale[`${element[0]}`][`${element[1][i]}`].length > 0) {
              tempCheck.push(false);
            } else {
              tempCheck.push(true);
            }
          }
        });
        if (
          !this.verifierRules("optionnelle", this.generale.OptionEtude.noteDeCalcule) &&
          !this.verifierRules(
            "optionnelle",
            this.generale.OptionEtude.etudeDeStructure
          ) &&
          !this.verifierRules("optionnelle", this.generale.OptionEtude.DAAT) &&
          !this.verifierRules("optionnelle", this.generale.OptionEtude.DICT) &&
          !this.verifierRules("optionnelle", this.generale.visiteSupplementaire)
        ) {
          tempCheck.push(false);
        } else {
          tempCheck.push(true);
        }
        condition = tempCheck.every((element) => element == false);
        if (condition) {
          return false;
        } else {
          return true;
        }
      } else if (key == "Maconnerie") {
        let condition = [];
        if (
          this.generale.tranchement.etat == "oui" ||
          this.generale.percements.etat == "oui"
        ) {
          if (
            !this.verifierRules(
              "obligatoire",
              this.selectedPercements,
              this.generale.percements.etat
            ) &&
            !this.verifierRules(
              "obligatoire",
              this.selectedTranchement,
              this.generale.tranchement.etat
            )
          ) {
            condition.push(false);
          } else {
            condition.push(true);
          }
        }
        if (
          !this.verifierRules("optionnelle", this.generale.ProtectionTravail) &&
          !this.verifierRules("optionnelle", this.generale.OptionsCivile.Decoupe) &&
          !this.verifierRules("optionnelle", this.generale.OptionsCivile.gravats) &&
          !this.verifierRules("optionnelle", this.generale.OptionsCivile.paveDeRue) &&
          !this.verifierRules(
            "optionnelle",
            this.generale.OptionsCivile.tracementManuelle
          ) &&
          !this.verifierRules("optionnelle", this.generale.OptionsCivile.enrobe) &&
          !this.verifierRules("optionnelle", this.generale.OptionsCivile.fourreaux)
        ) {
          condition.push(false);
        } else {
          condition.push(true);
        }

        if (condition.every((el) => el == false)) {
          return false;
        } else {
          return true;
        }

        // test sur les input de (Percements) et (Tranchement)
        //si il select un autre option il faut remplire le champ distance ou quantity
      } else if (key == "genieCivil") {
        let condition = [];

        if (
          this.generale.FouilleetMassif.etat == "oui" ||
          this.generale.ChambreDeTirage.etat == "oui"
        ) {
          if (
            !this.verifierRules(
              "obligatoire",
              this.selectedChambreDeTirage,
              this.generale.ChambreDeTirage.etat
            ) &&
            !this.verifierRules(
              "obligatoire",
              this.selectedFouilleetMassif,
              this.generale.FouilleetMassif.etat
            ) &&
            !this.verifierRules(
              "obligatoire",
              this.generale.protectionEtSchemasDeCables,
              this.generale.ChambreDeTirage.etat
            )
          ) {
            condition.push(false);
          } else {
            condition.push(true);
          }
        }

        if (
          !this.verifierRules(
            "optionnelle",
            this.generale.OptionsCivile.plaqueDeRoulement
          ) &&
          !this.verifierRules("optionnelle", this.generale.OptionsCivile.geotextile)
        ) {
          condition.push(false);
        } else {
          condition.push(true);
        }

        if (condition.every((el) => el == false)) {
          return false;
        } else {
          return true;
        }

        // test sur les input de (chambre de tirage) et (FouilleetMassif) et (Protection et et schémas de cables)
        //si il select un autre option il faut remplire le champ distance ou quantity
      }
      //return true;
    },
    //save data
    async validerDevisComplete() {
      await axios({
        url: process.env.VUE_APP_URL_API_CLIENT + "createAPDfromVTorFaisabilite/",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        data: JSON.stringify({
          token: this.$store.getters.getUserToken,
          vtId: this.vtId,
          generale: this.generale,
          borneSelected: this.listBorneSelected,
          selectedFouilleetMassif: this.selectedFouilleetMassif,
          selectedPercements: this.selectedPercements,
          selectedTranchement: this.selectedTranchement,
          selectedChambreDeTirage: this.selectedChambreDeTirage,
          productsToBeAdded: this.productsToBeAdded,
          nouveauPDL: this.nouveauPDL,
        }),
      })
        .then((res) => {
          if (res.data.status == true) {
            this.message = res.data.message;
          } else {
            this.message = res.data.message;
          }
        })
        .catch((err) => {});
    },
  },
};
</script>

<style scoped>
.imgs-wrapper {
}
.tgbt-img-wrapper {
}
.tgbt-img {
  width: 100px;
  height: 100px;
}
.tdirve-img-wrapper {
  justify-content: center;
}
.tdirve-img {
  width: 100px;
  height: 100px;
}
.line {
  position: relative;
  width: 100%;
  height: 4px;
  background-color: #8aa7b4;
}

.input-tgbt-tdirve {
  position: absolute;
  top: -50px;
  left: 50%;
  transform: translateX(-50%);
  width: 50%;
  max-width: 200px;
  height: 56px;
}
/************ borne ************* */
.item {
  display: inline-block;
}
.borne-wrapper {
  display: flex;
  flex-direction: column;
  margin-top: 64px;
}
.nbr-borne-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.nbr-borne {
  max-width: 200px;
  height: 56px;
}
.borne-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
  min-height: 80px;
  padding: 15px;
  border-radius: 6px;
  background-color: rgb(179, 206, 206);
}
.borne {
  flex: 1;
  min-width: 200px;
  max-width: 350px;
  min-height: 100px;
  background-color: #fff;
  padding: 15px;
  border-radius: 6px;
  box-shadow: 0px 4px 16px 0px rgba(182, 182, 182, 0.75);
}
.borne-btn-close {
  color: rgb(221, 113, 113);
  cursor: pointer;
}

.borne-btn-add {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f1f1f1;
  padding: 8px 16px;
  border-radius: 4px;
  border: 1px solid #dae6e4;
  cursor: pointer;
  margin-bottom: 60px;
  margin-right: 15px;
}

.borne-btn-add-icon {
  font-size: 32px;
  color: #4f6772;
  padding: 0px;
}

.borne-counter {
  position: relative;
  font-size: 24px;
  font-weight: 700;
  font-family: sans-serif;
  color: #414242;
  padding: 8px 24px;
  border-radius: 4px;
  border: 1px solid #667f8b;
  background-color: rgb(255, 255, 255);
  margin-bottom: 60px;
}
.borne-counter::before {
  content: "";
  position: absolute;
  top: -60px;
  left: 50%;
  transform: translateX(-50%);
  height: 60px;
  width: 3px;
  background-color: #8aa7b4;
}
.borne-counter::after {
  content: "";
  position: absolute;
  bottom: -60px;
  left: 50%;
  transform: translateX(-50%);
  height: 60px;
  width: 3px;
  background-color: #8aa7b4;
}

/********** questions *************** */
.section-card {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  background-color: #fff;
  border: 1px solid #dadce0;
  border-left: 5px solid #05a36e;
  border-radius: 8px;
  padding: 20px;
  box-shadow: inset 1px 1px 0 rgba(0, 0, 0, 0.1), inset 0 -1px 0 rgba(0, 0, 0, 0.07);
  margin-bottom: 20px;
}
.section-card-title {
  width: 100%;
  font-family: "docs-Roboto", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 24pt;
  line-height: 1.25;
  letter-spacing: 0;
  color: #202124;
}
.questions-wrapper {
  margin-top: 40px;
}
.question-card {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border: 1px solid #dadce0;
  box-shadow: inset 1px 1px 0 rgba(78, 78, 78, 0.1), inset 0 -1px 0 rgba(78, 78, 78, 0.07);
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
}

.question-card-title {
  font-size: 12pt;
  font-weight: 600;
  font-family: "docs-Roboto", Helvetica, Arial, sans-serif;
  letter-spacing: 0;
  margin-bottom: 20px;
}
</style>
