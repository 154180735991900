import { render, staticRenderFns } from "./profilAvenant.vue?vue&type=template&id=cbc72162&scoped=true&"
import script from "./profilAvenant.vue?vue&type=script&lang=js&"
export * from "./profilAvenant.vue?vue&type=script&lang=js&"
import style0 from "./profilAvenant.vue?vue&type=style&index=0&id=cbc72162&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cbc72162",
  null
  
)

export default component.exports