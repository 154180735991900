<template>
    <div class="main">
      <div
        class="dropzone-container"
        :style="isDragging && 'border-color: green;'"
        @dragover="dragover"
        @dragleave="dragleave"
        @drop="drop"
      >
        <input
          type="file"
          multiple
          name="file"
          id="fileInput"
          class="hidden-input"
          @change="onChange"
          ref="file"
          accept=".pdf,.jpg,.jpeg,.png"
        />
  
        <label for="fileInput" class="file-label">
        <v-icon :color="$colors[0]" size="60">mdi-cloud-upload</v-icon>
          <div v-if="isDragging">Séléctionnez un fichier à importer</div>
          <div style="{ color: $colors[0] }" v-else>Déposer votre/vos fichier(s) ici <u>click here</u> to upload.</div>
        </label>

       <div class="preview-container mt-4" v-if="files.length">
        <div v-for="file in files" :key="file.name" class="preview-card">
          <div>
            <img class="preview-img" :src="generateThumbnail(file)" />
            <p :title="file.name">
              {{ makeName(file.name) }}
              {{ Math.round(file.size / 1000) + "kb" }}
            </p>
          </div>
          <div>
            <button
              class="ml-2"
              type="button"
              @click="remove(files.indexOf(file))"
              title="Remove file"
            >
              <b>&times;</b>
            </button>
          </div>
        </div>
      </div>
      </div>
      <!-- send -->
      <button class="btn  w-100 mx-auto" style=" margin:10px 20px;background-color: rgb(0, 121, 145);color: rgb(245, 243, 234);" @click="uploadFiles()">Upload</button>
  </div>
  </template>
  
  <script>
  import axios from "axios";

  export default {
    name: "uploadFilesProfilClient",
    props:{
        idSociete: {
            type: String,
            required: true,
        },
        selectedFile: {
            type: Object,
            required: true,
        },
    },
    data() {

      return {
        isDragging: false,
        files: [],
      };
    },
    methods: {
        // get the files uploaded
      onChange() {
       
       // if empty
       if(this.$refs.file.files.length > 0){
        
       let files = this.$refs.file.files;
        if (this.files.length > 0) {
          // check deplicated
          for (let i = 0; i < this.files.length; i++) {
            for (let j = i + 1; j < this.files.length; j++) {
              if (this.files[i].name === this.files[j].name) {
                this.files.splice(j, 1);
              }
            }
          }
        }

       // append only if not dragged
        if(this.files.length == 0){
            for (let i = 0; i < files.length; i++) {
                this.files.push(files[i]);
              }
        }
        // append if dragged
        else{
            for (let i = 0; i < files.length; i++) {
                this.files.push(files[i]);
              }
        }
        // remove deplicated keys
        for (let i = 0; i < this.files.length; i++) {
            for (let j = i + 1; j < this.files.length; j++) {
              if (this.files[i].name === this.files[j].name) {
                // without splice
                this.files.splice(j, 1);


              }
            }
          }
        
      }
        
      },
      // display the thumbnail of the file uploaded
      generateThumbnail(file) {
      let fileSrc = URL.createObjectURL(file);
      setTimeout(() => {
        URL.revokeObjectURL(fileSrc);
      }, 1000);
      return fileSrc;
    },
    // display the name of the file uploaded
    makeName(name) {
      return (
        name.split(".")[0].substring(0, 3) +
        "..." +
        name.split(".")[name.split(".").length - 1]
      );
    },

    // remove the file uploaded
    remove(i) {
        this.files.splice(i, 1);
    },
    dragover(e) {
        e.preventDefault();
        this.isDragging = true;
    },
    dragleave() {
        this.isDragging = false;
    },
    drop(e) {
        e.preventDefault();
        // append the files uploaded to refs.file.files and convert to file list
        const newFileList = new DataTransfer();
        this.isDragging = false;
        this.files = [...this.files, ...e.dataTransfer.files];
        console.log(this.files);
        
        for (let i = 0; i < this.files.length; i++) {
            newFileList.items.add(this.files[i]);
        }
        this.$refs.file.files = newFileList.files;

     
  
        console.log("list refs")

        
        
    },
    uploadFiles() {
        const files = this.files;
        // list of files uploaded
        const formData = new FormData();
        formData.append("id_societe", this.idSociete);
        formData.append("categorie", this.selectedFile.categorie);
        formData.append("token", this.$cookies.get("token"));
        for (let i = 0; i < files.length; i++) {
          formData.append("document", files[i]);
        }
        axios({
            method: "POST",
            url: process.env.VUE_APP_URL_API_CLIENT + this.selectedFile.uploadUrl,
            data: formData,
            headers: {
                "Content-Type": "multipart/form-data",
            },

        }).then((res) =>{
            console.log(res);
            this.$emit("updateFiles", res.data);
            this.files = [];
        }).catch((err) =>{
            console.log(err);
      
        })
        } ,
    },
  };
  </script>
  <style scoped>
  
    .dropzone-container {
        padding: 4rem;
        background: #f7fafc;
        border: 1px solid #e2e8f0;
        border-radius: 0.5rem;
        text-align: center;
        cursor: pointer;
        margin:10px 20px;

    }
    .hidden-input {
        opacity: 0;
        overflow: hidden;
        position: absolute;
        width: 1px;
        height: 1px;
    }

    .file-label {
        font-size: 20px;
        display: block;
        cursor: pointer;
    }

    .preview-container {
        display: flex;
        margin-top: 2rem;
    }

    .preview-card {
        display: flex;
        border: 1px solid #a2a2a2;
        padding: 5px;
        margin-left: 5px;
    }

    .preview-img {
        width: 50px;
        height: 50px;
        border-radius: 5px;
        border: 1px solid #a2a2a2;
        background-color: #a2a2a2;
    }
    .dropzone-container {
        border: 2px dashed;
        border-color: #9e9e9e;
    }
  </style>