import { render, staticRenderFns } from "./NotreApproche.vue?vue&type=template&id=785c4f7c&scoped=true&"
import script from "./NotreApproche.vue?vue&type=script&lang=js&"
export * from "./NotreApproche.vue?vue&type=script&lang=js&"
import style0 from "./NotreApproche.vue?vue&type=style&index=0&id=785c4f7c&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "785c4f7c",
  null
  
)

export default component.exports