<template>
  <div class="mt-6 mr-2 ml-3">
    <div style="height: 100%">
      <loadingComponent
        style="position: absolute"
        v-if="dialogLoading"
        :dialogProps="dialogLoading"
        :messageDialogeProps="messageError"
        :loadingProps="loadingEnedis"
        :errorProps="errorDialog"
        @closeDialog="close()"
      />
      <v-card v-if="!loadingEnedis && !infosCourbe" class="ma-4 rounded-lg">
        <div id="rowConnected" v-if="infosCourbe == false">
          <v-toolbar
            class="rounded-t-lg"
            :style="{ background: $styles.background }"
            dark
          >
            <span class="pl-0">Effectuer un devis</span>
          </v-toolbar>
          <div class="pa-4">
            <v-form ref="formACD">
              <v-form ref="formSiret" class="d-flex flex-row">
                <v-combobox
                  dense
                  :search-input.sync="societeRechercheModel"
                  v-model="societeRechercheModel"
                  outlined
                  :items="listSociete"
                  :rules="[
                    $rules.required('un Siret/Raison Social valide'),
                    $rules.isSanitize,
                    $rules.injection_sql,
                  ]"
                  @update:search-input="getPropositionSociete()"
                  label="Siret ou Raison sociale"
                ></v-combobox>
                <v-btn
                  :color="$colors[0]"
                  class="ml-4"
                  @click="getNumCompteurBySociete"
                  :loading="loadingEnedis"
                >
                  <v-icon class="white--text">mdi-magnify</v-icon>
                </v-btn>
              </v-form>
              <div class="d-flex flex-row" v-if="listCompteurValider.length > 0">
                <v-autocomplete
                  outlined
                  dense
                  @change="getInfosCompteur"
                  :rules="[
                    $rules.required('un numéro de compteur'),
                    $rules.isSanitize,
                    $rules.isNumber,
                  ]"
                  v-model="compteur.numCompteurACD"
                  :items="listCompteurValider"
                  item-text="numCompteur"
                  item-value="id"
                  label="Compteur"
                ></v-autocomplete>
              </div>
              <v-row v-if="listCompteurValider.length > 0" class="px-3">
                <!-- info Compteur -->
                <v-col
                  cols="6"
                  class="pa-0 px-1"
                  v-for="(value, key, index) in compteur"
                  :key="index"
                  v-if="listCompteurValider.length > 0"
                >
                  <v-text-field
                    v-if="key !== 'numCompteurACD'"
                    disabled
                    :label="key"
                    outlined
                    dense
                    v-model="compteur[key]"
                  >
                    <template v-slot:label>
                      <span v-if="key == 'segment'">Segment</span>
                      <span v-else-if="key == 'kva'"> Puissance (kva)</span>
                      <span v-else-if="key == 'puissanceAtteinteMaximale'">
                        Puissance maximale atteinte (kva)</span
                      >
                      <span v-else-if="key == 'optionTarifaire'"> Option tarifaire</span>
                      <span v-else>{{ key }} </span>
                    </template>
                  </v-text-field>
                </v-col>
                <v-col cols="12" class="pa-0 px-1 mb-4">
                  <CourbeDeCharge
                    @maxValue="handleMaxValue"
                    v-if="listCompteurValider.length > 0"
                    :id="idCompteur"
                  />
                </v-col>
                <!-- les questions des form -->
                <GraphDevis
                  ref="GraphDevis"
                  :maxRechedValue="maxValue"
                  :selectedCompteur="compteur"
                  @TDIRVEBorne="TDIRVEBorne = $event"
                />

                <!-- les question commerciale -->
                <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                  <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                    <v-text-field
                      label="Date d'installation souhaitée"
                      type="date"
                      :rules="[rules.required]"
                      dense
                      outlined
                      v-model="formDevis.periodeInstallationSouhaitee"
                    />
                  </v-col>
                  <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                    <v-textarea
                      label="Restriction d'acces au site"
                      outlined
                      rows="5"
                      row-height="25"
                      :rules="[rules.required]"
                      v-model="formDevis.restrictionAccesSite"
                    ></v-textarea>
                  </v-col>
                  <v-row class="d-flex align-items-center justify-content-center">
                  </v-row>
                </v-col>
                <!-- validate/cancel buttons -->
                <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                  <div class="d-flex justify-content-end">
                    <v-btn @click="" color="error" text class="mx-2">Annuler</v-btn>
                    <v-btn
                      @click="saveDevis"
                      :style="{ background: $styles.background }"
                      class="text-white"
                    >
                      <span class="Poppins-Bold">Valider</span>
                    </v-btn>
                  </div>
                </v-col>
              </v-row>
            </v-form>
          </div>
        </div>
      </v-card>
    </div>
  </div>
</template>
<script>
import axios from "@/plugins/axios";
import loadingComponent from "../../components/Loading";
import CourbeDeCharge from "./components/CourbeDeCharge.vue";
import GraphDevis from "./components/GraphDevis.vue";
export default {
  name: "TestEffectuerUnDevis",
  components: { loadingComponent, CourbeDeCharge, GraphDevis },
  data() {
    return {
      TDIRVEBorne: false,
      ///formulaire calcule
      snackbar: false,
      messageSnackbar: "",
      messageError: "",
      societeRechercheModel: "",
      infosCourbe: false,
      dialogLoading: false,
      loadingEnedis: false,
      errorCodePostalEnedis: false,
      errorEnedis: false,
      errorDialog: false,
      adresseUser: "",
      idCompteur: null,
      compteur: {
        // id :"",
        segment: "",
        kva: 0,
        puissanceAtteinteMaximale: 0,
        optionTarifaire: "",
        numCompteurACD: "",
      },
      listSociete: [],
      listCompteurValider: [],
      afficheInfos: false,
      versionVoitureModels: "",
      listeVersionModels: [],
      modeleVoiture: "",
      prixEssence: 0,
      prixElec: 0,
      distanceVoiture: 0,
      consoVoiture: 0,
      listeModelesVoitures: [],
      coutEssence: 0,
      coutElec: 0,
      prixDifferenceEssenceElec: 0,
      maxValue: 0,
      showCourbe: false,
      // data
      listSiret: [],
      listCompteurs: [],
      listMaterials: {
        Cable: [],
        Borne: [],
        options: [],
      },
      // state
      selectCompteursVisible: false,
      selectMaterialsVisible: false,
      formDevis: {
        compteurs: [],
        nouveauPDL: "non",
        restrictionAccesSite: "",
        periodeInstallationSouhaitee: "",
      },

      // rules
      rules: {
        isString: (value) =>
          !/\d/.test(value) || "Ce champ ne peut pas etre composé de chiffres",
        required: (value) => !!value || "Ce champ est obligatoire.",
        siretLength: (v) => v.length == 14 || "Le siret doit comporter 14 chiffres",
        isNumber: (v) =>
          (typeof v === "string" && v.match(/^[0-9]+$/) != null) ||
          "Ce champ n'accepte que les chiffres",
        hasItemSelected: (v) =>
          v.length >= 1 || "Vous devez selectionner aux moins un compteur",
      },
    };
  },
  mounted() {
    if (this.$route.query.raisonSocial) {
      this.societeRechercheModel = this.$route.query.raisonSocial;
      this.getNumCompteurBySociete();
    }
  },
  created() {},
  methods: {
    async getPropositionSociete() {
      if (this.societeRechercheModel === null) {
        this.listSociete = [];
        this.listCompteurValider = [];
      }
      if (this.$refs.formSiret.validate()) {
        await axios({
          url: process.env.VUE_APP_URL_API_CLIENT + "getPropositionSocieteByCompte/",
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          data: JSON.stringify({
            token: this.$store.getters.getUserToken,
            societeValue: this.$DOMPurify.sanitize(this.societeRechercheModel),
          }),
        }).then((res) => {
          this.listSociete = res.data.result;
        });
      }
    },
    async getInfosCompteur() {
      await axios({
        url: process.env.VUE_APP_URL_API_CLIENT + "getInformationsCompteurById/",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        data: JSON.stringify({
          token: this.$store.getters.getUserToken,
          idCompteur: this.compteur.numCompteurACD,
        }),
      }).then((res) => {
        let response = res.data.compteur;
        this.idCompteur = response.id;
        // this.compteur.id = response.id;
        this.compteur.kva = response.kva;
        this.compteur.puissanceAtteinteMaximale = response.puissanceAtteinteMaximale;
        this.compteur.segment = response.segment;
        this.compteur.optionTarifaire = response.optionTarifaire;
        //this.formDevis.compteurs.push(this.compteur)
        this.formDevis.compteurs = res.data.compteur;

        // reload the courbe de charge component
      });
      await axios({
        url: process.env.VUE_APP_URL_API_CLIENT + "availableDevisItemsView/",
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
        data: JSON.stringify({
          token: this.$store.getters.getUserToken,
        }),
      }).then((res) => {
        this.listMaterials.Cable = res.data.cables;
        this.listMaterials.Borne = res.data.bornes;
        this.listMaterials.options = res.data.options;
      });
    },
    async getNumCompteurBySociete() {
      if (this.$route.query.raisonSocial || this.$refs.formSiret.validate()) {
        this.dialogLoading = true;
        this.loadingEnedis = true;
        await axios({
          url: process.env.VUE_APP_URL_API_CLIENT + "getListCompteurParSiretByCompte/",
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          data: JSON.stringify({
            token: this.$store.getters.getUserToken,
            societeValue: this.societeRechercheModel,
          }),
        })
          .then((res) => {
            let listCompteur = res.data.listeCompteur;
            if (listCompteur.length > 0) {
              this.listCompteurValider = res.data.listeCompteur;
              this.compteur.numCompteurACD = this.listCompteurValider[0]["id"];
              this.loadingEnedis = false;
              this.dialogLoading = false;
              this.getInfosCompteur();
            }
          })
          .catch((error) => {
            console.log(error);
            if (error.response.status === 404) {
              // Gérer la réponse 404 ici
              this.messageError = error.response.data.messageError;
              this.errorDialog = true;
              this.loadingEnedis = false;
            } else {
              // Gérer les autres erreurs
              console.log("Une erreur s'est produite");
            }
          });
      }
    },
    async saveDevis() {
      // Validate form
      const isDevisValid = this.$refs.formACD.validate();
      if (isDevisValid) {
        console.log(this.formDevis);
        // Access the states data from the GraphDevis component
        const questionsList = this.$refs.GraphDevis.lesQuestions;
        const distance_alimentation = this.$refs.GraphDevis.distance_alimentation;
        const nbrBorne = this.$refs.GraphDevis.nbrBorne;
        const listBorneSelected = this.$refs.GraphDevis.listBorneSelected;
        const signialisationQuestion = this.$refs.GraphDevis.signialisationQuestion;
        this.formDevis.nouveauPDL = this.$refs.GraphDevis.nouveauPDL;
        const donnerForm = {
          ...this.formDevis,
          BorneTDIRVE: this.TDIRVEBorne,
          distance_alimentation: distance_alimentation,
          nbrBorne: nbrBorne,
          listBorneSelected: listBorneSelected,
          questionsList: questionsList,
          signialisationQuestion: signialisationQuestion,
        };
        this.dialogLoading = true;
        this.loadingEnedis = true;
        await axios({
          // url: process.env.VUE_APP_URL_API_CLIENT + "create_faisabilite_from_questions/",
          url: process.env.VUE_APP_URL_API_CLIENT + "create_faisabilite_estimation/",
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          data: {
            token: this.$store.getters.getUserToken,
            Donner: JSON.stringify(donnerForm),
          },
        })
          .then((res) => {
            if (res.data.status == true) {
              this.$toast.success("Devis Effectuer Avec Succès");
              this.messageError = res.data.message;
              this.errorDialog = false;
            } else {
              this.messageError = res.data.message;
              this.errorDialog = true;
            }
          })
          .catch((err) => {
            this.messageError = "une erreur technique est survenue";
            this.errorDialog = true;
          });
        this.dialogLoading = false;
        this.loadingEnedis = false;
      }
    },
    close() {
      (this.errorDialog = false), (this.dialogLoading = false);
    },
    async handleMaxValue(value) {
      this.maxValue = value;
    },
  },
};
</script>

<style scoped></style>
