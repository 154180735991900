import "@fortawesome/fontawesome-free/css/all.css";
import "material-design-icons-iconfont/dist/material-design-icons.css";
import Vue from "vue";
import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: "fa" || "md",
  },
  theme: {
    themes: {
      light: {
        background: "#f5f6ff",
        dark_green: "#007991",
        dark_blue: "#002542",
        light_green: "#01f6bb",
        light_green2: "#00e676",
        leftMenuIconColor: "#002542",
        primary_text: "#14324F",
      },
      dark: {
        background: "#002542",
        dark_green: "#007991",
        dark_blue: "#002542",
        light_green: "#01f6bb",
        light_green2: "#00e676",
        leftMenuIconColor: "#002542",
        primary_text: "#fff",
      },
    },
  },
});
