<template>
     <v-dialog
         v-model="showModal"
         persistent
         max-width="80%"
         style="z-index: 9999;"
         >
         <v-card>
             <v-toolbar
                 dark>
                 <span class="text-h6 pl-0">Informations Event</span>
             </v-toolbar>
             <v-form ref="formAddEvent" class="pa-4">
                 <v-row class="ma-0 pa-0">
                     <v-col cols="12" sm="4" md="4" lg="4" xl='4' class="ma-0 pa-0">
                         <v-text-field
                         dense
                         v-model="editedEvent.title"
                         placeholder="25 rue Baltimore 1234"
                         prepend-inner-icon="mdi-map-search-outline"
                         label="Nom"
                         outlined/>
                     </v-col>
                     <v-col cols="12" sm="4" md="4" lg="4" xl='4' class="ma-0 pa-0">
                         <v-text-field
                         dense
                         type="date"
                         v-model="editedEvent.start"
                         placeholder="25 rue Baltimore 1234"
                         prepend-inner-icon="mdi-map-search-outline"
                         label="Time Debut"
                         outlined/>
                     </v-col>
                     <v-col cols="12" sm="4" md="4" lg="4" xl='4' class="ma-0 pa-0">
                         <v-text-field
                         dense
                         type="date "
                         v-model="editedEvent.end"
                         placeholder="25 rue Baltimore 1234"
                         prepend-inner-icon="mdi-map-search-outline"
                         label="Time Fin"
                         outlined/>
                     </v-col>
                     <v-col cols="12" sm="12" md="12" lg="12" xl='12' class="ma-0 pa-0">
                         <v-row>
                             <v-col cols="12" sm="4" md="4" lg="4" xl='4' class="ma-0 pa-0">
                                 <v-btn @click="closeModal"> Close </v-btn>
                             </v-col>
                             <v-col cols="12" sm="4" md="4" lg="4" xl='4' class="ma-0 pa-0">
                                 <v-btn @click="updateEventMonthly"> Modifier </v-btn>
                             </v-col>
                             {{ editedEvent }}
                         </v-row>
                     </v-col>
                 </v-row>
             </v-form>

         </v-card>
     </v-dialog>
</template>
<script>
export default {
    name: 'EventUpdateModal',
    props: {
        showModal: {
            type: Boolean,
            default: false,
        },
        event: {
            type:Object,
        },
    },
    data() {
        return {
            editedEvent: {
                id: '',
                title: '',
                start: '',
                end: '',
            },
        }
    },
    methods: {
        closeModal() {
            this.$emit('closeModal');
        },
        updateEventMonthly() {
            this.$emit('updateEvent', this.editedEvent);
        },  
    },
    watch: {
        // When the modal is opened, we fill the form with the selected event immediately
        showModal: {
            handler: function (val) {
                if (val) {
                    this.editedEvent.id = this.event.id;
                    this.editedEvent.title = this.event.title;
                    this.editedEvent.start = this.event.start;
                    this.editedEvent.end = this.event.end;
                }
            },
            deep: true,
        },
    },
}
</script>
<style scoped>
</style>