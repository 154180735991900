<template>
  <div class="d-flex flex-row justify-center align-center" style="height: 100%;">
    <div class="d-flex flex-column align-center">
      <span class="Poppins-Bold" style="color: #007991; font-size: 150px;">ERROR</span>
      <span class="Poppins-Bold" style="color: #02161a; font-size: 20px;">{{ message }}</span>
      <v-btn color="#007991" class="my-4" to="/"><span style="color: #fff; font-size: 14px; font-weight: 700;">Retour</span></v-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: "SolarPresentation",
  props: ['message'],
}
</script>