
import jsPDF from "jspdf";
import "jspdf-autotable";
import { PDFDocument, rgb, StandardFonts, degrees, PDFName, PDFString, TextAlignment, setFillingRgbColor, setFontAndSize, layoutMultilineText, setBorderColor } from "pdf-lib";

class PDFDevisBorneIRVEGenerator {
  constructor(data) {
    this.devisData = data;
  }

  // pdf generation
  async generatePresentation() {
    try {
        const pageDeGardeDoc = await this.pageDeGarde();
        const firstPartDoc = await this.loadPdfFromPath("/pdf/devis-borne-irve/AUTOMATISATION_DEVIS_ENEFFIC_BORNE_IRVE_2-4.pdf");
        const syntheseTechniqueDoc = await this.syntheseTechniqueFiller();
        const tableDevisDoc = await this.tableDevisFiller();
        const signAndConditionsPartDoc = await this.signAndConditionsPageFiller();
        const lastPartDoc = await this.loadPdfFromPath("/pdf/devis-borne-irve/AUTOMATISATION_DEVIS_ENEFFIC_BORNE_IRVE_Last.pdf");
  
        const bornesSelectedFilesPath = {
            "Autel-1-7KW": "/pdf/devis-borne-irve/bornes_pdf/borne_autel_MaxiCharger_AC_Wallbox_7kW.pdf",
            "Autel-2-7KW": "/pdf/devis-borne-irve/bornes_pdf/borne_autel_MaxiCharger_AC_Wallbox_7kW.pdf",
            "Autel-1-22KW": "/pdf/devis-borne-irve/bornes_pdf/borne_autel_MaxiCharger_AC_Wallbox_7kW-22kW.pdf",
            "Autel-2-22KW": "/pdf/devis-borne-irve/bornes_pdf/borne_autel_MaxiCharger_AC_Ultra_22kWx2_7kW-22kW.pdf",
            "Autel/TOTEM-2-7KW": "/pdf/devis-borne-irve/bornes_pdf/borne_autel_MaxiCharger_DC_Fast_2kW-240kW.pdf",
            "Autel/TOTEM-2-22KW": "/pdf/devis-borne-irve/bornes_pdf/borne_autel_MaxiCharger_DC_Compact_40kW-47kW.pdf",
            "Hager/TOTEM-2-7KW": "/pdf/devis-borne-irve/bornes_pdf/borne_hager_Witty_Park_XEV600_2kW-7kW.pdf",
            "Hager/TOTEM-2-22KW": "/pdf/devis-borne-irve/bornes_pdf/borne_hager_Witty_Share_XEV1R22T2_2kW-22kW.pdf",
            "Hager-1-7KW": "/pdf/devis-borne-irve/bornes_pdf/borne_hager_Witty_Park_XEV600_2kW-7kW.pdf",
            "Hager-2-7KW": "/pdf/devis-borne-irve/bornes_pdf/borne_hager_Witty_Park_XEV600_2kW-7kW.pdf",
            "Hager-1-22KW": "/pdf/devis-borne-irve/bornes_pdf/borne_hager_Witty_Share_XEV1R22T2_2kW-22kW.pdf",
            "Hager-2-22KW": "/pdf/devis-borne-irve/bornes_pdf/borne_hager_Witty_Share_XEV1R22T2_2kW-22kW.pdf",
            "AutelResidentiel-1-7kW": "/pdf/devis-borne-irve/bornes_pdf/borne_autel_MaxiCharger_DC_HiPower_480kW-240kW.pdf",
        }
        let listBornesSelected = this.devisData?.selected_bornes || [];
  
        let mergedPdf = await PDFDocument.create()
        mergedPdf = await this.addPagesToPdf(mergedPdf, pageDeGardeDoc);
        mergedPdf = await this.addPagesToPdf(mergedPdf, firstPartDoc);
        mergedPdf = await this.addPagesToPdf(mergedPdf, syntheseTechniqueDoc);
        mergedPdf = await this.addPagesToPdf(mergedPdf, tableDevisDoc);
        mergedPdf = await this.addPagesToPdf(mergedPdf, signAndConditionsPartDoc);
        for (let i = 0; i < listBornesSelected.length; i++) {
            const borneKey = listBornesSelected[i];
            const borneSelectedDoc = await this.loadPdfFromPath(bornesSelectedFilesPath[borneKey]);
            if (borneSelectedDoc) {
                mergedPdf = await this.addPagesToPdf(mergedPdf, borneSelectedDoc, -90);
            }
        }
        mergedPdf = await this.addPagesToPdf(mergedPdf, lastPartDoc);
  
  
        // save pdf
        const filledPdfBytes = await mergedPdf.save();
  
        // Download the filled PDF
        const blob = new Blob([filledPdfBytes], { type: "application/pdf" });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = "Présentation-DEVIS-ENEFFIC-BORNE-IRVE.pdf";
        link.click();
    } catch (error) {
        console.log(error)
    }
  }
  // to merge pdf pages
  async addPagesToPdf(mergedPdf, donnerPdf, pageRotation) {
    const copiedPages = await mergedPdf.copyPages(
        donnerPdf,
        donnerPdf.getPageIndices()
    )
    copiedPages.forEach((page) => {
        if(pageRotation){
            page.setRotation(degrees(pageRotation));
        }
        mergedPdf.addPage(page)
    })
    return mergedPdf;
  }
  async pageDeGarde() {
    // Load the PDF from the project folder
    const pdfPath = "/pdf/devis-borne-irve/AUTOMATISATION_DEVIS_ENEFFIC_BORNE_IRVE_1.pdf";
    const response = await fetch(pdfPath);
    const arrayBuffer = await response.arrayBuffer();
    const pdfDoc = await PDFDocument.load(arrayBuffer);

    const page = pdfDoc.getPages()[0];
    const pageSize = page.getSize();

    const fontFamilyBold = await pdfDoc.embedFont(StandardFonts.HelveticaBold);
    const fontFamily = await pdfDoc.embedFont(StandardFonts.Helvetica);

    const fontSize = 13;
    const textColor = rgb(0.078, 0.196, 0.309);
    const textColorGreen = rgb(0.0039, 0.9647, 0.7333);
    const xPreparedBy = pageSize.width/2 - 202;
    const xSociete = pageSize.width/2 + 50;

    //*************************************** */ 
    //*************** prepare par: ********** */ 
    //*************************************** */ 
    // full name
    let prenom = this.capitalizeWords(this.devisData.preparedBy.prenom.toString());
    let nom = this.capitalizeWords(this.devisData.preparedBy.nom.toString());
    page.drawText(prenom + " " + nom, {
        x: xPreparedBy,
        y: 160,
        size: fontSize,
        color: textColor,
    });
    // email
    page.drawText(this.devisData.preparedBy.email.toString(), {
        x: xPreparedBy,
        y: 140,
        size: fontSize,
        color: textColor,
    });
    // telephone
    page.drawText(this.devisData.preparedBy.tel.toString(), {
        x: xPreparedBy,
        y: 120,
        size: fontSize,
        color: textColor,
    });

    //*************************************** */ 
    //*************** Societe *************** */ 
    //*************************************** */ 
    // Raison Sociale
    page.drawText(this.devisData.societeData.raison.toString(), {
        x: xSociete,
        y: 230,
        size: fontSize,
        color: textColorGreen,
    });
    // nom
    page.drawText(this.devisData.societeData.nom.toString(), {
        x: xSociete,
        y: 190,
        size: fontSize,
        color: textColor,
    });
    // prenom
    page.drawText(this.devisData.societeData.prenom.toString(), {
        x: xSociete,
        y: 175,
        size: fontSize,
        color: textColor,
    });
    // email
    page.drawText(this.devisData.societeData.adresseEmail.toString(), {
        x: xSociete,
        y: 150,
        size: fontSize,
        color: textColor,
    });
    // telephone
    page.drawText(this.devisData.societeData.numTele.toString(), {
        x: xSociete,
        y: 115,
        size: fontSize,
        color: textColor,
    });
  
    return pdfDoc;
  }
  async syntheseTechniqueFiller() {
    // Load the PDF from the project folder
    const pdfPath = "/pdf/devis-borne-irve/AUTOMATISATION_DEVIS_ENEFFIC_BORNE_IRVE_5.pdf";
    const response = await fetch(pdfPath);
    const arrayBuffer = await response.arrayBuffer();
    const pdfDoc = await PDFDocument.load(arrayBuffer);
    const page = pdfDoc.getPages()[0];
    const pageSize = page.getSize();
    const fontFamilyBold = await pdfDoc.embedFont(StandardFonts.HelveticaBold);
    const fontFamily = await pdfDoc.embedFont(StandardFonts.Helvetica);

    const fontSize = 24;
    const textColor = [0.078, 0.196, 0.309];
    let xLeft = 95;
    let xRight = pageSize.width - 345;
    let y = pageSize.height - 268;

    // nombreDeBorn
    this.drawAlignedText({
        page: page, 
        x: xLeft, 
        y: y, 
        align: 'center',
        text: this.devisData.autreData.nombreDeBorn.toString(), 
        font: fontFamily, 
        size: fontSize,
        textColor: textColor,
        width: 200,
        height: 80,
    });
    // nombreDePointDeRecharge
    y -= 150; // move cursor down
    this.drawAlignedText({
        page: page, 
        x: xRight, 
        y: y, 
        align: 'center',
        text: this.devisData.autreData.nombreDePointDeRecharge.toString(), 
        font: fontFamily, 
        size: fontSize,
        textColor: textColor,
        width: 180,
        height: 75,
    });
    // puissanceDeBorn
    y -= 157; // move cursor down
    this.drawMultiLineText({
        page: page, 
        x: xLeft, 
        y: y, 
        align: 'center',
        text: this.devisData.autreData.puissanceDeBorn.toString(), 
        font: fontFamily, 
        size: 13,
        textColor: textColor,
        width: 190,
        height: 80,
    });
    // PrimeAdvenir
    y -= 155; // move cursor down
    this.drawAlignedText({
        page: page, 
        x: xRight - 5, 
        y: y, 
        align: 'center',
        text: this.devisData.autreData.PrimeAdvenir.toString(), 
        font: fontFamily, 
        size: fontSize,
        textColor: textColor,
        width: 185,
        height: 80,
    });
  
    return pdfDoc;
  }
  async tableDevisFiller() {
    //var mergedPdf = await PDFDocument.create();
    // Load the PDF from the project folder
    const pdfPath = "/pdf/devis-borne-irve/table1Devis.pdf";
    const response = await fetch(pdfPath);
    const arrayBuffer = await response.arrayBuffer();
    
    var pdfDoc = await PDFDocument.load(arrayBuffer);
    var page = pdfDoc.getPages()[0]; // Get the first page
    const pageSize = page.getSize();
    
    // Get the form containing all the fields
    var form = pdfDoc.getForm();
    var fields = form.getFields();
  
    var fontBig = await pdfDoc.embedFont(StandardFonts.HelveticaBold);
    var fontSmall = await pdfDoc.embedFont(StandardFonts.Helvetica);
  
    // devis Number
    page.drawText(this.devisData.autreData.devisNumber.toString(), {
        x: pageSize.width / 2 - 37,
        y: pageSize.height - 99,
        size: 22,
        color: rgb(0.078, 0.196, 0.309),
    });
  
    // societe
    page.drawText(this.devisData.societeData.raison.toString(), {
        x: pageSize.width / 2 +10,
        y: pageSize.height - 175,
        size: 12,
        color: rgb(0, 0, 0),
        font: fontBig,
    });
    page.drawText(this.devisData.societeData.voie.toString(), {
        x: pageSize.width / 2 +10,
        y: pageSize.height - 190,
        size: 10,
        color: rgb(0, 0, 0),
        font: fontSmall,
    });
    page.drawText("T.V.A:" + this.devisData.societeData.TVA.toString(), {
        x: pageSize.width / 2 +10,
        y: pageSize.height - 205,
        size: 10,
        color: rgb(0, 0, 0),
        font: fontSmall,
    });
    let yPos = 570;
    page = this.drawTableEnteteDevis(page, 35, yPos, fontSmall)
    //page = this.drawLinesIInPage(page, yPos+23, yPos-30)
    var NewPageAdded = false
    let XPos2 = 75;
    let fontSizeMiddelTitle = 10;
    let fontSizeText = 10;
    var i = 0
    var resAddPageDevis = []
    for(var indexLot=0; indexLot<this.devisData.resultatSelected.length; indexLot++){
        const LOTObject = this.devisData.resultatSelected[indexLot];
        page, yPos = await this.drawLineTilte(page, `${LOTObject.title}`, yPos, XPos2, fontBig)
  
        for (var indexSub=0; indexSub<LOTObject.items.length; indexSub++){
            const subLOTObject = LOTObject.items[indexSub];
            var line = ["", subLOTObject.subtitle]
            // create new page si Ypos arrive <= 170 et remettre yPos a 660 et continuer a afficher le tableau
            resAddPageDevis= await this.testHeightMax(page, yPos, pdfDoc)
            page = resAddPageDevis[0]
            yPos = resAddPageDevis[1]
            pdfDoc = resAddPageDevis[2]
            NewPageAdded = resAddPageDevis[3]
            if (subLOTObject.subtitle) { 
                page, yPos = await this.drawLineSubTilte(page, line, yPos, fontBig)
            }
            for(var indexMat=0; indexMat<subLOTObject.items.length; indexMat++){
                const materialObject = subLOTObject.items[indexMat];
                var lineItem = [
                    `${indexLot}.${indexSub}.${indexMat}`, 
                    materialObject.description, 
                    materialObject.quantite, 
                    materialObject.unite, 
                    materialObject.PUHT.toString(), 
                    materialObject.totalHT.toString(),
                ];
                // create new page si Ypos arrive <= 170 et remettre yPos a 660 et continuer a afficher le tableau
                resAddPageDevis= await this.testHeightMax(page, yPos, pdfDoc)
                page = resAddPageDevis[0]
                yPos = resAddPageDevis[1]
                pdfDoc = resAddPageDevis[2]
                NewPageAdded = resAddPageDevis[3]
                page, yPos = await this.drawLine(page, lineItem, yPos, fontSmall, form, i)
            }
            
            // create new page si Ypos arrive <= 170 et remettre yPos a 660 et continuer a afficher le tableau
            resAddPageDevis= await this.testHeightMax(page, yPos, pdfDoc)
            page = resAddPageDevis[0]
            yPos = resAddPageDevis[1]
            pdfDoc = resAddPageDevis[2]
            NewPageAdded = resAddPageDevis[3]
            if(subLOTObject.subtitle){
                page, yPos = await this.drawLineSubTilteTotal(page, "Sous-total " + subLOTObject.subtitle, subLOTObject.total + "", yPos, fontBig, form)
            }
        }
        
        // create new page si Ypos arrive <= 170 et remettre yPos a 660 et continuer a afficher le tableau
        resAddPageDevis= await this.testHeightMax(page, yPos, pdfDoc)
        page = resAddPageDevis[0]
        yPos = resAddPageDevis[1]
        pdfDoc = resAddPageDevis[2]
        NewPageAdded = resAddPageDevis[3]
        page, yPos = await this.drawLineSubTilteTotal(page, "Sous-total "+LOTObject.title, LOTObject.total+"", yPos, fontBig, form)
    }
    // ******************#START TVA**************
    // const squareTVAWidth = 230;
    const squareTVAWidth = (pageSize.width / 2) - 35;
    const squareTVAheight = 93;
    var tmpYPosCloseTable = yPos
    yPos = yPos-squareTVAheight-25
    var xDevisTVA = pageSize.width - squareTVAWidth - 35;
    var yDevisTVA = yPos;
    var TotalHT = this.devisData.tvaResultat.TotalHT;
    var TVA = this.devisData.tvaResultat.TVA;
    var TotalTTC = this.devisData.tvaResultat.TotalTTC;
    var percentTVA = this.devisData.tvaResultat.percentTVA;
    var percentBase = TotalHT;
    var percentTotalTVA = TVA;
    resAddPageDevis= await this.testHeightMax(page, yPos, pdfDoc)
    page = resAddPageDevis[0]
    yPos = resAddPageDevis[1]
    pdfDoc = resAddPageDevis[2]
    NewPageAdded = resAddPageDevis[3]
    if(NewPageAdded == true){
        page, yPos = await this.drawLineSubTilteTotal(page, "", "", yPos, fontBig, form)
        page, yPos = await this.drawLineSubTilteTotal(page, "", "", yPos, fontBig, form)
        
        page.drawLine({
        start: { x: 35, y: yPos-10 },
        end: { x: 560, y: yPos-10 },
        thickness: 0.7,
        color: rgb(0.8705, 0.8705, 0.8705)
        });
        yPos = yPos - squareTVAheight - 25
        yDevisTVA = yPos;
    }else{
        
        page.drawLine({
        start: { x: 35, y: tmpYPosCloseTable-10 },
        end: { x: 560, y: tmpYPosCloseTable-10 },
        thickness: 0.7,
        color: rgb(0.8705, 0.8705, 0.8705)
        });
    }
    this.drawDevisTVARectangle(page, xDevisTVA, yDevisTVA, squareTVAWidth, squareTVAheight, pageSize, fontSmall, TotalHT, TVA, TotalTTC, percentTVA, percentBase, percentTotalTVA, form);
    // ******************#END TVA**************
    return pdfDoc;
  }
  async signAndConditionsPageFiller() {
    // Load the PDF from the project folder
    const pdfPath = "/pdf/devis-borne-irve/AUTOMATISATION_DEVIS_ENEFFIC_BORNE_IRVE_8-11.pdf";
    const response = await fetch(pdfPath);
    const arrayBuffer = await response.arrayBuffer();
    const pdfDoc = await PDFDocument.load(arrayBuffer);

    const pageZero = pdfDoc.getPages()[0];
    const pageSize = pageZero.getSize();

    const fontFamilyBold = await pdfDoc.embedFont(StandardFonts.HelveticaBold);
    const fontFamily = await pdfDoc.embedFont(StandardFonts.Helvetica);

    // Devis Number
    pageZero.drawText(this.devisData.autreData.devisNumber.toString(), {
        x: 85,
        y: pageSize.height - 296,
        size: 10,
        color: rgb(0, 0, 0),
    });
  
    return pdfDoc;
  }
  async loadPdfFromPath(pdfPath) {
    // Load the PDF from the project folder
    const response = await fetch(pdfPath);
    const arrayBuffer = await response.arrayBuffer();
    const pdfDoc = await PDFDocument.load(arrayBuffer);
  
    return pdfDoc;
  }

  // helpers
  drawTableEnteteDevis(page, posx, posy, font){
    const fillColor = rgb(0.078, 0.196, 0.309); // RGB values are normalized to range [0, 1]
    page.drawRectangle({
        x: posx,
        y: posy,
        width: 525,
        height: 23,
        color: fillColor,
        fillOpacity: 1, // Optionally set fill opacity (range [0, 1])
    });
    
    const fontSize = 10;
    let tmpPosY = posy + 8;
    let tmpPosX = posx + 25;
    var widthInput = 340;
    var text = "Désignation";
    var widthText = font.widthOfTextAtSize(text, fontSize);
    page.drawText("Désignation",{
        x: tmpPosX + (widthInput/2) - (widthText/2),
        y: tmpPosY,
        size: fontSize,
        color: rgb(1, 1, 1),
        },
    )
    tmpPosX += widthInput;
    widthInput = 30;
    text = "Qté";
    widthText = font.widthOfTextAtSize(text, fontSize);
    page.drawText("Qté",{
        x: tmpPosX + (widthInput/2) - (widthText/2),
        y: tmpPosY,
        size: fontSize,
        color: rgb(1, 1, 1),
        },
    )
    tmpPosX += widthInput;
    widthInput = 30;
    text = "U";
    widthText = font.widthOfTextAtSize(text, fontSize);
    page.drawText("U",{
        x: tmpPosX + (widthInput/2) - (widthText/2),
        y: tmpPosY,
        size: fontSize,
        color: rgb(1, 1, 1),
        },
    )
    tmpPosX += widthInput;
    widthInput = 47;
    text = "PUHT";
    widthText = font.widthOfTextAtSize(text, fontSize);
    page.drawText("PUHT",{
        x: tmpPosX + (widthInput/2) - (widthText/2),
        y: tmpPosY,
        size: fontSize,
        color: rgb(1, 1, 1),
        },
    )
    tmpPosX += widthInput;
    widthInput = 45;
    text = "Total H.T";
    widthText = font.widthOfTextAtSize(text, fontSize);
    page.drawText("Total H.T",{
        x: tmpPosX + (widthInput/2) - (widthText/2),
        y: tmpPosY,
        size: fontSize,
        color: rgb(1, 1, 1),
        },
    )
    return page
  }
  drawLinesIInPage(page, posY, posY2){
    page.drawLine({
        start: { x: 35, y: posY },
        end: { x: 35, y: posY2 },
        thickness: 0.7,
        color: rgb(0.8705, 0.8705, 0.8705)
    });
    page.drawLine({
        start: { x: 70, y: posY },
        end: { x: 70, y: posY2 },
        thickness: 0.7,
        color: rgb(0.8705, 0.8705, 0.8705)
    });
    page.drawLine({
        start: { x: 400, y: posY },
        end: { x: 400, y: posY2 },
        thickness: 0.7,
        color: rgb(0.8705, 0.8705, 0.8705)
    });
    page.drawLine({
        start: { x: 430, y: posY },
        end: { x: 430, y: posY2 },
        thickness: 0.7,
        color: rgb(0.8705, 0.8705, 0.8705)
    });
    page.drawLine({
        start: { x: 460, y: posY },
        end: { x: 460, y: posY2 },
        thickness: 0.7,
        color: rgb(0.8705, 0.8705, 0.8705)
    });
    page.drawLine({
        start: { x: 505, y: posY },
        end: { x: 505, y: posY2 },
        thickness: 0.7,
        color: rgb(0.8705, 0.8705, 0.8705)
    });
    page.drawLine({
        start: { x: 560, y: posY },
        end: { x: 560, y: posY2 },
        thickness: 0.7,
        color: rgb(0.8705, 0.8705, 0.8705)
    });
    return page
  }
  async drawLineTilte(page, text, yPos, posXText, fontBig){
    let posYTable = yPos+23
    let posY2Table = yPos-30
    let PosYtext = yPos-30
    let fontSizeBigTitle = 12;
    page = this.drawLinesIInPage(page, posYTable, posY2Table)
    var multiText = layoutMultilineText(text, {
        alignment: 'left', 
        fontSize: fontSizeBigTitle,   
        font: fontBig,
        lineHeight: 12,
        bounds: { width: 400 - 80, height: 1000  }
    })
    
    var startingPositon=PosYtext
    for(let i = 0; i < multiText.lines.length; i++) {
        
        page.drawText(`${multiText.lines[i].text}`, {
            x: posXText+2,
            y: startingPositon,
            size: fontSizeBigTitle,
            maxWidth: 400 - 70,
            font: fontBig,
            color: rgb(0, 0, 0),
        })
        startingPositon = startingPositon - (11)
    }
    startingPositon = startingPositon+11
    return page, PosYtext
  }
  async drawLineSubTilteTotal(page, text, totalText, yPos, fontBig, form){
    let XPos1 = 50;
    let XPos2 = 75;
    let XPos3 = 405;
    let XPos4 = 435;
    let XPos5 = 460;
    let XPos6 = 515;
    let posYTable = yPos+23
    let posY2Table = yPos-30
    let PosYtext = yPos-20
    let fontSizeBigTitle = 10;
    page = this.drawLinesIInPage(page, posYTable, posY2Table)
    let rightText = this.setTextRight(page, fontBig, fontSizeBigTitle)
    var startingPositon=PosYtext
    var multiText = layoutMultilineText(text, {
        alignment: 'right', 
        fontSize: fontSizeBigTitle,   
        font: fontBig,
        lineHeight: 12,
        bounds: { width: 400 - 80, height: 1000  }
    })
    
    for(let i = 0; i < multiText.lines.length; i++) {
        if(i == 0)
        rightText(`${multiText.lines[i].text}`, XPos2+10, startingPositon)
        else
        page.drawText(`${multiText.lines[i].text}`, {
            x: XPos2+10,
            y: startingPositon,
            size: fontSizeBigTitle,
            maxWidth: 400 - 70,
            font: fontBig,
            color: rgb(0, 0, 0),
        })
        startingPositon = startingPositon - (11)
    }
    startingPositon = startingPositon+11
    let textColor = [0, 0, 0]
    this.drawAlignedText({
      page: page, 
      x: XPos6-9, 
      y: PosYtext, 
      width: 53,
      align: 'center',
      text: this.to_fixed2LocaleStringEuro(totalText), 
      font: fontBig, 
      size: fontSizeBigTitle,
      textColor: textColor,
    })
    return page, PosYtext
  }
  async drawLineSubTilte(page, line, yPos, fontBig){
    let XPos1 = 50;
    let XPos2 = 75;
    let XPos3 = 405;
    let XPos4 = 435;
    let XPos5 = 460;
    let XPos6 = 510;
    let posYTable = yPos+23
    let posY2Table = yPos-30
    let PosYtext = yPos-20
    let fontSizeBigTitle = 11;
    page = this.drawLinesIInPage(page, posYTable, posY2Table)
    page.drawText(line[0], {
        x: XPos1,
        y: PosYtext,
        size: fontSizeBigTitle,
        font: fontBig,
        color: rgb(0, 0, 0) // Black color
    });
    var multiText = layoutMultilineText(line[1], {
        alignment: 'left', 
        fontSize: fontSizeBigTitle,   
        font: fontBig,
        lineHeight: 12,
        bounds: { width: 400 - 80, height: 1000  }
    })
    
    var startingPositon=PosYtext
    for(let i = 0; i < multiText.lines.length; i++) {
        
        page.drawText(`${multiText.lines[i].text}`, {
            x: XPos2-2,
            y: startingPositon,
            size: fontSizeBigTitle,
            maxWidth: 400 - 70,
            font: fontBig,
            color: rgb(0, 0, 0),
        })
        startingPositon = startingPositon - (11)
    }
    startingPositon = startingPositon+11
    return page, startingPositon
  }
  async drawLine(page, line, yPos, normalFont, form, i){
    let XPos1 = 50;
    let XPos2 = 75;
    let XPos3 = 405;
    let XPos4 = 435;
    let XPos5 = 465;
    let XPos6 = 510;
    let posYTable = yPos+23
    let posY2Table = yPos-30
    let PosYtext = yPos-24
    let fontSizeBigTitle = 10;
    page = this.drawLinesIInPage(page, posYTable, posY2Table)
    let textColor = [0, 0, 0]
    // number of line
    this.drawAlignedText({
      page: page,
      x: XPos1-14,
      y: PosYtext, 
      align: 'center', 
      text: `${line[0]}`,
      font: normalFont,
      size: fontSizeBigTitle,
      textColor: textColor,
      width: 33,
      height: 22,
    });
    
    var multiText = layoutMultilineText(line[1], {
        alignment: 'left', 
        fontSize: fontSizeBigTitle,   
        font: normalFont,
        lineHeight: 12,
        bounds: { width: 320, height: 1000  }
    })
    
    var startingPositon=PosYtext+7
    for(let i = 0; i < multiText.lines.length; i++) {
        
        page.drawText(`${multiText.lines[i].text}`, {
            x: 75,
            y: startingPositon,
            size: fontSizeBigTitle,
            maxWidth: 320,
            color: rgb(0, 0, 0),
        })
        startingPositon = startingPositon - (11)
    }
    startingPositon = startingPositon+11;
    // Qte
    this.drawAlignedText({
      page: page,
      x: XPos3-4,
      y: PosYtext, 
      align: 'center', 
      text: `${line[2]}`,
      font: normalFont,
      size: fontSizeBigTitle,
      textColor: textColor,
      width: 28,
      height: 22,
    });
    // Unite
    this.drawAlignedText({
      page: page,
      x: XPos4-4,
      y: PosYtext, 
      align: 'center', 
      text: `${line[3]}`,
      font: normalFont,
      size: fontSizeBigTitle,
      textColor: textColor,
      width: 28,
      height: 22,
    });
    // PUHT
    this.drawAlignedText({
      page: page,
      x: XPos5-4,
      y: PosYtext, 
      align: 'center', 
      text: `${line[4]}`,
      font: normalFont,
      size: fontSizeBigTitle,
      textColor: textColor,
      width: 43,
      height: 22,
    });
    // PUHT
    this.drawAlignedText({
      page: page,
      x: XPos6-4,
      y: PosYtext, 
      align: 'center', 
      text: `${line[5]}`,
      font: normalFont,
      size: fontSizeBigTitle,
      textColor: textColor,
      width: 53,
      height: 22,
    });
    return page, startingPositon
  }
  async testHeightMax(page, y, mergedPdf){
    let newPage = page
    let newy = 660
    let NewPageAdded = false
    if(y<130){
        // Load the PDF from the project folder
        const pdfPath = "/pdf/devis-borne-irve/suiteDevis.pdf";
        const response = await fetch(pdfPath);
        const arrayBuffer = await response.arrayBuffer();
        var pdfDoc = await PDFDocument.load(arrayBuffer);
        newy = 660;
        const copiedPages = await mergedPdf.copyPages(
        pdfDoc,
        pdfDoc.getPageIndices()
        )
        copiedPages.forEach((page) => {
        mergedPdf.addPage(page)
        })
        newPage = mergedPdf.getPages()[mergedPdf.getPageCount()-1]; // Get the first page
        NewPageAdded = true
    }else{
        newy = y
    }
    return [newPage, newy, mergedPdf, NewPageAdded]
  }
  async drawDevisTVARectangle(page, x, y, squareTVAWidth, squareTVAheight, pageSize, font, TotalHT, TVA, TotalTTC, percentTVA, percentBase, percentTotalTVA, form){
    TotalHT+=""
    TVA+=""
    TotalTTC+=""
    percentTVA+=""
    percentBase+=""
    percentTotalTVA+=""
    const fontSize1 = 9;
    const fontSize2 = 12;
    
    page.drawRectangle({
        x: x,
        y: y,
        width: squareTVAWidth,
        height: squareTVAheight,
        // color: rgb(0.94, 0.96, 0.97),
        borderColor: rgb(0.8, 0.8, 0.8),
        borderWidth: 1,
    });
  
    // square label
    const labelText = "Devis (EUR)";
    const heightOfLabelText = 8;
    const widthOfLabelText = font.widthOfTextAtSize(labelText, 8)
    page.drawRectangle({
        x: x + 8,
        y: y + squareTVAheight - 4,
        width: widthOfLabelText + 4,
        height: heightOfLabelText,
        color: rgb(1, 1, 1),
    });
    page.drawText(labelText+"",{
        x: x + 10,
        y: y + squareTVAheight - 2,
        font: font,
        size: 8,
        color: rgb(0, 0, 0),
        lineHeight: 8,
    });
  
    // Total H.T
    page.drawText("Total H.T",{
        x: x + 10,
        y: y + squareTVAheight - 17,
        font: font,
        size: fontSize1,
        color: rgb(0, 0, 0),
        lineHeight: fontSize1,
    });
    const TotalHTWithUnit = this.to_fixed2LocaleStringEuro(TotalHT + "");
    const widthOfTotalHTText = font.widthOfTextAtSize(TotalHTWithUnit, fontSize1);
    page.drawText(TotalHTWithUnit,{
        x: x + squareTVAWidth - widthOfTotalHTText - 10,
        y: y + squareTVAheight - 17,
        font: font,
        size: fontSize1,
        color: rgb(0, 0, 0),
        lineHeight: fontSize1,
    });
  
    // TVA
    page.drawText("TVA",{
        x: x + 10,
        y: y + squareTVAheight - 30,
        font: font,
        size: fontSize1,
        color: rgb(0, 0, 0),
        lineHeight: fontSize1,
    });
    
    const TVAWithUnit = this.to_fixed2LocaleStringEuro(TVA + "");
    const widthOfTVAText = font.widthOfTextAtSize(TVAWithUnit, fontSize1);
    page.drawText(TVAWithUnit,{
        x: x + squareTVAWidth - widthOfTVAText - 10,
        y: y + squareTVAheight - 30,
        font: font,
        size: fontSize1,
        color: rgb(0, 0, 0),
        lineHeight: fontSize1,
    });
  
    // Total T.T.C
    page.drawText("Total T.T.C",{
        x: x + 10,
        y: y + squareTVAheight - 45,
        font: font,
        size: fontSize2,
        color: rgb(0, 0, 0),
        lineHeight: fontSize2,
    });
    const TotalTTCWithUnit = this.to_fixed2LocaleStringEuro(TotalTTC + "");
    const widthOfTotalTTCText = font.widthOfTextAtSize(TotalTTCWithUnit, fontSize2);
    page.drawText(TotalTTCWithUnit,{
        x: x + squareTVAWidth - widthOfTotalTTCText - 10,
        y: y + squareTVAheight - 45,
        font: font,
        size: fontSize2,
        color: rgb(0, 0, 0),
        lineHeight: fontSize2,
    });
  
    // *****table******
    const rowHeigth = 15;
    var XfirstInput = x + 10
    var YfirstInput1 =  y + squareTVAheight - rowHeigth - 56
    var YfirstInput2 =  y + squareTVAheight - (2*rowHeigth) - 56
    var widthInput = (squareTVAWidth - 20)/3
    // headers
    this.drawAlignedText({page: page,x: XfirstInput,y: YfirstInput1, align: 'center', text: "% TVA",font: font,size: 10,textColor: [0,0,0],width: widthInput,height: rowHeigth,borderColor: [0.8, 0.8, 0.8],borderWidth: 1,});
    this.drawAlignedText({page: page,x: XfirstInput+widthInput, y: YfirstInput1, align: 'center', text: "Base",font: font,size: 10,textColor: [0,0,0],width: widthInput,height: rowHeigth,borderColor: [0.8, 0.8, 0.8],borderWidth: 1,});
    this.drawAlignedText({page: page,x: XfirstInput+(widthInput*2), y: YfirstInput1, align: 'center', text: "Total TVA",font: font,size: 10,textColor: [0,0,0],width: widthInput,height: rowHeigth,borderColor: [0.8, 0.8, 0.8],borderWidth: 1,});
    // values
    this.drawAlignedText({page: page,x: XfirstInput,y: YfirstInput2, align: 'center', text: `${percentTVA}`,font: font,size: 10,textColor: [0,0,0],width: widthInput,height: rowHeigth,borderColor: [0.8, 0.8, 0.8],borderWidth: 1,});
    this.drawAlignedText({page: page,x: XfirstInput+widthInput, y: YfirstInput2, align: 'center', text: `${this.to_fixed2OrText(percentBase)}`,font: font,size: 10,textColor: [0,0,0],width: widthInput,height: rowHeigth,borderColor: [0.8, 0.8, 0.8],borderWidth: 1,});
    this.drawAlignedText({page: page,x: XfirstInput+(widthInput*2), y: YfirstInput2, align: 'center', text: `${this.to_fixed2OrText(percentTotalTVA)}`,font: font,size: 10,textColor: [0,0,0],width: widthInput,height: rowHeigth,borderColor: [0.8, 0.8, 0.8],borderWidth: 1,});

  }
  
  setTextRight(page, font, size){
    return (text, x, y) => {
        const widthText = font.widthOfTextAtSize(text, size)
        x = x + 300
        page.drawText(`${text}`, {
            x: x - widthText,
            y: y,
            size: size,
            maxWidth: 400 - 70,
            font: font,
            color: rgb(0, 0, 0),
        })
    }
  }
  async drawAlignedText({page, text, x= 0, y= 0, font, size, align, textColor= [0, 0, 0], width=0, height=0, borderWidth=0, borderColor}){
    const widthText = font.widthOfTextAtSize(text, size);
    const heightText = font.heightAtSize(size) - 2;
    // calculate center position
    let xPosition = x + width / 2;
    if(align == 'center') xPosition = xPosition - widthText/2;
    if(align == 'left')  xPosition = x;
    // draw rectangle
    if(width>0 && borderWidth>0){
      let rectProps = {
        x: x,
        y: y,
        width: width,
        height: height>0 ? height : heightText,
        borderWidth: borderWidth,
      }
      if(borderColor) rectProps.borderColor = rgb(borderColor[0], borderColor[1], borderColor[2]);
      page.drawRectangle(rectProps);
    }
    if(height>0) y = y + (height/2) - (heightText/2)
    // draw text
    page.drawText(text, {
      x: xPosition,
      y: y,
      size: size,
      font: font,
      color: rgb(textColor[0], textColor[1], textColor[2]),
    });
  }
  async drawMultiLineText({page, text, x= 0, y= 0, font, size, align, textColor= [0, 0, 0], width=0, height=0, borderWidth=0, borderColor}){
    // Prepare for multi-line text layout
    const multiText = layoutMultilineText(text, {
        alignment: align || 'left',
        fontSize: size,
        font: font,
        lineHeight: size * 1.2, // Line height is often 120% of font size
        bounds: { width: width, height: 1000 } // Set a very high height to ensure all text is considered
    });

    // Calculate the total height of the text content
    const textContentHeight = multiText.lines.length * size * 1.2;

    // Adjust vertical starting position based on the total height of the laid out text
    let startingPosition = y;
    startingPosition = y + (height > 0 ? (height - textContentHeight) / 2 : 0) + textContentHeight - size * 1.2;
    
    // Draw border rectangle if required
    if (width > 0 && borderWidth > 0) {
        let rectProps = {
            x: x,
            y: y,
            width: width,
            height: height > 0 ? height : textContentHeight,
            borderWidth: borderWidth,
        };
        if (borderColor) rectProps.borderColor = rgb(borderColor[0], borderColor[1], borderColor[2]);
        page.drawRectangle(rectProps);
    }

    // Draw each line of text
    multiText.lines.forEach((line) => {
        let lineX = x;
        if (align === 'center') lineX += (width - font.widthOfTextAtSize(line.text, size)) / 2;
        if (align === 'right') lineX += width - font.widthOfTextAtSize(line.text, size);

        page.drawText(line.text, {
            x: lineX,
            y: startingPosition,
            size: size,
            maxWidth: width,
            color: rgb(textColor[0], textColor[1], textColor[2]),
        });
        startingPosition -= size * 1.2; // Move down for the next line
    });
  }

  // formatters
  capitalizeWords(text) {
    if(!text) return "";
    // Split the text into an array of words
    let words = text.split(' ');
    // Iterate through each word and capitalize the first letter
    for (let i = 0; i < words.length; i++) {
        words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
    }
    // Join the words back into a single string and return
    return words.join(' ');
  }
  to_fixed2LocaleStringEuro(num) {
    if(!isNaN(num) && num !== null && num !== undefined && num !== ""){
        // num is number
        if(num == 0) return "0";
        return Number(parseFloat(num).toFixed(2)).toLocaleString("fr-FR").replace(/\u202F/g, ' ').toString() + " €";
    }else{
        // num is text
        if(num !== null && num !== undefined && num !== ""){
            return num
        }
        return ""
    }
  }
  to_fixed2OrText(num) {
    if(!isNaN(num) && num !== null && num !== undefined && num !== ""){
        // num is number
        return Number(parseFloat(num).toFixed(2)).toLocaleString("fr-FR").replace(/\u202F/g, ' ').toString() + "";
    }else{
        // num is text
        if(num !== null && num !== undefined && num !== ""){
            return num
        }
        return ""
    }
  }
}

export default PDFDevisBorneIRVEGenerator;