import dayjs from "dayjs";
import "dayjs/locale/en"; 
import 'dayjs/locale/fr';
import 'dayjs/locale/it';
import 'dayjs/locale/es';

function getBrowserLocales(options = {}) {
  const defaultOptions = {
    languageCodeOnly: false,
  };
  const opt = {
    ...defaultOptions,
    ...options,
  };
  const browserLocales =
    navigator.languages === undefined
      ? [navigator.language]
      : navigator.languages;
  if (!browserLocales) {
    return undefined;
  }
  return browserLocales.map((locale) => {
    const trimmedLocale = locale.trim();
    return opt.languageCodeOnly
      ? trimmedLocale.split(/-|_/)[0]
      : trimmedLocale;
  });
}

const locales = getBrowserLocales({ languageCodeOnly: true });
const locale = locales ? locales[0] : "en";

dayjs.locale(locale);

export default dayjs;
