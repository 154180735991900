<template>
  <div class="p-4">
    <div class="shadow-sm">
        <breadcrumb :listNavs="listNavs"/>
    </div>
    <v-card class="p-4">
      <h4 class="header-title">Notifications</h4>
      <div class="notifications-wrapper">
        <div class="notif-item" v-for="(item,index) in notificationItems">
          <div class="notif-content">
            <p class="notif-title">{{ item.title }}</p>
            <p class="notif-info">{{ item.description }}</p>
          </div>
          <p class="notif-date">{{ item.date }}</p>
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
import axios from "@/plugins/axios";
import breadcrumb from "@/components/breadcrumb.vue";


export default {
  name: "Notifications",
  components: {
    breadcrumb,
  },
  data() {
    return {
      listNavs : [
        {
          name: "Accueil",
          link: "/",
        },
        {
          name: "Notifications",
          link: "/notifications",
        },
      ],
      notificationItems: [
        {
          title: "Notification 1",
          description: "Description 1",
          date: "2021-05-05",
        },
        {
          title: "Notification 2",
          description: "Description 2",
          date: "2021-05-05",
        },
        {
          title: "Notification 3",
          description: "Description 3",
          date: "2021-05-05",
        },
      ],
    };
  },
}
</script>

<style scoped>
.header-title{
  font-size:20px;
  font-weight: 700;
  color: #03776d;
  margin-bottom: 20px;
}
.notifications-wrapper{
  display: flex;
  flex-direction: column;
}
.notif-item{
  border-radius: 4px;
  padding:15px;
  background-color:#eeeeee;
  margin-bottom: 8px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
}
.notif-title{
  font-size:14px;
  font-weight:600;
  margin-bottom: 8px;
}
.notif-info{
  font-size:12px;
  font-weight:400;
  margin-bottom: 0px;
}
.notif-date{
  font-size:11px;
  font-weight:400;
  color: #8b8b8b;
  margin-bottom: 0px;
}
</style>